var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Số tiền cho tài xế giới thiệu",
                prop: "rewardParent",
              },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.rewardParent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rewardParent", $$v)
                  },
                  expression: "form.rewardParent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Số tiền cho khách hàng giới thiệu",
                prop: "rewardCustomer",
              },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.rewardCustomer,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rewardCustomer", $$v)
                  },
                  expression: "form.rewardCustomer",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Số tiền cho cửa hàng giới thiệu",
                prop: "rewardStore",
              },
            },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.rewardStore,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rewardStore", $$v)
                  },
                  expression: "form.rewardStore",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }