var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "noHeader",
      attrs: {
        title: "",
        visible: _vm.visible,
        fullscreen: "",
        top: "0",
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "custom-btn",
              attrs: { type: "primary", size: "default" },
              on: {
                click: function ($event) {
                  _vm.drawer = true
                },
              },
            },
            [_vm._v("Chỉnh sửa")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "custom-container", attrs: { gutter: 24 } },
        [
          _c(
            "el-col",
            {
              staticStyle: { padding: "0", overflow: "hidden" },
              attrs: { span: 19, offset: 0 },
            },
            [
              _c("div", { staticClass: "top-navbar" }, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#fade2f",
                        "margin-right": "5px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "auto", height: "60px" },
                        attrs: { src: "/images/logo_rectangle.png", alt: "" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "deep-blue",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "flex-end",
                      },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticStyle: {
                            margin: "5px 0",
                            "font-size": "15px",
                            "font-weight": "900",
                          },
                        },
                        [_vm._v("DRAWS "), _c("br"), _vm._v("Bốc thăm")]
                      ),
                    ]
                  ),
                ]),
                _c(
                  "h1",
                  {
                    staticClass: "deep-blue",
                    staticStyle: { margin: "5px 0" },
                  },
                  [_vm._v("BỐC THĂM NGẪU NHIÊN ĐIỆN TỬ")]
                ),
              ]),
              _vm.prizeData
                ? _c(
                    "el-row",
                    { staticClass: "box-container", attrs: { gutter: 12 } },
                    [
                      _c("el-col", { attrs: { span: 14, offset: 0 } }, [
                        _c(
                          "h1",
                          {
                            staticClass: "text-center title",
                            staticStyle: { "font-size": "3.5rem" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "text-white border-black" },
                              [_vm._v("WIN")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  margin: "0",
                                  "font-size": "25px",
                                  color: "#b9b9b9",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.idealPrizeTypeTrans[_vm.prizeData.type]
                                      .title
                                  ) + ": "
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.prizeData.used + 1)),
                                ]),
                                _vm._v("/" + _vm._s(_vm.prizeData.quantity)),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "text-yellow border-black font" },
                              [_vm._v(_vm._s(_vm.prizeData.name))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#AFDBFF",
                              "font-weight": "800",
                              "margin-left": "40px",
                            },
                          },
                          [
                            _c("p", { staticStyle: { "font-size": "20px" } }, [
                              _vm._v("Trong Chiến Dịch Mua: "),
                              _c(
                                "b",
                                { staticStyle: { "font-weight": "900" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.prizeData.multiIdealProduct.name)
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticStyle: { "font-size": "20px" } }, [
                              _vm._v("Được Bốc Thăm Ngày: "),
                              _c(
                                "b",
                                { staticStyle: { "font-weight": "900" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.moment().format("DD/MM/YYYY"))
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticStyle: { "font-size": "20px" } }, [
                              _vm._v("Người bốc thăm: "),
                              _c(
                                "b",
                                { staticStyle: { "font-weight": "900" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.drawnData.staff
                                        ? _vm.drawnData.staff
                                        : "--"
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticStyle: { "font-size": "20px" } }, [
                              _c(
                                "span",
                                { staticStyle: { color: "transparent" } },
                                [_vm._v("Người bốc thăm:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b",
                                { staticStyle: { "font-weight": "900" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.drawnData.title
                                        ? _vm.drawnData.title
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("p", { staticStyle: { "font-size": "20px" } }, [
                              _c(
                                "span",
                                { staticStyle: { color: "transparent" } },
                                [_vm._v("Người bốc thăm:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b",
                                { staticStyle: { "font-weight": "900" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.drawnData.branch
                                        ? _vm.drawnData.branch
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 10, offset: 0 } }, [
                        _c("img", {
                          staticStyle: {
                            width: "100%",
                            "object-fit": "contain",
                            "margin-bottom": "2em",
                            "max-height": "500px",
                          },
                          attrs: { src: _vm.prizeData.imageUrl, alt: "" },
                        }),
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            position: "relative",
                          },
                          attrs: { span: 24, offset: 0 },
                        },
                        [
                          !_vm.started
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                    position: "absolute",
                                    left: "100px",
                                    top: "50%",
                                    cursor: "pointer",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                  on: { click: _vm.handleStartDraw },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "90px",
                                      height: "90px",
                                    },
                                    attrs: {
                                      src: "/images/draw-btn.png",
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticStyle: { "min-height": "52px" } }, [
                            _vm.showInfo && _vm.result[0].idealCode.customer
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-white color-white-large text-center",
                                    class: _vm.showInfo
                                      ? "zoomEffectClass"
                                      : "",
                                    staticStyle: {
                                      "font-size": "45px",
                                      margin: "0",
                                      "margin-top": "0",
                                    },
                                  },
                                  [
                                    _vm._v("Chúc mừng khách hàng: "),
                                    _c("span", { staticClass: "text-yellow" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showInfo
                                            ? _vm.result[0].idealCode.customer
                                                .name
                                            : "--"
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm.showInfo &&
                                !_vm.result[0].idealCode.customer
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-white color-white-large text-center",
                                    staticStyle: {
                                      "font-size": "45px",
                                      margin: "0",
                                      "margin-top": "2em",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Không có khách hàng nào trúng thưởng"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              class: _vm.started
                                ? "code-wrap started"
                                : "code-wrap",
                              staticStyle: { "margin-top": "-10px" },
                            },
                            [
                              _c("br"),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-white",
                                      staticStyle: {
                                        "word-break": "keep-all",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [_vm._v("Thăm số: ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "code" }, [
                                        _c(
                                          "div",
                                          [
                                            _vm.result.length && _vm.started
                                              ? _c("elapse-string", {
                                                  attrs: {
                                                    text: _vm.result[0]
                                                      .idealCode.code,
                                                  },
                                                  on: {
                                                    textClick:
                                                      _vm.handleGotoPage,
                                                    onDrawed: _vm.onDrawed,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.showInfo &&
                              _vm.result[0].idealCode.idealOrderDetail
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        color: "rgb(155, 207, 250)",
                                        "font-size": "17px",
                                        "font-weight": "900",
                                        margin: "4px 0 12px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Đã mua hàng lúc: " +
                                          _vm._s(
                                            _vm.showInfo
                                              ? _vm.$formatDateTime(
                                                  _vm.result[0].idealCode
                                                    .idealOrderDetail
                                                    .dateCreated,
                                                  "HH:mm"
                                                ) +
                                                  ", ngày " +
                                                  _vm.$formatDateTime(
                                                    _vm.result[0].idealCode
                                                      .idealOrderDetail
                                                      .dateCreated,
                                                    "DD/MM/YYYY"
                                                  )
                                              : "--"
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.showInfo &&
                          _vm.result[0].idealCode.idealOrderDetail
                            ? _c(
                                "h1",
                                {
                                  staticClass:
                                    "text-center text-white color-white-large",
                                  staticStyle: {
                                    "font-size": "45px",
                                    margin: "0px",
                                  },
                                },
                                [_vm._v("ĐÃ TRÚNG THƯỞNG")]
                              )
                            : _vm._e(),
                          _vm.showInfo &&
                          _vm.result[0].idealCode.idealOrderDetail
                            ? _c(
                                "p",
                                {
                                  staticClass: "text-center",
                                  staticStyle: {
                                    color: "#9bcffa",
                                    "font-size": "17px",
                                    "font-weight": "800",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Bốc Thăm Điện Tử Lúc: " +
                                      _vm._s(_vm.drawedAt)
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isMute
                ? _c(
                    "div",
                    {
                      staticClass: "mute-btn",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        position: "absolute",
                        left: "10px",
                        bottom: "10px",
                        cursor: "pointer",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                      on: { click: _vm.handleMute },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/images/unmute.svg", alt: "" },
                      }),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "mute-btn",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        position: "absolute",
                        left: "10px",
                        bottom: "10px",
                        cursor: "pointer",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                      on: { click: _vm.handleMute },
                    },
                    [_c("img", { attrs: { src: "/images/mute.svg", alt: "" } })]
                  ),
              _vm.checkBocThamHost()
                ? _c(
                    "div",
                    {
                      staticClass: "mute-btn ",
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        position: "absolute",
                        left: "60px",
                        bottom: "10px",
                        cursor: "pointer",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                      on: { click: _vm.logout },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/images/logout.svg", alt: "" },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticClass: "code-table",
              staticStyle: { position: "relative" },
              attrs: { span: 5, offset: 0 },
            },
            [
              _c(
                "h3",
                {
                  staticStyle: {
                    "text-align": "center",
                    "text-transform": "uppercase",
                    color: "#f34d21",
                    "font-size": "19px",
                  },
                },
                [
                  _vm._v(
                    "Danh sách " + _vm._s(_vm.totalIdealCodeList) + " thăm "
                  ),
                  _c("br"),
                  _vm._v(" của chiến dịch này"),
                ]
              ),
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-bottom": "0.5em",
                    float: "right",
                    "margin-right": "10px",
                  },
                  attrs: {
                    disabled: _vm.started && !_vm.showInfo,
                    size: "small",
                  },
                  on: { click: _vm.handleNewDraw },
                },
                [_vm._v("Bốc thăm chiến dịch khác")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-bottom": "1em",
                    float: "right",
                    "margin-right": "10px",
                  },
                  attrs: {
                    disabled: !_vm.showInfo,
                    size: "small",
                    type: "danger",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.beforeDrawOtherPrize },
                },
                [_vm._v("Lượt bốc thăm mới")]
              ),
              _c("el-input", {
                staticClass: "search-input",
                staticStyle: { "background-color": "#f4f4f4", border: "none" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm mã thăm dự thưởng",
                  size: "normal",
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return (function () {
                      _vm.page = 1
                      _vm.getIdealCodeList()
                    })($event)
                  },
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "#fff",
                    padding: "0",
                    "border-radius": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c("pagination", {
                        attrs: {
                          layout: "prev, pager, next",
                          total: _vm.totalIdealCodeList,
                          page: _vm.page,
                          limit: 100,
                          "pager-count": 3,
                        },
                        on: {
                          "update:page": function ($event) {
                            _vm.page = $event
                          },
                          pagination: _vm.onPagination,
                        },
                      }),
                      _vm.no
                        ? _c("div", { staticClass: "badge-white" }, [
                            _vm._v(" No: " + _vm._s(_vm.no) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      ref: "resultTable",
                      staticClass: "custom-table",
                      staticStyle: { "margin-top": "0" },
                      attrs: { id: "resultTable", data: _vm.idealCodeList },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "#", width: "50" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.no) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "date",
                          label: "Danh sách các thăm",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "div",
                                  {
                                    class:
                                      _vm.result.length &&
                                      row.code ==
                                        _vm.result[0].idealCode.code &&
                                      _vm.showInfo
                                        ? "text-yellow zoomEffectClass winner-code"
                                        : "",
                                  },
                                  [_vm._v(_vm._s(row.code))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "Các giải thưởng khác",
            visible: _vm.visibleRecommendDraw,
            width: "30%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleRecommendDraw = $event
            },
          },
        },
        [
          _c(
            "p",
            {
              staticClass: "text-center",
              staticStyle: { "font-weight": "bold" },
            },
            [
              _vm._v(
                "Chiến dịch này vẫn còn các giải thưởng khác. Bấm chọn danh sách giải thưởng bên dưới để tiếp tiếp tục lượt bốc thăm mới!"
              ),
            ]
          ),
          _vm._l(
            _vm.prizeList.filter(function (e) {
              return e.quantity > e.used
            }),
            function (prize) {
              return _c(
                "div",
                {
                  key: prize.id,
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    cursor: "pointer",
                    background: "#efefef",
                    padding: "1em",
                    "border-radius": "10px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      "border-radius": "10px",
                      "margin-right": "15px",
                      "object-fit": "cover",
                    },
                    attrs: {
                      width: "150px",
                      height: "auto",
                      src: prize.imageUrl,
                      alt: "",
                    },
                  }),
                  _c("div", { staticStyle: { width: "100%" } }, [
                    _c("h2", [_vm._v(_vm._s(prize.name))]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                          width: "100%",
                        },
                      },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s(prize.used) + "/" + _vm._s(prize.quantity)
                          ),
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function () {
                                return _vm.handleDrawOtherPrize(prize.id)
                              },
                            },
                          },
                          [_vm._v("Chọn giải thưởng")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function () {
                      return (_vm.visibleRecommendDraw = false)
                    },
                  },
                },
                [_vm._v("Ngưng bốc thăm")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }