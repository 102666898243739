"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scholarshipApi = void 0;
var _request = require("@/utils/request");
var scholarshipApi = exports.scholarshipApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/scholarship',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/scholarship',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/scholarship/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/scholarship/".concat(id),
      method: 'delete'
    });
  },
  highlight: function highlight(id, data) {
    return (0, _request.request)({
      url: "/scholarship/".concat(id, "/highlight"),
      method: 'patch',
      data: data
    });
  }
};