var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "employer-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.type == _vm.CareerType.Customer
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Hình chân dung", prop: "avatar" } },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.avatar,
                              width: 150,
                              height: 150,
                            },
                            on: { "upload:success": _vm.handleUploadSuccess },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Cấp bậc", prop: "careerLevelId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                remote: true,
                                "remote-method": _vm.fetchCareerLevel,
                              },
                              model: {
                                value: _vm.form.careerLevelId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "careerLevelId", $$v)
                                },
                                expression: "form.careerLevelId",
                              },
                            },
                            _vm._l(_vm.careerLevels, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Ngành nghề",
                            prop: "careerCategoryId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                remote: true,
                                "remote-method": _vm.fetchCareerCategory,
                              },
                              model: {
                                value: _vm.form.careerCategoryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "careerCategoryId", $$v)
                                },
                                expression: "form.careerCategoryId",
                              },
                            },
                            _vm._l(_vm.careerCategories, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.CareerType.Company
                            ? "Hình thức làm việc"
                            : "Tìm việc",
                        prop: "workTypeIds",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: true,
                            filterable: "",
                            remote: true,
                            "remote-method": _vm.fetchWorkType,
                            "multiple-limit":
                              _vm.type == _vm.CareerType.Company ? 0 : 1,
                          },
                          model: {
                            value: _vm.form.workTypeIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "workTypeIds", $$v)
                            },
                            expression: "form.workTypeIds",
                          },
                        },
                        _vm._l(_vm.workTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == _vm.CareerType.Customer
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Tìm việc tại Thành phố",
                            prop: "cityId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                disabled: _vm.formDisable,
                              },
                              on: { change: _vm.handleChangeCity },
                              model: {
                                value: _vm.form.cityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "cityId", $$v)
                                },
                                expression: "form.cityId",
                              },
                            },
                            _vm._l(_vm.cities, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.nameWithType,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Customer
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Tìm việc tại Quận huyện",
                            prop: "districtId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                disabled: _vm.formDisable,
                              },
                              on: { change: _vm.handleChangeDistrict },
                              model: {
                                value: _vm.form.districtId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "districtId", $$v)
                                },
                                expression: "form.districtId",
                              },
                            },
                            _vm._l(_vm.districts, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.nameWithType,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.CareerType.Company
                            ? "Địa chỉ"
                            : "Địa chỉ thường trú",
                        prop: "address",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == _vm.CareerType.Customer
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("AddressForm", {
                        attrs: {
                          form: _vm.form,
                          formDisable: _vm.formDisable,
                          cityFieldName: "residentCityId",
                          districtFieldName: "residentDistrictId",
                          wardFieldName: "residentWardId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Thành phố", prop: "cityId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                disabled: _vm.formDisable,
                              },
                              on: { change: _vm.handleChangeCity },
                              model: {
                                value: _vm.form.cityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "cityId", $$v)
                                },
                                expression: "form.cityId",
                              },
                            },
                            _vm._l(_vm.cities, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.nameWithType,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Quận huyện", prop: "districtId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                disabled: _vm.formDisable,
                              },
                              on: { change: _vm.handleChangeDistrict },
                              model: {
                                value: _vm.form.districtId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "districtId", $$v)
                                },
                                expression: "form.districtId",
                              },
                            },
                            _vm._l(_vm.districts, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.nameWithType,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Phường xã", prop: "wardId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                disabled: _vm.formDisable,
                              },
                              model: {
                                value: _vm.form.wardId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "wardId", $$v)
                                },
                                expression: "form.wardId",
                              },
                            },
                            _vm._l(_vm.wards, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.nameWithType,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trình độ", prop: "careerDegreeIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: true,
                            filterable: "",
                            remote: true,
                            "remote-method": _vm.fetchCareerDegree,
                            "multiple-limit":
                              _vm.type == _vm.CareerType.Company ? 0 : 1,
                          },
                          model: {
                            value: _vm.form.careerDegreeIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "careerDegreeIds", $$v)
                            },
                            expression: "form.careerDegreeIds",
                          },
                        },
                        _vm._l(_vm.careerDegrees, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Giới tính yêu cầu", prop: "gender" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null },
                          }),
                          _vm._l(_vm.Gender, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: {
                                label: _vm.GenderTrans[item],
                                value: item,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày tháng năm sinh", prop: "dob" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "Chọn một ngày",
                          format: "dd/MM/yyyy",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.dob,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dob", $$v)
                          },
                          expression: "form.dob",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Độ tuổi từ", prop: "fromAge" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.form.fromAge,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fromAge", $$v)
                              },
                              expression: "form.fromAge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Đến", prop: "toAge" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.form.toAge,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "toAge", $$v)
                              },
                              expression: "form.toAge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mức lương", prop: "wageType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.wageType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "wageType", $$v)
                                },
                                expression: "form.wageType",
                              },
                            },
                            _vm._l(
                              Object.values(_vm.careerWageTypeTrans),
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.title,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.wageType == _vm.CareerWageType.Range
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mức lương từ", prop: "fromMoney" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.form.fromMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fromMoney", $$v)
                              },
                              expression: "form.fromMoney",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.wageType &&
              _vm.form.wageType != _vm.CareerWageType.Custom
                ? _c(
                    "el-col",
                    {
                      attrs: {
                        span:
                          _vm.form.wageType == _vm.CareerWageType.Fixed
                            ? 12
                            : 6,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.form.wageType == _vm.CareerWageType.Fixed
                                ? "Nhập mức lương"
                                : "Đến",
                            prop: "toMoney",
                          },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.form.toMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "toMoney", $$v)
                              },
                              expression: "form.toMoney",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Kinh nghiệm",
                        prop: "careerYearConfigId",
                      },
                    },
                    [
                      _vm.type == _vm.CareerType.Company
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                remote: true,
                                "remote-method": _vm.fetchCareerYearConfig,
                              },
                              model: {
                                value: _vm.form.careerYearConfigId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "careerYearConfigId", $$v)
                                },
                                expression: "form.careerYearConfigId",
                              },
                            },
                            _vm._l(_vm.careerYearConfigs, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          )
                        : _c("el-input", {
                            model: {
                              value: _vm.form.experienceYear,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "experienceYear", $$v)
                              },
                              expression: "form.experienceYear",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày đăng", prop: "dateCreated" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "Chọn một ngày",
                          format: "dd/MM/yyyy",
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.dateCreated,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dateCreated", $$v)
                          },
                          expression: "form.dateCreated",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Thời gian hết hạn", prop: "expiredAt" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "Chọn một ngày",
                          format: "dd/MM/yyyy",
                        },
                        model: {
                          value: _vm.form.expiredAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expiredAt", $$v)
                          },
                          expression: "form.expiredAt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Nhà tuyển dụng",
                            prop: "employerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                remote: true,
                                "remote-method": _vm.fetchEmployer,
                              },
                              model: {
                                value: _vm.form.employerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "employerId", $$v)
                                },
                                expression: "form.employerId",
                              },
                            },
                            _vm._l(_vm.employers, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Tên người liên hệ",
                        prop: "contactName",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.contactName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contactName", $$v)
                          },
                          expression: "form.contactName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Chiết khấu", prop: "moneyCommission" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.moneyCommission,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "moneyCommission", $$v)
                          },
                          expression: "form.moneyCommission",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == _vm.CareerType.Customer
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Thời hạn hợp đồng",
                            prop: "contractYear",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.contractYear,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractYear", $$v)
                              },
                              expression: "form.contractYear",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Tiêu đề tin tuyển dụng", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Số lượng ứng tuyển",
                            prop: "numPeople",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.form.numPeople,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "numPeople", $$v)
                              },
                              expression: "form.numPeople",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Vị trí tuyển dụng",
                            prop: "workPosition",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.workPosition,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "workPosition", $$v)
                              },
                              expression: "form.workPosition",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mô tả công việc", prop: "desc" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "2000",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "desc", $$v)
                              },
                              expression: "form.desc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Yêu cầu", prop: "requirement" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "2000",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.requirement,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "requirement", $$v)
                              },
                              expression: "form.requirement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Phúc lợi", prop: "benefit" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "2000",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.benefit,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "benefit", $$v)
                              },
                              expression: "form.benefit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == _vm.CareerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Kinh nghiệm / Kỹ năng chi tiết",
                            prop: "skill",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              maxlength: "2000",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.skill,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "skill", $$v)
                              },
                              expression: "form.skill",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }