var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "create" ? "Thêm mới cửa hàng" : "Cập nhật cửa hàng",
        visible: _vm.visible,
        fullscreen: "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _vm.status == "create" ||
          (_vm.status == "update" &&
            _vm.form.profileStatus == _vm.ProfileStoreStatus.Approve)
            ? [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "Thông tin", name: "info" } },
                  [
                    _vm.status == "update"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-document-copy",
                              size: "small",
                            },
                            on: { click: _vm.handleClone },
                          },
                          [_vm._v("Sao chép thông tin từ cửa hàng hàng này")]
                        )
                      : _vm._e(),
                    _c("mart-info", {
                      ref: "martInfo",
                      attrs: {
                        form: _vm.form,
                        formStatus: _vm.status,
                        storeRecommends: _vm.storeRecommends,
                        marker: _vm.marker,
                        "form-disable": _vm.formDisable,
                      },
                    }),
                  ],
                  1
                ),
                _vm.status == "update"
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          lazy: "",
                          label: "Lịch sử giao dịch",
                          name: "transaction",
                        },
                      },
                      [
                        _c("transaction-store", {
                          attrs: { storeId: _vm.form.id },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.status == "update" && _vm.visiblePartnerConfig
                  ? _c(
                      "el-tab-pane",
                      { attrs: { label: "Đối tác", name: "partner" } },
                      [
                        _c("mart-partner", {
                          ref: "martPartner",
                          attrs: {
                            form: _vm.form,
                            "form-disable": _vm.formDisable,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.status == "update"
                  ? _c(
                      "el-tab-pane",
                      { attrs: { name: "store-rate", label: "Đánh giá" } },
                      [
                        _c("store-rate", {
                          attrs: { storeId: _vm.form.id, store: _vm.form },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.status == "update"
                  ? _c(
                      "el-tab-pane",
                      { attrs: { name: "menufood", label: "Nhóm sản phẩm" } },
                      [
                        _c("menu-food", {
                          attrs: {
                            storeId: _vm.form.id,
                            disable: _vm.formDisable,
                          },
                          on: {
                            "update:storeId": function ($event) {
                              return _vm.$set(_vm.form, "id", $event)
                            },
                            "update:store-id": function ($event) {
                              return _vm.$set(_vm.form, "id", $event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.status == "update"
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: { name: "food", label: "Tên thức ăn/thực phẩm" },
                      },
                      [
                        _c("food", {
                          attrs: {
                            storeId: _vm.form.id,
                            disable: _vm.formDisable,
                          },
                          on: {
                            "update:storeId": function ($event) {
                              return _vm.$set(_vm.form, "id", $event)
                            },
                            "update:store-id": function ($event) {
                              return _vm.$set(_vm.form, "id", $event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Hồ sơ đăng ký", name: "profile" } },
                [
                  _c("store-profile-sign-up", {
                    attrs: {
                      onApprove: _vm.handleApprove,
                      form: _vm.form,
                      onUpdate: _vm.updateData,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.tabName == "info" || _vm.tabName == "partner"
        ? _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              (!_vm.formDisable &&
                _vm.form.profileStatus == _vm.ProfileStoreStatus.Approve) ||
              _vm.status == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loadingButton, type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.status == "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("Lưu")]
                  )
                : _vm._e(),
              _vm.form.profileStatus == _vm.ProfileStoreStatus.Pending
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loadingButton },
                      on: { click: _vm.handleApprove },
                    },
                    [_vm._v(" Duyệt ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("clone-store-modal", { ref: "CloneStoreModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }