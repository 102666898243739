var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleChangeStatus },
          model: {
            value: _vm.agentOrderQuery.status,
            callback: function ($$v) {
              _vm.$set(_vm.agentOrderQuery, "status", $$v)
            },
            expression: "agentOrderQuery.status",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "all" } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Tất cả "),
            ]),
          ]),
          _c(
            "el-tab-pane",
            { attrs: { name: _vm.AgentOrderStatus.PendingPayment } },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.AgentOrderStatusTrans[
                        _vm.AgentOrderStatus.PendingPayment
                      ].label
                    ) + " "
                  ),
                  _c("el-badge", {
                    attrs: {
                      value: _vm.summary.PendingPayment,
                      type: "primary",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("el-tab-pane", { attrs: { name: _vm.AgentOrderStatus.Pending } }, [
            _c(
              "span",
              { attrs: { slot: "label" }, slot: "label" },
              [
                _vm._v(
                  _vm._s(
                    _vm.AgentOrderStatusTrans[_vm.AgentOrderStatus.Pending]
                      .label
                  ) + " "
                ),
                _c("el-badge", {
                  attrs: { value: _vm.summary.Pending, type: "primary" },
                }),
              ],
              1
            ),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.AgentOrderStatus.Confirm } }, [
            _c(
              "span",
              { attrs: { slot: "label" }, slot: "label" },
              [
                _vm._v(
                  _vm._s(
                    _vm.AgentOrderStatusTrans[_vm.AgentOrderStatus.Confirm]
                      .label
                  ) + " "
                ),
                _c("el-badge", {
                  attrs: { value: _vm.summary.Confirm, type: "success" },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-tab-pane",
            { attrs: { name: _vm.AgentOrderStatus.Delivering } },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.AgentOrderStatusTrans[_vm.AgentOrderStatus.Delivering]
                        .label
                    ) + " "
                  ),
                  _c("el-badge", {
                    attrs: { value: _vm.summary.Delivering, type: "success" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: _vm.AgentOrderStatus.Complete } },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.AgentOrderStatusTrans[_vm.AgentOrderStatus.Complete]
                        .label
                    ) + " "
                  ),
                  _c("el-badge", {
                    attrs: { value: _vm.summary.Complete, type: "success" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("el-tab-pane", { attrs: { name: _vm.AgentOrderStatus.Return } }, [
            _c(
              "span",
              { attrs: { slot: "label" }, slot: "label" },
              [
                _vm._v(
                  _vm._s(
                    _vm.AgentOrderStatusTrans[_vm.AgentOrderStatus.Return].label
                  ) + " "
                ),
                _c("el-badge", {
                  attrs: { value: _vm.summary.Return, type: "danger" },
                }),
              ],
              1
            ),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.AgentOrderStatus.Cancel } }, [
            _c(
              "span",
              { attrs: { slot: "label" }, slot: "label" },
              [
                _vm._v(
                  _vm._s(
                    _vm.AgentOrderStatusTrans[_vm.AgentOrderStatus.Cancel].label
                  ) + " "
                ),
                _c("el-badge", {
                  attrs: { value: _vm.summary.Cancel, type: "danger" },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm kiếm theo mã đơn hàng" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchOrder($event)
                },
              },
              model: {
                value: _vm.agentOrderQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.agentOrderQuery, "search", $$v)
                },
                expression: "agentOrderQuery.search",
              },
            }),
          ],
          1
        ),
      ]),
      _c("agent-order-table", {
        attrs: {
          tableData: _vm.tableData,
          agentOrderQuery: _vm.agentOrderQuery,
          total: _vm.total,
          loadingList: _vm.loadingList,
          getList: _vm.getList,
        },
        on: { onSubmitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }