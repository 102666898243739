var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên đơn vị tặng quà" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "nickname",
              label: "Tên Cá nhân/Tập thể/Tổ chức",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", [
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.name) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("label", [_vm._v("Tên đăng nhập: ")]),
                          _c("span", [_vm._v(_vm._s(row.username))]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại liên hệ", prop: "contactPhone" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.contactPhone) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "120px", label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: {
                          type: _vm.StatusTransform[row.status].type,
                          size: "mini",
                          effect: "dark",
                        },
                      },
                      [_vm._v(_vm._s(_vm.StatusTransform[row.status].name))]
                    ),
                    row.status == _vm.SponsorStatus.Reject
                      ? _c(
                          "div",
                          { staticStyle: { "white-space": "pre-line" } },
                          [
                            _c("label", [_vm._v("Lý do từ chối: ")]),
                            _c("span", [_vm._v(_vm._s(row.rejectReason))]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDate(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "150",
              "class-name": "small-padding fixed-width",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _vm.checkPermissionApprove &&
                        row.status == _vm.SponsorStatus.Pending
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "flex-direction": "column",
                                  gap: "5px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApprove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Duyệt")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Từ chối")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("sponsor-modal", { ref: "Modal", on: { "submit:ok": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }