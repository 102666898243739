"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.object.to-string.js");
var _vendor = require("@/api/vendor.api");
var _vendorDiscounting = require("@/api/vendorDiscounting.api");
var _discouting = require("@/types/discouting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {},
      visible: false,
      loading: false,
      DiscountingType: _discouting.DiscountingType
    };
  },
  created: function created() {
    this.fetchRegion();
  },
  methods: {
    updateData: function updateData() {
      var _this = this;
      this.loading = true;
      _vendorDiscounting.vendorDiscountingApi.update(this.form.id, {
        vendorDiscounting: this.form
      }).then(function () {
        _this.$notify.success({
          title: "Thông báo",
          message: "Cập nhật thành công"
        });
        _this.$emit("submit:success");
        _this.visible = false;
      }).finally(function () {
        return _this.loading = false;
      });
    },
    handleUpdate: function handleUpdate(form) {
      this.form = (0, _objectSpread2.default)({}, form);
      this.visible = true;
    }
  }
};