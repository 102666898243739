var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      !_vm.agentQuery.agentRankId || _vm.agentQuery.parentId
        ? _c("agent-select-rank", {
            attrs: {
              agentQuery: _vm.agentQuery,
              loadingList: _vm.loadingList,
              getList: _vm.getList,
            },
            on: { selectAgentLevel7: _vm.onSelectAgentRankId },
          })
        : _vm._e(),
      !_vm.isShowAgentCustomerTable
        ? _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleChangeStatus },
              model: {
                value: _vm.agentQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.agentQuery, "status", $$v)
                },
                expression: "agentQuery.status",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "Tất cả", name: "all" } }),
              _c("el-tab-pane", {
                attrs: { label: "Chưa duyệt", name: _vm.AgentStatus.Pending },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Đã duyệt", name: _vm.AgentStatus.Approve },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Từ chối", name: _vm.AgentStatus.Reject },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isShowAgentCustomerTable
        ? _c("agent-table", {
            attrs: {
              tableData: _vm.tableData,
              agentQuery: _vm.agentQuery,
              total: _vm.total,
              loadingList: _vm.loadingList,
              getList: _vm.getList,
              agentRanks: _vm.agentRanks,
            },
            on: { "submit:ok": _vm.getList },
          })
        : _c("agent-customer-table", {
            ref: "AgentCustomerTable",
            attrs: { form: _vm.form, formDisable: false },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }