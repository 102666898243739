var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-blue", staticStyle: { "margin-top": "5px" } },
    [
      _vm._v(
        " (Đã quay " +
          _vm._s(_vm.idealDrawCounter) +
          "/" +
          _vm._s(this.idealPrize.quantity) +
          ") "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }