var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Lịch sử giao dịch",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Loại tủ")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "Tất cả" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.balanceType,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "balanceType", $$v)
                  },
                  expression: "query.balanceType",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "Tủ chính", value: _vm.BalanceType.Main },
                }),
                _c("el-option", {
                  attrs: {
                    label: "Tủ tín dụng",
                    value: _vm.BalanceType.Credit,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExport,
                },
                on: { click: _vm.handleExport },
              },
              [_vm._v("Xuất excel")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Loại giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.CompanyTransactionTypeTrans[row.type])
                      ),
                    ]),
                    row.order
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(" Mã đơn: "),
                          ]),
                          _c("span", [_vm._v(_vm._s(row.order.code))]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tiền giao dịch", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-weight": "bold" },
                        style:
                          row.change < 0 ? "color:#F56C6C" : "color:#67C23A",
                      },
                      [
                        _vm._v(
                          _vm._s(row.change > 0 ? "+" : "") +
                            _vm._s(_vm.$formatNumberVN(row.change)) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tiền sau khi giao dịch", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(
                        _vm._s(_vm.$formatNumberVN(row.balanceAfterChange))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại tủ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.BalanceTypeTrans[row.balanceType]) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thành viên" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.order
                      ? [
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Họ tên: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.order.customer.name)),
                            ]),
                          ]),
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" SĐT: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.order.customer.phone)),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }