var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách sản phẩm yêu cầu trả hàng",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.mallProducts,
            border: "",
            stripe: "",
            id: "product-list",
          },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              prop: "name",
              label: "Tên hàng",
              align: "center",
              width: "270",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c("div", { staticClass: "info" }, [
                        row.mallProduct
                          ? _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(row.mallProduct.name)),
                            ])
                          : _vm._e(),
                      ]),
                      row.mallProduct
                        ? _c("img", {
                            staticClass: "image",
                            attrs: {
                              src: _vm.$url.image(row.mallProduct.thumbnail),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số lượng", prop: "quantity", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giá", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$formatNumberVN(row.price)) + "đ"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hình ảnh, video bằng chứng", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: { display: "flex", "margin-top": "10px" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          _vm._l(
                            _vm.form.medias.slice(0, 3),
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  item.type == "VIDEO"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "video-thumbnail",
                                          on: {
                                            click: function () {
                                              return _vm.viewVideo(item.url)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-video-play",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.type == "IMAGE"
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px",
                                          "border-radius": "5px",
                                          "margin-right": "5px",
                                          "object-fit": "cover",
                                        },
                                        attrs: {
                                          fit: "cover",
                                          src: item.url,
                                          "preview-src-list": _vm.form.medias
                                            .filter(function (item) {
                                              return item.type == "IMAGE"
                                            })
                                            .map(function (item) {
                                              return item.url
                                            }),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("br"),
                      ]
                    ),
                    _vm.form.medias.length > 3
                      ? _c(
                          "el-popover",
                          { attrs: { placement: "bottom", trigger: "click" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              _vm._l(
                                _vm.form.medias.slice(3),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      item.type == "VIDEO"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "video-thumbnail",
                                              on: {
                                                click: function () {
                                                  return _vm.viewVideo(item.url)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-video-play",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.type == "IMAGE"
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "40px",
                                              "border-radius": "5px",
                                              "margin-right": "5px",
                                            },
                                            attrs: {
                                              fit: "cover",
                                              src: item.url,
                                              "preview-src-list":
                                                _vm.form.medias
                                                  .filter(function (item) {
                                                    return item.type == "IMAGE"
                                                  })
                                                  .map(function (item) {
                                                    return item.url
                                                  }),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "-5px",
                                  width: "fit-content",
                                  cursor: "pointer",
                                  color: "#46a6ff",
                                },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " +" +
                                    _vm._s(_vm.form.medias.length - 3) +
                                    " ảnh khác"
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("video-modal", { ref: "VideoModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }