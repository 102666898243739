var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-position": "left",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên phụ" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.formData.subName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "subName", $$v)
                          },
                          expression: "formData.subName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.formData.isBlock,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isBlock", $$v)
                            },
                            expression: "formData.isBlock",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Triển khai", value: false },
                          }),
                          _c("el-option", {
                            attrs: { label: "Chưa triển khai", value: true },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Bước nhảy (m)", prop: "step" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.formData.step,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "step", $$v)
                          },
                          expression: "formData.step",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại phương tiện", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "type", $$v)
                            },
                            expression: "formData.type",
                          },
                        },
                        _vm._l(_vm.VehicleType, function (item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Vị trí hiển thị" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.formData.position,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "position", $$v)
                          },
                          expression: "formData.position",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại bánh xe", prop: "wheelType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.formData.wheelType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "wheelType", $$v)
                            },
                            expression: "formData.wheelType",
                          },
                        },
                        _vm._l(_vm.WheelTypeTrans, function (item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item, value: key },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ghi chú", prop: "note" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.formData.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "note", $$v)
                          },
                          expression: "formData.note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ảnh", prop: "image" } },
                    [
                      _c("single-image-s-3", {
                        attrs: {
                          pathImage: _vm.formData.image,
                          width: 200,
                          height: 200,
                        },
                        on: { "upload:success": _vm.handleUploadSuccess },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }