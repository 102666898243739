var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-grid item-box" },
    _vm._l(_vm.vehicles, function (item) {
      return _c(
        "div",
        {
          key: item.id,
          staticClass: "item",
          on: {
            click: function ($event) {
              return _vm.$emit("onEdit", item)
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: {
                width: "60px",
                height: "40px",
                "border-radius": "50%",
                "object-fit": "cover",
              },
              style:
                item.vehicleCity && !item.vehicleCity.isShow
                  ? "filter: grayscale(1);"
                  : "",
              attrs: { src: _vm.$url.image(item.image), alt: "" },
            }),
          ]),
          _c("div", { staticClass: "info-box" }, [
            _c("div", { staticClass: "info-item" }, [
              _c("label", { staticClass: "type-info" }, [_vm._v("Tên: ")]),
              _c("span", { staticClass: "value-info" }, [
                _vm._v(
                  _vm._s(item.name) +
                    " " +
                    _vm._s(item.subName && " | " + item.subName)
                ),
              ]),
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("label", { staticClass: "type-info" }, [
                _vm._v("Km khởi điểm: "),
              ]),
              _c("span", { staticClass: "value-info" }, [
                _vm._v(_vm._s(item.vehicleCity && item.vehicleCity.kmMinPrice)),
              ]),
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("label", { staticClass: "type-info" }, [
                _vm._v("Giá khởi điểm: "),
              ]),
              _c("span", { staticClass: "value-info" }, [
                _vm._v(
                  _vm._s(
                    item.vehicleCity && _vm.formatVND(item.vehicleCity.minPrice)
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("label", { staticClass: "type-info" }, [
                _vm._v("Giá Km tiếp theo: "),
              ]),
              _c("span", { staticClass: "value-info" }, [
                _vm._v(
                  _vm._s(
                    item.vehicleCity && _vm.formatVND(item.vehicleCity.priceKm1)
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("label", { staticClass: "type-info" }, [
                _vm._v("Chiết khấu: "),
              ]),
              _c("span", { staticClass: "value-info" }, [
                _vm._v(
                  _vm._s(
                    item.vehicleCity && item.vehicleCity.commission + " %"
                  ) + " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "info-item" }, [
              _c("label", { staticClass: "type-info" }, [
                _vm._v("Phí di chuyển: "),
              ]),
              _c("span", { staticClass: "value-info" }, [
                _vm._v(
                  _vm._s(
                    item.vehicleCity &&
                      _vm.formatVND(item.vehicleCity.priceMinute)
                  ) + " / phút "
                ),
              ]),
            ]),
            item.vehicleCity
              ? _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Khoảng cách tìm: "),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(
                      _vm._s(item.vehicleCity.distanceScanDriver) + " Km "
                    ),
                  ]),
                ])
              : _vm._e(),
            item.vehicleCity
              ? _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Thời gian nhận đơn: "),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(item.vehicleCity.timeToAccept) + "(s)"),
                  ]),
                ])
              : _vm._e(),
            item.vehicleCity
              ? _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Thời gian quét đơn: "),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(item.vehicleCity.timeFindDriver) + "(s)"),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("i", { staticClass: "el-icon-info button-info" }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }