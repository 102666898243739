var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
            _c(
              "el-select",
              {
                attrs: { filterable: "", placeholder: "" },
                on: { change: _vm.fetchConfigs },
                model: {
                  value: _vm.cityId,
                  callback: function ($$v) {
                    _vm.cityId = $$v
                  },
                  expression: "cityId",
                },
              },
              _vm._l(_vm.cities, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleCopy },
              },
              [_vm._v("Sao chép cấu hình sang tỉnh thành khác")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("h3", [_vm._v(" Tài xế BIKE ")]),
          _c("config-reward-ref-driver-table", {
            attrs: {
              data: _vm.bikeConfig,
              onChangeEnable: _vm.handleChangeEnable,
              onUpdate: _vm.handleUpdate,
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "32px" } },
        [
          _c("h3", [_vm._v(" Tài xế CAR ")]),
          _c("config-reward-ref-driver-table", {
            attrs: {
              data: _vm.carConfig,
              onChangeEnable: _vm.handleChangeEnable,
              onUpdate: _vm.handleUpdate,
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
      _c("config-reward-ref-driver-modal", {
        ref: "ConfigRewardRefDriverModal",
        on: { "submit:ok": _vm.fetchConfigs },
      }),
      _c("copy-reward-ref-driver-modal", {
        ref: "CopyRewardRefDriverModal",
        attrs: { cities: _vm.cities },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }