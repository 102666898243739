var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("Thêm mới")]
          ),
        ],
        1
      ),
      _c(
        "el-table-draggable",
        { on: { drop: _vm.handleDrop } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: { data: _vm.merchandiseUnits, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "60", align: "center", label: "STT" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c("span", [_vm._v(_vm._s($index + 1))]),
                        _c("i", {
                          staticClass: "el-icon-sort",
                          staticStyle: {
                            cursor: "move",
                            "font-size": "15px",
                            "font-weight": "bold",
                            color: "black",
                            "margin-left": "4px",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "Đơn vị" },
              }),
              _c("el-table-column", {
                attrs: { label: "Hành động" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("merchandise-unit-modal", {
        ref: "MerchandiseUnitModal",
        on: { "submit:ok": _vm.fetchMerchandiseUnits },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }