var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
              _c("el-date-picker", {
                attrs: { type: "daterange", format: "dd/MM/yyyy" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: {
              query: _vm.query,
              visibleWard: false,
              visibleDistrict: false,
            },
            on: { "change:address": _vm.fetchData },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Loại")]),
              _c(
                "el-select",
                {
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "type", $$v)
                    },
                    expression: "query.type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", {
                    attrs: { label: "Cửa hàng", value: _vm.BannerType.PopUp },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "Khuyến mãi",
                      value: _vm.BannerType.PopUpPromotion,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.fetchData },
                },
                [_vm._v("Tìm")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "small",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Thêm popup")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Popup", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticStyle: { "border-radius": "5px" },
                      attrs: {
                        src: _vm.$url.image(row.thumbnail),
                        width: "150",
                        alt: "",
                      },
                    }),
                    _c("br"),
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉnh thành" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.city ? row.city.nameWithType : "Tất cả khu vực"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Cửa hàng / Khuyến mãi" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.store
                      ? _c("div", [
                          _c("img", {
                            staticStyle: { "border-radius": "5px" },
                            attrs: {
                              src: _vm.$url.image(row.store.thumbnail),
                              width: "150",
                              alt: "",
                            },
                          }),
                          _c("br"),
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              _vm._s(row.store.name) +
                                " - " +
                                _vm._s(row.store.phone)
                            ),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(" " + _vm._s(row.store.address) + " "),
                          ]),
                        ])
                      : _vm._e(),
                    row.promotion
                      ? _c("div", [
                          _c("img", {
                            staticStyle: { "border-radius": "5px" },
                            attrs: {
                              src: _vm.$url.image(row.promotion.thumbnail),
                              width: "150",
                              alt: "",
                            },
                          }),
                          _c("br"),
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(_vm._s(row.promotion.title)),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.type == _vm.BannerType.PopUp
                            ? "Cửa hàng"
                            : "Khuyến mãi"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian", prop: "startAt", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngày bắt đầu:"),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatDate(row.startAt))),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Ngày kết thúc:"),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.$formatDate(row.endAt)))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("Xóa")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("store-popup-modal", {
        ref: "StorePopupModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }