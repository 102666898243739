var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Thông tin GD" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "4px",
                        },
                      },
                      [
                        _c("div", [
                          _c("label", [_vm._v("Mã nội bộ: ")]),
                          _c("span", [_vm._v(_vm._s(row.code))]),
                        ]),
                        _c("div", [
                          _c("label", [_vm._v("Mã GD NCC: ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(row.otherTransId || "Chưa có thông tin")
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.cardOrderQuery.type !== _vm.CardOrderType.Bill
            ? _c("el-table-column", {
                attrs: { label: "Nhà mạng" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.cardProduct
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.CardProductTypeTrans[
                                        row.cardProduct.type
                                      ]
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2265637022
                ),
              })
            : _vm._e(),
          _vm.cardOrderQuery.type == _vm.CardOrderType.Bill
            ? _c("el-table-column", {
                attrs: { label: "Loại hóa đơn" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.cardProduct
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s("" + row.cardProduct.name) +
                                      " - " +
                                      _vm._s("" + row.cardProduct.code)
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1354940418
                ),
              })
            : _vm._e(),
          _vm.cardOrderQuery.type == _vm.CardOrderType.Bill
            ? _c("el-table-column", {
                attrs: { label: "Mã hóa đơn" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.billingCode
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s("" + row.billingCode)),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3645168561
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", align: "center", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.CardOrderStatusTrans[row.status].color,
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.CardOrderStatusTrans[row.status].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Đơn giá (đ)", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatCurrency(row.amount))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phí giao dịch", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatCurrency(row.fee))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hoa hồng", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatCurrency(row.commission))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thành tiền (đ)", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatCurrency(row.moneyFinal))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phương thức thanh toán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.PaymentTypeTrans[row.paymentType])),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời điểm giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.cardOrderQuery.page,
          limit: _vm.cardOrderQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.cardOrderQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.cardOrderQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }