"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.idealNotificationApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var idealNotificationApi = exports.idealNotificationApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/idealNotification',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/idealNotification',
      method: 'post',
      data: data
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/idealNotification/".concat(id),
      data: data,
      method: 'patch'
    });
  },
  publish: function publish(id) {
    return (0, _request.default)({
      url: "/idealNotification/".concat(id, "/publish"),
      method: 'patch'
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/idealNotification/".concat(id),
      method: 'delete'
    });
  }
};