var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v(" Thêm việc làm ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                type: "primary",
                icon: "el-icon-s-tools",
                size: "small",
                loading: _vm.loadingFindContent,
              },
              on: { click: _vm.handleConfigContent },
            },
            [_vm._v(" Cấu hình nội dung ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.partTimeJobs, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Công việc", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "danger",
                          size: "mini",
                          loading: row.loadingDelete,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("Xóa")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchPartTimeJobs,
        },
      }),
      _c("part-time-job-modal", {
        ref: "PartTimeJobModal",
        on: { "submit:ok": _vm.fetchPartTimeJobs },
      }),
      _c("content-define-modal", {
        ref: "ContentDefineModal",
        attrs: { "submit:ok": _vm.fetchContentDefines },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }