var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "order-detail-list",
        title: "Chi tiết đơn - " + _vm.formData.code + " ",
        visible: _vm.dialogVisible,
        width: "1400px",
        "append-to-body": "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c(
          "div",
          [
            _vm._v("Chi tiết đơn - " + _vm._s(_vm.formData.code) + " - "),
            _c(
              "el-tag",
              {
                staticStyle: { transform: "translateY(-2px)" },
                attrs: {
                  type: _vm.agentOrderStatusTrans[_vm.formData.status].color,
                  effect: "dark",
                },
              },
              [
                _c("b", [
                  _vm._v(
                    _vm._s(_vm.agentOrderStatusTrans[_vm.formData.status].label)
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticStyle: { display: "flex", gap: "50px", width: "100%" } },
        [
          _c(
            "ul",
            {
              staticClass: "payment-detail",
              staticStyle: { width: "100%", "max-width": "100%" },
            },
            [
              _c("li", [
                _c("label", [_vm._v("Nhà cung cấp:")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.provider.name))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Kho hàng:")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.depot.name))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Hình thức vận chuyển:")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.agentOrderShipProviderTrans[_vm.formData.shipProvider]
                        .label
                    )
                  ),
                ]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Phương thức thanh toán:")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.AgentOrderPaymentTypeTrans[_vm.formData.paymentType]
                        .label
                    )
                  ),
                ]),
              ]),
              _c("li", [
                _c("label", [
                  _vm._v(
                    "Phí ship (" +
                      _vm._s(
                        _vm.agentOrderShipFeeByTrans[_vm.formData.shipFeeBy]
                          .label
                      ) +
                      "):"
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.formData.shipFee != 0 ? "+" : "") +
                        " " +
                        _vm.$formatCurrency(_vm.formData.shipFee)
                    )
                  ),
                ]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Thuế:")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.formData.moneyTax != 0 ? "+" : "") +
                        " " +
                        _vm.$formatCurrency(_vm.formData.moneyTax)
                    )
                  ),
                ]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Khuyến mãi:")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.formData.moneyDiscount != 0 ? "-" : "") +
                        " " +
                        _vm.$formatCurrency(_vm.formData.moneyDiscount)
                    )
                  ),
                ]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Tổng tiền hàng:")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$formatCurrency(_vm.formData.moneyProduct))
                  ),
                ]),
              ]),
              _c("el-divider"),
              _c("li", [
                _c("label", [_vm._v("Thành tiền:")]),
                _c(
                  "span",
                  { staticStyle: { color: "#C1952D", "font-weight": "700" } },
                  [_vm._v(_vm._s(_vm.$formatCurrency(_vm.formData.moneyFinal)))]
                ),
              ]),
              _c("li", [
                _c("label", [_vm._v("Tổng hoa hồng bán lẻ:")]),
                _c(
                  "span",
                  { staticStyle: { "font-weight": "700", color: "#C1952D" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$formatCurrency(_vm.formData.moneyCommission))
                    ),
                  ]
                ),
              ]),
              _c("li", [
                _c("label", [_vm._v("Tổng chiết khấu của nhà cung cấp:")]),
                _c(
                  "span",
                  { staticStyle: { "font-weight": "700", color: "#C1952D" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$formatCurrency(
                          _vm.formData.moneyProviderCommission
                        )
                      )
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "ul",
            {
              staticClass: "payment-detail",
              staticStyle: { width: "100%", "max-width": "100%" },
            },
            [
              _c("li", [
                _c("label", [_vm._v("Tên người đặt: ")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.agent.name))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Email: ")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.agent.email))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Số điện thoại:")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.agent.phone))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Địa chỉ:")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.agent.address))]),
              ]),
              _c("el-divider"),
              _c("li", [
                _c("label", [_vm._v("Tên người nhận: ")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.agentCustomer.name))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Số điện thoại:")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.agentCustomer.phone))]),
              ]),
              _c("li", [
                _c("label", [_vm._v("Địa chỉ:")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.formData.agentCustomer.address)),
                ]),
              ]),
              _vm.formData.refundReason
                ? _c("li", [
                    _c("label", [_vm._v("Lý do hoàn trả:")]),
                    _c("span", [_vm._v(_vm._s(_vm.formData.refundReason))]),
                  ])
                : _vm._e(),
              _c("li", [
                _c("label", [_vm._v("Ghi chú:")]),
                _c("span", [_vm._v(_vm._s(_vm.formData.note))]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.formData.agentOrderDetails, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { prop: "name", align: "center", label: "Hình ảnh" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticClass: "image",
                      staticStyle: { height: "60px", width: "'auto'" },
                      attrs: {
                        src: _vm.$url.image(row.product.image),
                        alt: "",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "Tên sản phẩm",
              align: "center",
              width: "270",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(row.product.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giá", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$formatNumberVN(row.product.price))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số lượng", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.quantity))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thành tiền", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$formatNumberVN(row.totalPice))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin hoa hồng", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Phần trăm: "),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(row.commissionPercent) + " (%)"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Hoa hồng: "),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$formatNumberVN(row.commission))),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
            attrs: { label: "Thông tin chiết khấu", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Phần trăm: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(row.providerCommissionPercent) + " (%)"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Chiết khẩu: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.providerCommission))
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
            attrs: { label: "Thông tin thuế", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Phần trăm: "),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.taxPercent) + " (%)")]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Thuế: ")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$formatNumberVN(row.totalTax))),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.formData.orderReceipt
        ? _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "flex-end" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    width: "200px",
                    "justify-content": "space-between",
                    "margin-top": "30px",
                  },
                },
                [
                  _c("label", [_vm._v("Hóa đơn (đỏ):")]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        color: "red",
                        "font-weight": "600",
                        "font-style": "italic",
                        "text-decoration": "underline",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleOpenOrderReceipt(
                            _vm.formData.orderReceipt
                          )
                        },
                      },
                    },
                    [_vm._v("Chi tiết")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { width: "0", height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
      _c("agent-order-receipt-modal", { ref: "AgentOrderReceiptModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }