var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Thưởng theo", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "" },
                  model: {
                    value: _vm.form.rewardSaleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "rewardSaleType", $$v)
                    },
                    expression: "form.rewardSaleType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "Theo số tiền",
                      value: _vm.ConfigRewardSaleType.Amount,
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "Theo số phần trăm",
                      value: _vm.ConfigRewardSaleType.Percent,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.form.rewardSaleType == _vm.ConfigRewardSaleType.Amount
                    ? "Số tiền thưởng cố định/ cuốc"
                    : "Phần trăm thưởng/cuốc",
              },
            },
            [
              _c(
                "el-input",
                {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: {
                        alias: "decimal",
                        groupSeparator: ".",
                        rightAlign: false,
                        autoGroup: true,
                        repeat: 20,
                        autoUnmask: true,
                        allowMinus: false,
                      },
                      expression:
                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                    },
                  ],
                  model: {
                    value: _vm.form.rewardSaleValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "rewardSaleValue", $$v)
                    },
                    expression: "form.rewardSaleValue",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v(
                      _vm._s(
                        _vm.form.rewardSaleType ==
                          _vm.ConfigRewardSaleType.Amount
                          ? "VNĐ"
                          : "%"
                      )
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm.form.rewardSaleType == _vm.ConfigRewardSaleType.Percent
            ? _c(
                "el-form-item",
                { attrs: { label: "Số tiền thưởng tối đa/ cuốc" } },
                [
                  _c(
                    "el-input",
                    {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: {
                            alias: "decimal",
                            groupSeparator: ".",
                            rightAlign: false,
                            autoGroup: true,
                            repeat: 20,
                            autoUnmask: true,
                            allowMinus: false,
                          },
                          expression:
                            "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                        },
                      ],
                      model: {
                        value: _vm.form.rewardSaleMaxValue,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "rewardSaleMaxValue", $$v)
                        },
                        expression: "form.rewardSaleMaxValue",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("VNĐ")])],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Kích hoạt", prop: "rewardSaleEnabled" } },
            [
              _c("el-switch", {
                staticStyle: { display: "block" },
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  "active-text": "Bật",
                  "inactive-text": "Tắt",
                },
                model: {
                  value: _vm.form.rewardSaleEnabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rewardSaleEnabled", $$v)
                  },
                  expression: "form.rewardSaleEnabled",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }