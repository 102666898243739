"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallReturnOrderApi = void 0;
var _request = require("@/utils/request");
var mallReturnOrderApi = exports.mallReturnOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/mallReturnOrder',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/mallReturnOrder/".concat(id)
    });
  },
  summary: function summary(params) {
    return (0, _request.request)({
      url: '/mallReturnOrder/summary/status',
      params: params
    });
  },
  confirm: function confirm(id, data) {
    return (0, _request.request)({
      url: "/mallReturnOrder/".concat(id, "/confirm"),
      method: 'patch',
      data: data
    });
  }
};