"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletApi = void 0;
var _request = require("@/utils/request");
var walletApi = exports.walletApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/wallet',
      params: params
    });
  },
  block: function block(id, data) {
    return (0, _request.request)({
      url: "/wallet/".concat(id, "/block"),
      method: 'patch',
      data: data
    });
  }
};