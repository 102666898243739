"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var storeApi = exports.storeApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/store',
      params: params
    });
  },
  getRef: function getRef(params) {
    return (0, _request.default)({
      url: '/store/children-ref',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/store/',
      data: data,
      method: 'post'
    });
  },
  online: function online(id) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/online"),
      method: 'post'
    });
  },
  offline: function offline(id) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/offline"),
      method: 'post'
    });
  },
  approve: function approve(storeId) {
    return (0, _request.default)({
      url: "/store/".concat(storeId, "/approve"),
      method: 'post'
    });
  },
  transaction: function transaction(storeId, params) {
    return (0, _request.default)({
      url: "/store/".concat(storeId, "/transaction"),
      params: params
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/delete"),
      method: 'post'
    });
  },
  password: function password(id, data) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/password"),
      method: 'post',
      data: data
    });
  },
  updateRateStar: function updateRateStar(id, data) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/update-rate-star"),
      method: 'post',
      data: data
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/cancel"),
      method: 'post'
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/store/".concat(id)
    });
  },
  totalSale: function totalSale(id, params) {
    return (0, _request.default)({
      url: "/store/".concat(id, "/total-sale"),
      params: params
    });
  }
};