var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "Thêm" : "Cập nhật",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thông tin", name: "info" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-position": "top",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "Tên" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "Loại" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(
                          _vm.StoreRecommendTypeTrans,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hình", prop: "icon" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("single-image", {
                            attrs: {
                              width: 100,
                              height: 100,
                              pathImage: _vm.form.icon,
                              urlUpload: "/storeRecommend/upload",
                            },
                            on: { "upload:success": _vm.uploadSuccess },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Loại ẩm thực", name: "foodType" } },
            [
              _c("FoodType", {
                attrs: { storeRecommendId: _vm.form.id },
                on: {
                  "update:storeRecommendId": function ($event) {
                    return _vm.$set(_vm.form, "id", $event)
                  },
                  "update:store-recommend-id": function ($event) {
                    return _vm.$set(_vm.form, "id", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }