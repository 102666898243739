var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "application-page app-container" }, [
    !!_vm.driverSelect
      ? _c(
          "div",
          { staticClass: "application-content" },
          [
            _c("head-application", {
              on: { "submit:success": _vm.fetchDriver },
            }),
            _c("div", { staticClass: "divider" }, [
              _c("div", { ref: "progress", staticClass: "progress" }),
            ]),
            _c("h2", { staticClass: "gray-color" }, [
              _vm._v("Thông tin cá nhân"),
            ]),
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c("section-item", {
                    attrs: {
                      title: item.label,
                      required: item.required,
                      completed: item.completed,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$router.push(item.link)
                      },
                    },
                  }),
                  _c("div", { staticClass: "divider-light" }),
                ],
                1
              )
            }),
            _c("h2", { staticClass: "gray-color" }, [
              _vm._v("Thông tin phương tiện"),
            ]),
            _vm._l(_vm.dataVehicle, function (item) {
              return _c(
                "div",
                { key: item.name },
                [
                  _c("section-item", {
                    attrs: {
                      title: item.label,
                      required: item.required,
                      completed: item.completed,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$router.push(item.link)
                      },
                    },
                  }),
                  _c("div", { staticClass: "divider-light" }),
                ],
                1
              )
            }),
            _c("h2", { staticClass: "gray-color" }, [_vm._v("Lịch sử duyệt")]),
            _c("div", { staticClass: "section-item" }, [
              _c("div", { staticClass: "prefix" }, [
                _vm._v(" Nhân viên duyệt hồ sơ online "),
              ]),
              _c("div", { staticClass: "center" }, [
                _c("span", { class: "non-required" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.driverSelect.approvedStaff
                          ? _vm.driverSelect.approvedStaff.name
                          : "--"
                      )
                    ),
                  ]),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$formatDateTime(_vm.driverSelect.acceptedAt)) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "section-item" }, [
              _c("div", { staticClass: "prefix" }, [
                _vm._v(" Nhân viên duyệt hồ sơ chính thức "),
              ]),
              _c("div", { staticClass: "center" }, [
                _c("span", { class: "non-required" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        _vm.driverSelect.completedStaff
                          ? _vm.driverSelect.completedStaff.name
                          : "--"
                      )
                    ),
                  ]),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$formatDateTime(_vm.driverSelect.approvedAt)) +
                      " "
                  ),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "gray-color" }, [_vm._v("Ghi chú")]),
            _c("el-input", {
              attrs: {
                placeholder: "",
                type: "textarea",
                autosize: { minRows: 3, maxRows: 5 },
                clearable: "",
              },
              model: {
                value: _vm.form.note,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "note", $$v)
                },
                expression: "form.note",
              },
            }),
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                staticStyle: { "margin-top": "32px" },
                attrs: {
                  type: "primary",
                  size: "medium",
                  loading: _vm.loading,
                },
                on: { click: _vm.saveNote },
              },
              [_vm._v("Lưu ghi chú")]
            ),
            _c("div"),
            !_vm.isUpdateProfile && _vm.approveProfileVisible
              ? _c(
                  "div",
                  [
                    _vm.$store.state.driver.selected.accountStatus ==
                    _vm.AccountStatus.Pending
                      ? _c(
                          "el-button",
                          {
                            staticClass: "btn-save",
                            staticStyle: { "margin-top": "16px" },
                            attrs: {
                              type: "primary",
                              size: "medium",
                              loading: _vm.loading,
                            },
                            on: { click: _vm.handleApprove },
                          },
                          [_vm._v("Chấp nhận hồ sơ")]
                        )
                      : _vm.visibleBecomeDriver &&
                        _vm.$store.state.driver.selected.accountStatus ==
                          _vm.AccountStatus.Approve
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12, offset: 0 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn-save",
                                    staticStyle: { "margin-top": "16px" },
                                    attrs: {
                                      type: "success",
                                      size: "medium",
                                      loading: _vm.loading,
                                    },
                                    on: { click: _vm.handleComplete },
                                  },
                                  [_vm._v("Duyệt thành tài xế")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12, offset: 0 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn-save",
                                    staticStyle: { "margin-top": "16px" },
                                    attrs: {
                                      type: "danger",
                                      size: "medium",
                                      loading: _vm.loading,
                                    },
                                    on: { click: _vm.handleCancelApprove },
                                  },
                                  [_vm._v("Hủy duyệt online")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.visibleApproveProfile
              ? _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "btn-save",
                        staticStyle: { "margin-top": "16px", width: "100%" },
                        attrs: { trigger: "click", size: "medium" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-save",
                            attrs: {
                              type: "primary",
                              size: "medium",
                              loading: _vm.loading,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.ProfileStatusTrans[
                                  _vm.$store.state.driver.selected.profileStatus
                                ]
                              ) + " "
                            ),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                              staticStyle: { float: "right" },
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "menu-dropdown-custom",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          _vm._l(
                            Object.values(_vm.ProfileStatus).filter(function (
                              e
                            ) {
                              return e != "COMPLETE"
                            }),
                            function (item, index) {
                              return _c("el-dropdown-item", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-bold font-size--medium",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeProfileStatus(
                                          $event,
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.ProfileStatusTrans[item]) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isUpdateProfile && _vm.deleteProfileVisible
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn-save",
                        staticStyle: { "margin-top": "16px" },
                        attrs: {
                          type: "danger",
                          size: "medium",
                          loading: _vm.loading,
                        },
                        on: { click: _vm.handleDelete },
                      },
                      [_vm._v("Xoá hồ sơ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }