var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticStyle: { width: "400px" },
      attrs: {
        rules: _vm.rules,
        model: _vm.form,
        "label-position": "top",
        "label-width": "120px",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Tài khoản", prop: "username" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.username,
              callback: function ($$v) {
                _vm.$set(_vm.form, "username", $$v)
              },
              expression: "form.username",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mật khẩu", prop: "password" } },
        [
          _c("el-input", {
            attrs: { type: "password", "show-password": "" },
            model: {
              value: _vm.form.password,
              callback: function ($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("Cập nhật tài khoản")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }