"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderReceiptType = exports.AgentOrderStatusTrans = exports.AgentOrderStatus = exports.AgentOrderShipProviderTrans = exports.AgentOrderShipProvider = exports.AgentOrderShipFeeByTrans = exports.AgentOrderShipFeeBy = exports.AgentOrderPaymentTypeTrans = exports.AgentOrderPaymentType = exports.AgentOrderPaymentStatusTrans = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _payment = require("./payment");
var AgentOrderStatus;
(function (AgentOrderStatus) {
  AgentOrderStatus["PendingPayment"] = "PENDING_PAYMENT";
  AgentOrderStatus["Pending"] = "PENDING";
  AgentOrderStatus["Confirm"] = "CONFIRM";
  AgentOrderStatus["Delivering"] = "DELIVERING";
  AgentOrderStatus["Complete"] = "COMPLETE";
  AgentOrderStatus["Return"] = "RETURN";
  AgentOrderStatus["Cancel"] = "CANCEL";
})(AgentOrderStatus || (exports.AgentOrderStatus = AgentOrderStatus = {}));
var AgentOrderStatusTrans = exports.AgentOrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentOrderStatus.PendingPayment, {
  label: "Chờ thanh toán",
  color: '',
  value: AgentOrderStatus.PendingPayment
}), AgentOrderStatus.Pending, {
  label: "Chờ duyệt",
  color: '',
  value: AgentOrderStatus.Pending
}), AgentOrderStatus.Confirm, {
  label: "Đã duyệt",
  color: 'success',
  value: AgentOrderStatus.Confirm
}), AgentOrderStatus.Delivering, {
  label: "Đang giao",
  color: 'success',
  value: AgentOrderStatus.Delivering
}), AgentOrderStatus.Complete, {
  label: "Hoàn thành",
  color: 'success',
  value: AgentOrderStatus.Complete
}), AgentOrderStatus.Return, {
  label: "Hoàn trả",
  color: 'danger',
  value: AgentOrderStatus.Return
}), AgentOrderStatus.Cancel, {
  label: "Hủy",
  color: 'danger',
  value: AgentOrderStatus.Cancel
});
var AgentOrderShipFeeBy;
(function (AgentOrderShipFeeBy) {
  AgentOrderShipFeeBy["Sender"] = "SENDER";
  AgentOrderShipFeeBy["Receiver"] = "RECEIVER"; //người nhận
})(AgentOrderShipFeeBy || (exports.AgentOrderShipFeeBy = AgentOrderShipFeeBy = {}));
var AgentOrderShipFeeByTrans = exports.AgentOrderShipFeeByTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentOrderShipFeeBy.Sender, {
  label: "Người bán chịu phí",
  value: AgentOrderShipFeeBy.Sender
}), AgentOrderShipFeeBy.Receiver, {
  label: "Khách hàng chịu phí",
  value: AgentOrderShipFeeBy.Receiver
});
var AgentOrderPaymentType;
(function (AgentOrderPaymentType) {
  AgentOrderPaymentType["COD"] = "COD";
  AgentOrderPaymentType["Bank"] = "BANK";
  AgentOrderPaymentType["CreditCard"] = "CREDIT_CARD";
  AgentOrderPaymentType["InstallmentCreditCard"] = "INSTALLMENT_CREDIT_CARD"; //trả góp bằng thẻ tín dụng
})(AgentOrderPaymentType || (exports.AgentOrderPaymentType = AgentOrderPaymentType = {}));
var AgentOrderPaymentStatusTrans = exports.AgentOrderPaymentStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, _payment.PaymentStatus.Pending, {
  label: "Chờ thanh toán",
  value: _payment.PaymentStatus.Pending
}), _payment.PaymentStatus.Complete, {
  label: "Đã thanh toán",
  value: _payment.PaymentStatus.Complete
}), _payment.PaymentStatus.Fail, {
  label: "Thanh toán thất bại",
  value: _payment.PaymentStatus.Fail
});
var AgentOrderPaymentTypeTrans = exports.AgentOrderPaymentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentOrderPaymentType.COD, {
  label: "Thanh toán khi nhận hàng",
  value: AgentOrderPaymentType.COD
}), AgentOrderPaymentType.Bank, {
  label: "Chuyển khoản ngân hàng",
  value: AgentOrderPaymentType.Bank
}), AgentOrderPaymentType.CreditCard, {
  label: "Thẻ tín dụng quốc tế",
  value: AgentOrderPaymentType.CreditCard
}), AgentOrderPaymentType.InstallmentCreditCard, {
  label: "Trả góp bằng thẻ tín dụng",
  value: AgentOrderPaymentType.InstallmentCreditCard
});
var AgentOrderShipProvider;
(function (AgentOrderShipProvider) {
  AgentOrderShipProvider["AtStock"] = "AT_STOCK";
  AgentOrderShipProvider["GHTK"] = "GHTK";
  AgentOrderShipProvider["Nhanh"] = "NHANH";
  AgentOrderShipProvider["Provider"] = "PROVIDER"; //
})(AgentOrderShipProvider || (exports.AgentOrderShipProvider = AgentOrderShipProvider = {}));
var AgentOrderShipProviderTrans = exports.AgentOrderShipProviderTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentOrderShipProvider.AtStock, {
  label: "Nhận hàng tại kho hàng",
  value: AgentOrderShipProvider.AtStock
}), AgentOrderShipProvider.GHTK, {
  label: "Giao hàng tiết kiệm",
  value: AgentOrderShipProvider.GHTK
}), AgentOrderShipProvider.Nhanh, {
  label: "Giao hàng nhanh",
  value: AgentOrderShipProvider.Nhanh
}), AgentOrderShipProvider.Provider, {
  label: "VIETTEL POST",
  value: AgentOrderShipProvider.Provider
});
var OrderReceiptType;
(function (OrderReceiptType) {
  OrderReceiptType["Personal"] = "PERSONAL";
  OrderReceiptType["Company"] = "COMPANY";
})(OrderReceiptType || (exports.OrderReceiptType = OrderReceiptType = {}));