var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Khiếu nại của cửa hàng Mall",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.mallComplains, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { width: "100px", label: "Đơn hoàn trả", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.mallReturnOrder.code))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại khiếu nại", prop: "name", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.mallComplainTypeTrans[row.type].color,
                          size: "small",
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.mallComplainTypeTrans[row.type].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", prop: "name", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.complaintStatusTrans[row.status].color,
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.complaintStatusTrans[row.status].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "Cửa hàng", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            "border-radius": "5px",
                            "margin-right": "5px",
                          },
                          attrs: {
                            width: "40px",
                            height: "40px",
                            src: row.mall.thumbnail,
                          },
                        }),
                        _c("div", [_vm._v(_vm._s(row.mall.name))]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "300px", label: "Nội dung", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(row.content))]),
                    _c(
                      "div",
                      {
                        staticStyle: { display: "flex", "margin-top": "10px" },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          _vm._l(
                            row.medias.slice(0, 3),
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  item.type == "VIDEO"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "video-thumbnail",
                                          on: {
                                            click: function () {
                                              return _vm.viewVideo(item.url)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-video-play",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.type == "IMAGE"
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px",
                                          "border-radius": "5px",
                                          "margin-right": "5px",
                                          "object-fit": "cover",
                                        },
                                        attrs: {
                                          fit: "cover",
                                          src: item.url,
                                          "preview-src-list": row.medias
                                            .filter(function (item) {
                                              return item.type == "IMAGE"
                                            })
                                            .map(function (item) {
                                              return item.url
                                            }),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c("br"),
                      ]
                    ),
                    row.medias.length > 3
                      ? _c(
                          "el-popover",
                          { attrs: { placement: "bottom", trigger: "click" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              _vm._l(
                                row.medias.slice(3),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      item.type == "VIDEO"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "video-thumbnail",
                                              on: {
                                                click: function () {
                                                  return _vm.viewVideo(item.url)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-video-play",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.type == "IMAGE"
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "40px",
                                              "border-radius": "5px",
                                              "margin-right": "5px",
                                            },
                                            attrs: {
                                              fit: "cover",
                                              src: item.url,
                                              "preview-src-list": row.medias
                                                .filter(function (item) {
                                                  return item.type == "IMAGE"
                                                })
                                                .map(function (item) {
                                                  return item.url
                                                }),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "margin-top": "-5px",
                                  width: "fit-content",
                                  cursor: "pointer",
                                  color: "#46a6ff",
                                },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " +" +
                                    _vm._s(row.medias.length - 3) +
                                    " ảnh khác"
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thao tác", prop: "name", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == _vm.complaintStatus.pending
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.approveAppeal(row)
                                  },
                                },
                              },
                              [_vm._v("Chấp nhận khiếu nại")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  margin: "0",
                                  "margin-top": "10px",
                                },
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rejectAppeal(row)
                                  },
                                },
                              },
                              [_vm._v("Từ chối khiếu nại")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("video-modal", { ref: "VideoModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }