var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pack-box" },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v("Điểm giao #" + _vm._s(_vm.index + 1)),
      ]),
      _c(
        "el-form",
        { attrs: { model: _vm.detail, rules: _vm.rules } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Địa chỉ", prop: "endAddress" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Địa chỉ"),
                              ]),
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "line-height": "12px",
                                    "z-index": "1000",
                                    "margin-left": "4px",
                                  },
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickAddress(_vm.detail)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-edit" }),
                                  _vm._v(" Thay đổi"),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("el-input", {
                        attrs: { value: _vm.detail.endAddress },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Người nhận", prop: "receiverName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.detail.receiverName,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "receiverName", $$v)
                          },
                          expression: "detail.receiverName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "SĐT", prop: "receiverPhone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.detail.receiverPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "receiverPhone", $$v)
                          },
                          expression: "detail.receiverPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại hàng hóa", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.detail.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "name", $$v)
                          },
                          expression: "detail.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trọng lượng", prop: "weight" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.detail.weight,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "weight", $$v)
                          },
                          expression: "detail.weight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "COD" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.detail.cod,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "cod", $$v)
                          },
                          expression: "detail.cod",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ghi chú" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.detail.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.detail, "note", $$v)
                  },
                  expression: "detail.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }