var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "chat" },
      [
        _c("chat-window", {
          attrs: {
            height: _vm.heightChat,
            "current-user-id": 0,
            rooms: _vm.rooms,
            "loading-rooms": _vm.loadingRoom,
            messages: _vm.messages,
            "rooms-loaded": _vm.roomLoaded,
            "show-files": true,
            "show-audio": false,
            "show-add-room": false,
            "show-emojis": false,
            "show-reaction-emojis": false,
            "messages-loaded": _vm.messagesLoaded,
            "message-actions": [
              {
                name: "deleteMessage",
                title: "Xóa tin nhắn",
                onlyMe: true,
              },
            ],
            "room-actions": _vm.roomActions,
            "room-id": _vm.roomSelected.roomId,
            "accepted-files": "image/png, image/jpeg",
          },
          on: {
            "delete-message": _vm.handleDeleteMessage,
            "room-action-handler": _vm.handleActionHandler,
            "send-message": _vm.sendMessage,
            "fetch-more-rooms": _vm.fetchMoreRooms,
            "fetch-messages": _vm.fetchMoreMessage,
            "open-file": _vm.handleViewImage,
          },
        }),
        _c("el-image", {
          ref: "imagePreview",
          staticClass: "image",
          style: "height:0;display:none",
          attrs: {
            alt: "",
            "z-index": 99999,
            fit: "contain",
            src: _vm.$url.image(_vm.pathImage),
            "preview-src-list": [_vm.$url.image(_vm.pathImage)],
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }