var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticStyle: { "padding-left": "15px" } },
    _vm._l(
      Object.values(_vm.idealDrawCounter).sort(function (a, b) {
        return a.type.localeCompare(b.type)
      }),
      function (item) {
        return _c("li", { key: item.id }, [
          _c(
            "p",
            [
              item.type == _vm.idealPrizeType.Main
                ? _c("el-tag", { attrs: { size: "mini", effect: "dark" } }, [
                    _c("b", [_vm._v("Giải chính")]),
                  ])
                : _c("el-tag", { attrs: { size: "mini" } }, [
                    _c("b", [_vm._v("Giải phụ")]),
                  ]),
              _c("b", [_vm._v(" " + _vm._s(item.name) + ":")]),
              _vm._v(" "),
              _c("br"),
              _vm._v("Đã bốc thăm "),
              _c("b", [
                _vm._v(
                  _vm._s(item.quantity) + _vm._s(_vm.getTotalPrize(item.id))
                ),
              ]),
              _vm._v(" lần "),
            ],
            1
          ),
        ])
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }