var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm tên")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v(" Thêm chi nhánh ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "name",
              label: "Tên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thành phố" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.cities, function (item) {
                    return _c("div", { key: item.id }, [
                      _c("span", [_vm._v("- " + _vm._s(item.nameWithType))]),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Quận huyện" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.districts, function (item) {
                    return _c("div", { key: item.id }, [
                      _c("span", [_vm._v("- " + _vm._s(item.nameWithType))]),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phường xã" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.wards, function (item) {
                    return _c("div", { key: item.id }, [
                      _c("span", [_vm._v("- " + _vm._s(item.nameWithType))]),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nhân viên" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.staffs, function (item) {
                    return _c("div", { key: item.id }, [
                      _c("span", [_vm._v("- " + _vm._s(item.name))]),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "180",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v(" Xoá ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("branch-dialog", {
        ref: "branch-dialog",
        on: {
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }