"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _agentDashboard = require("@/api/agentDashboard.api");
var _BarChart = _interopRequireDefault(require("../BarChart.vue"));
var _moment = _interopRequireDefault(require("moment"));
var _default = exports.default = {
  components: {
    BarChart: _BarChart.default
  },
  props: ["listQuery"],
  watch: {
    date: function date(newVal) {
      this.getData();
    },
    status: function status(newVal) {
      this.getData();
    },
    listQuery: {
      handler: function handler(val) {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: "Tuần trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "Tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "3 tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      chartData: [],
      status: "",
      STATUS_ORDER: {
        COMPLETE: "Đã hoàn thành",
        CUSTOMER_CANCEL: "Người Dùng hủy chuyến",
        DRIVER_CANCEL: "Tài xế hủy chuyến"
      }
    };
  },
  created: function created() {
    // this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        fromAt: this.date ? (0, _moment.default)(this.date[0]).unix() : undefined,
        toAt: this.date ? (0, _moment.default)(this.date[1]).unix() : undefined,
        status: this.status
      });
      _agentDashboard.agentDashboardApi.summaryAgentOrder(data).then(function (res) {
        var data = res.data;
        _this.chartData = data.map(function (item) {
          return {
            money: item.money,
            // money: 1100000,
            date: item.orderDate
          };
        });
        console.log("chart data", _this.chartData);
        _this.$nextTick().then(function () {
          _this.$refs["chart"].initChart();
        });
      });
    }
  }
};