var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleChangeStatus },
          model: {
            value: _vm.query.status,
            callback: function ($$v) {
              _vm.$set(_vm.query, "status", $$v)
            },
            expression: "query.status",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: _vm.ProductStatus.Pending } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Chưa duyệt "),
            ]),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.ProductStatus.Approve } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Đã duyệt "),
            ]),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.ProductStatus.Reject } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Đã từ chối "),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên sản phẩm" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Tạo mới")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  loading: _vm.loadingExcel,
                  size: "small",
                  icon: "el-icon-download",
                },
                on: { click: _vm.handleExportExcel },
              },
              [_vm._v(" Xuất excel ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleImport },
              },
              [_vm._v(" Thêm sản phẩm (Excel) ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            key: "code",
            attrs: {
              "class-name": "text-center",
              width: "100",
              label: "Mã",
              index: "code",
              prop: "code",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "name",
              label: "Tên sản phẩm",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", [
                        row.image
                          ? _c("img", {
                              staticStyle: {
                                width: "50px",
                                height: "auto",
                                "object-fit": "contain",
                                "margin-right": "10px",
                              },
                              attrs: { src: row.image, alt: "" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.name) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", label: "Giá", prop: "price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.$formatCurrency(row.price)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "150",
              label: "Chiết khấu trả cho Fclass (%)",
              prop: "appCommissionPercent",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.appCommissionPercent) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "150",
              label: "Hoa hồng trả cho nhà bán lẻ (%)",
              prop: "commissionPercent",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.commissionPercent) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin khác", prop: "commissionPercent" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.agentBrand
                      ? _c("div", [
                          _c("label", [_vm._v("Thương hiệu: ")]),
                          _c("span", [_vm._v(_vm._s(row.agentBrand.name))]),
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("label", [_vm._v("Nhà cung cấp: ")]),
                      _c("span", [_vm._v(_vm._s(row.provider.name))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Kho", prop: "depot" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.depots
                            .map(function (item) {
                              return item.name
                            })
                            .join(", ")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          effect: "dark",
                          type: _vm.ProductStatusTrans[row.status].color,
                        },
                      },
                      [_vm._v(_vm._s(_vm.ProductStatusTrans[row.status].label))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDate(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "150",
              "class-name": "small-padding fixed-width",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.query.status == _vm.ProductStatus.Pending
                                  ? "Duyệt"
                                  : "Cập nhật"
                              )
                            ),
                          ]
                        ),
                        _vm.query.status == _vm.ProductStatus.Pending
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReject(row)
                                  },
                                },
                              },
                              [_vm._v(" Từ chối ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("Product-modal", {
        ref: "ProductModal",
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("import-product-modal", {
        ref: "ImportProductModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }