"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.object.values.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _idealProduct = require("@/types/idealProduct");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    idealProduct: {
      type: Object
    }
  },
  data: function data() {
    return {
      idealDrawCounter: {},
      idealPrizeType: _idealProduct.IdealPrizeType
    };
  },
  watch: {
    idealProduct: {
      handler: function handler() {
        if (this.idealProduct.idealDrawHistories.length) {
          this.handleCountIdealDraw(this.idealProduct.idealDrawHistories);
        }
      },
      immediate: true
    }
  },
  methods: {
    handleCountIdealDraw: function handleCountIdealDraw(idealDrawHistories) {
      var counterOj = {};
      idealDrawHistories.forEach(function (item) {
        var id = item.idealAward.idealPrize.id;
        var name = item.idealAward.idealPrize.name;
        var type = item.idealAward.idealPrize.type;
        if (counterOj[id]) {
          counterOj[id].quantity += 1;
        } else {
          counterOj[id] = {
            quantity: 1,
            name: name,
            id: id,
            type: type
          };
        }
      });
      this.idealDrawCounter = counterOj;
      console.log("object", Object.values(counterOj));
    },
    getTotalPrize: function getTotalPrize(prizeId) {
      var find = this.idealProduct.idealPrizes.find(function (e) {
        return e.id == prizeId;
      });
      if (find) {
        return "/".concat(find.quantity);
      }
    }
  }
};