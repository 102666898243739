"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CardProductTypeTrans = exports.CardProductType = exports.CARD_SUPPORTS = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _CardProductTypeTrans;
var CardProductType;
(function (CardProductType) {
  CardProductType["Mobi"] = "MOBI";
  CardProductType["Vinaphone"] = "VINAPHONE";
  CardProductType["Viettel"] = "VIETTEL";
  CardProductType["GMobile"] = "G_MOBILE";
  CardProductType["Garena"] = "GARENA_GAME";
  CardProductType["Zing"] = "ZING_GAME";
  CardProductType["Vtc"] = "VCOIN_GAME";
  CardProductType["SohaCoin"] = "SOHA_GAME";
  CardProductType["Gate"] = "GATE_GAME";
  CardProductType["FunCard"] = "FUNCA_GAME";
  CardProductType["Kul"] = "KUL_GAME";
  CardProductType["Gosu"] = "GOSU_GAME";
  CardProductType["VietNamMobile"] = "VIETNAM_MOBILE";
  CardProductType["Electric"] = "ELECTRIC";
  CardProductType["Water"] = "WATER";
  CardProductType["Internet"] = "INTERNET";
  CardProductType["PostPaid"] = "POST_PAID";
  CardProductType["DataViettel"] = "DATA_VIETTEL";
  CardProductType["DataVinaphone"] = "DATA_VINAPHONE";
  CardProductType["DataMobi"] = "DATA_MOBI";
  CardProductType["Finance"] = "FINANCE";
  CardProductType["Insurance"] = "INSURANCE";
  CardProductType["Telecom"] = "TELECOM";
  CardProductType["TV"] = "TV";
})(CardProductType || (exports.CardProductType = CardProductType = {}));
var CardProductTypeTrans = exports.CardProductTypeTrans = (_CardProductTypeTrans = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_CardProductTypeTrans, CardProductType.Mobi, 'Mobifone'), CardProductType.Viettel, 'Viettel'), CardProductType.Vinaphone, 'Vinaphone'), CardProductType.GMobile, 'Gmobile'), CardProductType.Garena, 'Garena'), CardProductType.Zing, 'Zing'), CardProductType.Vtc, 'Vcoin'), CardProductType.SohaCoin, 'Soha Coin'), CardProductType.Gate, 'Gate'), CardProductType.FunCard, 'Funcash'), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_CardProductTypeTrans, CardProductType.Kul, 'Kul'), CardProductType.Gosu, 'Gosu'), CardProductType.VietNamMobile, 'Vietnam Mobile'), CardProductType.Electric, 'Bill điện'), CardProductType.Water, 'Bill nước'), CardProductType.Internet, 'Bill internet'), CardProductType.PostPaid, 'Di động trả sau'), CardProductType.DataMobi, 'Data Mobifone'), CardProductType.DataViettel, 'Data Viettel'), CardProductType.DataVinaphone, 'Data Vinaphone'), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_CardProductTypeTrans, CardProductType.Finance, 'Tài chính'), CardProductType.Insurance, 'Bảo hiểm'), CardProductType.Telecom, 'Viễn thông'), CardProductType.TV, 'Truyền hình'));
var CARD_SUPPORTS = exports.CARD_SUPPORTS = [{
  name: CardProductTypeTrans[CardProductType.Viettel],
  // code: CardProductType.Viettel,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000],
  type: CardProductType.Viettel
}, {
  name: CardProductTypeTrans[CardProductType.Mobi],
  // code: CardProductType.Mobi,
  values: [10000, 20000, 50000, 100000, 200000, 500000],
  type: CardProductType.Mobi
}, {
  name: CardProductTypeTrans[CardProductType.Vinaphone],
  // code: CardProductType.Vinaphone,
  values: [10000, 20000, 50000, 100000, 200000, 500000],
  type: CardProductType.Vinaphone
}, {
  name: CardProductTypeTrans[CardProductType.VietNamMobile],
  // code: CardProductType.Vinaphone,
  values: [10000, 20000, 30000, 50000, 100000, 200000, 300000, 500000],
  type: CardProductType.VietNamMobile
}, {
  name: CardProductTypeTrans[CardProductType.GMobile],
  // code: CardProductType.GMobile,
  values: [10000, 20000, 50000, 100000, 200000, 500000],
  type: CardProductType.GMobile
}, {
  name: CardProductTypeTrans[CardProductType.Garena],
  // code: CardProductType.Garena,
  values: [20000, 50000, 100000, 200000, 500000],
  type: CardProductType.Garena
}, {
  name: CardProductTypeTrans[CardProductType.Zing],
  // code: CardProductType.Zing,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000],
  type: CardProductType.Zing
}, {
  name: CardProductTypeTrans[CardProductType.Vtc],
  // code: CardProductType.Vtc,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000],
  type: CardProductType.Vtc
}, {
  name: CardProductTypeTrans[CardProductType.SohaCoin],
  // code: CardProductType.SohaCoin,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000],
  type: CardProductType.SohaCoin
}, {
  name: CardProductTypeTrans[CardProductType.Gate],
  // code: CardProductType.Gate,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000],
  type: CardProductType.Gate
}, {
  name: CardProductTypeTrans[CardProductType.FunCard],
  // code: CardProductType.FunCard,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000],
  type: CardProductType.FunCard
}, {
  name: CardProductTypeTrans[CardProductType.Kul],
  // code: CardProductType.Kul,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000],
  type: CardProductType.Kul
}, {
  name: CardProductTypeTrans[CardProductType.Gosu],
  // code: CardProductType.Gosu,
  values: [10000, 20000, 50000, 100000, 200000, 500000, 1000000],
  type: CardProductType.Gosu
}, {
  name: CardProductTypeTrans[CardProductType.Electric],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.Electric
}, {
  name: CardProductTypeTrans[CardProductType.Water],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.Water
}, {
  name: CardProductTypeTrans[CardProductType.Internet],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.Internet
}, {
  name: CardProductTypeTrans[CardProductType.Finance],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.Finance
}, {
  name: CardProductTypeTrans[CardProductType.Insurance],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.Insurance
}, {
  name: CardProductTypeTrans[CardProductType.Telecom],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.Telecom
}, {
  name: CardProductTypeTrans[CardProductType.TV],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.TV
}, {
  name: CardProductTypeTrans[CardProductType.PostPaid],
  // code: CardProductType.Gosu,
  values: [],
  type: CardProductType.PostPaid
}, {
  name: CardProductTypeTrans[CardProductType.DataViettel],
  // code: CardProductType.Viettel,
  values: [6000, 7000, 10000, 11000, 15000, 17000, 20000, 22000, 29000, 30000, 36000, 38000, 70000, 90000, 120000, 150000],
  type: CardProductType.DataViettel
}, {
  name: CardProductTypeTrans[CardProductType.DataMobi],
  // code: CardProductType.Viettel,
  values: [10000, 14000, 15000, 20000, 28000, 30000, 42000, 56000, 84000],
  type: CardProductType.DataMobi
}, {
  name: CardProductTypeTrans[CardProductType.DataVinaphone],
  // code: CardProductType.Viettel,
  values: [20000, 50000, 60000, 70000, 100000, 120000, 150000, 160000],
  type: CardProductType.DataVinaphone
}];