"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionMallApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var transactionMallApi = exports.transactionMallApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/transactionMall",
      params: params
    });
  }
};