var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bio-detail" }, [
    _c(
      "div",
      { staticClass: "application-content" },
      [
        _c("head-section", { attrs: { title: "Ảnh chân dung" } }),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "avatar", label: "Hình nền trên ứng dụng" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    urlUpload: "/drivers/avatar/upload",
                    pathImage: _vm.$url.image(_vm.form.avatar),
                    name: "image",
                  },
                  on: { "upload:success": _vm.uploadSuccess },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Giới tính", prop: "gender" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.gender,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gender", $$v)
                      },
                      expression: "form.gender",
                    },
                  },
                  _vm._l(_vm.Gender, function (item, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: _vm.GenderTrans[item], value: item },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btn-save",
            attrs: { type: "primary", size: "medium", loading: _vm.loading },
            on: { click: _vm.handleSubmit },
          },
          [_vm._v("Lưu")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }