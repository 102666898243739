var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update" ? "Cập nhật sản phẩm" : "Thêm mới sản phẩm",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "info", label: "Thông tin" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                _c("product-info", {
                  ref: "ProductInfo",
                  attrs: {
                    form: _vm.form,
                    formDisable: false,
                    dialogStatus: _vm.status,
                    fileList: _vm.fileList,
                    extraContentList: _vm.extraContentList,
                    row: _vm.row,
                  },
                  on: {
                    "update:form": function ($event) {
                      _vm.form = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("el-tab-pane", { attrs: { name: "rate", label: "Đánh giá" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                _c("agent-product-rate-table", {
                  attrs: { productId: this.form.id },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.status == "create"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.createData },
                },
                [_vm._v("Đồng ý")]
              )
            : _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.form.status == _vm.ProductStatus.Pending
                        ? _vm.handleApprove(_vm.form)
                        : _vm.updateData()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.form.status == _vm.ProductStatus.Pending
                        ? "Duyệt"
                        : "Cập nhật"
                    )
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }