var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.dialogStatus == "add"
            ? "Thêm nhóm sản phẩm phụ"
            : "Cập nhật nhóm sản phẩm phụ",
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                disabled: _vm.dialogDisabled,
                rules: _vm.rules,
                model: _vm.tempData,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Sản phẩm", prop: "storeProductId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.tempData.storeProductId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "storeProductId", $$v)
                                },
                                expression: "tempData.storeProductId",
                              },
                            },
                            _vm._l(_vm.products, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Tên nhóm sản phẩm phụ",
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "name", $$v)
                              },
                              expression: "tempData.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Phương thức chọn",
                            prop: "selectType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.tempData.selectType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "selectType", $$v)
                                },
                                expression: "tempData.selectType",
                              },
                            },
                            _vm._l(_vm.SelectTypeTrans, function (name, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: name, value: key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Bắt buộc chọn",
                            prop: "isRequiredSelect",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-text": "Bắt buộc",
                              "inactive-text": "Không bắt buộc",
                            },
                            model: {
                              value: _vm.tempData.isRequiredSelect,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "isRequiredSelect", $$v)
                              },
                              expression: "tempData.isRequiredSelect",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Hiển thị", prop: "isVisible" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-text": "Bật",
                              "inactive-text": "Ẩn",
                            },
                            model: {
                              value: _vm.tempData.isVisible,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "isVisible", $$v)
                              },
                              expression: "tempData.isVisible",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Vị trí hiển thị", prop: "priority" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.tempData.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "priority", $$v)
                              },
                              expression: "tempData.priority",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.dialogDisabled &&
          _vm.dialogStatus != "edit" &&
          _vm.tempData.storeProductId
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleImport },
                },
                [_vm._v("Thêm hàng loạt")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          !_vm.dialogDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("import-sub-food-group-modal", {
        ref: "ImportSubFoodGroupModal",
        attrs: { storeProductId: _vm.tempData.storeProductId },
        on: {
          "update:storeProductId": function ($event) {
            return _vm.$set(_vm.tempData, "storeProductId", $event)
          },
          "update:store-product-id": function ($event) {
            return _vm.$set(_vm.tempData, "storeProductId", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }