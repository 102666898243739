var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update" ? "Cập nhật cấu hình" : "Thêm mới cấu hình",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "value" } },
                    [
                      _vm.form.dataType == _vm.ConfigurationType.Number
                        ? _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.form.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "value", $$v)
                              },
                              expression: "form.value",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }