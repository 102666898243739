var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Ngày bắt đầu" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.$formatDate(row.start)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Số ngày tối đa đạt doanh thu để thưởng",
              prop: "numOfDay",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.numOfDay)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Tổng doanh số đạt đuợc để thưởng",
              prop: "totalSale",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.totalSale)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Giá trị thưởng",
              prop: "rewardValue",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.rewardValue)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉnh / thành phố", prop: "city.nameWithType" },
          }),
          _c("el-table-column", {
            attrs: { label: "Kích hoạt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleChangeEnable(row)
                        },
                      },
                      model: {
                        value: row.isEnable,
                        callback: function ($$v) {
                          _vm.$set(row, "isEnable", $$v)
                        },
                        expression: "row.isEnable",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("guarantee-bonus-sale-modal", {
        ref: "GuaranteeBonusSaleModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }