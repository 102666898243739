"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolApi = void 0;
var _request = require("@/utils/request");
var schoolApi = exports.schoolApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/school',
      params: params
    });
  },
  findOne: function findOne(id, params) {
    return (0, _request.request)({
      url: "/school/".concat(id),
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/school',
      data: data,
      method: 'post'
    });
  },
  import: function _import(data) {
    return (0, _request.request)({
      url: '/school/import',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/school/".concat(id),
      method: 'patch',
      data: data
    });
  },
  visible: function visible(id, data) {
    return (0, _request.request)({
      url: "/school/".concat(id, "/visible"),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/school/".concat(id),
      method: 'delete'
    });
  },
  resetPassword: function resetPassword(id, data) {
    return (0, _request.request)({
      url: "/school/".concat(id, "/resetPassword"),
      method: 'patch',
      data: data
    });
  },
  approve: function approve(id) {
    return (0, _request.request)({
      url: "/school/".concat(id, "/approve"),
      method: 'patch'
    });
  },
  reject: function reject(id, data) {
    return (0, _request.request)({
      url: "/school/".concat(id, "/reject"),
      method: 'patch',
      data: data
    });
  }
};