"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShareConfigType = void 0;
var ShareConfigType;
(function (ShareConfigType) {
  //customer
  ShareConfigType["CustomerShareImage"] = "CUSTOMER_SHARE_IMAGE";
  ShareConfigType["CustomerShareCustomerContent"] = "CUSTOMER_SHARE_CUSTOMER_CONTENT";
  ShareConfigType["CustomerShareMerchantContent"] = "CUSTOMER_SHARE_MERCHANT_CONTENT";
  ShareConfigType["CustomerShareDriverContent"] = "CUSTOMER_SHARE_DRIVER_CONTENT";
  ShareConfigType["CustomerShareMallContent"] = "CUSTOMER_SHARE_MALL_CONTENT";
  ShareConfigType["CustomerShareContent"] = "CUSTOMER_SHARE_CONTENT";
  ShareConfigType["CustomerShareMedic"] = "CUSTOMER_SHARE_MEDIC";
  ShareConfigType["CustomerShareBookingHotel"] = "CUSTOMER_SHARE_BOOKING_HOTEL";
  ShareConfigType["CustomerShareCareer"] = "CUSTOMER_SHARE_CAREER";
  //merchant
  ShareConfigType["MerchantShareImage"] = "MERCHANT_SHARE_IMAGE";
  ShareConfigType["MerchantShareDriverContent"] = "MERCHANT_SHARE_DRIVER_CONTENT";
  ShareConfigType["MerchantShareMerchantContent"] = "MERCHANT_SHARE_MERCHANT_CONTENT";
  ShareConfigType["MerchantShareContent"] = "MERCHANT_SHARE_CONTENT";
  //driver
  ShareConfigType["DriverShareImage"] = "DRIVER_SHARE_IMAGE";
  ShareConfigType["DriverShareDriverContent"] = "DRIVER_SHARE_DRIVER_CONTENT";
  ShareConfigType["DriverShareMerchantContent"] = "DRIVER_SHARE_MERCHANT_CONTENT";
  ShareConfigType["DriverShareCustomerContent"] = "DRIVER_SHARE_CUSTOMER_CONTENT";
  ShareConfigType["DriverShareMallContent"] = "DRIVER_SHARE_MALL_CONTENT";
  ShareConfigType["DriverShareContent"] = "DRIVER_SHARE_CONTENT";
})(ShareConfigType || (exports.ShareConfigType = ShareConfigType = {}));