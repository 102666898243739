"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
var state = {
  selected: null,
  isUpdate: false
};
var mutations = {
  setSelected: function setSelected(state, store) {
    state.selected = JSON.parse(JSON.stringify(store));
  },
  setIsUpdate: function setIsUpdate(state, val) {
    state.isUpdate = val;
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations
};