var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên loại hình" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Tạo mới")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table-draggable",
        { on: { drop: _vm.handleDrop } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.data, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "60", align: "center", label: "STT" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c("span", [_vm._v(_vm._s($index + 1))]),
                        _c("i", {
                          staticClass: "el-icon-sort",
                          staticStyle: {
                            cursor: "move",
                            "font-size": "15px",
                            "font-weight": "bold",
                            color: "black",
                            "margin-left": "4px",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { width: "80", label: "Icon" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("img", {
                          staticStyle: { "object-fit": "cover" },
                          attrs: {
                            src: row.icon,
                            width: "40",
                            height: "40",
                            alt: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tên loại hình", prop: "name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [_vm._v(" " + _vm._s(row.name) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "right",
                  width: "100",
                  label: "Thứ tự",
                  prop: "position",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Thao tác", width: "300", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("school-major-modal", {
        ref: "SchoolMajorModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }