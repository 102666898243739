var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item " },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên agency" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getList($event)
                },
              },
              model: {
                value: _vm.agentQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.agentQuery, "search", $$v)
                },
                expression: "agentQuery.search",
              },
            }),
          ],
          1
        ),
        !_vm.agentQuery.parentId
          ? _c(
              "div",
              { staticClass: "filter-item " },
              [
                _c("label", [_vm._v("Thứ hạng")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { filterable: "", clearable: "" },
                    on: {
                      change: function () {
                        _vm.agentQuery.parentId = null
                        _vm.getList()
                      },
                    },
                    model: {
                      value: _vm.agentQuery.agentRankId,
                      callback: function ($$v) {
                        _vm.$set(_vm.agentQuery, "agentRankId", $$v)
                      },
                      expression: "agentQuery.agentRankId",
                    },
                  },
                  _vm._l(_vm.agentRanks, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "filter-item " },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.getList },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item " },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Tạo mới")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item " },
          [
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "primary",
                  loading: _vm.loadingExcel,
                  icon: "el-icon-download",
                },
                on: { click: _vm.handleExportExcel },
              },
              [_vm._v(" Xuất excel ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "nickname",
              label: "Tên agency",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", [
                        row.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "50px",
                                height: "auto",
                                "object-fit": "contain",
                                "margin-right": "10px",
                              },
                              attrs: { src: row.avatar, alt: "" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.name) + " ")]
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("label", [_vm._v("Tên đăng nhập: ")]),
                          _c("span", [_vm._v(_vm._s(row.username))]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.phone || "Chưa có thông tin") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã Affiliate", prop: "affCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.affCode || "Chưa có thông tin") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thứ hạng", prop: "agentRank" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.agentRank
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.agentRank.name || "Chưa có thông tin"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã giới thiệu", prop: "refCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.refCode || "Chưa có thông tin") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Người giới thiệu" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.parent ? row.parent.name : "Chưa có thông tin"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ", prop: "address" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.renderAddress(row)) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.agentStatusTrans[row.status].color,
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.agentStatusTrans[row.status].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDate(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "150",
              "class-name": "small-padding fixed-width",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        row.status == _vm.AgentStatus.Pending
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "flex-direction": "column",
                                  gap: "5px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApprove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Duyệt")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Từ chối")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        row.status == _vm.AgentStatus.Approve
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResetPassword(row)
                                  },
                                },
                              },
                              [_vm._v(" Cấp lại mật khẩu ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.agentQuery.page,
          limit: _vm.agentQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.agentQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.agentQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("Agent-modal", {
        ref: "AgentModal",
        on: { "submit:ok": _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }