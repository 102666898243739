var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Sao chép  thông tin cửa hàng",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingFetchData,
              expression: "loadingFetchData",
            },
          ],
        },
        [
          _c("upload-excel", {
            staticStyle: { "margin-top": "12px" },
            attrs: { onSuccess: _vm.uploadSuccess, sheetName: _vm.sheetName },
          }),
          _c(
            "el-link",
            {
              staticStyle: { "margin-top": "12px" },
              attrs: { type: "primary", href: "/danh_sach_cua_hang.xlsx" },
            },
            [
              _c("div", { staticClass: "el-icon-download" }),
              _vm._v(" Tải file mẫu "),
            ]
          ),
          _c("div", { staticStyle: { "margin-top": "12px" } }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Tổng dòng: " + _vm._s(_vm.data.length)),
            ]),
            _vm.countSuccess
              ? _c("span", { staticStyle: { color: "rgb(10, 187, 135)" } }, [
                  _vm._v(" (Thành công: " + _vm._s(_vm.countSuccess) + ")"),
                ])
              : _vm._e(),
          ]),
          _vm.isProgressing
            ? _c(
                "div",
                [_c("el-progress", { attrs: { percentage: _vm.percent } })],
                1
              )
            : _vm._e(),
          _vm.errors.length
            ? _c(
                "div",
                { staticStyle: { "margin-top": "16px" } },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(
                      "Danh sách dòng lỗi (" + _vm._s(_vm.errors.length) + ")"
                    ),
                  ]),
                  _vm._l(_vm.errors, function (item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(" " + _vm._s(item) + " "),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.data.length,
                loading: _vm.loading,
              },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }