"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.excelDateToJSDate = excelDateToJSDate;
exports.formatDate = formatDate;
exports.formatDateDay = formatDateDay;
exports.formatDateTime = formatDateTime;
var moment = require("moment");
function formatDate(timestamp) {
  if (timestamp) {
    return moment.unix(timestamp).format("DD/MM/YYYY");
  }
  return "--";
}
function formatDateTime(timestamp) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "HH:mm, DD/MM/YYYY";
  if (timestamp) {
    return moment.unix(timestamp).format(format);
  }
  return "--";
}
function formatDateDay(timestamp) {
  return moment.unix(timestamp).format("dddd, MM/DD/YYYY");
}
function excelDateToJSDate(date) {
  return new Date(Math.round((date - 25569) * 86400 * 1000));
}