import { render, staticRenderFns } from "./PromotionTable.vue?vue&type=template&id=613c0ade&scoped=true"
import script from "./PromotionTable.vue?vue&type=script&lang=ts"
export * from "./PromotionTable.vue?vue&type=script&lang=ts"
import style0 from "./PromotionTable.vue?vue&type=style&index=0&id=613c0ade&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613c0ade",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Untitled/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('613c0ade')) {
      api.createRecord('613c0ade', component.options)
    } else {
      api.reload('613c0ade', component.options)
    }
    module.hot.accept("./PromotionTable.vue?vue&type=template&id=613c0ade&scoped=true", function () {
      api.rerender('613c0ade', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/promotion/PromotionTable.vue"
export default component.exports