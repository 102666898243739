"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderDeliveryType = exports.OrderDeliveryStatus = exports.OrderDeliveryDetailStatus = void 0;
var OrderDeliveryDetailStatus;
(function (OrderDeliveryDetailStatus) {
  OrderDeliveryDetailStatus["Pending"] = "PENDING";
  OrderDeliveryDetailStatus["Complete"] = "COMPLETE";
  OrderDeliveryDetailStatus["Refund"] = "REFUND";
})(OrderDeliveryDetailStatus || (exports.OrderDeliveryDetailStatus = OrderDeliveryDetailStatus = {}));
var OrderDeliveryType = exports.OrderDeliveryType = {
  Customer: "CUSTOMER",
  Store: "STORE"
};
var OrderDeliveryStatus;
(function (OrderDeliveryStatus) {
  OrderDeliveryStatus["PendingPayment"] = "PENDING_PAYMENT";
  OrderDeliveryStatus["FindDriver"] = "FIND_DRIVER";
  OrderDeliveryStatus["AcceptOrder"] = "ACCEPT_ORDER";
  OrderDeliveryStatus["Run"] = "RUN";
  OrderDeliveryStatus["Pay"] = "PAY";
  OrderDeliveryStatus["Complete"] = "COMPLETE";
  OrderDeliveryStatus["CustomerCancel"] = "CUSTOMER_CANCEL";
  OrderDeliveryStatus["DriverCancel"] = "DRIVER_CANCEL";
})(OrderDeliveryStatus || (exports.OrderDeliveryStatus = OrderDeliveryStatus = {}));