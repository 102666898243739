var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "food-detail-list",
        title: "Chi tiết đơn - " + _vm.formData.code,
        visible: _vm.dialogVisible,
        width: "1200px",
        "append-to-body": "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "detail", label: "Chi tiết đơn" } },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("ul", { staticClass: "payment-detail" }, [
                  _c("li", [
                    _c("span", [_vm._v("Trạng thái thanh toán:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.PaymentStatusTrans[_vm.formData.paymentStatus]
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Giá cước:")]),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(
                        "+ " +
                          _vm._s(
                            _vm.$formatCurrency(_vm.formData.moneyDistance)
                          )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Phí nền tảng:")]),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(
                        "+ " +
                          _vm._s(
                            _vm.$formatCurrency(_vm.formData.moneyBaseCustomer)
                          )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Thưởng chuyến đi:")]),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(
                        "+ " +
                          _vm._s(
                            _vm.$formatCurrency(_vm.formData.moneyRewardDriver)
                          )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Phụ thu giờ cao điểm:")]),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(
                        "+ " +
                          _vm._s(
                            _vm.$formatCurrency(_vm.formData.moneyRushHour)
                          )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Tip:")]),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(
                        "+ " + _vm._s(_vm.$formatCurrency(_vm.formData.tip))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Tổng cước phí:")]),
                    _c("span", { staticClass: "warning" }, [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyTotal))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Doanh thu không Thuế:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$formatCurrency(_vm.formData.moneyTotalNoTax)
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [
                      _vm._v("Thuế VAT " + _vm._s(_vm.formData.vat) + "%:"),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyVAT))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Phí sử dụng ứng dụng :")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$formatCurrency(_vm.formData.moneyUseAppDriver)
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Phí nền tảng tài xế:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$formatCurrency(_vm.formData.moneyBaseDriver)
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Thu nhập tài xế:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$formatCurrency(_vm.formData.moneyIncomeNoTax)
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Thuế TNCN 1,5%:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyTaxDriver))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Tài xế thực nhận:")]),
                    _c("span", { staticClass: "warning" }, [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyIncome))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Khuyến mãi:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyDiscount))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Người Dùng trả (Tiền mặt):")]),
                    _c("span", { staticClass: "warning" }, [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyFinal))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.formData.reviewStar > 0
                ? _c("review-panel", {
                    staticStyle: { "margin-bottom": "12px" },
                    attrs: {
                      star: _vm.formData.reviewStar,
                      content: _vm.formData.reviewContent,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "logs", label: "Logs" } },
            [
              _c("transport-order-logging", {
                attrs: { orderId: _vm.formData.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "driver-transport", label: "Tài xế di chuyển" } },
            [
              _c("TrackingGPS", {
                ref: "TrackingGPS",
                attrs: { visibleDateFilter: false },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { width: "0", height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }