var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card", "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Kích hoạt", name: "active" } },
            [
              _c("user", {
                attrs: {
                  "is-block": false,
                  name: "active",
                  "tab-name": _vm.tabName,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Khoá", name: "block" } },
            [
              _c("user", {
                attrs: {
                  "is-block": true,
                  name: "block",
                  "tab-name": _vm.tabName,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }