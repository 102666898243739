import { render, staticRenderFns } from "./BioDetail.vue?vue&type=template&id=ed842c1c&scoped=true"
import script from "./BioDetail.vue?vue&type=script&lang=ts"
export * from "./BioDetail.vue?vue&type=script&lang=ts"
import style0 from "./BioDetail.vue?vue&type=style&index=0&id=ed842c1c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed842c1c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Untitled/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ed842c1c')) {
      api.createRecord('ed842c1c', component.options)
    } else {
      api.reload('ed842c1c', component.options)
    }
    module.hot.accept("./BioDetail.vue?vue&type=template&id=ed842c1c&scoped=true", function () {
      api.rerender('ed842c1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/driver-profile/detail/section/BioDetail.vue"
export default component.exports