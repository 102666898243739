var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật chương trình"
            : "Thêm mới chương trình",
        visible: _vm.visible,
        width: "98%",
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { name: "product", label: "Sản phẩm" } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { padding: "1em" }, attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "border-right": "1px solid #efefef" },
                          attrs: { span: 12, offset: 0 },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 12 } },
                            [
                              _c("h2", { staticStyle: { "margin-top": "0" } }, [
                                _vm._v("Sản phẩm"),
                              ]),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      "Hình ảnh (Tỷ lệ 2/1, khuyến nghị: 500 x 250, dung lượng tối đa 2MB)",
                                    prop: "eVoucher.imageUrl",
                                  },
                                },
                                [
                                  _c("SingleImageS3", {
                                    attrs: {
                                      pathImage: _vm.form.imageUrl,
                                      width: 300,
                                      height: 120,
                                    },
                                    on: { "upload:success": _vm.setImage },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tên CTKM",
                                        prop: "promotionName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.form.promotionName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "promotionName",
                                              $$v
                                            )
                                          },
                                          expression: "form.promotionName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tên chiến dịch",
                                        prop: "name",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.form.isPublished &&
                                            _vm.status == "update",
                                        },
                                        model: {
                                          value: _vm.form.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "name", $$v)
                                          },
                                          expression: "form.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Đơn vị", prop: "unit" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "VD: vnđ/cây",
                                          disabled:
                                            _vm.form.isPublished &&
                                            _vm.status == "update",
                                        },
                                        model: {
                                          value: _vm.form.unit,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "unit", $$v)
                                          },
                                          expression: "form.unit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        label: "Giá bán Net",
                                        prop: "price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.form.isPublished &&
                                            _vm.status == "update",
                                        },
                                        on: {
                                          input: function () {
                                            return _vm.handleCalcMoneyFinal()
                                          },
                                        },
                                        model: {
                                          value: _vm.form.price,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "price", $$v)
                                          },
                                          expression: "form.price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        label: "Phần trăm VAT",
                                        prop: "taxPercent",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.form.isPublished &&
                                              _vm.status == "update",
                                          },
                                          on: {
                                            input: function () {
                                              return _vm.handleCalcMoneyFinal()
                                            },
                                          },
                                          model: {
                                            value: _vm.form.taxPercent,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "taxPercent",
                                                $$v
                                              )
                                            },
                                            expression: "form.taxPercent",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "append" },
                                              slot: "append",
                                            },
                                            [_vm._v("%")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "margin-top": "-10px",
                                        color: "#2295ff",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Thuế VAT: " +
                                          _vm._s(
                                            isNaN(_vm.form.moneyTax)
                                              ? 0
                                              : _vm.$formatNumberVN(
                                                  _vm.form.moneyTax
                                                )
                                          ) +
                                          " vnđ"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        label: "Giá bán Gross",
                                        prop: "finalPrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          readonly: true,
                                          disabled: true,
                                        },
                                        model: {
                                          value: _vm.form.finalPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "finalPrice",
                                              $$v
                                            )
                                          },
                                          expression: "form.finalPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Mô tả",
                                        prop: "description",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled:
                                            _vm.form.isPublished &&
                                            _vm.status == "update",
                                          type: "textarea",
                                          autosize: { minRows: 3, col: 24 },
                                        },
                                        model: {
                                          value: _vm.form.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "form.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.status == "create" ? 12 : 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        label: "Số lượng phải bán",
                                        prop: "quantity",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.status == "update" &&
                                            (_vm.form.isPublished ||
                                              _vm.form.sold + _vm.form.pending >
                                                0),
                                        },
                                        model: {
                                          value: _vm.form.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "quantity", $$v)
                                          },
                                          expression: "form.quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.status == "update"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: {
                                                alias: "decimal",
                                                groupSeparator: ".",
                                                rightAlign: false,
                                                autoGroup: true,
                                                repeat: 20,
                                                autoUnmask: true,
                                                allowMinus: false,
                                              },
                                              expression:
                                                "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                            },
                                          ],
                                          attrs: { label: "Số lượng đã bán" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.form.finalSold,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "finalSold",
                                                  $$v
                                                )
                                              },
                                              expression: "form.finalSold",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.status == "create" ? 12 : 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Ngày bốc thăm (dự kiến)",
                                        prop: "maxDrawDate",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled: _vm.status == "update",
                                          type: "datetime",
                                          placeholder: "DD/MM/YYYY",
                                          format: "HH:mm dd/MM/yyyy",
                                          "value-format": "yyyy-MM-dd HH:mm",
                                        },
                                        model: {
                                          value: _vm.form.maxDrawDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "maxDrawDate",
                                              $$v
                                            )
                                          },
                                          expression: "form.maxDrawDate",
                                        },
                                      }),
                                      _vm.status == "update"
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "mini",
                                              },
                                              on: {
                                                click: _vm.handleRenewDrawDate,
                                              },
                                            },
                                            [_vm._v("Gia hạn ngày bốc thăm")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isIdealRewardRef
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        "margin-right": "4px",
                                      },
                                    },
                                    [_vm._v("Cho phép thưởng người giới thiệu")]
                                  ),
                                  _c("el-switch", {
                                    attrs: {
                                      disabled:
                                        _vm.status == "update" &&
                                        (_vm.form.isPublished ||
                                          _vm.form.sold + _vm.form.pending > 0),
                                      "active-color": "#13ce66",
                                      "inactive-color": "#ff4949",
                                    },
                                    model: {
                                      value: _vm.form.isAllowReward,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isAllowReward", $$v)
                                      },
                                      expression: "form.isAllowReward",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isIdealRewardRef
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12, offset: 0 } },
                                    [
                                      _vm.form.isAllowReward == true
                                        ? _c(
                                            "el-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "mask",
                                                  rawName: "v-mask",
                                                  value: {
                                                    alias: "decimal",
                                                    groupSeparator: ".",
                                                    rightAlign: false,
                                                    autoGroup: true,
                                                    repeat: 20,
                                                    autoUnmask: true,
                                                    allowMinus: false,
                                                  },
                                                  expression:
                                                    "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                                },
                                              ],
                                              attrs: {
                                                label:
                                                  "Số lượng sản phẩm cần mua",
                                                prop: "needToReward",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled:
                                                    _vm.status == "update" &&
                                                    (_vm.form.isPublished ||
                                                      _vm.form.sold +
                                                        _vm.form.pending >
                                                        0),
                                                },
                                                model: {
                                                  value: _vm.form.needToReward,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "needToReward",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.needToReward",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12, offset: 0 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        label: "Số lượng phiếu trên 1 sản phẩm",
                                        prop: "genCodeRate",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled:
                                            _vm.status == "update" &&
                                            (_vm.form.isPublished ||
                                              _vm.form.sold + _vm.form.pending >
                                                0),
                                        },
                                        model: {
                                          value: _vm.form.genCodeRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "genCodeRate",
                                              $$v
                                            )
                                          },
                                          expression: "form.genCodeRate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("h2", [_vm._v("Vận chuyển")]),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Cân nặng (Sau khi đóng gói)",
                                    prop: "weight",
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.form.isPublished &&
                                          _vm.status == "update",
                                      },
                                      model: {
                                        value: _vm.form.weight,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "weight", $$v)
                                        },
                                        expression: "form.weight",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("gr"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "el-form-item__label",
                              staticStyle: {
                                "padding-left": "6px",
                                width: "100%",
                              },
                            },
                            [
                              _vm._v(
                                "Kích thước đóng gói (Phí vận chuyển thực tế sẽ thay đổi nếu bạn nhập sai kích thước)"
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "length", label: "Dài" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.form.isPublished &&
                                          _vm.status == "update",
                                        placeholder: "Dài",
                                      },
                                      model: {
                                        value: _vm.form.length,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "length", $$v)
                                        },
                                        expression: "form.length",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("cm"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "width", label: "Rộng" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.form.isPublished &&
                                          _vm.status == "update",
                                        placeholder: "Rộng",
                                      },
                                      model: {
                                        value: _vm.form.width,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "width", $$v)
                                        },
                                        expression: "form.width",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("cm"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "height", label: "Cao" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: {
                                            alias: "decimal",
                                            groupSeparator: ".",
                                            rightAlign: false,
                                            autoGroup: true,
                                            repeat: 20,
                                            autoUnmask: true,
                                            allowMinus: false,
                                          },
                                          expression:
                                            "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: "Cao",
                                        disabled:
                                          _vm.form.isPublished &&
                                          _vm.status == "update",
                                      },
                                      model: {
                                        value: _vm.form.height,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "height", $$v)
                                        },
                                        expression: "form.height",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("cm"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "flex",
                        width: "100%",
                        "justify-content": "end",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [_vm._v("Đóng")]
                      ),
                      _vm.status == "create" ||
                      _vm.$checkAction("update-product")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  _vm.status == "create"
                                    ? _vm.createData()
                                    : _vm.updateData()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.status == "create" ? "Tạo" : "Cập nhật"
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.status == "update"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: { name: "idealPrize", label: "Giải trúng thưởng" },
                    },
                    [
                      _c("IdealPrizesTab", {
                        attrs: { idealProduct: _vm.form },
                        on: { submitOk: _vm.onSubmitOk },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.status == "update"
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: { name: "idealCode", label: "Danh sách các thăm" },
                    },
                    [
                      _c("ideal-code-page", {
                        attrs: { productId: _vm.form.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("renew-modal", {
        ref: "renewModalRef",
        on: {
          submitOk: function (newDate) {
            _vm.onRenewSuccess(newDate)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }