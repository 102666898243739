var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tiêu đề", prop: "mallBanner.title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.mallBanner.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.mallBanner, "title", $$v)
                  },
                  expression: "form.mallBanner.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Vị trí hiển thị", prop: "mallBanner.layout" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.form.mallBanner.layout,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.mallBanner, "layout", $$v)
                    },
                    expression: "form.mallBanner.layout",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "Đầu trang",
                      value: _vm.bannerLayoutTrans["TOP"].value,
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "Giữa trang",
                      value: _vm.bannerLayoutTrans["BOTTOM"].value,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-radio",
            {
              attrs: { label: _vm.MallBannerType.Mall },
              model: {
                value: _vm.form.mallBanner.type,
                callback: function ($$v) {
                  _vm.$set(_vm.form.mallBanner, "type", $$v)
                },
                expression: "form.mallBanner.type",
              },
            },
            [_vm._v("Cho cửa hàng")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: _vm.MallBannerType.MallProduct },
              model: {
                value: _vm.form.mallBanner.type,
                callback: function ($$v) {
                  _vm.$set(_vm.form.mallBanner, "type", $$v)
                },
                expression: "form.mallBanner.type",
              },
            },
            [_vm._v("Cho sản phẩm")]
          ),
          _vm.form.mallBanner.type == _vm.MallBannerType.Mall
            ? _c(
                "el-form-item",
                { attrs: { label: "Cửa hàng", prop: "mallId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        remote: "",
                        "remote-method": _vm.fetchMalls,
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.mallId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "mallId", $$v)
                        },
                        expression: "form.mallId",
                      },
                    },
                    _vm._l(_vm.malls, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + " - " + item.address,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "Sản phẩm", prop: "mallProductIds" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.ProductTableModal.handleView(
                            _vm.selection
                          )
                        },
                      },
                    },
                    [_vm._v("Chọn sản phẩm")]
                  ),
                  _vm.selection.length
                    ? _c(
                        "div",
                        [
                          _vm._v("Số sản phẩm đã chọn: "),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.ProductTableModal.handleView(
                                    _vm.selection,
                                    true
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Xem " +
                                  _vm._s(_vm.selection.length) +
                                  " đã chọn"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "Ngày bắt đầu",
                        prop: "mallBanner.startAt",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "dd/MM/yyyy",
                          "value-format": "timestamp",
                          type: "date",
                          placeholder: "Pick a day",
                        },
                        model: {
                          value: _vm.form.mallBanner.startAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.mallBanner, "startAt", $$v)
                          },
                          expression: "form.mallBanner.startAt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ngày kết thúc",
                        prop: "mallBanner.endAt",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "dd/MM/yyyy",
                          "value-format": "timestamp",
                          type: "date",
                          placeholder: "Pick a day",
                        },
                        model: {
                          value: _vm.form.mallBanner.endAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.mallBanner, "endAt", $$v)
                          },
                          expression: "form.mallBanner.endAt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "mallBanner.image",
                label:
                  "Banner (Tỷ lệ: 20/8, khuyến nghị: 428 x 171, dung lượng tối đa 2MB)",
              },
            },
            [
              _c("SingleImageS3", {
                attrs: {
                  width: 300,
                  height: 120,
                  pathImage: _vm.form.mallBanner.image,
                  prefixFile: _vm.PrefixImage.Banner,
                },
                on: { "upload:success": _vm.setImage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
      _c("product-table-modal", {
        ref: "ProductTableModal",
        attrs: { module: _vm.module },
        on: { "submit:ok": _vm.handleSelectProducts },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }