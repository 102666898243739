"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.employerDepositApi = void 0;
var _request = require("@/utils/request");
var employerDepositApi = exports.employerDepositApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/employerDeposit',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/employerDeposit',
      data: data,
      method: 'post'
    });
  }
};