var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật tiêu chí",
        visible: _vm.visible,
        top: "30px",
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tiêu chí" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: "",
                  value:
                    _vm.ConfigProfessionalDriverParamsTrans[_vm.form.param],
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Giá trị", prop: "value" } },
            [
              _c(
                "el-input",
                {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: {
                        alias: "decimal",
                        groupSeparator: ".",
                        rightAlign: false,
                        autoGroup: true,
                        repeat: 20,
                        autoUnmask: true,
                        allowMinus: false,
                      },
                      expression:
                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                    },
                  ],
                  model: {
                    value: _vm.form.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "value", $$v)
                    },
                    expression: "form.value",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [
                    _vm._v(_vm._s(_vm.renderPrefixValue)),
                  ]),
                  _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.renderSuffixValue)),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: { click: _vm.updateData },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }