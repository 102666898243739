var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chi tiết đơn hàng",
        visible: _vm.visible,
        top: "30px",
        width: "900px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "list-box", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c("h3", [_vm._v("Hình ảnh lấy hàng")]),
            _c(
              "div",
              {
                staticClass: "item light-box-shadow",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _c("img", {
                  staticStyle: { height: "250px" },
                  attrs: { src: _vm.data.imagePickup, alt: "" },
                }),
                _c("div", { staticClass: "mask" }, [
                  _c(
                    "span",
                    {
                      staticClass: "icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.handleView(_vm.data.imagePickup)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-zoom-in" })]
                  ),
                ]),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 12, offset: 0 } }, [
            _c("h3", [_vm._v("Hình ảnh giao hàng")]),
            _c(
              "div",
              {
                staticClass: "item light-box-shadow",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _c("img", {
                  staticStyle: { height: "250px" },
                  attrs: { src: _vm.data.imageDelivered, alt: "" },
                }),
                _c("div", { staticClass: "mask" }, [
                  _c(
                    "span",
                    {
                      staticClass: "icon-delete",
                      on: {
                        click: function ($event) {
                          return _vm.handleView(_vm.data.imageDelivered)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-zoom-in" })]
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "Xem ảnh",
            visible: _vm.previewModal,
            width: "500px",
            top: "50px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              staticStyle: { width: "250px", "object-fit": "contain" },
              attrs: { src: _vm.imageSelected, alt: "" },
            }),
          ]),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.previewModal = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }