var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật thưởng",
        visible: _vm.visible,
        top: "30px",
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Từ giờ", prop: "from" } },
                    [
                      _c("el-time-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "hh:mm",
                          "value-format": "hh:mm",
                          "picker-options": {
                            start: "00:00",
                            step: "00:15",
                            end: "23:30",
                          },
                        },
                        model: {
                          value: _vm.form.from,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "from", $$v)
                          },
                          expression: "form.from",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đến giờ", prop: "to" } },
                    [
                      _c("el-time-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "h:mm",
                          "value-format": "hh:mm",
                          "picker-options": {
                            start: "00:00",
                            step: "00:15",
                            end: "23:30",
                          },
                        },
                        model: {
                          value: _vm.form.to,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "to", $$v)
                          },
                          expression: "form.to",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Số cuốc tối thiểu", prop: "minOrders" },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.minOrders,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "minOrders", $$v)
                          },
                          expression: "form.minOrders",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Số cuốc tối đa (0: không giới hạn)",
                        prop: "maxOrders",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.maxOrders,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "maxOrders", $$v)
                          },
                          expression: "form.maxOrders",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Mức thưởng chuẩn",
                        prop: "rewardAmount",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                            },
                          ],
                          model: {
                            value: _vm.form.rewardAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "rewardAmount", $$v)
                            },
                            expression: "form.rewardAmount",
                          },
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(" / cuốc ngắn "),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: { click: _vm.updateData },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }