var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-form bg-yellow" },
    [
      _vm._m(0),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "companyName", label: "Tên tổ chức" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập vào tên tổ chức",
                    },
                    model: {
                      value: _vm.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "taxCode", label: "Mã số thuế tổ chức" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập vào mã số thuế tổ chức",
                    },
                    model: {
                      value: _vm.form.taxCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "taxCode", $$v)
                      },
                      expression: "form.taxCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Địa chỉ trụ sở chính",
                    prop: "companyAddress",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập vào địa chỉ trụ sở chính",
                    },
                    model: {
                      value: _vm.form.companyAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyAddress", $$v)
                      },
                      expression: "form.companyAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Tài khoản ngân hàng số", prop: "bankNumber" } },
        [
          _c("el-input", {
            attrs: { size: "medium" },
            model: {
              value: _vm.form.bankNumber,
              callback: function ($$v) {
                _vm.$set(_vm.form, "bankNumber", $$v)
              },
              expression: "form.bankNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "bankId", label: "Tại ngân hàng" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: {
                filterable: "",
                "allow-create": "",
                placeholder: "Chọn ngân hàng",
              },
              model: {
                value: _vm.form.bankId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "bankId", $$v)
                },
                expression: "form.bankId",
              },
            },
            _vm._l(_vm.bankList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Tỉnh/Thành", prop: "cityId" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { filterable: "", size: "medium" },
              on: { change: _vm.handleChangeCity },
              model: {
                value: _vm.form.cityId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "cityId", $$v)
                },
                expression: "form.cityId",
              },
            },
            _vm._l(_vm.cities, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.nameWithType, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Quận/huyện", prop: "districtId" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { size: "medium", filterable: "" },
              on: { change: _vm.handleChangeDistrict },
              model: {
                value: _vm.form.districtId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "districtId", $$v)
                },
                expression: "form.districtId",
              },
            },
            _vm._l(_vm.districts, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.nameWithType, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Phường/xã", prop: "wardId" } },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { size: "medium", filterable: "" },
              model: {
                value: _vm.form.wardId,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "wardId", $$v)
                },
                expression: "form.wardId",
              },
            },
            _vm._l(_vm.wards, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.nameWithType, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "tel", label: "Số cơ quan" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập vào số cơ quan",
                    },
                    model: {
                      value: _vm.form.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "phone", label: "Số di động" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập vào số di động",
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "email", label: "Email" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "Nhập vào email" },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { prop: "ownerName", label: "Người đại diện đăng ký" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "Nhập người đại diện đăng ký",
                    },
                    model: {
                      value: _vm.form.ownerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerName", $$v)
                      },
                      expression: "form.ownerName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0, xs: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "ownerPosition", label: "Chức vụ" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "Nhập vào chức vụ" },
                    model: {
                      value: _vm.form.ownerPosition,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerPosition", $$v)
                      },
                      expression: "form.ownerPosition",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "certImage1",
            label: "Hình ảnh giấy ủy quyền của Đại diện theo pháp luật",
          },
        },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.certImage1, width: 200, height: 200 },
            on: {
              "upload:success": function (url) {
                return _vm.handleUploadSuccess("certImage1", url)
              },
            },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.form.certImage1
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleViewImage(_vm.form.certImage1)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-view" }),
                      _vm._v(" Xem ảnh "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "certImage2",
            label: "Hình ảnh giấy chứng nhận đăng ký Kinh doanh",
          },
        },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.certImage2, width: 200, height: 200 },
            on: {
              "upload:success": function (url) {
                return _vm.handleUploadSuccess("certImage2", url)
              },
            },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.form.certImage2
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleViewImage(_vm.form.certImage2)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-view" }),
                      _vm._v(" Xem ảnh "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "certImage3",
            label:
              "Hình ảnh bản quyền tác giả / Giấy ủy quyền có Công chứng / Giấy tờ tương đương",
          },
        },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.certImage3, width: 200, height: 200 },
            on: {
              "upload:success": function (url) {
                return _vm.handleUploadSuccess("certImage3", url)
              },
            },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.form.certImage3
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleViewImage(_vm.form.certImage3)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-view" }),
                      _vm._v(" Xem ảnh "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("span", { staticStyle: { color: "#ff4949" } }, [
        _vm._v(
          "Tôi xin hoàn toàn tự chịu trách nhiệm về bản quyền tác giả trước pháp luật"
        ),
      ]),
      _c(
        "el-form-item",
        { attrs: { prop: "sealImage", label: "Hình ảnh con dấu của Tổ chức" } },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.sealImage, width: 200, height: 200 },
            on: {
              "upload:success": function (url) {
                return _vm.handleUploadSuccess("sealImage", url)
              },
            },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.form.sealImage
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleViewImage(_vm.form.sealImage)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-view" }),
                      _vm._v(" Xem ảnh "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { display: "none" },
        attrs: { src: _vm.image, "preview-src-list": [_vm.image] },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("img", {
        staticStyle: { "vertical-align": "middle" },
        attrs: { width: "40", src: require("@/assets/images/icon4.png") },
      }),
      _c("span", { staticStyle: { "vertical-align": "middle" } }, [
        _vm._v(" Thông tin tổ chức "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }