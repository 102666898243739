var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
    },
    [
      _c("h2", [_vm._v("Bài video")]),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "medium" },
          on: {
            click: function ($event) {
              return _vm.handleAddOrUpdateVideo()
            },
          },
        },
        [_vm._v("Tải video")]
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "name", label: "Tên video" } },
        [
          _c("el-input", {
            attrs: { maxlength: "100", "show-word-limit": "" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Tải video lên", prop: "sourceUrl" } },
        [
          _c("span", [_vm._v("(Dung lượng tối đa ...MB)")]),
          _c("UploadVideoS3", {
            attrs: { videoUrl: _vm.form.sourceUrl, width: 400, height: 200 },
            on: {
              "upload:success": _vm.setVideo,
              delete: _vm.handleDeleteVideo,
              onDurationChange: function (value) {
                return (_vm.form.duration = value)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Thực hành", prop: "description" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 6 },
              maxlength: "1000",
              "show-word-limit": "",
            },
            model: {
              value: _vm.form.description,
              callback: function ($$v) {
                _vm.$set(_vm.form, "description", $$v)
              },
              expression: "form.description",
            },
          }),
        ],
        1
      ),
      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
        _c("label", [_vm._v("Các video đã tải")]),
      ]),
      _vm.multipleSelection.length
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "5px" },
              attrs: { type: "danger", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.deleteMultipleVideo()
                },
              },
            },
            [_vm._v("Xóa")]
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          attrs: {
            data: this.eduLectures,
            border: "",
            stripe: "",
            "highlight-current-row": "",
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "current-change": _vm.handleCurrentChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "55" },
          }),
          _c("el-table-column", {
            attrs: { label: "STT", width: "50", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_vm._v(" " + _vm._s($index + 1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tên bài video", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.name) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Thời lượng", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDuration(row.duration)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Video", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "info",
                          on: {
                            click: function ($event) {
                              return _vm.handleOpenVideo(row.sourceUrl)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "name" }, [
                            _c("i", { staticClass: "el-icon-video-play" }),
                            _vm._v("Xem video"),
                          ]),
                        ]
                      ),
                      _c("video", {
                        staticClass: "video",
                        staticStyle: { "object-fit": "cover" },
                        attrs: { src: row.sourceUrl, alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("VideoModal", {
        ref: "VideoModal",
        attrs: { title: "Video bài học" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }