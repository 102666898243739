var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        fullscreen: "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Tiêu đề",
                                prop: "promotion.title",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.promotion.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.promotion, "title", $$v)
                                  },
                                  expression: "form.promotion.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Code", prop: "promotion.code" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.promotion.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.promotion, "code", $$v)
                                  },
                                  expression: "form.promotion.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: "Ngày bắt đầu",
                                prop: "promotion.start",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "dd/MM/yyyy",
                                  "value-format": "timestamp",
                                  type: "date",
                                  placeholder: "Pick a day",
                                },
                                model: {
                                  value: _vm.form.promotion.start,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.promotion, "start", $$v)
                                  },
                                  expression: "form.promotion.start",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Ngày kết thúc",
                                prop: "promotion.end",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "dd/MM/yyyy",
                                  "value-format": "timestamp",
                                  type: "date",
                                  placeholder: "Pick a day",
                                },
                                model: {
                                  value: _vm.form.promotion.end,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.promotion, "end", $$v)
                                  },
                                  expression: "form.promotion.end",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Khuyến mãi cho" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled:
                                      _vm.orderType !=
                                      _vm.PromotionOrderType.OrderFood,
                                  },
                                  model: {
                                    value: _vm.form.promotion.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.promotion, "type", $$v)
                                    },
                                    expression: "form.promotion.type",
                                  },
                                },
                                _vm._l(
                                  _vm.PromotionTypeTrans,
                                  function (item, key) {
                                    return _c("el-option", {
                                      key: key,
                                      attrs: { label: item, value: key },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Giá trị đơn hàng tối thiểu (" +
                                  _vm.PromotionTypeTrans[
                                    _vm.form.promotion.type
                                  ] +
                                  ")",
                                prop: "promotion.conditionMinPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.promotion.conditionMinPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.promotion,
                                      "conditionMinPrice",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.promotion.conditionMinPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giảm giá theo",
                                prop: "discountType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.form.promotion.discountType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.promotion,
                                        "discountType",
                                        $$v
                                      )
                                    },
                                    expression: "form.promotion.discountType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "Theo giá",
                                      value: "PRICE",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Theo phần trăm",
                                      value: "PERCENT",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giảm giá",
                                prop: "promotion.discountValue",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.promotion.discountValue,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.promotion,
                                      "discountValue",
                                      $$v
                                    )
                                  },
                                  expression: "form.promotion.discountValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giảm tối đa",
                                prop: "promotion.discountValueMax",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                attrs: {
                                  placeholder: "Để trống sẽ không áp dụng",
                                },
                                model: {
                                  value: _vm.form.promotion.discountValueMax,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.promotion,
                                      "discountValueMax",
                                      $$v
                                    )
                                  },
                                  expression: "form.promotion.discountValueMax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Số lần được sử dụng" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.form.promotion.numberUses,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.promotion,
                                      "numberUses",
                                      $$v
                                    )
                                  },
                                  expression: "form.promotion.numberUses",
                                },
                              }),
                              _vm.form.promotion.numberUses
                                ? _c(
                                    "small",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.promotion.numberUses
                                          ) +
                                          " lần/khách hàng "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _vm.orderType == _vm.PromotionOrderType.OrderFood
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Cửa hàng",
                                    prop: "storeIds",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        multiple: "",
                                        filterable: "",
                                        placeholder: "",
                                        size: "medium",
                                        remote: "",
                                        "remote-method": _vm.fetchStore,
                                      },
                                      on: {
                                        clear: function ($event) {
                                          _vm.form.storeIds = []
                                        },
                                      },
                                      model: {
                                        value: _vm.form.storeIds,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "storeIds", $$v)
                                        },
                                        expression: "form.storeIds",
                                      },
                                    },
                                    _vm._l(_vm.stores, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tỉnh thành", prop: "cityId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "Tất cả",
                                    size: "medium",
                                  },
                                  on: { change: _vm.changeCity },
                                  model: {
                                    value: _vm.form.cityId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cityId", $$v)
                                    },
                                    expression: "form.cityId",
                                  },
                                },
                                [
                                  !_vm.$store.state.user.info.branch
                                    ? _c("el-option", {
                                        attrs: { label: "Tất cả", value: 0 },
                                      })
                                    : _vm._e(),
                                  _vm._l(_vm.cities, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Quận/huyện",
                                prop: "districtId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "Tất cả",
                                    size: "medium",
                                  },
                                  model: {
                                    value: _vm.form.districtId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "districtId", $$v)
                                    },
                                    expression: "form.districtId",
                                  },
                                },
                                [
                                  !_vm.$store.state.user.info.branch ||
                                  (_vm.$store.state.user.info.branch &&
                                    !_vm.$store.state.user.info.branch.districts
                                      .length)
                                    ? _c("el-option", {
                                        attrs: { label: "Tất cả", value: 0 },
                                      })
                                    : _vm._e(),
                                  _vm._l(_vm.districts, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _vm.orderType == _vm.PromotionOrderType.Order
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Phương tiện di chuyển",
                            prop: "vehicleIds",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                clearable: "",
                                placeholder: "",
                                size: "medium",
                              },
                              on: {
                                clear: function ($event) {
                                  _vm.form.vehicleIds = []
                                },
                              },
                              model: {
                                value: _vm.form.vehicleIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "vehicleIds", $$v)
                                },
                                expression: "form.vehicleIds",
                              },
                            },
                            _vm._l(_vm.vehicles, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    "" +
                                    item.name +
                                    (!!item.subName
                                      ? " (" + item.subName + ")"
                                      : ""),
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "text-center", attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Hình ảnh - Hiển thị ở chi tiết khuyến mãi (Tỷ lệ: 20/8, khuyến nghị: 428 x 171, dung lượng tối đa 2MB)",
                                prop: "promotion.image",
                              },
                            },
                            [
                              _c("SingleImageS3", {
                                attrs: {
                                  pathImage: _vm.form.promotion.image,
                                  prefixFile: _vm.PrefixImage.Promotion,
                                  width: 300,
                                  height: 120,
                                },
                                on: { "upload:success": _vm.setImage },
                              }),
                            ],
                            1
                          ),
                          _vm.orderType == _vm.PromotionOrderType.OrderFood
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      "Hình ảnh - Hiển thị ở màn hình tìm quán (Tỷ lệ: 1/1, khuyến nghị: 700 x 700, dung lượng tối đa 2MB)",
                                    prop: "promotion.thumbnail",
                                  },
                                },
                                [
                                  _c("SingleImageS3", {
                                    attrs: {
                                      prefixFile:
                                        _vm.PrefixImage.PromotionSquare,
                                      width: 200,
                                      height: 200,
                                      pathImage: _vm.form.promotion.thumbnail,
                                    },
                                    on: { "upload:success": _vm.setThumbnail },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mô tả", prop: "body" } },
                            [
                              _c("Tinymce", {
                                ref: "editor",
                                attrs: { width: "100%", height: 400 },
                                on: { onInit: _vm.setBody },
                                model: {
                                  value: _vm.form.promotion.body,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.promotion, "body", $$v)
                                  },
                                  expression: "form.promotion.body",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }