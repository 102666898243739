var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thẻ kỹ năng" } },
            [_c("edu-tag-table", { attrs: { type: _vm.EduTagType.Skill } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Thẻ giảng viên" } },
            [_c("edu-tag-table", { attrs: { type: _vm.EduTagType.Host } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }