"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigurationType = exports.ConfigurationParams = exports.ConfigurationGroup = exports.ConfigRewardSaleType = void 0;
var ConfigurationType;
(function (ConfigurationType) {
  ConfigurationType["Number"] = "NUMBER";
  ConfigurationType["String"] = "STRING";
  ConfigurationType["Boolean"] = "BOOLEAN";
  ConfigurationType["Image"] = "IMAGE";
  ConfigurationType["File"] = "FILE";
})(ConfigurationType || (exports.ConfigurationType = ConfigurationType = {}));
;
var ConfigurationGroup;
(function (ConfigurationGroup) {})(ConfigurationGroup || (exports.ConfigurationGroup = ConfigurationGroup = {}));
var ConfigRewardSaleType;
(function (ConfigRewardSaleType) {
  ConfigRewardSaleType["Amount"] = "AMOUNT";
  ConfigRewardSaleType["Percent"] = "PERCENT"; //thưởng theo %
})(ConfigRewardSaleType || (exports.ConfigRewardSaleType = ConfigRewardSaleType = {}));
var ConfigurationParams;
(function (ConfigurationParams) {
  ConfigurationParams["Phone"] = "PHONE";
  ConfigurationParams["Commission"] = "COMMISSION";
  ConfigurationParams["Email"] = "emial";
  ConfigurationParams["Address"] = "ADDRESS";
  ConfigurationParams["DistanceFindDriver"] = "DISTANCE_FIND_DRIVER";
  ConfigurationParams["TimeBan"] = "TIME_BAN";
  ConfigurationParams["TimeFindDriver"] = "TIME_FIND_DRIVER";
  ConfigurationParams["About"] = "ABOUT";
  ConfigurationParams["FAQ"] = "FAQ";
  ConfigurationParams["Letter"] = "LETTER";
  ConfigurationParams["HideDestinationOnDriver"] = "HIDE_DESTINATION_ON_DRIVER";
  ConfigurationParams["PriceList"] = "PRICE_LIST";
  ConfigurationParams["RewardCustomerParent"] = "REWARD_CUSTOMER_PARENT";
  ConfigurationParams["RewardCustomerChildren"] = "REWARD_CUSTOMER_CHILDREN";
  ConfigurationParams["RewardDriverParent"] = "REWARD_DRIVER_PARENT";
  ConfigurationParams["RewardDriverChildren"] = "REWARD_DRIVER_CHILDREN";
  ConfigurationParams["PercentPaymentBalancePromotion"] = "PERCENT_PAYMENT_BALANCE_PROMOTION";
  ConfigurationParams["VAT"] = "VAT";
  ConfigurationParams["TaxIncome"] = "TAX_INCOME";
  ConfigurationParams["MoneyBaseCustomer"] = "MONEY_BASE_CUSTOMER";
  ConfigurationParams["MoneyBaseDriver"] = "MONEY_BASE_DRIVER";
  ConfigurationParams["MoneyRewardDriver"] = "MONEY_REWARD_DRIVER";
  ConfigurationParams["MoneyOrderFood"] = "MONEY_ORDER_FOOD";
  ConfigurationParams["MoneyServiceFood"] = "MONEY_SERVICE_FOOD";
  ConfigurationParams["TimeToAccept"] = "TIME_TO_ACCEPT";
  ConfigurationParams["DealFclassIcon"] = "DEAL_FCLASS_ICON";
  ConfigurationParams["DealFclassName"] = "DEAL_FCLASS_NAME";
  ConfigurationParams["DealMerchantIcon"] = "DEAL_MERCHANT_ICON";
  ConfigurationParams["DealMerchantName"] = "DEAL_MERCHANT_NAME";
  ConfigurationParams["NearMeIcon"] = "NEAR_ME_ICON";
  ConfigurationParams["VisibleVnpay"] = "VISIBLE_VNPAY";
  ConfigurationParams["DriverCommitLink"] = "DRIVER_COMMIT_LINK";
  ConfigurationParams["DriverBehaviorRuleLink"] = "DRIVER_BEHAVIOR_RULE_LINK";
  ConfigurationParams["StoreTermLink"] = "STORE_TERM_LINK";
  ConfigurationParams["MallTermLink"] = "MALL_TERM_LINK";
  ConfigurationParams["FeeUseApp"] = "FEE_USE_APP";
  ConfigurationParams["FeeInsurance"] = "FEE_INSURANCE";
  ConfigurationParams["NoteCheckIn"] = "NOTE_CHECK_IN";
  ConfigurationParams["StoreLimit"] = "STORE_LIMIT";
  ConfigurationParams["SecurePolicy"] = "SECURE_POLICY";
  ConfigurationParams["PaymentProcess"] = "PAYMENT_PROCESS";
  ConfigurationParams["OrderTransportCashBackPercent"] = "ORDER_TRANSPORT_CASH_BACK_PERCENT";
  ConfigurationParams["OrderDeliveryCashBackPercent"] = "ORDER_DELIVERY_CASH_BACK_PERCENT";
  ConfigurationParams["OrderFoodCashBackPercent"] = "ORDER_FOOD_CASH_BACK_PERCENT";
  ConfigurationParams["OrderMallCashBackPercent"] = "ORDER_MALL_CASH_BACK_PERCENT";
  ConfigurationParams["IdealCashBackPercent"] = "IDEAL_CASH_BACK_PERCENT";
  ConfigurationParams["IdealContentShare"] = "IDEAL_CONTENT_SHARE";
  ConfigurationParams["IdealDrawTimeout"] = "IDEAL_DRAW_TIME_OUT";
  ConfigurationParams["IdealDrawAudioUrl"] = "IDEAl_DRAW_AUDIO_URL";
  ConfigurationParams["IdealDrawNotificationTitle"] = "IDEAL_DRAW_NOTIFICATION_TITLE";
  ConfigurationParams["IdealDrawNotificationBody"] = "IDEAL_DRAW_NOTIFICATION_BODY";
  ConfigurationParams["IdealDrawReachedNotificationTitle"] = "IDEAL_DRAW_REACHED_NOTIFICATION_TITLE";
  ConfigurationParams["IdealDrawReachedNotificationBody"] = "IDEAL_DRAW_REACHED_NOTIFICATION_BODY";
  ConfigurationParams["IdealRewardRef"] = "IDEAL_REWARD_REF";
  ConfigurationParams["UserSignupPolicy"] = "USER_SIGNUP_POLICY";
  ConfigurationParams["CustomerBirthdayMessage"] = "CUSTOMER_BIRTHDAY_MESSAGE"; // Noi dung thong bao sinh nhat
})(ConfigurationParams || (exports.ConfigurationParams = ConfigurationParams = {}));