"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onlinePaymentTypeTrans = exports.OnlinePaymentType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var OnlinePaymentType;
(function (OnlinePaymentType) {
  OnlinePaymentType["Momo"] = "MOMO";
  OnlinePaymentType["Vnpay"] = "VNPAY";
  OnlinePaymentType["Normal"] = "NORMAL";
  OnlinePaymentType["ViettelPay"] = "VIETTEL_PAY";
  // Payoo = 'PAYOO',
})(OnlinePaymentType || (exports.OnlinePaymentType = OnlinePaymentType = {}));
var onlinePaymentTypeTrans = exports.onlinePaymentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, OnlinePaymentType.Momo, {
  type: "danger",
  value: OnlinePaymentType.Momo,
  label: "MOMO",
  img: "/momo.png"
}), OnlinePaymentType.Vnpay, {
  type: "",
  value: OnlinePaymentType.Vnpay,
  label: "VNPay",
  img: "/vnpay.jpg"
}), OnlinePaymentType.ViettelPay, {
  type: "success",
  value: OnlinePaymentType.ViettelPay,
  label: "Viettel Money",
  img: "/logo_viettel_money.png"
}), OnlinePaymentType.Normal, {
  type: "success",
  value: OnlinePaymentType.Normal,
  label: "Nạp tay",
  img: "/logo.png"
});