var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật danh mục (Cấp " + this.form.level + ")"
            : "Thêm mới danh mục (Cấp " + this.form.level + ")",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _vm.parentCategory
            ? _c(
                "el-form-item",
                { attrs: { label: "Danh mục (Cha)" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.parentCategory.name, disabled: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "name", label: "Tên danh mục" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "position", label: "Thứ tự" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.position,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "position", $$v)
                  },
                  expression: "form.position",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "", label: "Nổi bật" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "Nổi bật",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.form.isHighlight,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isHighlight", $$v)
                  },
                  expression: "form.isHighlight",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: _vm.parentCategory ? "" : "icon",
                label:
                  "Hình icon (Tỉ lệ: 1/1 - khuyến nghị: 300 x 300, dung lượng tối đa 2MB)",
              },
            },
            [
              _c("SingleImageS3", {
                attrs: { pathImage: _vm.form.icon, width: 150, height: 150 },
                on: { "upload:success": _vm.setImage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }