var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: {
              "prefix-icon": "el-icon-search",
              placeholder: "Tìm tên danh mục",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.getTableData($event)
              },
            },
            model: {
              value: _vm.listQuery.search,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "search", $$v)
              },
              expression: "listQuery.search",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v("Tìm")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v("Thêm")]
          ),
        ],
        1
      ),
      _c(
        "el-table-draggable",
        { on: { drop: _vm.handleDrop } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "technician-table",
              attrs: { data: _vm.tableData, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "60", align: "center", label: "STT" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c("span", [_vm._v(_vm._s($index + 1))]),
                        _c("i", {
                          staticClass: "el-icon-sort",
                          staticStyle: {
                            cursor: "move",
                            "font-size": "15px",
                            "font-weight": "bold",
                            color: "black",
                            "margin-left": "4px",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "Định lượng" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.value))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "unit", label: "Đơn vị" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.unit))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "price", label: "Giá tiền", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$formatNumberVN(row.price)) + "đ"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Ngày tạo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Hành động",
                  width: "150",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("Chi tiết")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemove(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("surcharge-detail-dialog", {
        ref: "dialog",
        attrs: { surchargeId: _vm.surchargeId },
        on: {
          "update:surchargeId": function ($event) {
            _vm.surchargeId = $event
          },
          "update:surcharge-id": function ($event) {
            _vm.surchargeId = $event
          },
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }