var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Đơn hàng", width: 250 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("img", {
                        staticStyle: {
                          "border-radius": "10px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          width: "45",
                          height: "45",
                          src: row.mallProduct.thumbnail,
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("span", { staticStyle: { color: "#46a6ff" } }, [
                          _vm._v("#" + _vm._s(row.mallOrder.code)),
                        ]),
                        _c("p", { staticStyle: { margin: "0" } }, [
                          _c("b", [_vm._v(_vm._s(row.mallProduct.name))]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Đánh giá", width: 500 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("b", [_vm._v(_vm._s(row.customer.name))]),
                    _c("el-rate", {
                      attrs: {
                        disabled: "",
                        "show-score": "",
                        "text-color": "#ff9900",
                        "score-template": "{value} sao",
                      },
                      model: {
                        value: row.star,
                        callback: function ($$v) {
                          _vm.$set(row, "star", $$v)
                        },
                        expression: "row.star",
                      },
                    }),
                    _vm._v(" " + _vm._s(row.content) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Video/Hình ảnh", width: 200 },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      { staticClass: "images-wrapper" },
                      _vm._l(row.medias.slice(0, 3), function (item, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            item.type == "VIDEO"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "video-thumbnail",
                                    on: {
                                      click: function () {
                                        return _vm.viewVideo(item.url)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-video-play",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            item.type == "IMAGE"
                              ? _c("el-image", {
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px",
                                    "border-radius": "5px",
                                    "margin-right": "5px",
                                  },
                                  attrs: {
                                    src: item.url,
                                    "preview-src-list": row.medias
                                      .filter(function (item) {
                                        return item.type == "IMAGE"
                                      })
                                      .map(function (item) {
                                        return item.url
                                      }),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    row.medias.length > 3
                      ? _c(
                          "el-popover",
                          { attrs: { placement: "bottom", trigger: "click" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              _vm._l(
                                row.medias.slice(3),
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      item.type == "VIDEO"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "video-thumbnail",
                                              on: {
                                                click: function () {
                                                  return _vm.viewVideo(item.url)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-video-play",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.type == "IMAGE"
                                        ? _c("el-image", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "40px",
                                              "border-radius": "5px",
                                              "margin-right": "5px",
                                            },
                                            attrs: {
                                              src: item.url,
                                              "preview-src-list": row.medias
                                                .filter(function (item) {
                                                  return item.type == "IMAGE"
                                                })
                                                .map(function (item) {
                                                  return item.url
                                                }),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  margin: "0",
                                  width: "fit-content",
                                  cursor: "pointer",
                                },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  " +" +
                                    _vm._s(row.medias.length - 3) +
                                    " ảnh khác"
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("video-modal", { ref: "VideoModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }