var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { staticClass: "right-control-tab" },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Tài xế" } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { value: _vm.drivers.length },
                    },
                    [
                      _c("span", { attrs: { size: "small" } }, [
                        _vm._v("Tài xế"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._l(_vm.drivers, function (driver) {
                return _c("driver-item", {
                  key: "driver-" + driver.id,
                  attrs: { driver: driver },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onClickDriver(driver)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }