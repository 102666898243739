var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vietmap" } },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: {
            filterable: "",
            remote: "",
            clearable: "",
            placeholder: "Nhập vị trí",
            "remote-method": _vm.onSearch,
            loading: _vm.loading,
            "value-key": "address",
          },
          on: { change: _vm.onSelect },
          model: {
            value: _vm.searchTerm,
            callback: function ($$v) {
              _vm.searchTerm = $$v
            },
            expression: "searchTerm",
          },
        },
        _vm._l(_vm.suggestions, function (place) {
          return _c("el-option", {
            key: place.location.lng,
            attrs: { label: place.address, value: place },
          })
        }),
        1
      ),
      _c("div", {
        ref: "mapContainer",
        staticStyle: { width: "100%", height: "400px", "margin-top": "12px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }