var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật danh mục (Cấp " + this.form.mallCategory.level + ")"
            : "Thêm mới danh mục (Cấp " + this.form.mallCategory.level + ")",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _vm.parentCategory
            ? _c(
                "el-form-item",
                { attrs: { label: "Danh mục (Cha)" } },
                [
                  _c("el-input", {
                    attrs: { value: _vm.parentCategory.name, disabled: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "mallCategory.name", label: "Tên danh mục" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.mallCategory.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.mallCategory, "name", $$v)
                  },
                  expression: "form.mallCategory.name",
                },
              }),
            ],
            1
          ),
          this.form.mallCategory.level == 1
            ? _c(
                "el-form-item",
                {
                  attrs: { prop: "mallCategory.name", label: "Loại danh mục" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "Tất cả" },
                      model: {
                        value: _vm.form.mallCategory.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.mallCategory, "type", $$v)
                        },
                        expression: "form.mallCategory.type",
                      },
                    },
                    _vm._l(
                      Object.keys(_vm.MallCategoryTypeTrans),
                      function (name) {
                        return _c("el-option", {
                          key: _vm.MallCategoryTypeTrans[name].value,
                          attrs: {
                            label: _vm.MallCategoryTypeTrans[name].label,
                            value: _vm.MallCategoryTypeTrans[name].value,
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "", label: "Phí chiết khấu(%)" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.commissionPercent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "commissionPercent", $$v)
                  },
                  expression: "form.commissionPercent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "mallCategory.image",
                label:
                  "Hình icon (Tỉ lệ: 1/1 - khuyến nghị: 300 x 300, dung lượng tối đa 2MB)",
              },
            },
            [
              _c("SingleImageS3", {
                attrs: {
                  pathImage: _vm.form.mallCategory.image,
                  width: 150,
                  height: 150,
                  prefixFile: _vm.PrefixImage.MallCategoryIcon,
                },
                on: { "upload:success": _vm.setImage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }