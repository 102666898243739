var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Cv" } },
            [_c("cv-table", { attrs: { type: _vm.CvSampleType.CV } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Cover Letter" } },
            [_c("cv-table", { attrs: { type: _vm.CvSampleType.Letter } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }