var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-panel" },
    [
      _c("h3", [_vm._v("Đánh giá")]),
      _c("el-rate", { attrs: { value: _vm.star, disabled: "" } }),
      _c("span", [_vm._v(_vm._s(_vm.content))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }