var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update" ? "Cập nhật khóa học" : "Thêm mới khóa học",
        visible: _vm.visible,
        fullscreen: "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-right": "1px solid #efefef",
                    "padding-right": "30px",
                  },
                  attrs: { span: 12 },
                },
                [
                  _c("h2", [_vm._v("E-leaning video info")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "Tên E-learning video" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "100", "show-word-limit": "" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "parentEduCategoryId",
                                label: "Danh mục cấp 1",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "" },
                                  on: { change: _vm.handleChangeParent },
                                  model: {
                                    value: _vm.form.parentEduCategoryId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "parentEduCategoryId",
                                        $$v
                                      )
                                    },
                                    expression: "form.parentEduCategoryId",
                                  },
                                },
                                _vm._l(_vm.eduCategories, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "eduCategoryId",
                                label: "Danh mục cấp 2",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.form.eduCategoryId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "eduCategoryId", $$v)
                                    },
                                    expression: "form.eduCategoryId",
                                  },
                                },
                                _vm._l(_vm.eduCategoriesChild, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "price", label: "Giá gốc" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "price", $$v)
                                  },
                                  expression: "form.price",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "findPrice",
                                label: "Giá khuyến mãi",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.findPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "findPrice", $$v)
                                  },
                                  expression: "form.findPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "eduStudentAgeId",
                        label: "Độ tuổi học viên",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.eduStudentAgeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "eduStudentAgeId", $$v)
                            },
                            expression: "form.eduStudentAgeId",
                          },
                        },
                        _vm._l(_vm.eduStudentAges, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Ngôn ngữ", prop: "languageId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.languageId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "languageId", $$v)
                                    },
                                    expression: "form.languageId",
                                  },
                                },
                                _vm._l(_vm.languages, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Ngôn ngữ phụ đề",
                                prop: "subTitleLanguageId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.subTitleLanguageId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "subTitleLanguageId",
                                        $$v
                                      )
                                    },
                                    expression: "form.subTitleLanguageId",
                                  },
                                },
                                _vm._l(_vm.languages, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Mức độ",
                                prop: "difficultLevel",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "", clearable: "" },
                                  model: {
                                    value: _vm.form.difficultLevel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "difficultLevel", $$v)
                                    },
                                    expression: "form.difficultLevel",
                                  },
                                },
                                _vm._l(
                                  _vm.EduCourseDifficultLevel,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item,
                                      attrs: {
                                        label:
                                          _vm.EduCourseDifficultLevelTrans[
                                            item
                                          ],
                                        value: item,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Nội dung E-learning video",
                        prop: "description",
                      },
                    },
                    [
                      _c("Tinymce", {
                        ref: "descriptionRef",
                        attrs: { lite: true, width: "100%", height: 0 },
                        on: { onInit: _vm.handleWhatLearnsInit },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Học viên sẽ đạt được kết quả gì thông qua E-learning video",
                        prop: "whatLearns",
                      },
                    },
                    [
                      _c("Tinymce", {
                        ref: "whatLearnsRef",
                        attrs: { lite: true, width: "100%", height: 0 },
                        on: { onInit: _vm.handleWhatLearnsInit },
                        model: {
                          value: _vm.form.whatLearns,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "whatLearns", $$v)
                          },
                          expression: "form.whatLearns",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Cấp học", prop: "schoolLevelIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "" },
                          on: {
                            change: function ($event) {
                              return _vm.checkIsSelectAllSchoolLevel()
                            },
                          },
                          model: {
                            value: _vm.form.schoolLevelIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "schoolLevelIds", $$v)
                            },
                            expression: "form.schoolLevelIds",
                          },
                        },
                        _vm._l(_vm.schoolLevels, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { transform: "translateY(-10px)" },
                      attrs: { label: "Chọn tất cả cấp học" },
                      on: { change: _vm.handleSelectAllLevels },
                      model: {
                        value: _vm.isAllLevels,
                        callback: function ($$v) {
                          _vm.isAllLevels = $$v
                        },
                        expression: "isAllLevels",
                      },
                    },
                    [_vm._v("Chọn tất cả cấp học")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "trailerVideo", label: "Ảnh bìa/Trailer" },
                    },
                    [
                      _c("p", { staticStyle: { margin: "0" } }, [
                        _vm._v("Hình ảnh (Tối đa 2MB)"),
                      ]),
                      _c("p", { staticStyle: { margin: "0" } }, [
                        _vm._v("Video (Tối đa 10MB)"),
                      ]),
                      _c("UploadS3", {
                        attrs: {
                          fileUrl: _vm.form.trailerVideo,
                          width: 400,
                          height: 200,
                          fileTypeAccept: ["video/mp4", "image/"],
                        },
                        on: {
                          "upload:success": _vm.setVideo,
                          delete: _vm.handleDeleteVideo,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "padding-left": "30px" },
                  attrs: { span: 12 },
                },
                [
                  _c("video-form", {
                    attrs: { eduCourse: this.form },
                    on: {
                      submitOk: function (data) {
                        return _vm.onVideosChange(data)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "end",
              gap: "5px",
              height: "50px",
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: {
                  width: "120px !important",
                  display: "block",
                  margin: "0",
                },
                attrs: { type: "primary", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.updateData()
                  },
                },
              },
              [_vm._v("Cập nhật")]
            ),
            _vm.form.status == _vm.EduCourseStatus.Pending
              ? _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "120px !important",
                      display: "block",
                      margin: "0",
                    },
                    attrs: { type: "success", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleApprove(_vm.form)
                      },
                    },
                  },
                  [_vm._v("Duyệt")]
                )
              : _vm._e(),
            _vm.form.status == _vm.EduCourseStatus.Pending
              ? _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "120px !important",
                      display: "block",
                      margin: "0",
                    },
                    attrs: { type: "danger", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleReject(_vm.form)
                      },
                    },
                  },
                  [_vm._v("Từ chối")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }