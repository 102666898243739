var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "identity-card" }, [
    _c(
      "div",
      { staticClass: "application-content" },
      [
        _c("head-section", {
          attrs: { title: "CMND / Thẻ Căn Cước / Hộ Chiếu" },
        }),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Chứng Minh Nhân Dân Mặt Trước",
                  prop: "imageIdentityFront",
                },
              },
              [
                _c("single-image", {
                  attrs: {
                    pathImage: _vm.$url.image(_vm.form.imageIdentityFront),
                    width: 150,
                    height: 150,
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "imageIdentityFront")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Chứng Minh Nhân Dân Mặt Sau",
                  prop: "imageIdentityBack",
                },
              },
              [
                _c("single-image", {
                  attrs: {
                    pathImage: _vm.$url.image(_vm.form.imageIdentityBack),
                    width: 150,
                    height: 150,
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "imageIdentityBack")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Số CMND / Thẻ Căn Cước / Hộ Chiếu",
                  prop: "numberIdentity",
                },
              },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.numberIdentity,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "numberIdentity", $$v)
                    },
                    expression: "form.numberIdentity",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Ngày Cấp", prop: "dateIdentityProvide" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "dd/mm/yyyy" },
                  model: {
                    value: _vm.form.dateIdentityProvide,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "dateIdentityProvide", $$v)
                    },
                    expression: "form.dateIdentityProvide",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Nơi Cấp", prop: "placeIdentityProvide" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.placeIdentityProvide,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "placeIdentityProvide", $$v)
                    },
                    expression: "form.placeIdentityProvide",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Địa Chỉ Tạm Trú", prop: "stayingAddress" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.stayingAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "stayingAddress", $$v)
                    },
                    expression: "form.stayingAddress",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btn-save",
            attrs: { type: "primary", size: "medium", loading: _vm.loading },
            on: { click: _vm.handleSubmit },
          },
          [_vm._v("Lưu")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }