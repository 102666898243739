var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
      attrs: { id: "vehicle" },
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn-add-service-group filter-item",
              attrs: { type: "primary", icon: "el-icon-circle-plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v(" Thêm loại xe ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vehicle-grid item-box" },
        _vm._l(_vm.dataList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              on: {
                click: function ($event) {
                  return _vm.handleEdit(item)
                },
              },
            },
            [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticStyle: { width: "60px", "border-radius": "50%" },
                  attrs: { src: _vm.$url.image(item.image), alt: "" },
                }),
              ]),
              _c("div", { staticClass: "info-box" }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [_vm._v("Tên:")]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(
                      _vm._s(item.name) +
                        " " +
                        _vm._s(item.subName && " | " + item.subName)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Trạng thái:"),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(
                      _vm._s(!item.isBlock ? "Triển khai" : "Chưa triển khai")
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Bước nhảy:"),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(item.step + " m")),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [_vm._v("Loại:")]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(_vm.VehicleType[item.type])),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Loại bánh xe:"),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(_vm.WheelTypeTrans[item.wheelType])),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Vị trí:"),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(item.position)),
                  ]),
                ]),
                _c("div", { staticClass: "info-item" }, [
                  _c("label", { staticClass: "type-info" }, [
                    _vm._v("Ghi chú:"),
                  ]),
                  _c("span", { staticClass: "value-info" }, [
                    _vm._v(_vm._s(item.note)),
                  ]),
                ]),
              ]),
              _c("i", { staticClass: "el-icon-info button-info" }),
            ]
          )
        }),
        0
      ),
      _c(
        "el-row",
        { staticClass: "item-box", attrs: { gutter: 20 } },
        [_c("el-col", { attrs: { span: 4, md: 6, xl: 4 } })],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "600px",
            top: "30px",
            title:
              _vm.dialogStatus == "edit" ? "Cập nhật phương tiện" : "Thêm xe",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin" } },
                    [
                      _c("VehicleForm", {
                        ref: "vehicleForm",
                        attrs: {
                          "base-url-media": _vm.baseUrlMedia,
                          "base-url": _vm.baseUrl,
                          "form-data": _vm.formData,
                          token: _vm.token,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm")
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }