"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallProductPropertySelectType = void 0;
var MallProductPropertySelectType;
(function (MallProductPropertySelectType) {
  MallProductPropertySelectType["Single"] = "SINGLE";
  MallProductPropertySelectType["Multiple"] = "MULTIPLE"; //Chọn nhiều
})(MallProductPropertySelectType || (exports.MallProductPropertySelectType = MallProductPropertySelectType = {}));