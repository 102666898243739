var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.vcoConfig,
          expression: "vcoConfig",
        },
      ],
      staticClass: "order-info",
    },
    [
      _c("div", [
        _c(
          "ul",
          { staticStyle: { "list-style": "none", "padding-left": "5px" } },
          [
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Code: ")]),
              _c("span", [_vm._v(_vm._s(_vm.order.code))]),
            ]),
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái: ")]),
              _c("span", [_vm._v(_vm._s(_vm.STATUS_ORDER[_vm.order.status]))]),
            ]),
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tổng tiền: ")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("toThousandFilter")(_vm.order.moneyFinal)) + "đ"
                ),
              ]),
            ]),
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tạo lúc: ")]),
              _c("span", [
                _vm._v(_vm._s(_vm.$formatDateTime(_vm.order.dateCreated))),
              ]),
            ]),
            _c("li", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Loại xe: ")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.order.vehicle
                      ? _vm.order.vehicle.name + " " + _vm.order.vehicle.subName
                      : ""
                  )
                ),
              ]),
            ]),
          ]
        ),
        _vm.visibleDetailButton
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("view-detail-order", _vm.order)
                      },
                    },
                  },
                  [_vm._v("Chi tiết chuyến")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }