"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdrawCustomerApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var withdrawCustomerApi = exports.withdrawCustomerApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/withdrawCustomer',
      params: params
    });
  },
  promotion: function promotion(data) {
    return (0, _request.default)({
      url: '/withdrawCustomer/promotion',
      method: 'post',
      data: data
    });
  },
  main: function main(data) {
    return (0, _request.default)({
      url: '/withdrawCustomer',
      method: 'post',
      data: data
    });
  }
};