var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update"
                ? "Cập nhật Tổ chức & Hộ gia đình"
                : "Thêm Tổ chức & Hộ gia đình",
            visible: _vm.dialogVisible,
            width: _vm.modalWidth,
            top: "15px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tabName,
                    callback: function ($$v) {
                      _vm.tabName = $$v
                    },
                    expression: "tabName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { name: "info", label: "Thông tin" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.formData,
                            rules: _vm.rules,
                            "label-width": "80px",
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Tên", prop: "name" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "name", $$v)
                                          },
                                          expression: "formData.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Mã số thuế" } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.taxCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "taxCode",
                                              $$v
                                            )
                                          },
                                          expression: "formData.taxCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thành phố",
                                        prop: "cityId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            clearable: "",
                                            disabled:
                                              _vm.$store.state.user.info.city,
                                          },
                                          model: {
                                            value: _vm.formData.cityId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "cityId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.cityId",
                                          },
                                        },
                                        _vm._l(_vm.cities, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.nameWithType,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.dialogStatus == "update"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "ref-customer",
                            label: "Giới thiệu (Nguời dùng)",
                          },
                        },
                        [_c("ref-customer", { attrs: { form: _vm.formData } })],
                        1
                      )
                    : _vm._e(),
                  _vm.dialogStatus == "update"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "vehicle",
                            label: "Giới thiệu (Tài xế)",
                          },
                        },
                        [
                          _c("vehicle-company-ref", {
                            attrs: {
                              form: _vm.formData,
                              companyId: _vm.formData.id,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dialogStatus == "update"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "ref-store",
                            label: "Giới thiệu (Cửa hàng F&B)",
                          },
                        },
                        [
                          _c("company-ref-store", {
                            attrs: { form: _vm.formData },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dialogStatus == "update"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            name: "ref-mall",
                            label: "Giới thiệu (Cửa hàng Mall)",
                          },
                        },
                        [
                          _c("company-ref-mall", {
                            attrs: { form: _vm.formData },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              [
                _vm.dialogStatus == "update" && _vm.tabName == "ref-customer"
                  ? _c(
                      "el-button",
                      {
                        attrs: { loading: _vm.submitLoading, type: "primary" },
                        on: { click: _vm.handleUpdateRef },
                      },
                      [_vm._v("Cập nhật mã giới thiệu ")]
                    )
                  : _vm._e(),
                _vm.tabName == "info"
                  ? _c(
                      "el-button",
                      {
                        attrs: { loading: _vm.submitLoading, type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogStatus == "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("Đồng ý ")]
                    )
                  : _vm._e(),
              ],
            ],
            2
          ),
        ]
      ),
      _c("CompanyRefDialogVue", {
        ref: "company-ref",
        on: { "update:success": _vm.handleSubmitCompanyRefOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }