var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: { model: _vm.formData, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên phụ" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.formData.subName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "subName", $$v)
                          },
                          expression: "formData.subName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Chiết khấu (%)" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.vehicleCity.commission,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleCity, "commission", $$v)
                          },
                          expression: "vehicleCity.commission",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phí di chuyển / phút" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        attrs: {
                          placeholder: "Cước phí di chuyển trên mỗi phút",
                        },
                        model: {
                          value: _vm.vehicleCity.priceMinute,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleCity, "priceMinute", $$v)
                          },
                          expression: "vehicleCity.priceMinute",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Khoảng cách tìm (Km)" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.vehicleCity.distanceScanDriver,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleCity, "distanceScanDriver", $$v)
                          },
                          expression: "vehicleCity.distanceScanDriver",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thời gian nhận đơn(s)" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.vehicleCity.timeToAccept,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleCity, "timeToAccept", $$v)
                          },
                          expression: "vehicleCity.timeToAccept",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thời gian quét đơn(s)" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.vehicleCity.timeFindDriver,
                          callback: function ($$v) {
                            _vm.$set(_vm.vehicleCity, "timeFindDriver", $$v)
                          },
                          expression: "vehicleCity.timeFindDriver",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: "", placeholder: "" },
                          model: {
                            value: _vm.formData.isBlock,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isBlock", $$v)
                            },
                            expression: "formData.isBlock",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Triển khai", value: false },
                          }),
                          _c("el-option", {
                            attrs: { label: "Chưa triển khai", value: true },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "Hiển thị" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: true },
                            model: {
                              value: _vm.vehicleCity.isShow,
                              callback: function ($$v) {
                                _vm.$set(_vm.vehicleCity, "isShow", $$v)
                              },
                              expression: "vehicleCity.isShow",
                            },
                          },
                          [_vm._v("Có")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: false },
                            model: {
                              value: _vm.vehicleCity.isShow,
                              callback: function ($$v) {
                                _vm.$set(_vm.vehicleCity, "isShow", $$v)
                              },
                              expression: "vehicleCity.isShow",
                            },
                          },
                          [_vm._v("Không")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            disabled: _vm.configSystem != "true",
            action: _vm.baseUrl + "/vehicles/avatar/upload",
            "show-file-list": false,
            name: "image",
            "on-success": _vm.handleUploadSuccess,
            "before-upload": _vm.beforeUpload,
            headers: { token: _vm.token },
          },
        },
        [
          _vm.formData.image
            ? _c("img", {
                staticClass: "avatar",
                attrs: { src: _vm.$url.image(_vm.formData.image) },
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }