"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolWithdrawApi = void 0;
var _request = require("@/utils/request");
var schoolWithdrawApi = exports.schoolWithdrawApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/schoolWithdraw',
      params: params
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/schoolWithdraw/".concat(id, "/complete"),
      method: 'patch'
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.request)({
      url: "/schoolWithdraw/".concat(id, "/cancel"),
      method: 'patch',
      data: data
    });
  }
};