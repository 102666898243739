"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVoucherValidStatus = exports.EVoucherDiscountType = void 0;
var EVoucherValidStatus;
(function (EVoucherValidStatus) {
  EVoucherValidStatus["Valid"] = "VALID";
  EVoucherValidStatus["Used"] = "USED";
  EVoucherValidStatus["NotFound"] = "NOT_FOUND";
  EVoucherValidStatus["NotMatch"] = "NOT_MATCH";
  EVoucherValidStatus["NotEnoughCondition"] = "NOT_ENOUGH_CONDITION";
  EVoucherValidStatus["Expired"] = "EXPIRED";
})(EVoucherValidStatus || (exports.EVoucherValidStatus = EVoucherValidStatus = {}));
var EVoucherDiscountType;
(function (EVoucherDiscountType) {
  EVoucherDiscountType["Amount"] = "AMOUNT";
  EVoucherDiscountType["Percent"] = "PERCENT"; //theo %
})(EVoucherDiscountType || (exports.EVoucherDiscountType = EVoucherDiscountType = {}));