var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _vm.$devMode
            ? _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.SHARE_CONFIG_TRANS, function (value, key) {
                          return _c(
                            "el-radio",
                            {
                              key: key,
                              attrs: {
                                disabled: _vm.status == "update",
                                label: key,
                              },
                            },
                            [_vm._v(" " + _vm._s(value) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.visibleImage
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Ảnh (Tỷ lệ 8/9, khuyến nghị: 480 x 540, dung lượng tối đa 2MB)",
                  },
                },
                [
                  _c("SingleImageS3", {
                    attrs: {
                      width: 160,
                      height: 180,
                      pathImage: _vm.form.content,
                    },
                    on: {
                      "upload:success": function (path) {
                        return _vm.$set(_vm.form, "content", path)
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "Nội dung", prop: "content" } },
                [
                  _vm.visibleContentCode
                    ? _c("small", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          'Lưu ý: bắt buộc phải có "{code}" để hiển thị mã giới thiệu'
                        ),
                      ])
                    : _vm._e(),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: {
                        minRows: 3,
                        maxRows: 10,
                      },
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          (_vm.visibleEditImage && _vm.visibleImage) || !_vm.visibleImage
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }