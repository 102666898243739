var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: "Sản phẩm",
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.tempData,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên sản phẩm", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.tempData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempData, "name", $$v)
                      },
                      expression: "tempData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mã sản phẩm", prop: "code" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.tempData.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempData, "code", $$v)
                      },
                      expression: "tempData.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Mã dịch vụ", prop: "serviceCode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.tempData.serviceCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempData, "serviceCode", $$v)
                      },
                      expression: "tempData.serviceCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Chọn loại sản phẩm", prop: "cardSupport" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        placeholder: "",
                        remote: "",
                        clearable: "",
                        "value-key": "type",
                      },
                      on: { change: _vm.onSelectCardProduct },
                      model: {
                        value: _vm.tempData.cardSupport,
                        callback: function ($$v) {
                          _vm.$set(_vm.tempData, "cardSupport", $$v)
                        },
                        expression: "tempData.cardSupport",
                      },
                    },
                    _vm._l(_vm.CARD_SUPPORTS, function (item) {
                      return _c("el-option", {
                        key: item.type,
                        attrs: { label: "" + item.name, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.tempData.cardSupport && _vm.shouldShowValueSelect
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Chọn mệnh giá", prop: "value" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tempData.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempData, "value", $$v)
                            },
                            expression: "tempData.value",
                          },
                        },
                        _vm._l(
                          _vm.tempData.cardSupport.values,
                          function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: {
                                label: "" + _vm.$formatCurrency(item),
                                value: item,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.shouldShowDataUsage
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Dung lượng", prop: "note" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.tempData.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.tempData, "note", $$v)
                          },
                          expression: "tempData.note",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.shouldShowDataUsage
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Ngày sử dụng", prop: "expireNote" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.tempData.expireNote,
                          callback: function ($$v) {
                            _vm.$set(_vm.tempData, "expireNote", $$v)
                          },
                          expression: "tempData.expireNote",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.shouldShowTopup
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Topup" } },
                    [
                      _c("el-switch", {
                        staticStyle: { "margin-bottom": "1em" },
                        attrs: {
                          "active-text": "Cho phép",
                          "inactive-text": "Không cho phép",
                        },
                        model: {
                          value: _vm.tempData.isTopup,
                          callback: function ($$v) {
                            _vm.$set(_vm.tempData, "isTopup", $$v)
                          },
                          expression: "tempData.isTopup",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "Ẩn / Hiện" } },
                [
                  _c("el-switch", {
                    staticStyle: { "margin-bottom": "1em" },
                    attrs: { "active-text": "Bật", "inactive-text": "Tắt" },
                    model: {
                      value: _vm.tempData.isActive,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempData, "isActive", $$v)
                      },
                      expression: "tempData.isActive",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: {
                click: function ($event) {
                  return _vm.createOrUpdateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }