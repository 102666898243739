var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.form.type == _vm.DiscountingType.Topup
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "CK (%)" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                  min: 0,
                                  max: 100,
                                },
                                expression:
                                  "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              min: 0,\n              max: 100,\n            }",
                              },
                            ],
                            staticClass: "input-number",
                            model: {
                              value: _vm.form.topUpDiscount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "topUpDiscount", $$v)
                              },
                              expression: "form.topUpDiscount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "CK (%)" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                  min: 0,
                                  max: 100,
                                },
                                expression:
                                  "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              min: 0,\n              max: 100,\n            }",
                              },
                            ],
                            staticClass: "input-number",
                            model: {
                              value: _vm.form.cardDiscount,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cardDiscount", $$v)
                              },
                              expression: "form.cardDiscount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Commission (VND)" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                              min: 0,
                            },
                            expression:
                              "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              min: 0,\n            }",
                          },
                        ],
                        staticClass: "input-number",
                        model: {
                          value: _vm.form.commission,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "commission", $$v)
                          },
                          expression: "form.commission",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phí GD (đ)" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                              min: 0,
                            },
                            expression:
                              "{\n              alias: 'decimal',\n              groupSeparator: '.',\n              rightAlign: false,\n              autoGroup: true,\n              repeat: 20,\n              autoUnmask: true,\n              allowMinus: false,\n              min: 0,\n            }",
                          },
                        ],
                        staticClass: "input-number",
                        model: {
                          value: _vm.form.fee,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fee", $$v)
                          },
                          expression: "form.fee",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.updateData },
            },
            [_vm._v("Cập nhật")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }