var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }),
      _c(
        "el-table",
        { attrs: { data: _vm.AgentAffiliate, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "nickname",
              label: "Tên agency",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", [_vm._v(" " + _vm._s(row.parent.name) + " ")]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.parent.phone || "Chưa có thông tin") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã Affiliate", prop: "affCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.parent.affCode || "Chưa có thông tin") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thứ hạng", prop: "agentRank" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.parent.agentRank
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.parent.agentRank.name ||
                                    "Chưa có thông tin"
                                ) +
                                " (Cấp " +
                                _vm._s(row.level) +
                                ") "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã giới thiệu", prop: "refCode" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.parent.refCode || "Chưa có thông tin") +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDate(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchAgentAffiliate,
        },
      }),
      _c("AgentOrderDetailModal", { ref: "AgentOrderDetailModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }