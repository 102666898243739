"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merchandiseUnitApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var merchandiseUnitApi = exports.merchandiseUnitApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/merchandiseUnit',
      params: params
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/merchandiseUnit/".concat(id, "/update"),
      method: 'post',
      data: data
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/merchandiseUnit',
      method: 'post',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/merchandiseUnit/".concat(id, "/delete"),
      method: 'post'
    });
  }
};