"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DiscountingTypeTrans = exports.DiscountingType = exports.DiscountingStatusTrans = exports.DiscountingRequestType = exports.DiscountingRequestStatusTrans = exports.DiscountingRequestStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var DiscountingRequestStatus;
(function (DiscountingRequestStatus) {
  DiscountingRequestStatus["Pending"] = "PENDING";
  DiscountingRequestStatus["Pending2"] = "PENDING2";
  DiscountingRequestStatus["Approve"] = "APPROVE";
  DiscountingRequestStatus["Reject"] = "REJECT";
})(DiscountingRequestStatus || (exports.DiscountingRequestStatus = DiscountingRequestStatus = {}));
var DiscountingType;
(function (DiscountingType) {
  DiscountingType["Card"] = "C";
  DiscountingType["Topup"] = "T";
  DiscountingType["Bill"] = "B";
})(DiscountingType || (exports.DiscountingType = DiscountingType = {}));
var DiscountingRequestType;
(function (DiscountingRequestType) {
  DiscountingRequestType["Partner"] = "PARTNER";
  DiscountingRequestType["Agent"] = "AGENT";
  DiscountingRequestType["Vendor"] = "VENDOR";
})(DiscountingRequestType || (exports.DiscountingRequestType = DiscountingRequestType = {}));
var DiscountingTypeTrans = exports.DiscountingTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DiscountingType.Card, {
  value: DiscountingType.Card,
  label: "Thẻ"
}), DiscountingType.Topup, {
  value: DiscountingType.Topup,
  label: "TopUp"
}), DiscountingType.Bill, {
  value: DiscountingType.Bill,
  label: "Hóa đơn"
});
var DiscountingRequestStatusTrans = exports.DiscountingRequestStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DiscountingRequestStatus.Pending, {
  key: DiscountingRequestStatus.Pending,
  label: "Chờ cấp 1 duyệt",
  color: "orange"
}), DiscountingRequestStatus.Pending2, {
  key: DiscountingRequestStatus.Pending2,
  label: "Chờ cấp 2 duyệt",
  color: "blue"
}), DiscountingRequestStatus.Approve, {
  key: DiscountingRequestStatus.Approve,
  label: "Đã duyệt 2 cấp",
  color: "green"
}), DiscountingRequestStatus.Reject, {
  key: DiscountingRequestStatus.Reject,
  label: "Từ chối",
  color: "red"
});
var DiscountingStatusTrans;
(function (DiscountingStatusTrans) {
  DiscountingStatusTrans[DiscountingStatusTrans["\u0110ang m\u1EDF"] = 0] = "\u0110ang m\u1EDF";
  DiscountingStatusTrans[DiscountingStatusTrans["\u0110ang \u0111\xF3ng"] = 1] = "\u0110ang \u0111\xF3ng";
})(DiscountingStatusTrans || (exports.DiscountingStatusTrans = DiscountingStatusTrans = {}));