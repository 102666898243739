var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-profile-signup" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _vm.dialogStatus == "add"
            ? _c("account-form", { attrs: { form: _vm.form } })
            : _vm._e(),
          _c(
            "section",
            { staticClass: "section-form" },
            [
              _c("div", [
                _c("h4", [
                  _c("img", {
                    staticStyle: { "vertical-align": "middle" },
                    attrs: {
                      width: "40",
                      src: require("@/assets/images/icon3.png"),
                    },
                  }),
                  _c("span", { staticStyle: { "vertical-align": "middle" } }, [
                    _vm._v("Thông tin chung"),
                  ]),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "Người đăng ký bán khoá học Video là:" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.EduHostOwnerType.Teacher },
                      model: {
                        value: _vm.form.ownerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ownerType", $$v)
                        },
                        expression: "form.ownerType",
                      },
                    },
                    [_vm._v("Cá nhân Giảng Viên")]
                  ),
                  _vm.form.ownerType != _vm.EduHostOwnerType.Company
                    ? _c(
                        "el-radio",
                        {
                          attrs: { label: _vm.EduHostOwnerType.Personal },
                          model: {
                            value: _vm.form.ownerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerType", $$v)
                            },
                            expression: "form.ownerType",
                          },
                        },
                        [_vm._v("Khác")]
                      )
                    : _vm._e(),
                  _vm.form.ownerType == _vm.EduHostOwnerType.Company
                    ? _c(
                        "el-radio",
                        {
                          attrs: { label: _vm.EduHostOwnerType.Company },
                          model: {
                            value: _vm.form.ownerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerType", $$v)
                            },
                            expression: "form.ownerType",
                          },
                        },
                        [_vm._v("Khác")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.form.ownerType != _vm.EduHostOwnerType.Teacher
                ? _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: _vm.EduHostOwnerType.Personal },
                          model: {
                            value: _vm.form.ownerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerType", $$v)
                            },
                            expression: "form.ownerType",
                          },
                        },
                        [_vm._v("Cá nhân")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: _vm.EduHostOwnerType.Company },
                          model: {
                            value: _vm.form.ownerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerType", $$v)
                            },
                            expression: "form.ownerType",
                          },
                        },
                        [_vm._v("Tổ chức")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.form.ownerType == _vm.EduHostOwnerType.Personal ||
          _vm.form.ownerType == _vm.EduHostOwnerType.Teacher
            ? _c("host-form", {
                attrs: {
                  cities: _vm.cities,
                  bankList: _vm.bankList,
                  form: _vm.form,
                },
              })
            : _vm._e(),
          _vm.form.ownerType == _vm.EduHostOwnerType.Company
            ? _c("company-form", {
                attrs: {
                  cities: _vm.cities,
                  bankList: _vm.bankList,
                  form: _vm.form,
                },
              })
            : _vm._e(),
          _vm.form.accountStatus == _vm.EduHostAccountStatus.Pending
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-top": "12px" },
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "default",
                  },
                  on: { click: _vm.handleApprove },
                },
                [_vm._v("Duyệt")]
              )
            : _vm._e(),
          _vm.form.accountStatus == _vm.EduHostAccountStatus.Approve
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-top": "12px" },
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "default",
                  },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v("Cập nhật hồ sơ")]
              )
            : _vm._e(),
          _vm.dialogStatus == "add"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-top": "12px" },
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "default",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Thêm giảng viên")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }