var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.$devMode
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Thêm nhóm")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tên nhóm", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: _vm.$url.image(row.icon),
                          width: "20",
                          height: "40",
                          alt: "",
                        },
                      }),
                      _c("br"),
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(row.name)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phương tiện" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      _vm._l(row.vehicles, function (item) {
                        return _c("li", { key: item.id }, [
                          _vm._v(_vm._s(item.name)),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("vehicle-group-modal", {
        ref: "VehicleGroupModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }