var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "560px",
        title: _vm.status == "update" ? "Cập nhật" : "Thêm",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _vm.$devMode
            ? _c(
                "el-form-item",
                { attrs: { label: "Loại dữ liệu" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.ConfigurationType.Number },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v(" Number ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.ConfigurationType.String },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v(" String ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.ConfigurationType.Boolean },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v(" Boolean ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.ConfigurationType.Image },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v(" Image ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.ConfigurationType.File },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v(" File ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.form.param ==
                            _vm.ConfigurationParams.DealFclassIcon ||
                          _vm.form.param ==
                            _vm.ConfigurationParams.DealMerchantIcon ||
                          _vm.form.param == _vm.ConfigurationParams.NearMeIcon
                            ? "Icon"
                            : "Nội dung",
                        prop: "value",
                      },
                    },
                    [
                      _vm.form.param !=
                      _vm.ConfigurationParams.HideDestinationOnDriver
                        ? [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.form.type ==
                                    _vm.ConfigurationType.Number,
                                  expression:
                                    "form.type == ConfigurationType.Number",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: {
                                    alias: "decimal",
                                    groupSeparator: ".",
                                    radixPoint: ",",
                                    rightAlign: false,
                                    autoGroup: true,
                                    repeat: 20,
                                    autoUnmask: true,
                                  },
                                  expression:
                                    "{alias: 'decimal' ,groupSeparator: '.',radixPoint: ',' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true}",
                                },
                              ],
                              model: {
                                value: _vm.form.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "value", $$v)
                                },
                                expression: "form.value",
                              },
                            }),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.form.type ==
                                    _vm.ConfigurationType.String,
                                  expression:
                                    "form.type == ConfigurationType.String",
                                },
                              ],
                              attrs: {
                                type: _vm.isTypeStringArea ? "textarea" : "",
                              },
                              model: {
                                value: _vm.form.value,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "value", $$v)
                                },
                                expression: "form.value",
                              },
                            }),
                            _vm.form.type == _vm.ConfigurationType.Boolean
                              ? [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "true" },
                                      model: {
                                        value: _vm.form.value,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "value", $$v)
                                        },
                                        expression: "form.value",
                                      },
                                    },
                                    [_vm._v(" Có ")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "false" },
                                      model: {
                                        value: _vm.form.value,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "value", $$v)
                                        },
                                        expression: "form.value",
                                      },
                                    },
                                    [_vm._v(" Không ")]
                                  ),
                                ]
                              : _vm._e(),
                            _c("single-image", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.form.type ==
                                    _vm.ConfigurationType.Image,
                                  expression:
                                    "form.type == ConfigurationType.Image",
                                },
                              ],
                              attrs: {
                                pathImage: _vm.form.value,
                                urlUpload: "/configuration/upload",
                              },
                              on: {
                                "upload:success": function (path) {
                                  return _vm.$set(_vm.form, "value", path)
                                },
                              },
                            }),
                            _c("upload-file", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.form.type == _vm.ConfigurationType.File,
                                  expression:
                                    "form.type==ConfigurationType.File",
                                },
                              ],
                              attrs: { maxFileSize: 10 },
                              on: {
                                "upload-success": function (path) {
                                  return _vm.$set(_vm.form, "value", path)
                                },
                              },
                            }),
                          ]
                        : [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "true" },
                                model: {
                                  value: _vm.form.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "value", $$v)
                                  },
                                  expression: "form.value",
                                },
                              },
                              [_vm._v(" Có ")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "false" },
                                model: {
                                  value: _vm.form.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "value", $$v)
                                  },
                                  expression: "form.value",
                                },
                              },
                              [_vm._v(" Không ")]
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm.isTypeStringArea
                    ? _c(
                        "el-alert",
                        {
                          attrs: {
                            closable: false,
                            title: "Chú thích",
                            type: "info",
                          },
                        },
                        [
                          [
                            "IDEAL_DRAW_REACHED_NOTIFICATION_TITLE",
                            "IDEAL_DRAW_REACHED_NOTIFICATION_BODY",
                            "IDEAL_DRAW_NOTIFICATION_TITLE",
                            "IDEAL_DRAW_NOTIFICATION_BODY",
                          ].includes(_vm.form.param)
                            ? _c("ul", [
                                _c("li", [
                                  _c("b", [_vm._v("{prizeName}:")]),
                                  _vm._v(" tên giải"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{productName}:")]),
                                  _vm._v(" Sản phẩm mua"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{code}:")]),
                                  _vm._v(" Mã trúng"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{orderCode}:")]),
                                  _vm._v(" Mã đơn hàng"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{customerName}:")]),
                                  _vm._v(" Tên người trúng"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{day}:")]),
                                  _vm._v(" Ngày bốc thăm"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{month}:")]),
                                  _vm._v(" Tháng bốc thăm"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{year}:")]),
                                  _vm._v(" Năm bốc thăm"),
                                ]),
                                _c("li", [
                                  _c("b", [_vm._v("{nguoiBocTham}:")]),
                                  _vm._v(" Người bốc thăm"),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.form.param ==
                          _vm.ConfigurationParams.CustomerBirthdayMessage
                            ? _c("ul", [
                                _c("li", [
                                  _c("b", [_vm._v("{ten_kh}:")]),
                                  _vm._v(" tên khách hàng"),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.$devMode
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại", prop: "param" } },
                        [
                          _vm.status == "create"
                            ? _c("el-input", {
                                model: {
                                  value: _vm.form.param,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "param", $$v)
                                  },
                                  expression: "form.param",
                                },
                              })
                            : _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "small" },
                                  model: {
                                    value: _vm.form.param,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "param", $$v)
                                    },
                                    expression: "form.param",
                                  },
                                },
                                _vm._l(
                                  _vm.CONFIG_PARAMETER,
                                  function (value, key) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: key,
                                        attrs: {
                                          disabled: _vm.status == "update",
                                          label: key,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(value) + " ")]
                                    )
                                  }
                                ),
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.form.param ==
                            _vm.ConfigurationParams.DealFclassIcon ||
                          _vm.form.param ==
                            _vm.ConfigurationParams.DealMerchantIcon
                            ? "Tên"
                            : "Ghi chú",
                        prop: "note",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.form.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "update" ? _vm.updateData() : _vm.createData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }