var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm tên")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Phương thức thanh toán"),
              ]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.query.paymentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "paymentType", $$v)
                    },
                    expression: "query.paymentType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "MoMo",
                      value: _vm.BookingPaymentType.Momo,
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "VNPay",
                      value: _vm.BookingPaymentType.VnPay,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { align: "left", label: "Code", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticStyle: { color: "#46a6ff" } }, [
                      _vm._v(_vm._s(row.code)),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$formatDate(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Họ Tên: ")]),
                      _vm._v(
                        " " +
                          _vm._s(row.customerFirstName) +
                          " " +
                          _vm._s(row.customerLastName) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("SĐT: ")]),
                      _vm._v(" " + _vm._s(row.customerPhone) + " "),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Email: ")]),
                      _vm._v(" " + _vm._s(row.customerEmail) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Chi tiết" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.bookingHotel
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Khách sạn: "),
                          ]),
                          _vm._v(" " + _vm._s(row.bookingHotel.name) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin thanh toán", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" Đơn vị thanh toán: "),
                    _c("b", [
                      _vm._v(_vm._s(_vm.PaymentTypeTrans[row.paymentType])),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" Trạng thái: "),
                    _c("el-tag", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.PaymentStatusTrans[row.paymentStatus])
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "Trạng thái đơn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm.getStatusTag(row).type } },
                      [_vm._v(_vm._s(_vm.getStatusTag(row).name))]
                    ),
                    row.status == _vm.BookingOrderStatus.Cancel
                      ? _c("div", [
                          _c("label", [_vm._v("Lý do hủy: ")]),
                          _vm._v(" " + _vm._s(row.cancelReason) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Doanh thu", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%",
                        },
                      },
                      [
                        _c("b", [_vm._v("Tạm tính")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$formatCurrency(row.moneyTotal))),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%",
                        },
                      },
                      [
                        _c("b", [_vm._v("Giảm giá")]),
                        _c("span", [
                          _vm._v(
                            "-" + _vm._s(_vm.$formatCurrency(row.moneyDiscount))
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%",
                          "border-top": "1px solid #efefef",
                        },
                      },
                      [
                        _c("b", [_vm._v("Tổng cộng")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$formatCurrency(row.moneyFinal))),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }