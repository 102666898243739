var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          align: "right",
          "unlink-panels": "",
          "range-separator": "To",
          "start-placeholder": "Start date",
          "end-placeholder": "End date",
          format: "dd/MM/yyyy",
          "value-format": "yyyy-MM-dd",
          "picker-options": _vm.pickerOptions,
        },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "155px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "ticket-alt", size: "3x" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Đơn hàng"),
                  ]),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Hoàn tất")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSource.order.total,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Tổng tiền")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSource.order.money,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Tổng chiết khấu")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSource.commission,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "155px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-shopping" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "percentage", size: "3x" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Sản phẩm"),
                  ]),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Số lượng")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSource.product.total,
                          duration: 3200,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }