var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Thời gian")]),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "range-separator": "|",
                format: "dd/MM/yyyy",
                "start-placeholder": "Từ ngày",
                "end-placeholder": "Đến ngày",
                clearable: false,
              },
              on: { change: _vm.fetchData },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("PTTT")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.paymentType,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "paymentType", $$v)
                  },
                  expression: "query.paymentType",
                },
              },
              _vm._l(
                Object.keys(_vm.PaymentTypeTrans).filter(function (item) {
                  return (
                    item != _vm.PaymentType.Promotion &&
                    item != _vm.PaymentType.MAIN
                  )
                }),
                function (name, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: _vm.PaymentTypeTrans[name], value: name },
                  })
                }
              ),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { loading: _vm.loadingExport, type: "primary" },
                on: { click: _vm.handleExport },
              },
              [_vm._v("Xuất Excel")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        _vm._l(_vm.columns, function (col, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              "min-width": "50px",
              width: col.label.length * 6 + 50 + "px",
              label: col.label,
              prop: col.prop,
              align: col.align || "left",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      !col.prop.includes("customRender")
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.generateProp(row, col.prop, "string")
                                ) +
                                " "
                            ),
                          ])
                        : col.prop == "mallOrderDetails.quantity-customRender"
                        ? _c(
                            "div",
                            _vm._l(
                              row.mallOrderDetails,
                              function (detail, index) {
                                return _c("p", { key: index }, [
                                  _vm._v(
                                    _vm._s(detail.mallProduct.name) +
                                      " (x" +
                                      _vm._s(detail.quantity) +
                                      ")"
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : col.prop == "GHN-shipFee-customRender"
                        ? _c("div", [
                            row.carrierType == "GHN"
                              ? _c("div", [_vm._v(_vm._s(row.shipFee))])
                              : _vm._e(),
                          ])
                        : col.prop == "GHTK-shipFee-customRender"
                        ? _c("div", [
                            row.carrierType == "GHTK"
                              ? _c("div", [_vm._v(_vm._s(row.shipFee))])
                              : _vm._e(),
                          ])
                        : col.prop == "moneyProductNET-customRender"
                        ? _c("div", [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  Number(row.moneyProduct) -
                                    Number(row.moneyTax)
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }