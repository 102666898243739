var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "multiple-table" } },
    [
      _vm.isMultipleSelect
        ? _c("div", { staticClass: "feature-title" }, [
            _vm._v(
              _vm._s(
                _vm.mode == "printBill" ? "Chế độ in bill hàng loạt" : ""
              ) +
                _vm._s(
                  _vm.mode == "confirm"
                    ? "Chế độ yêu cầu giao hàng hàng loạt"
                    : ""
                ) +
                " "
            ),
            _c(
              "div",
              {
                staticClass: "close-button",
                on: { click: _vm.handleCancelFeature },
              },
              [_c("i", { staticClass: "el-icon-close" })]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "260px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm mã đơn, số điện thoại khách",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái đơn hàng"),
              ]),
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isMultipleSelect,
                    remote: "",
                    placeholder: "Trạng thái đơn hàng",
                  },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.IdealOrderStatusTrans, function (name, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: name, value: key },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Hình thức thanh toán"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Hình thức thanh toán" },
                  model: {
                    value: _vm.listQuery.paymentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "paymentType", $$v)
                    },
                    expression: "listQuery.paymentType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(
                    Object.keys(_vm.PaymentTypeTrans).filter(function (item) {
                      return (
                        item != _vm.PaymentType.Promotion &&
                        item != _vm.PaymentType.MAIN
                      )
                    }),
                    function (name, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: _vm.PaymentTypeTrans[name],
                          value: name,
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái thanh toán"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái thanh toán" },
                  model: {
                    value: _vm.listQuery.paymentStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "paymentStatus", $$v)
                    },
                    expression: "listQuery.paymentStatus",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(
                    Object.keys(_vm.PaymentStatusTrans),
                    function (name, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: _vm.PaymentStatusTrans[name],
                          value: name,
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Đơn vị vận chuyển"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { remote: "", placeholder: "Đơn vị vận chuyển" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.carrierType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "carrierType", $$v)
                    },
                    expression: "listQuery.carrierType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.carrierTypeTrans, function (name, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: name, value: key },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Sản phẩm")]),
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: true,
                    remote: "",
                    placeholder: "Sản phẩm",
                    "remote-method": _vm.searchProduct,
                  },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.idealProductId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "idealProductId", $$v)
                    },
                    expression: "listQuery.idealProductId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.idealProducts, function (item) {
                    return _c(
                      "el-option",
                      {
                        key: _vm.key,
                        attrs: { label: "" + item.name, value: item.id },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              float: "left",
                              "margin-right": "20px",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.checkVisibleExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExcel,
                  },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-printer" },
              on: { click: _vm.handlePrintBill },
            },
            [_vm._v(" In bill hàng loạt ")]
          ),
          _vm.$checkAction("confirm-order")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary", icon: "el-icon-box" },
                  on: { click: _vm.handleConfirmBill },
                },
                [_vm._v(" Yêu cầu giao hàng hàng loạt ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("pagination", {
        staticStyle: { "padding-top": "0" },
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _vm.selectedOrderList.length && _vm.mode == "confirm"
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: {
                size: "medium",
                type: "primary",
                loading: _vm.loadingMultipleConfirm,
              },
              on: { click: _vm.handleMultipleAccept },
            },
            [
              _vm._v(
                " Yêu cầu giao hàng các đơn đã chọn (" +
                  _vm._s(_vm.selectedOrderList.length) +
                  ") "
              ),
            ]
          )
        : _vm._e(),
      _vm.selectedOrderList.length && _vm.mode == "printBill"
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: {
                size: "medium",
                type: "primary",
                loading: _vm.loadingMultipleConfirm,
              },
              on: { click: _vm.handleMultiplePrint },
            },
            [
              _vm._v(
                " Bắt đầu in bill hàng loạt (" +
                  _vm._s(_vm.selectedOrderList.length) +
                  ") "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleTable",
          attrs: {
            data: _vm.tableData,
            border: "",
            fit: "",
            "select-on-indeterminate": true,
            "row-key": _vm.id,
          },
          on: { select: _vm.handleSelectChange },
        },
        [
          _vm.isMultipleSelect
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Đơn hàng số",
              prop: "code",
              "min-width": "150px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-code" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _c("b", [_vm._v(_vm._s(row.code))]),
                        ]),
                      ]),
                      _c("li", [
                        _c("b", [_vm._v("Ghi chú:")]),
                        _vm._v(" " + _vm._s(row.description || "--")),
                      ]),
                      _c(
                        "li",
                        [
                          row.status == _vm.IdealOrderStatus.Pending &&
                          _vm.$checkAction("confirm-order")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    loading: row.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAccept(row)
                                    },
                                  },
                                },
                                [_vm._v(" Yêu cầu giao hàng ")]
                              )
                            : _vm._e(),
                          row.status == _vm.IdealOrderStatus.Confirm &&
                          _vm.$checkAction("confirm-order") &&
                          row.carrierType == _vm.CarrierType.TuThien
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    loading: row.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleConfirmDelivery(row)
                                    },
                                  },
                                },
                                [_vm._v(" Xác nhận đã giao hàng ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                        ]),
                      ]),
                    ]),
                    row.shipCode
                      ? _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [_c("el-tag", [_vm._v(_vm._s(row.shipCode))])],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin giao hàng", "min-width": "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customer
                      ? _c(
                          "ul",
                          { staticClass: "pay" },
                          [
                            _c(
                              "ul",
                              {
                                staticStyle: { "padding-left": "0" },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              _vm._l(row.idealOrderDetails, function (order) {
                                return _c(
                                  "li",
                                  {
                                    key: order.id,
                                    staticStyle: { "justify-content": "left" },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          margin: "0",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "color-blue" },
                                          [
                                            _vm._v(
                                              _vm._s(order.idealProduct.name)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "(Số lượng: " +
                                              _vm._s(order.quantity) +
                                              ")"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._l(row.giftIdealCodes, function (idealCode) {
                              return _c("div", { key: idealCode.id }, [
                                _c(
                                  "li",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Người giới thiệu: "),
                                    ]),
                                    idealCode.customer
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$refs[
                                                  "customer-dialog"
                                                ].handleEdit(idealCode.customer)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  idealCode.customer.name
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("span", [_vm._v("--")]),
                                  ],
                                  1
                                ),
                                _c(
                                  "li",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        "Code dành cho người giới thiệu: "
                                      ),
                                    ]),
                                    _c("el-tag", [
                                      _vm._v(_vm._s(idealCode.code)),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            _c(
                              "li",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Người đặt: "),
                                ]),
                                row.customer
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs[
                                              "customer-dialog"
                                            ].handleEdit(row.customer)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.customer.name) +
                                            " - " +
                                            _vm._s(row.customer.phone) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c("span", [_vm._v("--")]),
                              ],
                              1
                            ),
                            _c("li", [
                              _c("span", [_vm._v("Người nhận đơn:")]),
                              _c("span", [_vm._v(_vm._s(row.receiverName))]),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("Điện thoại người nhận:")]),
                              _c("span", [_vm._v(_vm._s(row.receiverPhone))]),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("Địa chỉ giao hàng:")]),
                              _c("span", [_vm._v(_vm._s(row.receiverAddress))]),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("Đơn vị giao hàng:")]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.carrierTypeTrans[row.carrierType])
                                ),
                              ]),
                            ]),
                            _c("li", [
                              _c("span", [_vm._v("Ngày bốc thăm (dự kiến):")]),
                              _c(
                                "div",
                                _vm._l(row.idealOrderDetails, function (order) {
                                  return _c(
                                    "p",
                                    {
                                      key: order.id,
                                      staticStyle: {
                                        margin: "0",
                                        "font-weight": "700",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$formatDateTime(
                                              order.idealProduct.maxDrawDateAt
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                            _c(
                              "li",
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Tình trạng đơn hàng: "),
                                ]),
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      type:
                                        _vm.orderTagOptions[row.status] &&
                                        _vm.orderTagOptions[row.status].type,
                                      size: "mini",
                                      effect:
                                        _vm.orderTagOptions[row.status] &&
                                        _vm.orderTagOptions[row.status].effect,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.IdealOrderStatusTrans[row.status]
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            row.shipCode
                              ? _c(
                                  "li",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Xác nhận của bên giao hàng: "),
                                    ]),
                                    row.isPickup
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              type: "success",
                                              size: "mini",
                                              effect: "dark",
                                            },
                                          },
                                          [_vm._v("Đã lấy hàng")]
                                        )
                                      : _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              type: "warning",
                                              size: "mini",
                                              effect: "dark",
                                            },
                                          },
                                          [_vm._v("Chưa lấy hàng")]
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    row.shipCode && row.carrierType != _vm.CarrierType.TuThien
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              plain: row.isPrint,
                              type: "primary",
                              size: "small",
                              icon: "el-icon-printer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs["order-receipt"].handlePrint([
                                  row,
                                ])
                              },
                            },
                          },
                          [_vm._v("In hoá đơn")]
                        )
                      : row.carrierType != _vm.CarrierType.TuThien
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "Không thể in hoá đơn do chưa có mã vận đơn",
                              placement: "top-start",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { filter: "grayscale(1)" },
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-printer",
                                },
                              },
                              [_vm._v("In hoá đơn")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row.carrierType != _vm.CarrierType.TuThien && row.shipCode
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-time",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$refs["delivery-log"].handleView(row)
                              },
                            },
                          },
                          [_vm._v("Log giao hàng")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thanh toán", "min-width": "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Trạng thái thanh toán:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.PaymentStatusTrans[row.paymentStatus])
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền hàng:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyProduct))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Phí vận chuyển:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.shipFee))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [
                          _vm._v(
                            "Khuyến mãi " +
                              _vm._s(
                                row.moneyDiscount > 0
                                  ? "(Tiền hàng)"
                                  : row.moneyDiscountShipFee > 0
                                  ? "(Phí ship)"
                                  : ""
                              ) +
                              ":"
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.moneyDiscount > 0
                                ? _vm.$formatCurrency(row.moneyDiscount)
                                : _vm.$formatCurrency(row.moneyDiscountShipFee)
                            )
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [
                          _vm._v(
                            "Người dùng trả (" +
                              _vm._s(_vm.PaymentTypeTrans[row.paymentType]) +
                              "):"
                          ),
                        ]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _c(
                        "li",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs["detail-dialog"].handleView(
                                    row
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-info" }),
                              _vm._v(" Xem Chi tiết "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("order-receipt", {
        ref: "order-receipt",
        on: { fetchData: _vm.getTableData },
      }),
      _c("driver-dialog", { ref: "driver-dialog" }),
      _c("customer-dialog", {
        ref: "customer-dialog",
        attrs: { "form-disable": true },
      }),
      _c("order-dialog", {
        ref: "order-dialog",
        on: { "update:success": _vm.getTableData },
      }),
      _c("detail-dialog", { ref: "detail-dialog" }),
      _c("delivery-log", { ref: "delivery-log" }),
      _c("ImagesOrder", { ref: "images-dialog" }),
      _c("mart-modal", { ref: "martModal", attrs: { "form-disable": true } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }