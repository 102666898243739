var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật tìm kiếm phổ biến"
            : "Thêm mới tìm kiếm phổ biến",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Danh mục", prop: "mallCategoryId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.form.mallCategoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "mallCategoryId", $$v)
                    },
                    expression: "form.mallCategoryId",
                  },
                },
                _vm._l(_vm.categories, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: "" + item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { prop: "mallPopularSearch.label", label: "Tên hiển thị" },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder:
                    "Nếu không điền thì lấy dữ liệu bên danh mục qua",
                },
                model: {
                  value: _vm.form.mallPopularSearch.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.mallPopularSearch, "label", $$v)
                  },
                  expression: "form.mallPopularSearch.label",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "mallPopularSearch.image",
                label:
                  "Hình icon (Tỉ lệ: 1/1, khuyến nghị: 300 x 300, dung lượng tối đa 2MB)",
              },
            },
            [
              _c("SingleImageS3", {
                attrs: {
                  pathImage: _vm.form.mallPopularSearch.image,
                  width: 150,
                  height: 150,
                  prefixFile: _vm.PrefixImage.MallSearchPopular,
                },
                on: { "upload:success": _vm.setImage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }