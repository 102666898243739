var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": "->",
          "start-placeholder": "Từ ngày",
          "end-placeholder": "Đến ngày",
          format: "dd-MM-yyyy",
          "value-format": "yyyy-MM-dd",
        },
        on: { change: _vm.queryDate },
        model: {
          value: _vm.datePicker,
          callback: function ($$v) {
            _vm.datePicker = $$v
          },
          expression: "datePicker",
        },
      }),
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-money" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "users", size: "3x" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-description" }, [
                _c("div", { staticClass: "card-panel-text" }, [
                  _vm._v("Người Dùng"),
                ]),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("Khách mới")]),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.dataSource.totalCustomersToday,
                        duration: 3200,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("Tổng khách")]),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.dataSource.totalCustomers,
                        duration: 3200,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c("div", { staticClass: "card-panel" }, [
              _c(
                "div",
                { staticClass: "card-panel-icon-wrapper icon-shopping" },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "car", size: "3x" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-description" }, [
                _c("div", { staticClass: "card-panel-text" }, [
                  _vm._v("Tài xế"),
                ]),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("Số lượng")]),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        "end-val": _vm.dataSource.totalDrivers,
                        duration: 3200,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("Tổng thu nhập")]),
                    _c("count-to", {
                      staticClass: "card-panel-num",
                      attrs: {
                        "start-val": 0,
                        prefix: "",
                        "end-val": _vm.dataSource.totalDriverIncome,
                        duration: 3200,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "155px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "ticket-alt", size: "3x" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Đơn hàng"),
                  ]),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Số lượng đơn đặt xe")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSource.totalOrders,
                          duration: 3600,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Số lượng đơn giao hàng")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": _vm.dataSource.totalOrderDeliveries,
                          duration: 3600,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Doanh số")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          prefix: "",
                          "end-val": _vm.dataSource.totalSales,
                          duration: 3600,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _c("el-col", { staticClass: "card-panel-col", attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "card-panel", staticStyle: { height: "155px" } },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-money" },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "percentage", size: "3x" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "card-panel-description" }, [
                  _c("div", { staticClass: "card-panel-text" }, [
                    _vm._v("Khuyến mãi / Lợi nhuận"),
                  ]),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Khuyến mãi")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          prefix: "",
                          "end-val": _vm.dataSource.totalDiscount,
                          duration: 3600,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("Lợi nhuận")]),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          prefix: "",
                          "end-val": _vm.dataSource.totalRevenues,
                          duration: 3600,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }