"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depositMallApi = void 0;
var _request = require("@/utils/request");
var depositMallApi = exports.depositMallApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/depositMall',
      params: params
    });
  },
  main: function main(data) {
    return (0, _request.request)({
      url: '/depositMall',
      data: data,
      method: 'post'
    });
  },
  promotion: function promotion(data) {
    return (0, _request.request)({
      url: '/depositMall/promotion',
      data: data,
      method: 'post'
    });
  }
};