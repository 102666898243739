"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eduWithdrawApi = void 0;
var _request = require("@/utils/request");
var eduWithdrawApi = exports.eduWithdrawApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/eduWithdraw',
      params: params
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/eduWithdraw/".concat(id, "/complete"),
      method: 'patch'
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.request)({
      url: "/eduWithdraw/".concat(id, "/cancel"),
      method: 'patch',
      data: data
    });
  }
};