"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.meetObjectApi = void 0;
var _request = require("@/utils/request");
var meetObjectApi = exports.meetObjectApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/meetObject',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/meetObject',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/meetObject/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/meetObject/".concat(id),
      method: 'delete'
    });
  }
};