var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-profile-signup" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "section",
            { staticClass: "section-form" },
            [
              _c("div", [
                _c("h4", [
                  _c("img", {
                    staticStyle: { "vertical-align": "middle" },
                    attrs: {
                      width: "40",
                      src: require("@/assets/images/icon3.png"),
                    },
                  }),
                  _c("span", { staticStyle: { "vertical-align": "middle" } }, [
                    _vm._v("Thông tin chung"),
                  ]),
                ]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "Cửa hàng của" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.StoreType.Individual },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v("Cá nhân")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: _vm.StoreType.Company },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [_vm._v("Công ty/Hộ Cá thể")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.type == _vm.StoreType.Company
            ? _c("company-form", { attrs: { form: _vm.form } })
            : _vm._e(),
          _c("owner-form", { attrs: { form: _vm.form } }),
          _vm.form.profileStatus == _vm.ProfileStoreStatus.Pending
            ? _c("store-form", {
                attrs: {
                  visibleInfo:
                    _vm.form.profileStatus == _vm.ProfileStoreStatus.Pending,
                  form: _vm.form,
                },
              })
            : _vm._e(),
          _vm.form.profileStatus == _vm.ProfileStoreStatus.Pending
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-top": "12px" },
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "default",
                  },
                  on: { click: _vm.handleApprove },
                },
                [_vm._v("Duyệt")]
              )
            : _vm._e(),
          _vm.form.profileStatus == _vm.ProfileStoreStatus.Approve
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-top": "12px" },
                  attrs: {
                    loading: _vm.loading,
                    type: "primary",
                    size: "default",
                  },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v("Cập nhật hồ sơ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }