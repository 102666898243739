var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    _vm._l(_vm.data, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "profile" },
        [
          _c("div", { staticClass: "img-box" }, [
            _c("img", { attrs: { src: _vm.$url.image(item.avatar), alt: "" } }),
            _c("span", { staticClass: "name" }, [
              _vm._v(" " + _vm._s(item.name) + " "),
            ]),
            _c("span", { staticClass: "phone" }, [_vm._v(_vm._s(item.phone))]),
            _c("span", { staticClass: "address" }, [
              _vm._v(
                _vm._s(item.district && item.district.nameWithType) +
                  ", " +
                  _vm._s(item.city && item.city.nameWithType)
              ),
            ]),
          ]),
          _c("div", { staticClass: "row align-items-center" }),
          item.driverProfile
            ? [
                _c("div", { staticClass: "info-box image-show" }, [
                  _c("h4", [_vm._v(" CMND ")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", [
                      _c("img", {
                        staticClass: "img-horizontal",
                        attrs: {
                          src: _vm.$url.image(
                            item.driverProfile.imageIdentityFront
                          ),
                        },
                      }),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("Mặt trước"),
                      ]),
                    ]),
                    _c("div", { staticStyle: { "margin-left": "8px" } }, [
                      _c("img", {
                        staticClass: "img-horizontal",
                        attrs: {
                          src: _vm.$url.image(
                            item.driverProfile.imageIdentityBack
                          ),
                        },
                      }),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("Mặt sau"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "info-box" }, [
                  _c("p", [
                    _vm._v(" Số CMND / Thẻ Căn Cước / Hộ Chiếu: "),
                    _c("span", [
                      _vm._v(_vm._s(item.driverProfile.numberIdentity)),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" Ngày Cấp: "),
                    _c("span", [
                      _vm._v(_vm._s(item.driverProfile.dateIdentityProvide)),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" Nơi Cấp: "),
                    _c("span", [
                      _vm._v(_vm._s(item.driverProfile.placeIdentityProvide)),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" Địa chỉ thường trú: "),
                    _c("span", [
                      _vm._v(_vm._s(item.driverProfile.permanentAddress)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "info-box" }, [
                  _c("h4", [_vm._v(" Bằng lái ")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", [
                      _c("img", {
                        staticClass: "img-horizontal",
                        attrs: {
                          src: _vm.$url.image(
                            item.driverProfile.imageDriverLicenseFront
                          ),
                        },
                      }),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("Mặt trước"),
                      ]),
                    ]),
                    _c("div", { staticStyle: { "margin-left": "8px" } }, [
                      _c("img", {
                        staticClass: "img-horizontal",
                        attrs: {
                          src: _vm.$url.image(
                            item.driverProfile.imageDriverLicenseBack
                          ),
                        },
                      }),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("Mặt sau"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "info-box" }, [
                  _c("p", [
                    _vm._v(" Số bằng lái xe: "),
                    _c("span", [
                      _vm._v(_vm._s(item.driverProfile.numberDriverLicense)),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" Ngày Cấp: "),
                    _c("span", [
                      _vm._v(
                        _vm._s(item.driverProfile.dateDriverLicenseProvide)
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" Ngày Hết Hạn: "),
                    _c("span", [
                      _vm._v(
                        _vm._s(item.driverProfile.dateDriverLicenseExpire)
                      ),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(" Hạng Bằng Lái: "),
                    _c("span", [
                      _vm._v(_vm._s(item.driverProfile.levelDriverLicence)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "info-box" }, [
                  _c("h4", [_vm._v(" Ảnh xe ")]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", [
                      _c("img", {
                        staticClass: "img-horizontal",
                        attrs: {
                          src: _vm.$url.image(
                            item.driverProfile.imageVehicleFront
                          ),
                        },
                      }),
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v("Mặt trước"),
                      ]),
                    ]),
                    _c("div", { staticStyle: { "margin-left": "8px" } }, [
                      _c("img", {
                        staticClass: "img-horizontal",
                        attrs: {
                          src: _vm.$url.image(
                            item.driverProfile.imageVehicleBack
                          ),
                        },
                      }),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v("Mặt sau"),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            : _vm._e(),
          _c("div", { staticClass: "pagebreak" }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }