var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Sản phẩm")]),
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Nhập để tìm kiếm",
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.handleSearchProduct,
                  },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.idealProductId,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "idealProductId", $$v)
                    },
                    expression: "query.idealProductId",
                  },
                },
                _vm._l(_vm.products, function (product) {
                  return _c("el-option", {
                    key: product.id,
                    attrs: {
                      label: product.name + " (" + product.code + ")",
                      value: product.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.$checkAction("boc-tham-ngau-nhien")
            ? _c(
                "router-link",
                {
                  attrs: { to: "/boc-tham-ngau-nhien/index", target: "_blank" },
                },
                [
                  _vm.$checkAction("boc-tham-ngau-nhien")
                    ? _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: {
                            "margin-top": "20px",
                            "margin-left": "10px",
                          },
                          attrs: { type: "danger", icon: "el-icon-plus" },
                        },
                        [_c("b", [_vm._v("Bốc thăm ngẫu nhiên")])]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Mã trúng thưởng", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.idealCode.isReward
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              size: "mini",
                              effect: "dark",
                            },
                          },
                          [_c("b", [_vm._v("Đã trao thưởng")])]
                        )
                      : _vm._e(),
                    !row.idealCode.isReward
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "warning",
                              size: "mini",
                              effect: "dark",
                            },
                          },
                          [_c("b", [_vm._v("Chưa trao thưởng")])]
                        )
                      : _vm._e(),
                    _c("br"),
                    _c("span", { staticStyle: { "font-size": "15px" } }, [
                      _c("b", [_vm._v(" " + _vm._s(row.idealCode.code))]),
                    ]),
                    _c("br"),
                    _c("i", [
                      _vm._v(
                        "Tạo ngày: " +
                          _vm._s(_vm.$formatDateTime(row.dateCreated))
                      ),
                    ]),
                    _c("br"),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-top": "1em" },
                        attrs: { size: "small" },
                        on: {
                          click: function () {
                            return _vm.handleShowDrawHistory({
                              idealProductId: row.multiIdealProduct.id,
                              idealCode: row.idealCode.code,
                            })
                          },
                        },
                      },
                      [_vm._v("Xem mã dự thưởng")]
                    ),
                    _c("br"),
                    !row.idealCode.isReward &&
                    _vm.$checkAction("confirm-reward")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-top": "1em" },
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function () {
                                return _vm.handleConfirmDraw(row.idealCode.id)
                              },
                            },
                          },
                          [_vm._v("Xác nhận đã trao thưởng")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Người bốc thăm", prop: "staff" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [
                      _c("b", [_vm._v("Tên: ")]),
                      _vm._v(_vm._s(row.staff.name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Username: ")]),
                      _vm._v(_vm._s(row.staff.name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("SĐT: ")]),
                      _vm._v(_vm._s(row.staff.phone)),
                    ]),
                    _c("el-divider", {
                      staticStyle: { margin: "0 !important" },
                    }),
                    _c("p", [
                      _c("b", [_vm._v("Chức vụ: ")]),
                      _vm._v(_vm._s(row.staff.role.name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Đơn vị công tác: ")]),
                      _vm._v(
                        _vm._s(row.staff.branch ? row.staff.branch.name : "--")
                      ),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Người giám sát:")]),
                      _vm._v(" " + _vm._s(row.staffName || "--")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "prize" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.multiIdealProduct
                      ? _c("div", [
                          row.multiIdealProduct.imageUrl
                            ? _c("img", {
                                attrs: {
                                  height: "80px",
                                  src: row.multiIdealProduct.imageUrl,
                                },
                              })
                            : _vm._e(),
                          row.multiIdealProduct
                            ? _c("h3", { staticStyle: { margin: "0" } }, [
                                _vm._v(_vm._s(row.multiIdealProduct.name)),
                              ])
                            : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(row.multiIdealProduct.code)),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phần thưởng", prop: "prize" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.idealAward
                      ? _c("img", {
                          attrs: {
                            height: "80px",
                            src: row.idealAward.idealPrize.imageUrl,
                          },
                        })
                      : _vm._e(),
                    row.multiIdealProduct
                      ? _c("h3", { staticStyle: { margin: "0" } }, [
                          _vm._v(_vm._s(row.idealAward.idealPrize.name)),
                        ])
                      : _vm._e(),
                    _c("p", { staticStyle: { margin: "0" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$formatNumberVN(
                            row.idealAward.idealPrize.finalPrice
                          )
                        ) + " VNĐ"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin người trúng thưởng", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.idealCode.customer
                      ? _c("div", [
                          _c("b", { staticStyle: { "font-size": "15px" } }, [
                            _vm._v(_vm._s(row.idealCode.customer.name)),
                          ]),
                          _c("br"),
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" SĐT: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.idealCode.customer.phone)),
                            ]),
                          ]),
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Email: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.idealCode.customer.email)),
                            ]),
                          ]),
                        ])
                      : _c("div", [_vm._v(" Không có người trúng ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tải lên video bốc thăm", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.videoUrl
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                              size: "normal",
                              effect: "dark",
                            },
                          },
                          [_c("b", [_vm._v("Chưa tải lên video")])]
                        )
                      : _vm._e(),
                    row.videoUrl
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon: "el-icon-video-play",
                            },
                            on: {
                              click: function () {
                                return _vm.handleOpenVideo(row.videoUrl)
                              },
                            },
                          },
                          [_vm._v("Xem video bốc thăm")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          width: "100%",
                          "margin-top": "5px",
                          "margin-left": "0",
                        },
                        attrs: {
                          icon: "el-icon-upload",
                          type: row.videoUrl ? "default" : "primary",
                          size: "small",
                        },
                        on: {
                          click: function () {
                            return _vm.handleUploadVideo(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            row.videoUrl ? "Cập nhật video" : "Tải lên video"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("IdealDrawModal", {
        ref: "IdealDrawModal",
        on: { submitOk: _vm.handleShowDrawResult },
      }),
      _c("IdealDrawResult", {
        ref: "IdealDrawResult",
        on: { submitOk: _vm.handleShowDrawHistory },
      }),
      _c("VideoModal", { ref: "VideoModal" }),
      _c("IdealDrawHistory", { ref: "IdealDrawHistory" }),
      _c("upload-video-modal", {
        ref: "UploadVideoModal",
        on: { submitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }