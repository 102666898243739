var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật kho" : "Thêm mới kho",
        visible: _vm.visible,
        width: "500px",
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "section",
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên kho", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Số điện thoại", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Địa chỉ", prop: "address" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Tỉnh thành", prop: "cityId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      on: { change: _vm.handleChangeCity },
                      model: {
                        value: _vm.form.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cityId", $$v)
                        },
                        expression: "form.cityId",
                      },
                    },
                    _vm._l(_vm.cities, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Quận huyện", prop: "districtId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      on: { change: _vm.handleChangeDistrict },
                      model: {
                        value: _vm.form.districtId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "districtId", $$v)
                        },
                        expression: "form.districtId",
                      },
                    },
                    _vm._l(_vm.districts, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Phường xã", prop: "wardId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      on: { change: _vm.handleChangeWard },
                      model: {
                        value: _vm.form.wardId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "wardId", $$v)
                        },
                        expression: "form.wardId",
                      },
                    },
                    _vm._l(_vm.wards, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          staticStyle: {
            display: "flex",
            width: "100%",
            "justify-content": "end",
          },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.status == "create" || _vm.$checkAction("update-warehouse")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }