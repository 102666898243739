var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm sản phẩm")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.$checkAction("add-warehouse")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" Thêm mới ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Kho", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("h3", { staticStyle: { margin: "0" } }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Số điện thoại: ")]),
                      _vm._v(_vm._s(row.phone)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.address))]),
                    _c("p", [
                      _c("b", [_vm._v("Phường/xã: ")]),
                      _vm._v(_vm._s(row.ward.name)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Quận/huyện: ")]),
                      _vm._v(_vm._s(row.district.nameWithType)),
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Tỉnh/thành phố: ")]),
                      _vm._v(_vm._s(row.city.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    _vm.$checkAction("delete-warehouse")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" Xoá ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("IdealAddressModal", {
        ref: "IdealAddressModal",
        on: { submitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }