var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8, offset: 0 } },
            [
              !this.idealProduct.isPublished
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-bottom": "10px",
                        "margin-right": "5px",
                      },
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.handleAddNewPrize },
                    },
                    [_vm._v("Thêm giải trúng thưởng")]
                  )
                : _vm._e(),
              _vm._l(_vm.idealPrizes, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class:
                      _vm.form.id == item.id
                        ? " active prize-item"
                        : "prize-item",
                    staticStyle: {
                      display: "flex",
                      "margin-bottom": "10px",
                      "margin-top": "10px",
                    },
                    on: {
                      click: function () {
                        return _vm.handleSelectPrize(item)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        "margin-right": "10px",
                        "object-fit": "cover",
                      },
                      attrs: { src: item.imageUrl, width: "60", height: "60" },
                    }),
                    _c(
                      "div",
                      [
                        _c("p", { staticStyle: { margin: "5px 0" } }, [
                          _c("b", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" (SL: " + _vm._s(item.quantity) + ")"),
                        ]),
                        item.type == _vm.IdealPrizeType.Sub
                          ? _c(
                              "el-tag",
                              { attrs: { type: "primary", effect: "light" } },
                              [_c("b", [_vm._v("Giải phụ")])]
                            )
                          : _vm._e(),
                        item.type == _vm.IdealPrizeType.Main
                          ? _c(
                              "el-tag",
                              { attrs: { type: "primary", effect: "dark" } },
                              [_c("b", [_vm._v("Giải chính")])]
                            )
                          : _vm._e(),
                        _c("p"),
                      ],
                      1
                    ),
                    _c("el-button", {
                      staticClass: "btn-delete",
                      staticStyle: {
                        "margin-left": "auto",
                        "font-size": "20px",
                        color: "red",
                      },
                      attrs: {
                        type: "text",
                        size: "mini",
                        icon: "el-icon-delete",
                      },
                      on: {
                        click: function (e) {
                          e.stopPropagation()
                          _vm.handleDelete(item)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "el-col",
            {
              staticStyle: {
                "border-left": "1px solid #efefef",
                margin: "auto",
              },
              attrs: { span: 10, offset: 0 },
            },
            [
              _c(
                "el-row",
                { staticStyle: { margin: "auto" }, attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c("h2", { staticStyle: { "margin-top": "0" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.status == "create"
                              ? "Thêm giải trúng thưởng"
                              : "Chỉnh sửa giải trúng thưởng"
                          ) + " "
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "Hình ảnh (Tỷ lệ 2/1, khuyến nghị: 500 x 250, dung lượng tối đa 2MB)",
                            prop: "imageUrl",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.imageUrl,
                              width: 300,
                              height: 120,
                            },
                            on: { "upload:success": _vm.setPrizeImage },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại giải thưởng", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: this.idealProduct.isPublished,
                              },
                              on: { change: _vm.handleChangeType },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  disabled: _vm.idealPrizes.some(function (e) {
                                    return e.type == _vm.IdealPrizeType.Main
                                  }),
                                  label: "Giải chính",
                                  value: _vm.IdealPrizeType.Main,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "Giải phụ",
                                  value: _vm.IdealPrizeType.Sub,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên", prop: "name" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                            },
                          ],
                          attrs: { label: "Giá bán Net", prop: "price" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: this.idealProduct.isPublished },
                            on: {
                              input: function () {
                                return _vm.handleCalcMoneyFinal()
                              },
                            },
                            model: {
                              value: _vm.form.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "price", $$v)
                              },
                              expression: "form.price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                            },
                          ],
                          attrs: { label: "Phần trăm VAT", prop: "taxPercent" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                disabled: this.idealProduct.isPublished,
                              },
                              on: {
                                input: function () {
                                  return _vm.handleCalcMoneyFinal()
                                },
                              },
                              model: {
                                value: _vm.form.taxPercent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "taxPercent", $$v)
                                },
                                expression: "form.taxPercent",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "append" }, slot: "append" },
                                [_vm._v("%")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-top": "-10px",
                            color: "#2295ff",
                          },
                        },
                        [
                          _vm._v(
                            "Thuế VAT: " +
                              _vm._s(
                                isNaN(_vm.form.moneyTax)
                                  ? 0
                                  : _vm.$formatNumberVN(_vm.form.moneyTax)
                              ) +
                              " vnđ"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: false,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                            },
                          ],
                          attrs: { label: "Giá bán Gross", prop: "finalPrice" },
                        },
                        [
                          _c("el-input", {
                            attrs: { readonly: true, disabled: true },
                            model: {
                              value: _vm.form.finalPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "finalPrice", $$v)
                              },
                              expression: "form.finalPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số lượng", prop: "quantity" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: this.idealProduct.isPublished,
                              type: "number",
                              min: 1,
                            },
                            model: {
                              value: _vm.form.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "quantity", $$v)
                              },
                              expression: "form.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.quantity > 1 &&
                      _vm.form.type == _vm.IdealPrizeType.Main
                        ? _c(
                            "el-alert",
                            { attrs: { type: "warning", "show-icon": "" } },
                            [
                              _vm._v(
                                " Giải đặc biệt chỉ nên có 1 giải duy nhất "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mô tả", prop: "description" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: this.idealProduct.isPublished,
                              type: "textarea",
                              autosize: { minRows: 3, col: 24 },
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Ảnh khác (Đề xuất ảnh tỉ lệ 2/1)",
                            prop: "thumbnail",
                          },
                        },
                        [
                          _c("MultipleImageS3", {
                            attrs: { fileList: _vm.fileList },
                            on: {
                              "upload:success": _vm.handleUploadSuccess,
                              "remove:success": _vm.handleRemove,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.handleSubmitPrize },
                },
                [_vm._v("Lưu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }