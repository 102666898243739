var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [_c("address-filter", { attrs: { query: _vm.listQuery } })],
        1
      ),
      _c("panel-group", { attrs: { listQuery: _vm.listQuery } }),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderLast", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("customer", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("sale", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("driver", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("cash", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("vnpay-last", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("company", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("use-app-driver", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("VAT", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("tax-driver", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("driver-income", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("SaleVehicle", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("customer-rank", { attrs: { listQuery: _vm.listQuery } }),
                _c("p", { staticClass: "chart-title" }, [
                  _vm._v("Top 5 người dùng"),
                ]),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("driver-rank", { attrs: { listQuery: _vm.listQuery } }),
                _c("p", { staticClass: "chart-title" }, [
                  _vm._v("Top 5 tài xế"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }