var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-item" },
    [
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Code: ")]),
        _c("span", [_vm._v(_vm._s(_vm.order.code))]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái: ")]),
        _c("span", [_vm._v(_vm._s(_vm.STATUS_ORDER[_vm.order.status]))]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tổng tiền: ")]),
        _c("span", [
          _vm._v(_vm._s(_vm.$formatNumberVN(_vm.order.moneyFinal)) + "đ"),
        ]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Bắt đầu: ")]),
        _c("span", [_vm._v(_vm._s(_vm.order.startAddress))]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Kết thúc: ")]),
        _c("span", [_vm._v(_vm._s(_vm.order.endAddress))]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tạo lúc: ")]),
        _c("span", [
          _vm._v(_vm._s(_vm.$formatDateTime(_vm.order.dateCreated))),
        ]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Loại xe: ")]),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.order.vehicle
                ? _vm.order.vehicle.name + " " + _vm.order.vehicle.subName
                : ""
            )
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Người Dùng: ")]),
        _c("span", [_vm._v(_vm._s(_vm.order.customer.name))]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("SĐT KH: ")]),
        _c("span", [_vm._v(_vm._s(_vm.order.customer.phone))]),
      ]),
      _vm.order.driver
        ? [
            _c("hr"),
            _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tài xế: ")]),
              _c("span", [_vm._v(_vm._s(_vm.order.driver.name))]),
            ]),
            _c("div", [
              _c("label", { attrs: { for: "" } }, [_vm._v("SĐT tài xế: ")]),
              _c("span", [_vm._v(_vm._s(_vm.order.driver.phone))]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }