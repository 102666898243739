"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConversationType = exports.ConversationMessageType = void 0;
var ConversationType;
(function (ConversationType) {
  ConversationType["Food"] = "FOOD";
  ConversationType["Mall"] = "MALL";
  ConversationType["Admin"] = "ADMIN";
  ConversationType["Driver"] = "DRIVER";
})(ConversationType || (exports.ConversationType = ConversationType = {}));
var ConversationMessageType;
(function (ConversationMessageType) {
  ConversationMessageType["Text"] = "TEXT";
  ConversationMessageType["Image"] = "IMAGE";
  ConversationMessageType["Video"] = "VIDEO";
  ConversationMessageType["Link"] = "LINK";
  ConversationMessageType["Audio"] = "AUDIO";
  ConversationMessageType["File"] = "FILE";
})(ConversationMessageType || (exports.ConversationMessageType = ConversationMessageType = {}));