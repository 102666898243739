var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm code",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: { query: _vm.listQuery, visibleWard: false },
            on: { "change:address": _vm.getTableData },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái đơn hàng"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái đơn hàng" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.STATUS_ORDER, function (name, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: name, value: key },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Hình thức thanh toán"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Hình thức thanh toán" },
                  model: {
                    value: _vm.listQuery.paymentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "paymentType", $$v)
                    },
                    expression: "listQuery.paymentType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(
                    Object.keys(_vm.PaymentTypeTrans).filter(function (item) {
                      return (
                        item != _vm.PaymentType.Promotion &&
                        item != _vm.PaymentType.MAIN
                      )
                    }),
                    function (name, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: _vm.PaymentTypeTrans[name],
                          value: name,
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái thanh toán"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Trạng thái thanh toán" },
                  model: {
                    value: _vm.listQuery.paymentStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "paymentStatus", $$v)
                    },
                    expression: "listQuery.paymentStatus",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(
                    Object.keys(_vm.PaymentStatusTrans),
                    function (name, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: _vm.PaymentStatusTrans[name],
                          value: name,
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.type == _vm.OrderDeliveryType.Store && !_vm.storeId
            ? _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Cửa hàng")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "Cửa hàng",
                        remote: "",
                        "remote-method": _vm.fetchStores,
                        "value-key": "id",
                      },
                      on: { change: _vm.getTableData },
                      model: {
                        value: _vm.listQuery.storeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "storeId", $$v)
                        },
                        expression: "listQuery.storeId",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Tất cả", value: null },
                      }),
                      _vm._l(_vm.stores, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.storeId
            ? _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Đơn test")]),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Đơn test" },
                      model: {
                        value: _vm.listQuery.isTest,
                        callback: function ($$v) {
                          _vm.$set(_vm.listQuery, "isTest", $$v)
                        },
                        expression: "listQuery.isTest",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Tất cả", value: null },
                      }),
                      _c("el-option", { attrs: { label: "Có", value: true } }),
                      _c("el-option", {
                        attrs: { label: "Không", value: false },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.checkVisibleCreate && _vm.type == _vm.OrderDeliveryType.Customer
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    loading: _vm.loadingExcel,
                  },
                  on: { click: _vm.createOrderExcel },
                },
                [_vm._v(" Tạo đơn (Excel) ")]
              )
            : _vm._e(),
          _vm.checkVisibleExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExcel,
                  },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
          _vm.checkVisibleExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExcel,
                  },
                  on: { click: _vm.handleExportExcelArea },
                },
                [_vm._v(" Xuất excel khu vuc ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("pagination", {
        staticStyle: { "padding-top": "0" },
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Code",
              prop: "code",
              "min-width": "200px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticClass: "list-code" },
                      [
                        row.isTest
                          ? _c(
                              "el-tag",
                              {
                                staticStyle: { "font-weight": "bold" },
                                attrs: { type: "danger", effect: "dark" },
                              },
                              [_vm._v("Đơn test")]
                            )
                          : _vm._e(),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", { staticStyle: { "font-size": "20px" } }, [
                            _c("b", [_vm._v(_vm._s(row.code))]),
                          ]),
                        ]),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", [
                            _c("b", [_vm._v(_vm._s(row.codeContract || "--"))]),
                          ]),
                        ]),
                        _c(
                          "li",
                          [
                            row.status != "STAFF_CANCEL" &&
                            row.status != "STORE_CANCEL" &&
                            row.status != "CUSTOMER_CANCEL" &&
                            row.status != "DRIVER_CANCEL" &&
                            row.status != "COMPLETE"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      type: "danger",
                                      loading: row.loadingDelete,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCancel(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" Huỷ đơn ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _vm.checkVisibleCompleteOrder &&
                            row.status != "FIND_DRIVER" &&
                            row.status != "CUSTOMER_CANCEL" &&
                            row.status != "STORE_CANCEL" &&
                            row.status != "STAFF_CANCEL" &&
                            row.status != "DRIVER_CANCEL" &&
                            row.status != "COMPLETE"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      type: "success",
                                      loading: row.loadingDelete,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleComplete(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" Hoàn thành đơn ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("li", [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatDateTime(row.dateCreated))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin", prop: "startName", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { padding: "0" } }, [
                      _c(
                        "li",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Người đặt: "),
                          ]),
                          row.customer
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs[
                                        "customer-dialog"
                                      ].handleEdit(row.customer)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.customer.name) +
                                      " - " +
                                      _vm._s(row.customer.phone) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v("--")]),
                        ],
                        1
                      ),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Người gửi: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(row.senderName) +
                              " - " +
                              _vm._s(row.senderPhone)
                          ),
                        ]),
                      ]),
                      !!row.receiverName
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Người nhận: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.receiverName) +
                                  " - " +
                                  _vm._s(row.receiverPhone)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "li",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tài xế: "),
                          ]),
                          row.driver
                            ? _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs["driver-dialog"].getData(
                                        row.driver.id,
                                        "transaction"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.driver.name) +
                                      " - " +
                                      _vm._s(row.driver.phone) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v("--")]),
                        ],
                        1
                      ),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Điểm đón: "),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.startAddress))]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khoảng cách: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatNumberVN(row.distance)) + " Km"
                          ),
                        ]),
                      ]),
                      row.vehicle
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phương tiện: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.vehicle.name) +
                                  " " +
                                  _vm._s(
                                    row.vehicle.subName &&
                                      " | " + row.vehicle.subName
                                  )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Trạng thái: "),
                        ]),
                        _c("span", [
                          _c(
                            "b",
                            {
                              class:
                                "color-" +
                                (_vm.tagOptions[row.status] &&
                                  _vm.tagOptions[row.status].color),
                            },
                            [_vm._v(_vm._s(_vm.STATUS_ORDER[row.status]))]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thanh toán", "min-width": "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Trạng thái thanh toán:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.PaymentStatusTrans[row.paymentStatus])
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tổng cước phí:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyTotal))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Khuyến mãi:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyDiscount))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.type == _vm.OrderDeliveryType.Customer
                                ? _vm.PaymentByTrans[row.paymentBy]
                                : "Cửa hàng"
                            ) +
                              " trả (" +
                              _vm._s(
                                _vm.type == _vm.OrderDeliveryType.Customer
                                  ? _vm.PaymentTypeTrans[row.paymentType]
                                  : "Tủ"
                              ) +
                              "):"
                          ),
                        ]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _c(
                        "li",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs["detail-dialog"].handleView(
                                    row
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-info" }),
                              _vm._v(" Xem Chi tiết "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { display: "none" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
      _c("driver-dialog", { ref: "driver-dialog" }),
      _c("customer-dialog", {
        ref: "customer-dialog",
        attrs: { "form-disable": true },
      }),
      _c("order-dialog", {
        ref: "order-dialog",
        on: { "update:success": _vm.getTableData },
      }),
      _c("delivery-detail-dialog", {
        ref: "detail-dialog",
        attrs: { type: _vm.type },
      }),
      _c("ImagesOrder", { ref: "images-dialog" }),
      _c("import-delivery-order-modal", {
        ref: "ImportDeliveryOrderModal",
        on: { "submit:ok": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }