var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chương trình tuyển sinh",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hình ảnh", prop: "image" } },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.image,
                          width: 150,
                          height: 150,
                        },
                        on: { "upload:success": _vm.handleUploadSuccess },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên chương trình tuyển sinh" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thời gian xét tuyển" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "dd/MM/yyyy",
                          type: "daterange",
                          "start-placeholder": "Từ ngày",
                          "end-placeholder": "Đến ngày",
                          clearable: false,
                        },
                        model: {
                          value: _vm.admissionDate,
                          callback: function ($$v) {
                            _vm.admissionDate = $$v
                          },
                          expression: "admissionDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên khóa học" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.courseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "courseName", $$v)
                          },
                          expression: "form.courseName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thời gian học" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "MM/yyyy",
                          type: "monthrange",
                          "start-placeholder": "Từ ngày",
                          "end-placeholder": "Đến ngày",
                          clearable: false,
                        },
                        model: {
                          value: _vm.studyDate,
                          callback: function ($$v) {
                            _vm.studyDate = $$v
                          },
                          expression: "studyDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày hết hạn", prop: "expiredAt" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "dd/MM/yyyy",
                          "alue-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.expiredAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expiredAt", $$v)
                          },
                          expression: "form.expiredAt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số tuần học" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.numOfWeeks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "numOfWeeks", $$v)
                          },
                          expression: "form.numOfWeeks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày khai giảng dự kiến" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "dd/MM/yyyy",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.openDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "openDate", $$v)
                          },
                          expression: "form.openDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thí sinh nộp lệ phí hồ sơ" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "price", $$v)
                          },
                          expression: "form.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Chấp nhận thanh toán Phí tuyển sinh khóa nêu trên cho Fclass là",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.moneyCommission,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "moneyCommission", $$v)
                          },
                          expression: "form.moneyCommission",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Địa chỉ" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phương thức tuyển sinh" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { multiple: "", filterable: "" },
                          model: {
                            value: _vm.form.schoolAdmissionMethodIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "schoolAdmissionMethodIds",
                                $$v
                              )
                            },
                            expression: "form.schoolAdmissionMethodIds",
                          },
                        },
                        _vm._l(_vm.schoolAdmissionMethod, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { transform: "translateY(-25px)" },
                      attrs: { type: "text", size: "default" },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("Thêm phương thức mới")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đối tượng tuyển sinh" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.object,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "object", $$v)
                          },
                          expression: "form.object",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phạm vi tuyển sinh" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.range,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "range", $$v)
                          },
                          expression: "form.range",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Chỉ tiêu (Tải lên file PDF)" } },
                    [
                      _c("upload-file", {
                        attrs: {
                          maxFileSize: 20,
                          drag: false,
                          file: _vm.form.target,
                        },
                        on: {
                          "upload-success": function (path) {
                            return _vm.$set(_vm.form, "target", path)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Ngưỡng đảm bảo chất lượng đầu vào, điều kiện nhận đăng ký xét tuyển",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.inputQuality,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "inputQuality", $$v)
                          },
                          expression: "form.inputQuality",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Chính sách ưu tiên" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.priorityPolicy,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "priorityPolicy", $$v)
                          },
                          expression: "form.priorityPolicy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Học phí dự kiến với sinh viên và lộ trình tăng học phí tối đa cho từng năm",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.feeEstimated,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "feeEstimated", $$v)
                          },
                          expression: "form.feeEstimated",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Các nội dung khác" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "note", $$v)
                          },
                          expression: "form.note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.isHighlight1,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isHighlight1", $$v)
                            },
                            expression: "form.isHighlight1",
                          },
                        },
                        [_vm._v(" Ngành học hấp dẫn tại Việt Nam")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.isHighlight2,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isHighlight2", $$v)
                            },
                            expression: "form.isHighlight2",
                          },
                        },
                        [_vm._v(" Ngành học HOT thế giới")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.updateData } },
            [_vm._v("Lưu thay đổi")]
          ),
        ],
        1
      ),
      _c("school-admission-method-modal", {
        ref: "SchoolAdmissionMethodModal",
        on: { "submit:ok": _vm.fetchSchoolAdmissionMethod },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }