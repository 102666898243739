var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "Provider-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.dialogStatus == "create"
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleOpenSelectMallModal },
                        },
                        [_vm._v("Chọn từ mall")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hình ảnh đại diện", prop: "avatar" } },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.avatar,
                          width: 150,
                          height: 150,
                        },
                        on: { "upload:success": _vm.handleUploadSuccess },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mã nhà cung cấp", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên đăng nhập", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.dialogStatus != "create" },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _vm.dialogStatus == "create"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Mật khẩu", prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: { "show-password": "" },
                            model: {
                              value: _vm.form.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên nhà cung cấp", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số điện thoại" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trạng thái khóa" } },
                    [
                      _c("el-switch", {
                        staticStyle: { "margin-bottom": "1em" },
                        attrs: {
                          "active-text": "Khóa",
                          "inactive-text": "Không khóa",
                          "active-color": "red",
                          "inactive-color": "#11CE66",
                        },
                        model: {
                          value: _vm.form.isBlocked,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isBlocked", $$v)
                          },
                          expression: "form.isBlocked",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "h3",
                  {
                    staticStyle: { "margin-top": "0", "margin-bottom": "8px" },
                  },
                  [_vm._v("Địa chỉ")]
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số nhà, tên đường", prop: "street" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.street,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "street", $$v)
                          },
                          expression: "form.street",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thành phố", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                {
                  attrs: { name: "1" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("h4", [
                            _c("img", {
                              staticStyle: { "vertical-align": "middle" },
                              attrs: {
                                width: "40",
                                src: require("@/assets/images/icon4.png"),
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "vertical-align": "middle" } },
                              [
                                _vm._v(
                                  " Thông tin Công ty/Hộ Cá thể (Chọn để thu gọn/ mở rộng) "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("company-form", {
                    attrs: { form: _vm.form, formDisable: _vm.formDisable },
                  }),
                ],
                1
              ),
              _c(
                "el-collapse-item",
                {
                  attrs: { name: "2" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("h4", [
                            _c("img", {
                              staticStyle: { "vertical-align": "middle" },
                              attrs: {
                                width: "40",
                                src: require("@/assets/images/icon2.png"),
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "vertical-align": "middle" } },
                              [
                                _vm._v(
                                  "Thông tin Chủ sở hữu/Người Đại diện Công ty/Hộ Cá thể /Cá nhân (Chọn để thu gọn/ mở rộng)"
                                ),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("owner-form", {
                    attrs: { form: _vm.form, formDisable: _vm.formDisable },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("select-mall-modal", {
        ref: "SelectMallModal",
        attrs: { form: _vm.form },
        on: {
          "update:form": function ($event) {
            _vm.form = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }