"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PrefixImage = void 0;
var PrefixImage;
(function (PrefixImage) {
  PrefixImage["Avatar"] = "avatar";
  PrefixImage["Food"] = "food";
  PrefixImage["Popup"] = "popup";
  PrefixImage["Banner"] = "banner";
  PrefixImage["News"] = "news";
  PrefixImage["Promotion"] = "promotion";
  PrefixImage["PromotionSquare"] = "promotion-square";
  PrefixImage["Mall"] = "mall";
  PrefixImage["MallCategoryIcon"] = "mall-category";
  PrefixImage["MallSearchPopular"] = "mall-search-popular";
})(PrefixImage || (exports.PrefixImage = PrefixImage = {}));