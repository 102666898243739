import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6c042597&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c042597",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Untitled/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c042597')) {
      api.createRecord('6c042597', component.options)
    } else {
      api.reload('6c042597', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=6c042597&scoped=true", function () {
      api.rerender('6c042597', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/transfer-balance/index.vue"
export default component.exports