var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Mã giao dịch")]),
            _c("el-input", {
              staticStyle: { width: "300px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "Tìm mã giao dịch",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchTransactions($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Ngày giao dịch")]),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                format: "dd/MM/yyyy",
                "range-separator": "To",
                "start-placeholder": "Start date",
                "end-placeholder": "End date",
                "value-format": "yyyy-MM-dd",
              },
              on: {
                change: function ($event) {
                  _vm.query.page = 1
                  _vm.fetchTransactions()
                },
              },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-search",
                },
                on: { click: _vm.fetchTransactions },
              },
              [_vm._v("Tìm")]
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  type: "primary",
                  loading: _vm.loadingExport,
                  icon: "el-icon-download",
                },
                on: { click: _vm.handleExportExcel },
              },
              [_vm._v(" Xuất excel ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.transactions, border: "", fit: "" } },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Mã giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.code || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã đơn hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.agentOrder
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOpenOrderDetail(row)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "600",
                                  color: "#1791FE",
                                },
                              },
                              [_vm._v(_vm._s(row.agentOrder.code))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.ProviderTransactionTypeTrans[row.type]) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tiền trước giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatCurrency(row.beforeChange)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tiền giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-weight": "bold" },
                        style:
                          row.change < 0 ? "color:#F56C6C" : "color:#67C23A",
                      },
                      [
                        _vm._v(
                          _vm._s(row.change > 0 ? "+" : "") +
                            _vm._s(_vm.$formatCurrency(row.change)) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số dư" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatCurrency(row.afterChange)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchTransactions,
        },
      }),
      _c("AgentOrderDetailModal", { ref: "AgentOrderDetailModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }