var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "To",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: { query: _vm.query },
            on: { "change:address": _vm.fetchNotifications },
          }),
          _vm.$checkAction("add-noti")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v("Tạo mới ")]
              )
            : _vm._e(),
          _vm.checkPermissionExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExport,
                  },
                  on: { click: _vm.handleExportExcel },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.notifications, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tiêu đề", prop: "title" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_c("label", [_vm._v(_vm._s(row.title))])]),
                    row.type == _vm.IdealNotificationType.Normal
                      ? _c("el-tag", { attrs: { size: "small" } }, [
                          _c("b", [_vm._v("Mở app")]),
                        ])
                      : _vm._e(),
                    row.type == _vm.IdealNotificationType.Banner
                      ? _c(
                          "el-tag",
                          { attrs: { type: "success", size: "small" } },
                          [_c("b", [_vm._v("Banner")])]
                        )
                      : _vm._e(),
                    row.type == _vm.IdealNotificationType.Product
                      ? _c(
                          "el-tag",
                          { attrs: { type: "warning", size: "small" } },
                          [_c("b", [_vm._v("Khuyến mãi")])]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Nội dung", prop: "body" } }),
          _c("el-table-column", {
            attrs: { label: "Khu vực" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.city
                      ? _c("div", [
                          _vm._v(" " + _vm._s(row.city.nameWithType) + " "),
                        ])
                      : _vm._e(),
                    row.district
                      ? _c("div", [
                          _vm._v(" " + _vm._s(row.district.nameWithType) + " "),
                        ])
                      : _vm._e(),
                    row.ward
                      ? _c("div", [
                          _vm._v(" " + _vm._s(row.ward.nameWithType) + " "),
                        ])
                      : _vm._e(),
                    !row.city && !row.district
                      ? [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tất cả"),
                          ]),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Banner / Khuyến mãi" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.type == _vm.IdealNotificationType.Banner
                      ? _c("img", {
                          staticStyle: { "border-radius": "8px" },
                          attrs: {
                            width: "150",
                            src: _vm.$url.image(row.idealBanner.image),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    row.type == _vm.IdealNotificationType.Product
                      ? _c("img", {
                          staticStyle: { "border-radius": "8px" },
                          attrs: {
                            width: "150",
                            src: _vm.$url.image(row.idealProduct.imageUrl),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Gửi thông báo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _c("label", [_vm._v("Hẹn lịch gửi:")]),
                      _vm._v(
                        " " + _vm._s(_vm.$formatDateTime(row.scheduleAt)) + " "
                      ),
                    ]),
                    _c("br"),
                    _c("span", [
                      _c("label", [_vm._v("Gửi lần cuối:")]),
                      _vm._v(
                        " " + _vm._s(_vm.$formatDateTime(row.publishAt)) + " "
                      ),
                    ]),
                    row.publishStaff
                      ? [
                          _c("br"),
                          _c("span", [
                            _c("label", [_vm._v("N.viên gửi:")]),
                            _vm._v(
                              " " +
                                _vm._s(row.publishStaff.name) +
                                " (" +
                                _vm._s(row.publishStaff.phone) +
                                ")"
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _c("br"),
                    _vm.$checkAction("send-noti")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              loading: row.loadingPublish,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handlePublish(row)
                              },
                            },
                          },
                          [_vm._v("Gửi thông báo")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", align: "center", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.$checkAction("copy-noti")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px 0", width: "100%" },
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(row)
                              },
                            },
                          },
                          [_vm._v("Copy")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "5px 0", width: "100%" },
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Chi tiết")]
                    ),
                    _vm.$checkAction("delete-noti")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px 0", width: "100%" },
                            attrs: { type: "danger", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchNotifications,
        },
      }),
      _c("notification-modal", {
        ref: "NotificationModal",
        attrs: {
          districtId: _vm.query.districtId,
          cityId: _vm.query.cityId,
          wardId: _vm.query.wardId,
        },
        on: { "submit:ok": _vm.fetchNotifications },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }