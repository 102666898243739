"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configProfessionalDriverApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var configProfessionalDriverApi = exports.configProfessionalDriverApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/configProfessionalDriver',
      params: params
    });
  },
  init: function init(data) {
    return (0, _request.default)({
      url: '/configProfessionalDriver/init',
      method: 'post',
      data: data
    });
  },
  update: function update(configProfessionalDriverId, data) {
    return (0, _request.default)({
      url: "/configProfessionalDriver/".concat(configProfessionalDriverId, "/update"),
      data: data,
      method: 'post'
    });
  }
};