"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WalletTransactionTypeTrans = exports.WalletTransactionType = exports.WalletMemberStatusTrans = exports.WalletMemberStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var WalletTransactionType;
(function (WalletTransactionType) {
  WalletTransactionType["Deposit"] = "DEPOSIT";
  WalletTransactionType["OrderFood"] = "ORDER_FOOD";
  WalletTransactionType["OrderTransport"] = "ORDER_TRANSPORT";
  WalletTransactionType["OrderDelivery"] = "ORDER_DELIVERY";
  WalletTransactionType["OrderTransportCancel"] = "ORDER_TRANSPORT_CANCEL";
  WalletTransactionType["Withdraw"] = "WITHDRAW";
  WalletTransactionType["MallOrder"] = "MALLO_ORDER";
  WalletTransactionType["OrderDeliveryCancel"] = "ORDER_DELIVERY_CANCEL";
  WalletTransactionType["OrderFoodCancel"] = "ORDER_FOOD_CANCEL";
  WalletTransactionType["MallOrderCancel"] = "MALLO_ORDER_CANCEL";
})(WalletTransactionType || (exports.WalletTransactionType = WalletTransactionType = {}));
var WalletTransactionTypeTrans = exports.WalletTransactionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, WalletTransactionType.Deposit, "Nạp tiền"), WalletTransactionType.Withdraw, 'Rút tiền'), WalletTransactionType.OrderDelivery, 'Thanh toán đơn giao hàng'), WalletTransactionType.OrderFood, 'Thanh toán đơn F&B'), WalletTransactionType.OrderTransport, 'Thanh toán đơn gọi xe'), WalletTransactionType.OrderTransportCancel, 'Hoàn trả tiền thanh toán đơn gọi xe'), WalletTransactionType.MallOrder, 'Thanh toán đơn FclassMall'), WalletTransactionType.OrderDeliveryCancel, "Hoàn trả tiền thanh toán đơn giao hàng"), WalletTransactionType.OrderFoodCancel, 'Hoàn trả tiền thanh toán đơn F&B'), WalletTransactionType.MallOrderCancel, 'Hoàn trả tiền thanh toán đơn FclassMall');
var WalletMemberStatus;
(function (WalletMemberStatus) {
  WalletMemberStatus["Pending"] = "PENDING";
  WalletMemberStatus["Approve"] = "APPROVE";
  WalletMemberStatus["Invite"] = "INVITE";
})(WalletMemberStatus || (exports.WalletMemberStatus = WalletMemberStatus = {}));
var WalletMemberStatusTrans = exports.WalletMemberStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, WalletMemberStatus.Approve, 'Đã duyệt'), WalletMemberStatus.Invite, 'Được mời tham gia'), WalletMemberStatus.Pending, 'Chờ duyệt');