var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.drag
    ? _c(
        "el-upload",
        {
          staticClass: "upload-file",
          attrs: {
            action: "",
            "on-change": _vm.handleChange,
            "file-list": _vm.fileList,
            "before-upload": _vm.beforeUpload,
            accept: _vm.accept,
            drag: "",
          },
          on: { "on-remove": _vm.handleRemove },
        },
        [
          _vm.isProgressing
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.percentUpload },
              })
            : [
                _c("i", { staticClass: "el-icon-upload" }),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v("Kéo thả hoặc "),
                  _c("em", [_vm._v("click vào để upload")]),
                ]),
              ],
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(_vm._s(_vm.description))]
          ),
        ],
        2
      )
    : _c(
        "div",
        [
          _c(
            "el-upload",
            {
              attrs: {
                action: "",
                "on-change": _vm.handleChange,
                "file-list": _vm.fileList,
                "before-upload": _vm.beforeUpload,
                "show-file-list": false,
                accept: _vm.accept,
              },
              on: { "on-remove": _vm.handleRemove },
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini", icon: "el-icon-upload2" } },
                [_vm._v("Tải file lên")]
              ),
            ],
            1
          ),
          _vm.file
            ? _c(
                "a",
                {
                  staticClass: "link",
                  staticStyle: { color: "#1890ff" },
                  attrs: { href: _vm.file, target: "_blank" },
                },
                [_vm._v(_vm._s(_vm.file))]
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }