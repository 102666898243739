var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "food-list" } },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-row filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Sản phẩm")]),
            _c("el-input", {
              staticStyle: { width: "300px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "Tìm tên sản phẩm",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getTableData($event)
                },
              },
              model: {
                value: _vm.listQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "filter-row",
            staticStyle: { transform: "translateY(10px)" },
          },
          [
            _c(
              "div",
              { staticClass: "filter-item", staticStyle: { flex: "1" } },
              [_c("span", [_vm._v(_vm._s(_vm.total) + " sản phẩm")])]
            ),
            !_vm.disable
              ? _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary", icon: "el-icon-edit" },
                    on: {
                      click: function ($event) {
                        return _vm.handleAdd()
                      },
                    },
                  },
                  [_vm._v("Thêm sản phẩm mới")]
                )
              : _vm._e(),
            !_vm.disable
              ? _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: { type: "primary", icon: "el-icon-plus" },
                    on: { click: _vm.handleImport },
                  },
                  [_vm._v("Thêm hàng loạt")]
                )
              : _vm._e(),
            !_vm.disable
              ? _c(
                  "el-button",
                  {
                    staticClass: "filter-item",
                    attrs: {
                      type: "primary",
                      icon: "el-icon-download",
                      loading: _vm.loadingExport,
                    },
                    on: { click: _vm.handleExport },
                  },
                  [_vm._v("Xuất excel")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "el-table-draggable",
        { on: { drop: _vm.handleDrop } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "technician-table",
              attrs: { data: _vm.tableData, border: "", fit: "" },
            },
            [
              _c("el-table-column", {
                attrs: { width: "60", align: "center", label: "STT" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var $index = ref.$index
                      return [
                        _c("span", [_vm._v(_vm._s($index + 1))]),
                        _c("i", {
                          staticClass: "el-icon-sort",
                          staticStyle: {
                            cursor: "move",
                            "font-size": "15px",
                            "font-weight": "bold",
                            color: "black",
                            "margin-left": "4px",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "Tên sản phẩm",
                  align: "center",
                  width: "270",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "info-box" }, [
                          _c("div", { staticClass: "info" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(row.name)),
                            ]),
                          ]),
                          _c("img", {
                            staticClass: "image",
                            staticStyle: { "object-fit": "cover" },
                            attrs: {
                              src: _vm.$url.image(row.thumbnail),
                              alt: "",
                            },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Thông tin" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _c("b", [_vm._v("Danh mục:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.storeCategory && row.storeCategory.name
                              )
                          ),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Thành phần:")]),
                          _vm._v(" " + _vm._s(row.description)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "high-light",
                            on: {
                              click: function ($event) {
                                return _vm.highlight(row)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                width: "20",
                                src: row.isHighlight
                                  ? _vm.startImg
                                  : _vm.unStartImg,
                              },
                            }),
                            _c(
                              "el-link",
                              {
                                attrs: {
                                  type: row.isHighlight ? "warning" : "",
                                },
                              },
                              [_vm._v(" Nổi bật ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Giá" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", { staticClass: "currency-row" }, [
                          _c("b", [_vm._v("Giá gốc:")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$formatCurrency(row.originPrice))
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "currency-row" }, [
                          _c("b", [_vm._v("Khuyến mãi:")]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$formatCurrency(row.finalPrice))),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Mô tả", prop: "note" },
              }),
              _c("el-table-column", {
                attrs: { width: "177", label: "Trạng thái" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-switch", {
                          staticStyle: { display: "block" },
                          attrs: {
                            value: row.isOutOfStock == false,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                            "active-text": "Còn hàng",
                            "inactive-text": "Hết",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleOutOfStock(row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Ngày tạo" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Hành động",
                  width: "auto",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "5px 0" },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("Chi tiết")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { margin: "5px 0" },
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEditVisible(row)
                                  },
                                },
                              },
                              [_vm._v("Hiển thị")]
                            ),
                            !_vm.disable
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "5px 0" },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Xóa")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("food-dialog", {
        ref: "dialog",
        attrs: { storeId: _vm.storeId, dialogDisabled: _vm.disable },
        on: {
          "update:storeId": function ($event) {
            _vm.storeId = $event
          },
          "update:store-id": function ($event) {
            _vm.storeId = $event
          },
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
      _c("food-visible-dialog", {
        ref: "FoodVisibleDialog",
        attrs: { storeId: _vm.storeId, dialogDisabled: _vm.disable },
        on: {
          "update:storeId": function ($event) {
            _vm.storeId = $event
          },
          "update:store-id": function ($event) {
            _vm.storeId = $event
          },
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
      _c("import-food-modal", {
        ref: "ImportFoodModal",
        attrs: { storeId: _vm.storeId },
        on: { "submit:ok": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }