var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "store-info", loading: _vm.dialogLoading } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 100 } },
            [
              _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                _c(
                  "section",
                  [
                    _c("h3", { staticClass: "title" }, [_vm._v("Thông tin")]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Tên Khách sạn/Resort",
                                  prop: "name",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Mã số thuế (nếu có)",
                                  prop: "taxCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.taxCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "taxCode", $$v)
                                    },
                                    expression: "form.taxCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Chủ khách sạn",
                                  prop: "bookingHostId",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Chủ khách sạn"),
                                        ]),
                                        _c(
                                          "el-link",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              "line-height": "12px",
                                              "z-index": "1000",
                                              "margin-left": "4px",
                                            },
                                            attrs: { type: "primary" },
                                            on: { click: _vm.handleAddHost },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-plus",
                                            }),
                                            _vm._v(" Thêm mới"),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      remote: "",
                                      "remote-method": _vm.fetchHost,
                                    },
                                    model: {
                                      value: _vm.form.bookingHostId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "bookingHostId", $$v)
                                      },
                                      expression: "form.bookingHostId",
                                    },
                                  },
                                  _vm._l(_vm.hosts, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label:
                                          item.companyName +
                                          " - " +
                                          item.companyPhone,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Địa chỉ", prop: "address" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("label", { attrs: { for: "" } }, [
                                          _vm._v("Địa chỉ"),
                                        ]),
                                        _c(
                                          "el-link",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              "line-height": "12px",
                                              "z-index": "1000",
                                              "margin-left": "4px",
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: _vm.handleChangeAddress,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-edit",
                                            }),
                                            _vm._v(" Thay đổi"),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.formDisable,
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.form.address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "address", $$v)
                                    },
                                    expression: "form.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [_c("AddressForm", { attrs: { form: _vm.form } })],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Website", prop: "website" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.website,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "website", $$v)
                                    },
                                    expression: "form.website",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Số điện thoại",
                                  prop: "phone",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "phone", $$v)
                                    },
                                    expression: "form.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Zalo", prop: "zalo" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.zalo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "zalo", $$v)
                                    },
                                    expression: "form.zalo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Email", prop: "email" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Đại diện là Ông/Bà",
                                  prop: "ownerName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.ownerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ownerName", $$v)
                                    },
                                    expression: "form.ownerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Chức vụ",
                                  prop: "ownerPosition",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: _vm.formDisable },
                                  model: {
                                    value: _vm.form.ownerPosition,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ownerPosition", $$v)
                                    },
                                    expression: "form.ownerPosition",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Quốc gia", prop: "nationId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      remote: true,
                                      "remote-method": _vm.fetchNation,
                                    },
                                    model: {
                                      value: _vm.form.nationId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "nationId", $$v)
                                      },
                                      expression: "form.nationId",
                                    },
                                  },
                                  _vm._l(_vm.nations, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Loại hình kinh doanh",
                                  prop: "businessType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { filterable: "" },
                                    model: {
                                      value: _vm.form.businessType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "businessType", $$v)
                                      },
                                      expression: "form.businessType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.BookingBusinessType,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: {
                                          label:
                                            _vm.BookingBusinessTypeTrans[item],
                                          value: item,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Tiêu chuẩn KS/R",
                                  prop: "rateStar",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { filterable: "" },
                                    model: {
                                      value: _vm.form.rateStar,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "rateStar", $$v)
                                      },
                                      expression: "form.rateStar",
                                    },
                                  },
                                  _vm._l(
                                    Array(5).fill(0),
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: index + 1 + " sao",
                                          value: index + 1,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "View phòng",
                                  prop: "roomViewIds",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { filterable: "", multiple: "" },
                                    model: {
                                      value: _vm.form.roomViewIds,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "roomViewIds", $$v)
                                      },
                                      expression: "form.roomViewIds",
                                    },
                                  },
                                  _vm._l(_vm.bookingViews, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.form.isHighlight,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isHighlight", $$v)
                                      },
                                      expression: "form.isHighlight",
                                    },
                                  },
                                  [_vm._v(" Nổi bật")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Tiện ích" } },
                              [
                                _c("el-tree", {
                                  ref: "tree",
                                  staticClass: "hotel-utils-tree",
                                  attrs: {
                                    "default-expand-all": true,
                                    data: _vm.treeData,
                                    props: _vm.defaultProps,
                                    "default-checked-keys":
                                      _vm.form.bookingUtilIds,
                                    "show-checkbox": "",
                                    "node-key": "id",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var data = ref.data
                                        return _c(
                                          "span",
                                          { staticClass: "custom-tree-node" },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(data.title)),
                                            ]),
                                          ]
                                        )
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Nổi bật",
                                  prop: "description",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 2, maxRows: 6 },
                                    disabled: _vm.formDisable,
                                  },
                                  model: {
                                    value: _vm.form.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "description", $$v)
                                    },
                                    expression: "form.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("label", [_vm._v("Chính sách hủy phòng")]),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 18 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.form.cancelRoom,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "cancelRoom",
                                                  $$v
                                                )
                                              },
                                              expression: "form.cancelRoom",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: false } },
                                                  [_vm._v(" Không hoàn tiền ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  { attrs: { gutter: 20 } },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label: true,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Có hoàn tiền "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.form.cancelRoom
                                                      ? _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  filterable:
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .cancelValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "cancelValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.cancelValue",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.cancelPolicies,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item,
                                                                      attrs: {
                                                                        label:
                                                                          item +
                                                                          "%",
                                                                        value:
                                                                          item,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 20 } },
                              [
                                _c("el-col", { attrs: { span: 6 } }, [
                                  _c("label", [_vm._v("Điều kiện đặt phòng")]),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 18 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value:
                                                _vm.form.bookingRoomCondition,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "bookingRoomCondition",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.bookingRoomCondition",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm
                                                          .BookingRoomConditionType
                                                          .No,
                                                    },
                                                  },
                                                  [_vm._v(" Không điều kiện ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm
                                                          .BookingRoomConditionType
                                                          .Paid,
                                                    },
                                                  },
                                                  [_vm._v(" Thanh toán 100% ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-row",
                                                  { attrs: { gutter: 20 } },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 12 } },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm
                                                                  .BookingRoomConditionType
                                                                  .Custom,
                                                            },
                                                          },
                                                          [_vm._v(" Đặt cọc ")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.form
                                                      .bookingRoomCondition ==
                                                    _vm.BookingRoomConditionType
                                                      .Custom
                                                      ? _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  filterable:
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .bookingRoomConditionValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "bookingRoomConditionValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.bookingRoomConditionValue",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.bookingConditions,
                                                                function (
                                                                  item
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: item,
                                                                      attrs: {
                                                                        label:
                                                                          item +
                                                                          "%",
                                                                        value:
                                                                          item,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Quy định giờ nhận phòng",
                                  prop: "inRoomHour",
                                },
                              },
                              [
                                _c("el-time-select", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    "picker-options": {
                                      start: "00:00",
                                      step: "00:01",
                                      end: "23:59",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.inRoomHour,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "inRoomHour", $$v)
                                    },
                                    expression: "form.inRoomHour",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Quy định giờ trả phòng",
                                  prop: "outRoomHour",
                                },
                              },
                              [
                                _c("el-time-select", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    "picker-options": {
                                      start: "00:00",
                                      step: "00:01",
                                      end: "23:59",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.outRoomHour,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "outRoomHour", $$v)
                                    },
                                    expression: "form.outRoomHour",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Giờ lễ tân mở cửa",
                                  prop: "receptionHour",
                                },
                              },
                              [
                                _c("el-time-select", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    "picker-options": {
                                      start: "00:00",
                                      step: "00:01",
                                      end: "23:59",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.receptionHour,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "receptionHour", $$v)
                                    },
                                    expression: "form.receptionHour",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Phí sử dụng ứng dụng nhận đặt phòng",
                                  prop: "feeAppValue",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                        },
                                        expression:
                                          "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                      },
                                    ],
                                    attrs: { disabled: _vm.formDisable },
                                    model: {
                                      value: _vm.form.feeAppValue,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "feeAppValue", $$v)
                                      },
                                      expression: "form.feeAppValue",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "70px" },
                                        attrs: { slot: "append" },
                                        slot: "append",
                                        model: {
                                          value: _vm.form.feeAppType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "feeAppType",
                                              $$v
                                            )
                                          },
                                          expression: "form.feeAppType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "%",
                                            value:
                                              _vm.BookingFeeAppType.Percent,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "đ",
                                            value: _vm.BookingFeeAppType.Fixed,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "Hình ảnh khách sạn (Tỷ lệ: 1/1, khuyến nghị: 700 x 700, dung lượng tối đa 2MB)",
                            size: "normal",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.avatar,
                              width: 200,
                              height: 200,
                              prefixFile: _vm.PrefixImage.Avatar,
                            },
                            on: {
                              "upload:success": function (url) {
                                return _vm.handleUploadSuccess("avatar", url)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Ảnh khác (Đề xuất ảnh tỉ lệ 1/1)",
                            prop: "thumbnail",
                          },
                        },
                        [
                          _c("MultipleImageS3", {
                            attrs: { fileList: _vm.fileList },
                            on: {
                              "upload:success": _vm.handleUploadMultipleSuccess,
                              "remove:success": _vm.handleRemove,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "Giấy ủy quyền số (nếu không phải ĐD theo PL)",
                            prop: "ownerAuthorNo",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.ownerAuthorNo,
                              width: 150,
                              sizeLimit: 5,
                              height: 150,
                            },
                            on: {
                              "upload:success": function (url) {
                                return _vm.handleUploadSuccess(
                                  "ownerAuthorNo",
                                  url
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Giấy CNĐKKD",
                            prop: "businessImage",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.businessImage,
                              width: 150,
                              sizeLimit: 5,
                              height: 150,
                            },
                            on: {
                              "upload:success": function (url) {
                                return _vm.handleUploadSuccess(
                                  "businessImage",
                                  url
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "Quyết định bổ nhiệm đại diện theo pháp luật",
                            prop: "ownerPresentLaw",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.formDisable },
                            model: {
                              value: _vm.form.ownerPresentLaw,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "ownerPresentLaw", $$v)
                              },
                              expression: "form.ownerPresentLaw",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giấy ủy quyền", prop: "authorNo" } },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.authorNo,
                              width: 150,
                              sizeLimit: 5,
                              height: 150,
                            },
                            on: {
                              "upload:success": function (url) {
                                return _vm.handleUploadSuccess("authorNo", url)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Tổng số phòng", prop: "totalRoom" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.formDisable },
                            model: {
                              value: _vm.form.totalRoom,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "totalRoom", $$v)
                              },
                              expression: "form.totalRoom",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { display: "none" },
        attrs: {
          src: _vm.selectedImage,
          alt: "Hình menu",
          "preview-src-list": [_vm.selectedImage],
        },
      }),
      _c("map-modal", {
        ref: "MapModal",
        attrs: {
          initLocation: {
            lat: _vm.form.lat,
            long: _vm.form.long,
          },
        },
      }),
      _c("customer-dialog", {
        ref: "CustomerDialog",
        on: {
          "create:success": _vm.fetchHost,
          "update:success": _vm.fetchHost,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }