var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gmap-map",
    {
      ref: "mapRef",
      staticStyle: { width: "100%", height: "600px" },
      attrs: {
        center: _vm.center,
        zoom: 7,
        options: {
          styles: _vm.mapStyle,
        },
      },
    },
    [
      _vm._t("default"),
      _vm._t("polyline"),
      _vm.visibleDriverInfo
        ? _c(
            "gmap-info-window",
            {
              attrs: { position: _vm.center, opened: _vm.visibleDriverInfo },
              on: {
                closeclick: function ($event) {
                  _vm.visibleDriverInfo = false
                },
              },
            },
            [
              _c("driver-info", {
                attrs: { driver: _vm.driverSelected },
                on: { "click-outside": _vm.handleClickOutside },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.map
        ? _vm._l(_vm.drivers, function (item) {
            return _c("custom-marker", {
              key: "driver-" + item.id,
              attrs: {
                duration: _vm.animationDriverFree,
                map: _vm.map,
                icon: _vm.renderIconVehicle(item),
                position: {
                  lat: item.lastGps.lat,
                  lng: item.lastGps.long,
                },
              },
              on: {
                click: function (latLng) {
                  return _vm.handleClickDriverMarker(latLng, item)
                },
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }