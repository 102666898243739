var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật thương hiệu"
            : "Thêm mới thương hiệu",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("agent-brand-info", {
            ref: "AgentBrandInfo",
            attrs: {
              form: _vm.form,
              formDisable: false,
              dialogStatus: _vm.status,
            },
            on: {
              "update:form": function ($event) {
                _vm.form = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.tabActive != "branch"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }