"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appFeatureTypeTrans = exports.AppFeatureType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _appFeatureTypeTrans;
var AppFeatureType;
(function (AppFeatureType) {
  AppFeatureType["SchoolAdmission"] = "SCHOOL_ADMISSION";
  AppFeatureType["Mall"] = "MALL";
  AppFeatureType["QRAccess"] = "QR_ACCESS";
  AppFeatureType["Food"] = "FOOD";
  AppFeatureType["Transport"] = "TRANSPORT";
  AppFeatureType["Delivering"] = "DELIVERING";
  AppFeatureType["Draw"] = "DRAW";
  AppFeatureType["Booking"] = "BOOKING";
  AppFeatureType["Edu"] = "EDU";
  AppFeatureType["Career"] = "CAREER";
  AppFeatureType["Meet"] = "MEET";
  AppFeatureType["Secondhand"] = "SECONDHAND";
  AppFeatureType["KhuyenHoc"] = "KHUYEN_HOC";
  AppFeatureType["Medic"] = "MEDIC";
  AppFeatureType["BuyCard"] = "BUY_CARD";
  AppFeatureType["Topup"] = "TOPUP";
  AppFeatureType["Bill"] = "BILL";
})(AppFeatureType || (exports.AppFeatureType = AppFeatureType = {}));
var appFeatureTypeTrans = exports.appFeatureTypeTrans = (_appFeatureTypeTrans = {}, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_appFeatureTypeTrans, AppFeatureType.SchoolAdmission, "Tuyển sinh"), AppFeatureType.Mall, "Trung tâm thương mại"), AppFeatureType.QRAccess, "QR Access"), AppFeatureType.Food, "Đơn thức ăn"), AppFeatureType.Transport, "Đơn gọi xe"), AppFeatureType.Delivering, "Đơn giao hàng"), AppFeatureType.Draw, "Bốc thăm"), AppFeatureType.Booking, "Đặt phòng KS - Du lịch"), AppFeatureType.Edu, "Khóa học"), AppFeatureType.Career, "Việc làm"), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_appFeatureTypeTrans, AppFeatureType.Meet, 'Fclass Meet'), AppFeatureType.Secondhand, '2nd hand - Gift'), AppFeatureType.KhuyenHoc, 'Khuyến học/Thiện nguyện'), AppFeatureType.Medic, 'Medic'), AppFeatureType.BuyCard, 'Mua thẻ cào'), AppFeatureType.Topup, 'Nạp điện thoại'), AppFeatureType.Bill, "Thanh toán hóa đơn"));