var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title:
          _vm.dialogStatus == "update" ? "Cập nhật tin tức" : "Thêm tin tức",
        visible: _vm.dialogVisible,
        width: "1200px",
        top: "15px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tiêu đề", prop: "title" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.formData.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "title", $$v)
                              },
                              expression: "formData.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tỉnh / thành phố" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: !_vm.$store.state.user.info.branch,
                                placeholder: "Tất cả",
                              },
                              on: { change: _vm.changeCity },
                              model: {
                                value: _vm.formData.cityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "cityId", $$v)
                                },
                                expression: "formData.cityId",
                              },
                            },
                            [
                              !_vm.$store.state.user.info.branch
                                ? _c("el-option", {
                                    attrs: { label: "Tất cả", value: 0 },
                                  })
                                : _vm._e(),
                              _vm._l(_vm.cities, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.nameWithType,
                                    value: item.id,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Quận huyện" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable:
                                  !_vm.branch ||
                                  (_vm.branch && !_vm.branch.districts.length),
                                placeholder: "Tất cả",
                              },
                              on: { change: _vm.changeDistrict },
                              model: {
                                value: _vm.formData.districtId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "districtId", $$v)
                                },
                                expression: "formData.districtId",
                              },
                            },
                            [
                              !_vm.branch ||
                              (_vm.branch && !_vm.branch.districts.length)
                                ? _c("el-option", {
                                    attrs: { label: "Tất cả", value: 0 },
                                  })
                                : _vm._e(),
                              _vm._l(_vm.districts, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.nameWithType,
                                    value: item.id,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Phường xã" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "Tất cả",
                                clearable:
                                  !_vm.branch ||
                                  (_vm.branch && !_vm.branch.wards.length),
                              },
                              model: {
                                value: _vm.formData.wardId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "wardId", $$v)
                                },
                                expression: "formData.wardId",
                              },
                            },
                            [
                              !_vm.branch ||
                              (_vm.branch && !_vm.branch.wards.length)
                                ? _c("el-option", {
                                    attrs: { label: "Tất cả", value: 0 },
                                  })
                                : _vm._e(),
                              _vm._l(_vm.wards, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.nameWithType,
                                    value: item.id,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              "Hình ảnh (Tỷ lệ 1/1, khuyến nghị: 500 x 500, dung lượng tối đa 2MB)",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.formData.image,
                              prefixFile: _vm.PrefixImage.News,
                              width: "100%",
                            },
                            on: { "upload:success": _vm.handleUploadSuccess },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mô tả", prop: "body" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: _vm.ModeType.Post },
                              model: {
                                value: _vm.formData.mode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "mode", $$v)
                                },
                                expression: "formData.mode",
                              },
                            },
                            [_vm._v("Dạng bài viết")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: _vm.ModeType.Link },
                              model: {
                                value: _vm.formData.mode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "mode", $$v)
                                },
                                expression: "formData.mode",
                              },
                            },
                            [_vm._v("Dạng liên kết ngoài")]
                          ),
                          _vm.formData.mode == _vm.ModeType.Link
                            ? _c("el-input", {
                                model: {
                                  value: _vm.formData.link,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "link", $$v)
                                  },
                                  expression: "formData.link",
                                },
                              })
                            : _c("Tinymce", {
                                ref: "editor",
                                attrs: { width: "100%", height: 400 },
                                model: {
                                  value: _vm.formData.body,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "body", $$v)
                                  },
                                  expression: "formData.body",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.submitLoading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogStatus == "create"
                    ? _vm.createData()
                    : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }