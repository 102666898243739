var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "upload-control" } },
    [
      !_vm.fileUrl
        ? _c(
            "el-upload",
            {
              style:
                "width:" +
                _vm.computeWidth +
                ";height:" +
                _vm.computeHeight +
                ";margin:0 auto",
              attrs: {
                name: "file",
                drag: "",
                "before-upload": _vm.beforeUpload,
                "on-success": _vm.handleSuccess,
                "show-file-list": false,
                headers: { token: _vm.token },
                "on-progress": _vm.onProgress,
                "on-error": _vm.onError,
              },
            },
            [
              _vm.isProgressing
                ? _c("el-progress", {
                    attrs: { type: "circle", percentage: _vm.percentUpload },
                  })
                : [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v(" Kéo thả vào đây hoặc "),
                      _c("em", [_vm._v("click vào để upload")]),
                    ]),
                  ],
            ],
            2
          )
        : _vm.checkIsVideo(_vm.videoSrc)
        ? _c(
            "div",
            {
              style:
                "display: flex; justify-content: center;width:" +
                _vm.computeWidth +
                ";height:" +
                _vm.computeHeight +
                ";margin:0 auto",
            },
            [
              _c(
                "div",
                { staticClass: "content-video" },
                [
                  _c("el-button", {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "0",
                      "z-index": "5",
                    },
                    attrs: {
                      stype: "",
                      type: "warning",
                      icon: "el-icon-delete",
                      size: "mini",
                    },
                    on: { click: _vm.handleDelete },
                  }),
                  _c("video", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { controls: "", src: _vm.videoSrc },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm.checkIsFilePdf(_vm.videoSrc)
        ? _c(
            "div",
            {
              style:
                "display: flex; justify-content: center;width:" +
                _vm.computeWidth +
                ";height:" +
                _vm.computeHeight +
                ";margin:0 auto",
            },
            [
              _c(
                "div",
                { staticClass: "content-video" },
                [
                  _c("el-button", {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "0",
                      "z-index": "5",
                    },
                    attrs: {
                      stype: "",
                      type: "warning",
                      icon: "el-icon-delete",
                      size: "mini",
                    },
                    on: { click: _vm.handleDelete },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "upload-file",
                      on: {
                        click: function ($event) {
                          return _vm.handleViewPdf(_vm.videoSrc)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-document",
                        staticStyle: { "font-size": "5rem" },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "line-height": "normal",
                          },
                        },
                        [_vm._v(_vm._s(_vm.videoSrc))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              style:
                "display: flex; justify-content: center;width:" +
                _vm.computeWidth +
                ";height:" +
                _vm.computeHeight +
                ";margin:0 auto",
            },
            [
              _c(
                "div",
                { staticClass: "content-video" },
                [
                  _c("el-button", {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "0",
                      "z-index": "5",
                    },
                    attrs: {
                      stype: "",
                      type: "warning",
                      icon: "el-icon-delete",
                      size: "mini",
                    },
                    on: { click: _vm.handleDelete },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      },
                      on: { click: _vm.handlePreviewImage },
                    },
                    [
                      _c("img", {
                        staticClass: "image",
                        staticStyle: {
                          "object-fit": "contain",
                          width: "100%",
                          height: "100%",
                        },
                        attrs: { src: _vm.fileUrl },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
      _c("el-image", {
        ref: "imagePreview",
        staticClass: "image",
        style: "height:0;display:none",
        attrs: {
          alt: "",
          "z-index": 99999,
          fit: "contain",
          src: _vm.$url.image(_vm.fileUrl),
          "preview-src-list": [_vm.$url.image(_vm.fileUrl)],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }