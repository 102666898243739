var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "560px",
        title: "Các trường bắt buộc",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "chọn các trường bắt buộc" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.requireList,
                    callback: function ($$v) {
                      _vm.requireList = $$v
                    },
                    expression: "requireList",
                  },
                },
                _vm._l(_vm.requireFormFieldConfig, function (item) {
                  return _c(
                    "el-checkbox",
                    {
                      key: item.value,
                      staticStyle: { display: "block" },
                      attrs: { label: item.value },
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }