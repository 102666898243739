var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "table-custom ",
      attrs: {
        id: "food-detail-list",
        title: "Log giao hàng - " + _vm.formData.code,
        visible: _vm.dialogVisible,
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.logs, border: "", fit: "" } },
        [
          _c("el-table-column", {
            staticStyle: { "vertical-align": "initial" },
            attrs: {
              "class-name": "vertical-start",
              prop: "name",
              label: "Thời gian",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatDateTime(
                            row.dateCreated,
                            "DD/MM/YYYY, HH:mm"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            staticStyle: { "vertical-align": "initial" },
            attrs: {
              "class-name": "vertical-start",
              prop: "name",
              label: "Log",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.note) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }