"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.meetQrApi = void 0;
var _request = require("@/utils/request");
var meetQrApi = exports.meetQrApi = {
  create: function create(data) {
    return (0, _request.request)({
      url: '/meetQr',
      data: data,
      method: 'post'
    });
  }
};