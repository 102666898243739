"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requireFormFieldConfig = exports.eduConfigTrans = exports.EduWithdrawStatusTrans = exports.EduWithdrawStatus = exports.EduTransactionTypeTrans = exports.EduTransactionType = exports.EduTagType = exports.EduOrderStatus = exports.EduOrderPaymentType = exports.EduLectureType = exports.EduLectureStatus = exports.EduHostOwnerType = exports.EduHostAccountStatus = exports.EduCustomFieldLayout = exports.EduCourseStatus = exports.EduCourseDifficultLevelTrans = exports.EduCourseDifficultLevel = exports.EduConfigParams = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var EduCourseDifficultLevel;
(function (EduCourseDifficultLevel) {
  EduCourseDifficultLevel["Low"] = "LOW";
  EduCourseDifficultLevel["Medium"] = "MEDIUM";
  EduCourseDifficultLevel["High"] = "HIGH";
})(EduCourseDifficultLevel || (exports.EduCourseDifficultLevel = EduCourseDifficultLevel = {}));
var EduCourseDifficultLevelTrans = exports.EduCourseDifficultLevelTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, EduCourseDifficultLevel.High, "Khó"), EduCourseDifficultLevel.Medium, "Vừa"), EduCourseDifficultLevel.Low, "Dễ");
var EduOrderPaymentType;
(function (EduOrderPaymentType) {
  EduOrderPaymentType["VnPay"] = "VNPAY";
  EduOrderPaymentType["Momo"] = "MOMO";
  EduOrderPaymentType["Free"] = "FREE";
})(EduOrderPaymentType || (exports.EduOrderPaymentType = EduOrderPaymentType = {}));
var EduWithdrawStatus;
(function (EduWithdrawStatus) {
  EduWithdrawStatus["Pending"] = "PENDING";
  EduWithdrawStatus["Complete"] = "COMPLETE";
  EduWithdrawStatus["Cancel"] = "CANCEL";
})(EduWithdrawStatus || (exports.EduWithdrawStatus = EduWithdrawStatus = {}));
var EduTagType;
(function (EduTagType) {
  EduTagType["Skill"] = "SKILL";
  EduTagType["Host"] = "HOST";
})(EduTagType || (exports.EduTagType = EduTagType = {}));
var EduWithdrawStatusTrans = exports.EduWithdrawStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, EduWithdrawStatus.Cancel, 'Từ chối'), EduWithdrawStatus.Pending, 'Chờ duyệt'), EduWithdrawStatus.Complete, 'Đã duyệt');
var EduTransactionType;
(function (EduTransactionType) {
  EduTransactionType["Order"] = "ORDER";
  EduTransactionType["OrderCommission"] = "ORDER_COMMISSION";
  EduTransactionType["Withdraw"] = "WITHDRAW";
  EduTransactionType["CancelWithdraw"] = "CANCEL_WITHDRAW";
})(EduTransactionType || (exports.EduTransactionType = EduTransactionType = {}));
var EduTransactionTypeTrans = exports.EduTransactionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, EduTransactionType.Order, 'Đơn thanh toán khóa học'), EduTransactionType.OrderCommission, 'Chiết khấu đơn'), EduTransactionType.Withdraw, 'Rút tiền'), EduTransactionType.CancelWithdraw, 'Hủy rút tiền');
var EduCourseStatus;
(function (EduCourseStatus) {
  EduCourseStatus["Pending"] = "PENDING";
  EduCourseStatus["Approve"] = "APPROVE";
  EduCourseStatus["Reject"] = "REJECT";
})(EduCourseStatus || (exports.EduCourseStatus = EduCourseStatus = {}));
var EduHostAccountStatus;
(function (EduHostAccountStatus) {
  EduHostAccountStatus["Pending"] = "PENDING";
  EduHostAccountStatus["Approve"] = "APPROVE";
  EduHostAccountStatus["Reject"] = "REJECT";
})(EduHostAccountStatus || (exports.EduHostAccountStatus = EduHostAccountStatus = {}));
var EduLectureStatus;
(function (EduLectureStatus) {
  EduLectureStatus["Temp"] = "TEMP";
  EduLectureStatus["Pending"] = "PENDING";
  EduLectureStatus["Reject"] = "REJECT";
  EduLectureStatus["Approve"] = "APPROVE";
})(EduLectureStatus || (exports.EduLectureStatus = EduLectureStatus = {}));
var EduOrderStatus;
(function (EduOrderStatus) {
  EduOrderStatus["Pending"] = "PENDING";
  EduOrderStatus["Complete"] = "COMPLETE";
  EduOrderStatus["Refund"] = "REFUND"; //hoàn trả tiền
})(EduOrderStatus || (exports.EduOrderStatus = EduOrderStatus = {}));
var EduCustomFieldLayout;
(function (EduCustomFieldLayout) {
  EduCustomFieldLayout["Course"] = "COURSE";
  EduCustomFieldLayout["Host"] = "HOST";
})(EduCustomFieldLayout || (exports.EduCustomFieldLayout = EduCustomFieldLayout = {}));
var EduLectureType;
(function (EduLectureType) {
  EduLectureType["Video"] = "VIDEO";
  EduLectureType["Pdf"] = "PDF";
})(EduLectureType || (exports.EduLectureType = EduLectureType = {}));
var EduHostOwnerType;
(function (EduHostOwnerType) {
  EduHostOwnerType["Teacher"] = "TEACHER";
  EduHostOwnerType["Personal"] = "PERSONAL";
  EduHostOwnerType["Company"] = "COMPANY"; //công ty
})(EduHostOwnerType || (exports.EduHostOwnerType = EduHostOwnerType = {}));
var EduConfigParams;
(function (EduConfigParams) {
  EduConfigParams["SignupFieldRequire"] = "SIGNUP_FIELD_REQUIRE";
  EduConfigParams["CommissionPercent"] = "COMMISSION_PERCENT"; //cấu hình chiết khấu
})(EduConfigParams || (exports.EduConfigParams = EduConfigParams = {}));
var eduConfigTrans = exports.eduConfigTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, EduConfigParams.SignupFieldRequire, "[Form đăng ký giảng viên] - Các trường bắt buộc"), EduConfigParams.CommissionPercent, "Cấu hình chiết khấu khóa học");
var requireFormFieldConfig = exports.requireFormFieldConfig = [{
  value: "companyName",
  label: "Tên Tổ chức/Người kinh doanh"
}, {
  value: "taxCode",
  label: "Mã số thuế"
}, {
  value: "companyAddress",
  label: "Địa chỉ công ty"
}, {
  value: "bankNumber",
  label: "Tài khoản ngân hàng số"
}, {
  value: "businessLicenseImage",
  label: "Giấy đăng ký kinh doanh Hộ cá thể"
}, {
  value: "teacherCertImage",
  label: "GCN giáo viên hoặc tương đương"
}, {
  value: "ownerName",
  label: "Họ tên"
}, {
  value: "idNumber",
  label: "CMND / CCCD / Hộ chiếu số"
}, {
  value: "ownerAddress",
  label: "Địa chỉ"
}, {
  value: "idFrontImage",
  label: "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (Mặt trước)"
}, {
  value: "idBackImage",
  label: "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (Mặt sau)"
}, {
  value: "name",
  label: "Tên giảng viên"
}, {
  value: "phone",
  label: "Điện thoại giảng viên"
}, {
  value: "email",
  label: "Email giảng viên"
}, {
  value: "description",
  label: "Giới thiệu giảng viên"
}, {
  value: "avatar",
  label: "Ảnh đại diện"
}, {
  value: "certImage4",
  label: "Giấy uỷ quyền"
}];