var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tìm kiếm tên banner" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getList($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v(" Thêm Banner ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Tên banner" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.mediaType == "IMAGE"
                      ? _c("img", {
                          staticStyle: { "border-radius": "8px" },
                          attrs: {
                            src: _vm.$url.image(row.url),
                            width: "150",
                            alt: "",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center",
                              "flex-direction": "column",
                              "margin-top": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-video-play",
                              staticStyle: { "font-size": "30px" },
                            }),
                            _c("span", [_vm._v("Video")]),
                          ]
                        ),
                    _c("br"),
                    _c("label", { domProps: { innerHTML: _vm._s(row.name) } }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nội dung" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Xem chi tiết ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isVisible == false
                      ? _c(
                          "el-tag",
                          { attrs: { type: "danger", size: "normal" } },
                          [_c("b", [_vm._v("Đã ẩn")])]
                        )
                      : _c(
                          "el-tag",
                          { attrs: { type: "success", size: "normal" } },
                          [_c("b", [_vm._v("Đã hiện")])]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    !row.isVisible
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(row)
                              },
                            },
                          },
                          [_vm._v(" Hiện ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleHide(row)
                              },
                            },
                          },
                          [_vm._v(" Ẩn ")]
                        ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v(" Xóa ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
            visible: _vm.dialogVisible,
            width: "800px",
            top: "15px",
            "close-on-press-escape": true,
            "before-close": _vm.reset,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tên banner",
                                        prop: "name",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "name", $$v)
                                          },
                                          expression: "formData.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Nhà cung cấp",
                                        prop: "providerId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { filterable: "" },
                                          on: { change: _vm.fetchProduct },
                                          model: {
                                            value: _vm.formData.providerId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "providerId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.providerId",
                                          },
                                        },
                                        _vm._l(_vm.providers, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Sản phẩm",
                                        prop: "productId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { filterable: "" },
                                          model: {
                                            value: _vm.formData.productId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "productId",
                                                $$v
                                              )
                                            },
                                            expression: "formData.productId",
                                          },
                                        },
                                        _vm._l(_vm.products, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c("el-switch", {
                                        staticStyle: { "margin-bottom": "1em" },
                                        attrs: {
                                          "active-text": "Tải lên video",
                                          "inactive-text": "Tải lên hình ảnh",
                                        },
                                        model: {
                                          value: _vm.isVideoUpload,
                                          callback: function ($$v) {
                                            _vm.isVideoUpload = $$v
                                          },
                                          expression: "isVideoUpload",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c("el-switch", {
                                        staticStyle: { "margin-bottom": "1em" },
                                        attrs: {
                                          "active-text": "Hiện",
                                          "inactive-text": "Ẩn",
                                        },
                                        model: {
                                          value: _vm.formData.isVisible,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "isVisible",
                                              $$v
                                            )
                                          },
                                          expression: "formData.isVisible",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.isVideoUpload
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              "Hình ảnh (Tỷ lệ: 428/324, khuyến nghị: 428px x 324px, dung lượng tối đa 1MB",
                                            prop: "thumbnail",
                                          },
                                        },
                                        [
                                          _c("SingleImageS3", {
                                            attrs: {
                                              pathImage: _vm.formData.url,
                                              width: 360,
                                              sizeLimit: 1,
                                              height: 144,
                                            },
                                            on: {
                                              "upload:success":
                                                _vm.handleUploadSuccess,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { span: 24, offset: 0 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label:
                                              "Video (Tỷ lệ 20/8, khuyến nghị: 1200x480)",
                                            prop: "videoUrl",
                                          },
                                        },
                                        [
                                          _c("UploadVideoS3", {
                                            attrs: {
                                              videoUrl: _vm.formData.videoUrl,
                                              width: 400,
                                              height: 200,
                                            },
                                            on: {
                                              "upload:success": _vm.setVideo,
                                              delete: _vm.handleDeleteVideo,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }