"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["form"],
  data: function data() {
    return {
      data: [1, 2, 3, 4, 5],
      rules: {
        kmMinPrice: {
          trigger: "submit",
          required: true,
          message: "Trường này là bắt buộc"
        },
        minPrice: {
          trigger: "submit",
          required: true,
          message: "Trường này là bắt buộc"
        },
        priceKm1: {
          trigger: "submit",
          required: true,
          message: "Trường này là bắt buộc"
        },
        km1: {
          trigger: "submit",
          required: true,
          message: "Trường này là bắt buộc"
        }
      }
    };
  }
};