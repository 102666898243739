"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.get = get;
exports.update = update;
exports.vehicleApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var vehicleApi = exports.vehicleApi = {
  get: get,
  create: create,
  update: update
};
function get() {
  return (0, _request.default)({
    url: "/vehicles",
    method: "get"
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/vehicles",
    method: "post",
    data: data
  });
}
function update(id, data) {
  return (0, _request.default)({
    url: "/vehicles/".concat(id, "/update"),
    method: "post",
    data: data
  });
}