var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            directives: [
              {
                name: "print",
                rawName: "v-print",
                value: _vm.printObj,
                expression: "printObj",
              },
            ],
            ref: "print-btn",
            staticStyle: { display: "none" },
            attrs: {
              type: "primary",
              size: "default",
              icon: "el-icon-printer",
            },
          },
          [_vm._v("In hoá đơn")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "order-receipt pagebreak",
            attrs: { id: "printableArea" },
          },
          _vm._l(_vm.formData, function (item, index) {
            return _c("div", { key: item.id }, [
              _c("div", { staticClass: "page-before" }),
              _c(
                "div",
                {
                  staticClass: "page",
                  staticStyle: { "padding-top": "0.5in" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "border",
                      staticStyle: { "border-bottom": "1px solid #000" },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "border",
                              staticStyle: {
                                display: "flex",
                                padding: "0 10px",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "40",
                                  height: "40",
                                  src: require("@/assets/images/logo.png"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                [
                                  _c("VueBarcode", {
                                    attrs: {
                                      displayValue: false,
                                      height: "40px",
                                      width: "1px",
                                      value: item.shipCode,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            margin: "0",
                                            "margin-bottom": "3px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Mã vận đơn: " +
                                              _vm._s(item.shipCode)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            margin: "0",
                                            "margin-bottom": "3px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Mã đơn hàng: " + _vm._s(item.code)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "border-right",
                              attrs: { span: 12, offset: 0 },
                            },
                            [
                              _c("br"),
                              _c("b", [_vm._v("Từ:")]),
                              _c("p", [
                                _vm._v("Tên: " + _vm._s(item.senderName)),
                              ]),
                              _c(
                                "p",
                                { staticStyle: { "line-height": "150%" } },
                                [
                                  _vm._v(
                                    "Địa chỉ: " +
                                      _vm._s(_vm.getFullAddress(item, "SENDER"))
                                  ),
                                ]
                              ),
                              _c("p", [
                                _vm._v("SĐT: " + _vm._s(item.senderPhone)),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "border-left",
                              attrs: { span: 12, offset: 0 },
                            },
                            [
                              _c("br"),
                              _c("b", [_vm._v("Đến:")]),
                              _c("p", [
                                _vm._v("Tên: " + _vm._s(item.receiverName)),
                              ]),
                              _c(
                                "p",
                                { staticStyle: { "line-height": "150%" } },
                                [
                                  _vm._v(
                                    "Địa chỉ: " +
                                      _vm._s(
                                        _vm.getFullAddress(item, "RECEIVER")
                                      )
                                  ),
                                ]
                              ),
                              _c("p", [
                                _vm._v("SĐT: " + _vm._s(item.receiverPhone)),
                              ]),
                            ]
                          ),
                          _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-center border-bottom border-top",
                                staticStyle: {
                                  "font-size": "15px",
                                  padding: "10px",
                                },
                              },
                              [
                                _c(
                                  "b",
                                  { staticStyle: { "font-size": "20px" } },
                                  [_vm._v("HN55.N1A")]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                padding: "10px",
                                "padding-left": "15px",
                              },
                              attrs: { span: 18, offset: 0 },
                            },
                            [
                              item.idealOrderDetails
                                ? _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: false,
                                        data: item.idealOrderDetails.slice(
                                          0,
                                          5
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        staticStyle: { "font-size": "10px" },
                                        attrs: {
                                          prop: "idealProduct.name",
                                          label:
                                            "Tên sản phẩm (Tổng SL: " +
                                            item.idealOrderDetails.length +
                                            " - Tổng KL: " +
                                            item.totalWeight +
                                            " kg )",
                                          width: "300",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  _vm._v(
                                                    _vm._s(
                                                      row.idealProduct.name
                                                    ) +
                                                      ", SL:" +
                                                      _vm._s(row.quantity)
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "note",
                                  staticStyle: {
                                    "font-size": "8px",
                                    position: "absolute",
                                    top: "78%",
                                    width: "70%",
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    { staticStyle: { "line-height": "100%" } },
                                    [
                                      _vm._v(
                                        " Kiểm tra tên sản phẩm và đối chiếu Mã vận đơn/Mã đơn hàng trên ứng dụng Fclass trước khi nhận hàng (Lưu ý: Một số sản phẩm có thể bị ẩn do danh sách quá dài)"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6, offset: 0 } },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "border",
                                      staticStyle: { height: "70px" },
                                      attrs: { span: 24, offset: 0 },
                                    },
                                    [
                                      _c("qrcode-vue", {
                                        attrs: {
                                          value: item.shipCode,
                                          size: "60",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("el-col", {
                                    staticClass: "border",
                                    staticStyle: { height: "70px" },
                                    attrs: { span: 24, offset: 0 },
                                  }),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "border",
                                      staticStyle: { height: "70px" },
                                      attrs: { span: 24, offset: 0 },
                                    },
                                    [
                                      _c("br"),
                                      _vm._v(" Ngày đặt hàng: "),
                                      _c("br"),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: {
                                            "margin-top": "5px",
                                            "font-size": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticStyle: {
                                                "line-height": "150%",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$formatDateTime(
                                                    item.dateCreated,
                                                    "DD/MM/YYYY"
                                                  )
                                                ) + " "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$formatDateTime(
                                                      item.dateCreated,
                                                      "HH:mm"
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "border-top": "1px dashed #000" },
                              attrs: { span: 11, offset: 0 },
                            },
                            [
                              _c("br"),
                              _vm._v(" Tiền thu người nhận: "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "15px",
                                    margin: "10px",
                                    "margin-left": "15px",
                                  },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        item.paymentStatus ==
                                          _vm.PaymentStatus.Complete
                                          ? 0
                                          : _vm.$formatNumberVN(item.moneyFinal)
                                      ) + " VND"
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c("el-col", {
                            staticStyle: { "border-top": "1px dashed #000" },
                            attrs: { span: 13, offset: 0 },
                          }),
                          _c("el-col", { attrs: { span: 24, offset: 0 } }, [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "10px",
                                  "line-height": "120%",
                                },
                              },
                              [
                                _c("b", [_vm._v("Chỉ dẫn giao hàng:")]),
                                _vm._v(
                                  " Không đồng kiểm. Chuyển hoàn sau 3 lần phát: lưu kho tối đa 05 ngày"
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              index != _vm.formData.length - 1
                ? _c("div", { staticClass: "pagebreak" })
                : _vm._e(),
            ])
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }