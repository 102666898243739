"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.orderFoodApi = void 0;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// @ts-check

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orderFood",
    params: listQuery
  });
}
function update(orderId, data) {
  return (0, _request.default)({
    url: "/orderFood/".concat(orderId, "/status/update"),
    method: "post",
    data: data
  });
}
var orderFoodApi = exports.orderFoodApi = {
  findAll: findAll,
  update: update,
  findOne: function findOne(orderId) {
    return (0, _request.default)({
      url: "/orderFood/".concat(orderId)
    });
  },
  cancel: function cancel(orderId) {
    return (0, _request.default)({
      url: "/orderFood/".concat(orderId, "/cancel"),
      method: 'post'
    });
  }
};