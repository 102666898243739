"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.careerWageTypeTrans = exports.GenderTrans = exports.Gender = exports.EWorkTypeTrans = exports.EWorkType = exports.CareerWageType = exports.CareerType = exports.CareerStatus = exports.CareerBannerLayoutTrans = exports.CareerBannerLayout = exports.CareerBannerContentTypeTrans = exports.CareerBannerContentType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var CareerBannerContentType;
(function (CareerBannerContentType) {
  CareerBannerContentType["None"] = "NONE";
  CareerBannerContentType["Employer"] = "EMPLOYER";
  CareerBannerContentType["Career"] = "CAREER";
})(CareerBannerContentType || (exports.CareerBannerContentType = CareerBannerContentType = {}));
var CareerType;
(function (CareerType) {
  CareerType["Company"] = "COMPANY";
  CareerType["Customer"] = "CUSTOMER";
})(CareerType || (exports.CareerType = CareerType = {}));
var Gender;
(function (Gender) {
  Gender["Male"] = "MALE";
  Gender["FeMale"] = "FE_MALE";
})(Gender || (exports.Gender = Gender = {}));
var GenderTrans = exports.GenderTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, Gender.Male, 'Nam'), Gender.FeMale, 'Nữ');
var EWorkType;
(function (EWorkType) {
  EWorkType["Month"] = "MONTH";
  EWorkType["Day"] = "DAY";
  EWorkType["Shift"] = "SHIFT";
  EWorkType["Hour"] = "HOUR";
})(EWorkType || (exports.EWorkType = EWorkType = {}));
var EWorkTypeTrans = exports.EWorkTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, EWorkType.Month, "Tháng"), EWorkType.Shift, "Buổi"), EWorkType.Day, "Ngày"), EWorkType.Hour, "Giờ");
var CareerBannerContentTypeTrans = exports.CareerBannerContentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CareerBannerContentType.None, {
  label: "Mặc định",
  value: CareerBannerContentType.None
}), CareerBannerContentType.Employer, {
  label: "Liên kết đến nhà tuyển dụng",
  value: CareerBannerContentType.Employer
}), CareerBannerContentType.Career, {
  label: "Liên kết đến việc làm",
  value: CareerBannerContentType.Career
});
var CareerBannerLayout;
(function (CareerBannerLayout) {
  CareerBannerLayout["Top"] = "TOP";
  CareerBannerLayout["Mid"] = "MID";
  CareerBannerLayout["Bottom"] = "BOTTOM";
})(CareerBannerLayout || (exports.CareerBannerLayout = CareerBannerLayout = {}));
var CareerBannerLayoutTrans = exports.CareerBannerLayoutTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CareerBannerLayout.Top, 'Đầu trang'), CareerBannerLayout.Mid, 'Giữa trang'), CareerBannerLayout.Bottom, 'Cuối trang');
var CareerWageType;
(function (CareerWageType) {
  CareerWageType["Range"] = "RANGE";
  CareerWageType["Fixed"] = "FIXED";
  CareerWageType["Custom"] = "CUSTOM";
})(CareerWageType || (exports.CareerWageType = CareerWageType = {}));
var careerWageTypeTrans = exports.careerWageTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, CareerWageType.Range, {
  title: "Lương theo khoảng",
  value: CareerWageType.Range
}), CareerWageType.Fixed, {
  title: "Số cụ thể",
  value: CareerWageType.Fixed
}), CareerWageType.Custom, {
  title: "Lương thoả thuận",
  value: CareerWageType.Custom
});
var CareerStatus;
(function (CareerStatus) {
  CareerStatus["Pending"] = "PENDING";
  CareerStatus["Approve"] = "APPROVE";
  CareerStatus["Reject"] = "REJECT";
})(CareerStatus || (exports.CareerStatus = CareerStatus = {}));