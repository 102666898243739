"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerTransferBalancePromotionApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var customerTransferBalancePromotionApi = exports.customerTransferBalancePromotionApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/customerTransferBalancePromotion',
      params: params
    });
  },
  complete: function complete(id, data) {
    return (0, _request.default)({
      url: "/customerTransferBalancePromotion/".concat(id, "/complete"),
      method: 'post',
      data: data
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.default)({
      url: "/customerTransferBalancePromotion/".concat(id, "/cancel"),
      method: 'post',
      data: data
    });
  }
};