"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallReturnOtherMethodTrans = exports.mallReturnOrderStatusTrans = exports.mallComplainTypeTrans = exports.MallReturnOrderStatus = exports.MallReturnOrderMethod = exports.MallComplainType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MallReturnOrderStatus;
(function (MallReturnOrderStatus) {
  MallReturnOrderStatus["Pending"] = "PENDING";
  MallReturnOrderStatus["Confirm"] = "CONFIRM";
  MallReturnOrderStatus["MallConfirm"] = "MALL_CONFIRM";
  MallReturnOrderStatus["Reject"] = "REJECT";
  MallReturnOrderStatus["Complete"] = "COMPLETE";
  MallReturnOrderStatus["MallComplain"] = "MALL_COMPLAIN";
  MallReturnOrderStatus["MallComplainReturn"] = "MALL_COMPLAIN_RETURN";
})(MallReturnOrderStatus || (exports.MallReturnOrderStatus = MallReturnOrderStatus = {}));
var MallReturnOrderMethod;
(function (MallReturnOrderMethod) {
  MallReturnOrderMethod["Refund"] = "REFUND";
  MallReturnOrderMethod["FromCustomer"] = "FROM_CUSTOMER";
  MallReturnOrderMethod["CarrierRefund"] = "CARRIER_REFUND";
  MallReturnOrderMethod["CarrierReturn"] = "CARRIER_RETURN";
})(MallReturnOrderMethod || (exports.MallReturnOrderMethod = MallReturnOrderMethod = {}));
var MallComplainType;
(function (MallComplainType) {
  MallComplainType["ReturnOrder"] = "RETURN_ORDER";
  MallComplainType["ReturnProduct"] = "RETURN_PRODUCT";
})(MallComplainType || (exports.MallComplainType = MallComplainType = {}));
var mallComplainTypeTrans = exports.mallComplainTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallComplainType.ReturnOrder, {
  label: "Yêu cầu hoàn hàng",
  color: "danger",
  value: MallComplainType.ReturnOrder
}), MallComplainType.ReturnProduct, {
  label: "Sản phẩm đổi trả",
  color: "warning",
  value: MallComplainType.ReturnProduct
});
var mallReturnOtherMethodTrans = exports.mallReturnOtherMethodTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallReturnOrderMethod.Refund, {
  label: "Hoàn tiền",
  color: "danger",
  value: MallReturnOrderMethod.Refund
}), MallReturnOrderMethod.FromCustomer, {
  label: "Người mua tự trả hàng",
  color: "warning",
  value: MallReturnOrderMethod.FromCustomer
}), MallReturnOrderMethod.CarrierRefund, {
  label: "Đơn vị vận chuyển nhận trả hàng & hoàn tiền",
  color: "success",
  value: MallReturnOrderMethod.CarrierRefund
}), MallReturnOrderMethod.CarrierReturn, {
  label: "Đơn vị vận chuyển nhận trả hàng & đổi hàng khác",
  color: "",
  value: MallReturnOrderMethod.CarrierReturn
});
var mallReturnOrderStatusTrans = exports.mallReturnOrderStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MallReturnOrderStatus.Pending, {
  label: "Yêu cầu mới",
  color: "",
  value: MallReturnOrderStatus.Pending,
  total: 0
}), MallReturnOrderStatus.Confirm, {
  label: "Fclass đã xác nhận",
  color: "warning",
  value: MallReturnOrderStatus.Confirm,
  total: 0
}), MallReturnOrderStatus.MallConfirm, {
  label: "Mall đã xác nhận",
  color: "success",
  value: MallReturnOrderStatus.MallConfirm,
  total: 0
}), MallReturnOrderStatus.MallComplain, {
  label: "Mall khiếu nại",
  color: "danger",
  value: MallReturnOrderStatus.MallComplain,
  total: 0
}), MallReturnOrderStatus.Reject, {
  label: "Đã từ chối",
  color: "danger",
  value: MallReturnOrderStatus.Reject,
  total: 0
}), MallReturnOrderStatus.Complete, {
  label: "Hoàn thành",
  color: "success",
  value: MallReturnOrderStatus.Complete,
  total: 0
});