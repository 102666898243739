var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { md: 8, xl: 6 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-add-service-group",
                  attrs: { type: "primary", icon: "el-icon-circle-plus" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("Thêm tip")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "item-box", attrs: { gutter: 20 } },
        _vm._l(_vm.tipData, function (item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 3, md: 4, xl: 3 } },
            [
              _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.handleEdit(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "info-box" }, [
                    _c("div", { staticClass: "info-item" }, [
                      _c("label", { staticClass: "type-info" }, [
                        _vm._v("Tip:"),
                      ]),
                      _c("span", { staticClass: "value-info" }, [
                        _vm._v(_vm._s(_vm.formatVND(item.amount))),
                      ]),
                    ]),
                  ]),
                  _c("i", { staticClass: "el-icon-info button-info" }),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "400px",
            title: _vm.dialogStatus == "edit" ? "Cập nhật tip" : "Thêm tip",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { rules: _vm.rulesServiceGroup, "label-position": "left" },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "label",
                        {
                          staticStyle: { "line-height": "36px" },
                          attrs: { for: "" },
                        },
                        [_vm._v("Số tiền")]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.tip.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.tip, "amount", $$v)
                          },
                          expression: "tip.amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm.dialogStatus == "edit"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("Xóa")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm")
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }