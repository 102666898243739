var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "date-box", staticStyle: { display: "flex" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": "To",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _c(
            "el-select",
            {
              staticStyle: { "margin-left": "8px", flex: "1" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.vehicleId,
                callback: function ($$v) {
                  _vm.vehicleId = $$v
                },
                expression: "vehicleId",
              },
            },
            _vm._l(_vm.vehicles, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("bar-chart", {
        ref: "chart",
        attrs: {
          "props-total": "total",
          "data-chart": _vm.chartData,
          "pointer-name": _vm.vehicleName,
          "attr-get": "vehicle",
          "bar-color": "#f4516c",
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.chartData = $event
          },
          "update:data-chart": function ($event) {
            _vm.chartData = $event
          },
        },
      }),
      _c("p", { staticClass: "chart-title" }, [_vm._v(" Doanh số ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }