var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Sự kiện", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(_vm.generateMessage(row)) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Chi tiết", prop: "note" } }),
          _c("el-table-column", {
            attrs: { label: "Vị trí tài xế" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.lat
                      ? _c(
                          "el-link",
                          {
                            attrs: {
                              target: "_blank",
                              type: "primary",
                              href:
                                "https://www.google.com/maps/search/" +
                                row.lat +
                                "," +
                                row.long +
                                "/",
                            },
                          },
                          [
                            _vm._v(
                              " https://www.google.com/maps/search/" +
                                _vm._s(row.lat) +
                                "," +
                                _vm._s(row.long) +
                                "/ "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatDateTime(
                            row.dateCreated,
                            "HH:mm:ss, DD/MM/YYYY"
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }