"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletTransactionApi = void 0;
var _request = require("@/utils/request");
var walletTransactionApi = exports.walletTransactionApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/walletTransaction',
      params: params
    });
  }
};