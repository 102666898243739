var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật quy định về nhận quà khuyến học/thiện nguyện"
            : "Thêm mới quy định về nhận quà khuyến học/thiện nguyện",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(
                          _vm.ScholarshipConditionType,
                          function (name, key) {
                            return _c("el-option", {
                              key: key,
                              attrs: {
                                label: _vm.ScholarshipConditionTypeTrans[name],
                                value: name,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thứ tự", prop: "no" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.no,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "no", $$v)
                          },
                          expression: "form.no",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24 } }, [
                _vm.form.type == _vm.ScholarshipConditionType.Select
                  ? _c("div", [
                      _c(
                        "div",
                        { staticStyle: { "margin-bottom": "18px" } },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Giá trị chọn"),
                          ]),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.handleAddValue },
                            },
                            [_vm._v("Thêm")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "overflow-y": "auto",
                            "max-height": "50vh",
                          },
                        },
                        _vm._l(_vm.details, function (item, index) {
                          return _c(
                            "el-form-item",
                            { key: index, staticStyle: { width: "99%" } },
                            [
                              _c(
                                "el-row",
                                {
                                  attrs: {
                                    gutter: 10,
                                    align: "middle",
                                    type: "flex",
                                  },
                                },
                                [
                                  _c("el-col", { attrs: { span: 3 } }, [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v("Giá trị " + _vm._s(index + 1)),
                                    ]),
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: item.name,
                                          callback: function ($$v) {
                                            _vm.$set(item, "name", $$v)
                                          },
                                          expression: "item.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("el-col", { attrs: { span: 1 } }, [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveValue(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          staticStyle: {
                                            color: "red",
                                            cursor: "pointer",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }