"use strict";

var _interopRequireWildcard = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _address = require("@/api/address");
var _date = require("@/utils/date");
var _CustomerRank = _interopRequireDefault(require("./components/CustomerRank"));
var _DriverRank = _interopRequireDefault(require("./components/DriverRank"));
var _DriverIncome = _interopRequireDefault(require("./components/chart/DriverIncome"));
var _OrderLast = _interopRequireDefault(require("./components/chart/OrderLast"));
var _Sale = _interopRequireDefault(require("./components/chart/Sale"));
var _Customer = _interopRequireDefault(require("./components/chart/Customer"));
var _Driver = _interopRequireDefault(require("./components/chart/Driver"));
var _SaleVehicle = _interopRequireDefault(require("./components/chart/SaleVehicle"));
var _Cash = _interopRequireDefault(require("./components/chart/Cash.vue"));
var _VnpayLast = _interopRequireDefault(require("./components/chart/VnpayLast.vue"));
var _Company = _interopRequireDefault(require("./components/chart/Company.vue"));
var _UseAppDriver = _interopRequireDefault(require("./components/chart/UseAppDriver.vue"));
var _VAT = _interopRequireDefault(require("./components/chart/VAT.vue"));
var _TaxDriver = _interopRequireDefault(require("./components/chart/TaxDriver.vue"));
var _index = _interopRequireDefault(require("@/components/AddressFilter/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "DashboardAdmin",
  components: {
    SaleVehicle: _SaleVehicle.default,
    PanelGroup: _PanelGroup.default,
    CustomerRank: _CustomerRank.default,
    DriverRank: _DriverRank.default,
    DriverIncome: _DriverIncome.default,
    OrderLast: _OrderLast.default,
    Sale: _Sale.default,
    Customer: _Customer.default,
    Driver: _Driver.default,
    Cash: _Cash.default,
    VnpayLast: _VnpayLast.default,
    Company: _Company.default,
    UseAppDriver: _UseAppDriver.default,
    VAT: _VAT.default,
    TaxDriver: _TaxDriver.default,
    AddressFilter: _index.default
  },
  data: function data() {
    return {
      listQuery: {
        cityId: null,
        districtId: null,
        wardId: null
      },
      cities: [],
      wards: [],
      districts: []
    };
  },
  created: function created() {},
  methods: {}
};