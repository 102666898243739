var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        "label-width": "80px",
        "label-position": "top",
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Giấy kiểm định ô tô" } },
                [
                  _c("SingleImage", {
                    attrs: { "path-image": _vm.form.verificationPaper },
                    on: { "upload:success": _vm.verificationPaperOk },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Ngày hết hạn kiểm định" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "dd/MM/yyyy",
                      "value-format": "dd/MM/yyyy",
                      type: "date",
                    },
                    model: {
                      value: _vm.form.verificationPaperExpire,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "verificationPaperExpire", $$v)
                      },
                      expression: "form.verificationPaperExpire",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "BH VC tự nguyện" } },
                [
                  _c("SingleImage", {
                    attrs: { "path-image": _vm.form.bhvc },
                    on: { "upload:success": _vm.updateVcOk },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Ngày hết hạn  BH VC tự nguyện" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "dd/MM/yyyy",
                      "value-format": "dd/MM/yyyy",
                      type: "date",
                    },
                    model: {
                      value: _vm.form.bhvcExpire,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bhvcExpire", $$v)
                      },
                      expression: "form.bhvcExpire",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "BH TNDS bắt buộc" } },
                [
                  _c("SingleImage", {
                    attrs: { "path-image": _vm.form.bhtnds },
                    on: { "upload:success": _vm.bhtndsOk },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Ngày hết hạn BH TNDS bắt buộc" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "dd/MM/yyyy",
                      "value-format": "dd/MM/yyyy",
                      type: "date",
                    },
                    model: {
                      value: _vm.form.bhtndsExpire,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bhtndsExpire", $$v)
                      },
                      expression: "form.bhtndsExpire",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Phù hiệu xe do sở GTVT cấp" } },
                [
                  _c("SingleImage", {
                    attrs: { "path-image": _vm.form.carBadge },
                    on: { "upload:success": _vm.carBadgeOk },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Ngày hết hạn phù hiệu xe do sở GTVT cấp" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "dd/MM/yyyy",
                      "value-format": "dd/MM/yyyy",
                      type: "date",
                    },
                    model: {
                      value: _vm.form.carBadgeExpire,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "carBadgeExpire", $$v)
                      },
                      expression: "form.carBadgeExpire",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Phí sử dụng đường bộ" } },
                [
                  _c("SingleImage", {
                    attrs: { "path-image": _vm.form.roadUseFee },
                    on: { "upload:success": _vm.roadUseFeeOk },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Ngày hết hạn phí sử dụng đường bộ" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "dd/MM/yyyy",
                      "value-format": "dd/MM/yyyy",
                      type: "date",
                    },
                    model: {
                      value: _vm.form.roadUseFeeExpire,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roadUseFeeExpire", $$v)
                      },
                      expression: "form.roadUseFeeExpire",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Giấy đăng ký xe" } },
                [
                  _c("SingleImage", {
                    attrs: { "path-image": _vm.form.registerVehiclePaper },
                    on: { "upload:success": _vm.registerVehiclePaperOk },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }