var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container table",
      staticStyle: { "max-width": "1000px" },
    },
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": "->",
          "start-placeholder": "Từ ngày",
          "end-placeholder": "Đến ngày",
          format: "dd-MM-yyyy",
          "value-format": "yyyy-MM-dd",
        },
        on: { change: _vm.queryDate },
        model: {
          value: _vm.datePicker,
          callback: function ($$v) {
            _vm.datePicker = $$v
          },
          expression: "datePicker",
        },
      }),
      _vm.reportData.current
        ? _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 10, offset: 0 } }),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 7, offset: 0 },
                },
                [_c("b", [_vm._v("Số liệu hiện tại")])]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 7, offset: 0 },
                },
                [_c("b", [_vm._v("Số liệu trước đó")])]
              ),
              _c(
                "el-col",
                {
                  staticClass: "table-striped-rows",
                  attrs: { span: 10, offset: 0 },
                },
                [
                  _c("p", { staticClass: "lv1" }, [
                    _c("b", [_vm._v("1. Số lượng người dùng (người):")]),
                  ]),
                  _c("p", { staticClass: "lv1" }, [
                    _c("b", [_vm._v("2. Số người bán:")]),
                  ]),
                  _c("p", { staticClass: "lv2" }, [
                    _vm._v(" "),
                    _c("b", [_vm._v(" 2.1 Tài xế")]),
                  ]),
                  _c("p", [_vm._v("- Fclass Bike")]),
                  _c("p", [_vm._v("- Fclass Car")]),
                  _c("p", [_vm._v("- Shipper")]),
                  _c("p", [_vm._v("- Fclass Car điện")]),
                  _c("p", [_vm._v("- Fclass Truck")]),
                  _c("p", [_vm._v("- Fclass Container")]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("2.2 Thương nhân F&B:")]),
                  ]),
                  _c("p", [_vm._v("- Fclass Merchant thức ăn qua nấu nướng:")]),
                  _c("p", [_vm._v("- Fclass Merchant thực phẩm:")]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("2.3 Thương nhân:")]),
                  ]),
                  _c("p", { staticClass: "lv1" }, [
                    _c("b", [_vm._v("3. Số lượng sản phẩm:")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "table-striped-rows",
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 7, offset: 0 },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.current.soLuongNguoiDung || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.current.fclassBike || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.current.fclassCar || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.current.shipper || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.current.fclassCarDien || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.current.fclassTruck || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.current.fclassContainer || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatNumber(
                            _vm.reportData.current.fclassMerchantNauNuong || 0
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatNumber(
                            _vm.reportData.current.fclassMerchantThucPham || 0
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.current.thuongNhan || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.current.soLuongSanPham || 0
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "table-striped-rows",
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 7, offset: 0 },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.soLuongNguoiDung || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.fclassBike || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.previous.fclassCar || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.previous.shipper || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.fclassCarDien || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.fclassTruck || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.fclassContainer || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatNumber(
                            _vm.reportData.previous.fclassMerchantNauNuong || 0
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatNumber(
                            _vm.reportData.previous.fclassMerchantThucPham || 0
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.thuongNhan || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.previous.soLuongSanPham || 0
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        { staticStyle: { "text-align": "right" } },
        [
          _c("el-col", { attrs: { span: 10, offset: 0 } }),
          _c("el-col", { attrs: { span: 7, offset: 0 } }, [
            _c("b", [_vm._v("Số đơn thành công")]),
          ]),
          _c("el-col", { attrs: { span: 7, offset: 0 } }, [
            _c("b", [_vm._v("Số đơn không thành công")]),
          ]),
        ],
        1
      ),
      _vm.reportData.current
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticClass: "table-striped-rows",
                  attrs: { span: 10, offset: 0 },
                },
                [
                  _c("p", { staticClass: "lv1" }, [
                    _c("b", [_vm._v("4. Số lượng giao dịch (đơn):")]),
                  ]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("4.1. Tổng đơn vận tải hành khách:")]),
                  ]),
                  _c("p", [_vm._v("- Fclass Bike:")]),
                  _c("p", [_vm._v("- Fclass Car:")]),
                  _c("p", [_vm._v("- Fclass Car điện:")]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("4.2 Tổng đơn vận tải hàng hóa:")]),
                  ]),
                  _c("p", [_vm._v("- Fclass Shipper:")]),
                  _c("p", [_vm._v("- Fclass Truck:")]),
                  _c("p", [_vm._v("- Fclass Container:")]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("4.3 Tổng đơn Fclass F&B:")]),
                  ]),
                  _c("p", [_vm._v("- Fclass F&B thức ăn")]),
                  _c("p", [_vm._v("- Fclass F&B thực phẩm")]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("4.4 Tổng đơn Fclass Mall:")]),
                  ]),
                  _c("p", { staticClass: "lv2" }, [
                    _c("b", [_vm._v("4.5 Tổng đơn Fclass Draws:")]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "table-striped-rows",
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 7, offset: 0 },
                },
                [
                  _c("p", [_vm._v("--")]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.order.fclassBike || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.order.fclassCar || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.fclassCarDien || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.fclassShipper || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(_vm.reportData.order.fclassTruck || 0)
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.fclassContainer || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.fclassFBThucAn || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.fclassFBThucPham || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.tongDonFclassMall || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.order.tongDonFclassDraws || 0
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticClass: "table-striped-rows",
                  staticStyle: { "text-align": "right" },
                  attrs: { span: 7, offset: 0 },
                },
                [
                  _c("p", [_vm._v("--")]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassBike || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassCar || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassCarDien || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassShipper || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassTruck || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassContainer || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [_vm._v("--")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassFBThucAn || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.fclassFBThucPham || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.tongDonFclassMall || 0
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.formatNumber(
                          _vm.reportData.orderFail.tongDonFclassDraws || 0
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 10, offset: 0 } }, [
            _c("p", { staticClass: "lv1" }, [
              _c("b", [_vm._v("Tổng giá trị giao dịch thành công (đ):")]),
            ]),
          ]),
          _c(
            "el-col",
            {
              staticStyle: { "text-align": "right" },
              attrs: { span: 7, offset: 0 },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(_vm.formatNumber(_vm.reportData.totalMoney || 0))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }