"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.map.js");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _format = require("@/utils/format");
//
//
//
//
//
//
//

require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    attrGet: String,
    pointerName: String,
    dataChart: Array,
    format: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    barColor: String
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  mounted: function mounted() {
    // this.initChart();
  },
  methods: {
    initChart: function initChart() {
      var _this = this;
      var self = this;
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.resize();
      this.chart.setOption((0, _defineProperty2.default)((0, _defineProperty2.default)({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function formatter(params) {
            if (self.format == "currency") {
              return "Income: ".concat((0, _format.formatCurrency)(parseInt(params[0].value)));
            }
            return "".concat(self.pointerName, ": ").concat(params[0].value);
          }
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "category",
          data: this.dataChart.map(function (item) {
            return item.date;
          }),
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "value",
          axisTick: {
            show: false
          }
        }]
      }, "tooltip", {
        trigger: "item",
        formatter: function formatter(params) {
          console.log("params nè", params);
          // Custom formatter function
          return "T\u1ED5ng ti\u1EC1n: ".concat(_this.$formatNumberVN(params.value));
        }
      }), "series", [{
        name: this.pointerName,
        type: "bar",
        stack: "vistors",
        barWidth: "60%",
        itemStyle: {
          color: this.barColor
        },
        data: this.dataChart.map(function (item) {
          return item[_this.attrGet];
        }),
        animationDuration: animationDuration
      }]));
    }
  }
};