var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm kiếm" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Mã giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.code || "--") + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customer
                      ? _c("div", [
                          _vm._v(
                            _vm._s(row.customer.name) +
                              " - " +
                              _vm._s(row.customer.phone)
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Quỹ nhóm" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.wallet
                      ? _c("div", [
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tên quỹ: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.wallet.name))]),
                          ]),
                          _c("div", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Mã quỹ: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.wallet.code))]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.WalletTransactionTypeTrans[row.type]) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tiền giao dịch" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-weight": "bold" },
                        style:
                          row.change < 0 ? "color:#F56C6C" : "color:#67C23A",
                      },
                      [
                        _vm._v(
                          _vm._s(row.change > 0 ? "+" : "") +
                            _vm._s(_vm.$formatCurrency(row.change)) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số dư" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatCurrency(row.afterChange)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }