"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallComplainApi = void 0;
var _request = require("@/utils/request");
var mallComplainApi = exports.mallComplainApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/mallComplain',
      params: params
    });
  },
  solved: function solved(id) {
    return (0, _request.request)({
      url: "/mallComplain/".concat(id, "/solved"),
      method: 'patch'
    });
  },
  reject: function reject(id) {
    return (0, _request.request)({
      url: "/mallComplain/".concat(id, "/reject"),
      method: 'patch'
    });
  }
};