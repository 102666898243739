var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.modalTitle,
        visible: _vm.visible,
        width: "500px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Giá", prop: "price" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Màu", prop: "color" } },
            [
              _c("el-color-picker", {
                model: {
                  value: _vm.form.color,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "color", $$v)
                  },
                  expression: "form.color",
                },
              }),
            ],
            1
          ),
          _vm.type == _vm.CvSampleType.CV
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Cấu hình thông tin bổ sung",
                    prop: "listOptions",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.listOptions,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "listOptions", $$v)
                        },
                        expression: "form.listOptions",
                      },
                    },
                    _vm._l(Object.values(_vm.CvOptionsType), function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item, attrs: { label: item } },
                        [_vm._v(_vm._s(_vm.CvOptionsTypeTrans[item]))]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.type == _vm.CvSampleType.CV
                    ? "File CV"
                    : "File Cover letter",
                prop: "docFile",
              },
            },
            [
              _c(
                "el-link",
                {
                  attrs: {
                    type: "primary",
                    href:
                      _vm.type == _vm.CvSampleType.CV
                        ? "/cv_sample.docx"
                        : "/coverletter_sample.docx",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-download" }),
                  _vm._v("Tải file mẫu"),
                ]
              ),
              _c("UploadFile", {
                ref: "UploadFile",
                attrs: { maxFileSize: 10 },
                on: {
                  "upload-success": function (path) {
                    return _vm.$set(_vm.form, "docFile", path)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }