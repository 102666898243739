var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
      attrs: { id: "vehicle" },
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "" },
                  on: { change: _vm.handleChangeCity },
                  model: {
                    value: _vm.cityId,
                    callback: function ($$v) {
                      _vm.cityId = $$v
                    },
                    expression: "cityId",
                  },
                },
                _vm._l(_vm.cities, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Quận huyện")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "" },
                  on: { change: _vm.fetchVehicle },
                  model: {
                    value: _vm.districtId,
                    callback: function ($$v) {
                      _vm.districtId = $$v
                    },
                    expression: "districtId",
                  },
                },
                [
                  !_vm.branch || (_vm.branch && !_vm.branch.districts.length)
                    ? _c("el-option", {
                        attrs: { label: "Tất cả", value: null },
                      })
                    : _vm._e(),
                  _vm._l(_vm.districts, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.nameWithType, value: item.id },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-add-service-group filter-item",
              staticStyle: { transform: "translateY(10px)" },
              attrs: { type: "primary", icon: "el-icon-connection" },
              on: { click: _vm.handleCopy },
            },
            [_vm._v(" Sao chép cấu hình sang tỉnh khác ")]
          ),
          _vm.districtId &&
          (!_vm.branch || (_vm.branch && !_vm.branch.districts.length))
            ? _c(
                "el-button",
                {
                  staticClass: "btn-add-service-group filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary", icon: "el-icon-connection" },
                  on: { click: _vm.handleCopyCityToDistrict },
                },
                [_vm._v(" Sao chép cấu hình từ tỉnh xuống huyện ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h3", [_vm._v(" Di chuyển ")]),
          _c("VehicleCard", {
            attrs: {
              vehicles: _vm.dataList.filter(function (e) {
                return e.type != "DELIVERY" && e.type != "DELIVERY_FOOD"
              }),
            },
            on: { onEdit: _vm.handleEdit },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h3", [_vm._v(" Giao hàng ")]),
          _c("VehicleCard", {
            attrs: {
              vehicles: _vm.dataList.filter(function (e) {
                return e.type == "DELIVERY"
              }),
            },
            on: { onEdit: _vm.handleEdit },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h3", [_vm._v(" Thức ăn ")]),
          _c("VehicleCard", {
            attrs: {
              vehicles: _vm.dataList.filter(function (e) {
                return e.type == "DELIVERY_FOOD"
              }),
            },
            on: { onEdit: _vm.handleEdit },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h3", [_vm._v(" Mall ")]),
          _c("VehicleCard", {
            attrs: {
              vehicles: _vm.dataList.filter(function (e) {
                return e.type == "MALL"
              }),
            },
            on: { onEdit: _vm.handleEdit },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "600px",
            top: "30px",
            title:
              _vm.dialogStatus == "edit" ? "Cập nhật phương tiện" : "Thêm xe",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin" } },
                    [
                      _c("VehicleForm", {
                        attrs: {
                          "vehicle-city": _vm.vehicleCity,
                          "base-url-media": _vm.baseUrlMedia,
                          "base-url": _vm.baseUrl,
                          "form-data": _vm.formData,
                          token: _vm.token,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Hoa hồng (chia sẻ)" } },
                    [
                      _c("commission-share-driver", {
                        attrs: { form: _vm.vehicleCity },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Giờ cao điểm" } },
                    [
                      _c("RushHourTab", {
                        attrs: { form: _vm.vehicleCity },
                        on: {
                          "update:form": function ($event) {
                            _vm.vehicleCity = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Bậc giá theo KM" } },
                    [
                      _c("PriceKm", {
                        ref: "price-km",
                        attrs: { form: _vm.vehicleCity },
                        on: {
                          "update:form": function ($event) {
                            _vm.vehicleCity = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thưởng hoàn thành đơn" } },
                    [
                      _c("RewardSale", {
                        attrs: { form: _vm.vehicleCity },
                        on: {
                          "update:form": function ($event) {
                            _vm.vehicleCity = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm.configSystem == "true" && _vm.dialogStatus == "edit"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.updateVehicle },
                    },
                    [_vm._v("Cập nhật vehicle")]
                  )
                : _vm._e(),
              _vm.configSystem == "true" && _vm.dialogStatus != "edit"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.createVehicle },
                    },
                    [_vm._v("Thêm vehicle")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.dialogStatus == "edit" ? "Cập nhật" : "Thêm")
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c("CopyConfigModal", {
        ref: "copyModal",
        attrs: { cities: _vm.cities },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }