var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update" ? "Cập nhật nội dung" : "Thêm mới nội dung",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.formDisable },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mô tả", prop: "body" } },
                    [
                      _vm.visible
                        ? _c("Tinymce", {
                            ref: "editor",
                            attrs: { width: "100%", height: 400 },
                            on: { onInit: _vm.handleContentInit },
                            model: {
                              value: _vm.form.body,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "body", $$v)
                              },
                              expression: "form.body",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleClose()
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }