var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.visible
    ? _c(
        "div",
        [
          !_vm.data.children
            ? [
                _c(
                  "el-menu-item",
                  {
                    attrs: { index: _vm.data.index },
                    on: { click: _vm.onChangeTab },
                  },
                  [
                    _c("template", { slot: "title" }, [
                      _vm._v(_vm._s(_vm.data.title)),
                    ]),
                  ],
                  2
                ),
              ]
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  staticClass: "submenu",
                  attrs: { index: _vm.data.index, "popper-append-to-body": "" },
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(" " + _vm._s(_vm.data.title) + " "),
                  ]),
                  _vm._l(_vm.data.children, function (child) {
                    return _c("SidebarItem", {
                      key: child.index,
                      attrs: { data: child },
                      on: { changeTab: _vm.onChangeTab },
                    })
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }