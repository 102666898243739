"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.employerTransactionApi = void 0;
var _request = require("@/utils/request");
var employerTransactionApi = exports.employerTransactionApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/employerTransaction',
      params: params
    });
  }
};