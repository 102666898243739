var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Code đơn hàng")]),
            _c("el-input", {
              staticStyle: { width: "300px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "Tìm mã giao dịch",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getTableData(_vm.listQuery.customerId)
                },
              },
              model: {
                value: _vm.listQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Ngày giao dịch")]),
            _c("el-date-picker", {
              attrs: {
                type: "date",
                format: "dd/MM/yyyy",
                "value-format": "yyyy-MM-dd",
              },
              on: {
                change: function ($event) {
                  _vm.listQuery.page = 1
                  _vm.getTableData(_vm.listQuery.customerId)
                },
              },
              model: {
                value: _vm.listQuery.createdDate,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "createdDate", $$v)
                },
                expression: "listQuery.createdDate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Loại đơn")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "Loại đơn" },
                model: {
                  value: _vm.listQuery.deliveryBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "deliveryBy", $$v)
                  },
                  expression: "listQuery.deliveryBy",
                },
              },
              [
                _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                _vm._l(_vm.OrderFoodDeliveryByTrans, function (name, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: name, value: key },
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-search",
                },
                on: {
                  click: function ($event) {
                    return _vm.getTableData(_vm.listQuery.customerId)
                  },
                },
              },
              [_vm._v("Tìm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Code", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.code) + " "),
                    _c("br"),
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          height: "auto",
                          "white-space": "normal",
                        },
                        attrs: {
                          type:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].type,
                          effect:
                            _vm.tagOptions[row.status] &&
                            _vm.tagOptions[row.status].effect,
                        },
                      },
                      [_vm._v(_vm._s(_vm.STATUS_ORDER[row.status]))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Ngày", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.formatDateTime(row.dateCreated)))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tài xế" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.driver
                      ? _c("span", [
                          _vm._v(
                            _vm._s(row.driver.name) +
                              " - " +
                              _vm._s(row.driver.phone)
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Quán" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.store
                      ? _c("span", [_vm._v(_vm._s(row.store.name))])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Điểm nhận", prop: "startName" },
          }),
          _c("el-table-column", {
            attrs: { label: "Khoảng cách", prop: "distance", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatNumberVN(row.distance)) + " Km "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Chi tiết thanh toán", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Trạng thái thanh toán:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.PaymentStatus[row.paymentStatus])),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Giá cước:")]),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " + _vm._s(_vm.formatCurrency(row.moneyDistance))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Phí nền tảng:")]),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " +
                              _vm._s(_vm.formatCurrency(row.moneyBaseCustomer))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Thưởng chuyến đi:")]),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " +
                              _vm._s(_vm.formatCurrency(row.moneyRewardDriver))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Phụ thu giờ cao điểm:")]),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v(
                            "+ " + _vm._s(_vm.formatCurrency(row.moneyRushHour))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tip:")]),
                        _c("span", { staticClass: "increase" }, [
                          _vm._v("+ " + _vm._s(_vm.formatCurrency(row.tip))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tổng cước phí:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyTotal))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Doanh thu không Thuế:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatCurrency(row.moneyTotalNoTax))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [
                          _vm._v("Thuế VAT " + _vm._s(row.vat) + "%:"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyVAT))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Phí sử dụng ứng dụng :")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatCurrency(row.moneyUseAppDriver))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Phí nền tảng tài xế:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatCurrency(row.moneyBaseDriver))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Thu nhập tài xế:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatCurrency(row.moneyIncomeNoTax))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Thuế TNCN 1,5%:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatCurrency(row.moneyTaxDriver))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tài xế thực nhận:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyIncome))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Khuyến mãi:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyDiscount))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Người Dùng trả (Tiền mặt):")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]),
                      _c(
                        "li",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs["detail-dialog"].handleView(
                                    row
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-info" }),
                              _vm._v(" Xem Chi tiết "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Đánh giá", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-rate", {
                      attrs: {
                        disabled: "",
                        "show-score": "",
                        "text-color": "#ff9900",
                      },
                      model: {
                        value: row.reviewStar,
                        callback: function ($$v) {
                          _vm.$set(row, "reviewStar", $$v)
                        },
                        expression: "row.reviewStar",
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(row.reviewContent))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getTableData(_vm.listQuery.customerId)
          },
        },
      }),
      _c("detail-dialog", { ref: "detail-dialog" }),
      _c("images-order", { ref: "images-dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }