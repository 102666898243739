var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: "Danh sách thành viên",
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v(" Thêm thành viên ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleImport },
              },
              [_vm._v(" Thêm hàng loạt ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              width: "230px",
              label: "Họ tên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-link", { attrs: { type: "primary" } }, [
                      _vm._v(" " + _vm._s(row.customer.name) + " "),
                    ]),
                    !row.isBlock
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" Active "),
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(" Block "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "customer.email", label: "Email" },
          }),
          _c("el-table-column", {
            attrs: { prop: "customer.phone", label: "Điện thoại" },
          }),
          _c("el-table-column", {
            attrs: { prop: "branch", label: "Đơn vị/chi nhánh" },
          }),
          _c("el-table-column", {
            attrs: { prop: "department", label: "Phòng ban" },
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Cập nhật ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(row)
                          },
                        },
                      },
                      [_vm._v(" Xóa ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("add-member-company-dialog", {
        ref: "customer-dialog",
        attrs: { "list-customer": _vm.customers },
        on: { "update:success": _vm.getList },
      }),
      _c("import-member-company-modal", {
        ref: "ImportMemberCompanyModal",
        attrs: { companyId: _vm.tempData.id },
        on: { "submit:ok": _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }