var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gmap-map",
    {
      ref: "mapRef",
      staticStyle: { width: "100%", height: "600px" },
      attrs: {
        center: _vm.center,
        zoom: 7,
        options: {
          styles: _vm.mapStyle,
        },
      },
    },
    [
      _vm._t("default"),
      _vm._t("polyline"),
      _vm.visibleDriverInfo
        ? _c(
            "gmap-info-window",
            {
              attrs: { position: _vm.center, opened: _vm.visibleDriverInfo },
              on: {
                closeclick: function ($event) {
                  _vm.visibleDriverInfo = false
                },
              },
            },
            [
              _c("driver-info", {
                attrs: { driver: _vm.driverSelected },
                on: { "click-outside": _vm.handleClickOutside },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.visibleOrderInfo
        ? _c(
            "gmap-info-window",
            {
              attrs: { position: _vm.center, opened: _vm.visibleOrderInfo },
              on: {
                closeclick: function ($event) {
                  _vm.visibleOrderInfo = false
                },
              },
            },
            [
              _c("order-info", {
                attrs: {
                  order: _vm.orderSelected,
                  "visible-detail-button":
                    _vm.orderSelected.status == "ACCEPT_ORDER" ||
                    _vm.orderSelected.status == "RUN",
                },
                on: {
                  "view-detail-order": _vm.handleClickViewDetailOrder,
                  "click-outside": _vm.handleClickOutside,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.map
        ? _vm._l(_vm.driverFree, function (item) {
            return _c("custom-marker", {
              key: "driver-" + item.id,
              attrs: {
                duration: _vm.animationDriverFree,
                map: _vm.map,
                icon: _vm.renderIconVehicle(item.driver),
                position: {
                  lat: item.gps.lat,
                  lng: item.gps.long,
                },
              },
              on: {
                click: function (latLng) {
                  return _vm.handleClickDriverMarker(latLng, item)
                },
              },
            })
          })
        : _vm._e(),
      _vm.map
        ? _vm._l(_vm.orderTransports, function (item, index) {
            return _c("custom-marker", {
              key: "order-" + index,
              attrs: {
                icon: _vm.orderIcon,
                duration: 0,
                map: _vm.map,
                position: {
                  lat: item.startLat,
                  lng: item.startLong,
                },
              },
              on: {
                click: function () {
                  return _vm.handleClickOrderMarker(item)
                },
              },
            })
          })
        : _vm._e(),
      _vm.map
        ? _vm._l(_vm.orderDeliveries, function (item, index) {
            return _c("custom-marker", {
              key: "order-delivery-" + index,
              attrs: {
                icon: _vm.orderIcon,
                duration: 0,
                map: _vm.map,
                position: {
                  lat: item.startLat,
                  lng: item.startLong,
                },
              },
              on: {
                click: function () {
                  return _vm.handleClickOrderMarker(item)
                },
              },
            })
          })
        : _vm._e(),
      _vm.map
        ? _vm._l(_vm.orderFoods, function (item, index) {
            return _c("custom-marker", {
              key: "order-food-" + index,
              attrs: {
                icon: _vm.orderIcon,
                duration: 0,
                map: _vm.map,
                position: {
                  lat: item.startLat,
                  lng: item.startLong,
                },
              },
              on: {
                click: function () {
                  return _vm.handleClickOrderMarker(item)
                },
              },
            })
          })
        : _vm._e(),
      _vm.startPosition && _vm.map
        ? _c("custom-marker", {
            attrs: {
              icon: _vm.startLocationIcon,
              duration: 0,
              name: "start location",
              map: _vm.map,
              position: _vm.startPosition,
            },
          })
        : _vm._e(),
      _vm.endPosition && _vm.map
        ? _c("custom-marker", {
            attrs: {
              icon: _vm.endLocationIcon,
              duration: 0,
              map: _vm.map,
              position: _vm.endPosition,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }