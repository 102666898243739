var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "identity-card" }, [
    _c(
      "div",
      { staticClass: "application-content" },
      [
        _c("head-section", { attrs: { title: "Thông tin xe" } }),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: { label: "Hình ảnh đầu xe", prop: "imageVehicleFront" },
              },
              [
                _c("single-image", {
                  attrs: {
                    pathImage: _vm.form.imageVehicleFront,
                    width: 150,
                    height: 150,
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "imageVehicleFront")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: { label: "Hình ảnh đuôi xe", prop: "imageVehicleBack" },
              },
              [
                _c("single-image", {
                  attrs: {
                    pathImage: _vm.form.imageVehicleBack,
                    width: 150,
                    height: 150,
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "imageVehicleBack")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Biển số", prop: "numberPlates" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.numberPlates,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "numberPlates", $$v)
                    },
                    expression: "form.numberPlates",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Nhãn hiệu", prop: "brandVehicle" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.brandVehicle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "brandVehicle", $$v)
                    },
                    expression: "form.brandVehicle",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Số loại", prop: "modelVehicle" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.modelVehicle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "modelVehicle", $$v)
                    },
                    expression: "form.modelVehicle",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Số chỗ", prop: "" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.seatVehicle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "seatVehicle", $$v)
                    },
                    expression: "form.seatVehicle",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Màu sơn", prop: "colorPaint" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.colorPaint,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "colorPaint", $$v)
                    },
                    expression: "form.colorPaint",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Năm sản xuất", prop: "produceYear" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.form.produceYear,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "produceYear", $$v)
                    },
                    expression: "form.produceYear",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btn-save",
            attrs: { type: "primary", size: "medium", loading: _vm.loading },
            on: { click: _vm.handleSubmit },
          },
          [_vm._v("Lưu")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }