var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm hàng loạt",
        visible: _vm.visible,
        width: "900px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.resetData,
      },
    },
    [
      _c("upload-excel", {
        staticStyle: { "margin-bottom": "12px" },
        attrs: { onSuccess: _vm.uploadSuccess, sheetName: _vm.sheetName },
      }),
      _vm.isProgressing
        ? _c(
            "div",
            [_c("el-progress", { attrs: { percentage: _vm.percent } })],
            1
          )
        : _vm._e(),
      _vm.isUploadSuccess
        ? _c("div", { staticStyle: { "margin-bottom": "16px" } }, [
            _c(
              "label",
              { staticStyle: { color: "green" }, attrs: { for: "" } },
              [_vm._v("Đã upload lên 1 file")]
            ),
          ])
        : _vm._e(),
      _vm.errors.length
        ? _c(
            "div",
            { staticStyle: { "margin-bottom": "16px" } },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Danh sách dòng lỗi"),
              ]),
              _vm._l(_vm.errors, function (item, index) {
                return _c("div", { key: index }, [
                  _vm._v(" " + _vm._s(item.msg) + " "),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.discountingRequestDetails.length > 0
        ? _c(
            "el-table",
            {
              attrs: {
                data: _vm.discountingRequestDetails,
                border: "",
                fit: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "STT",
                  "class-name": "text-center",
                  type: "index",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "username small-padding fixed-width",
                  label: "Mã SP/Mã nhà phát hành",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(row.product.code) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  891827596
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "username small-padding fixed-width",
                  label: "Tên sản phẩm",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(row.product.name) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3130137414
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "username small-padding fixed-width",
                  label: "CK cũ",
                  align: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(" " + _vm._s(row.oldValue) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1144160936
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  "class-name": "username small-padding fixed-width",
                  label: "CK mới",
                  align: "right",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("span", [_vm._v(" " + _vm._s(row.value) + " ")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  790527759
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.discountingRequestDetails.length > 0
        ? _c("div", { staticStyle: { "margin-top": "12px" } }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Tổng dòng: " + _vm._s(_vm.data.length)),
            ]),
          ])
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.loading,
                disabled: !_vm.data.length,
              },
              on: { click: _vm.createData },
            },
            [_vm._v("Thêm dữ liệu")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }