var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Chọn tỉnh thành" },
                    on: { change: _vm.handleChangeCity },
                    model: {
                      value: _vm.cityId,
                      callback: function ($$v) {
                        _vm.cityId = $$v
                      },
                      expression: "cityId",
                    },
                  },
                  _vm._l(_vm.cities, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Quận huyện")]),
                _c(
                  "el-select",
                  {
                    attrs: { filterable: "", placeholder: "Chọn quận huyện" },
                    on: { change: _vm.handleChangeDistrict },
                    model: {
                      value: _vm.districtId,
                      callback: function ($$v) {
                        _vm.districtId = $$v
                      },
                      expression: "districtId",
                    },
                  },
                  [
                    _vm.districts.length
                      ? _c("el-option", {
                          attrs: { label: "Tất cả", value: null },
                        })
                      : _vm._e(),
                    _vm._l(_vm.districts, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "btn-add-service-group filter-item",
                staticStyle: { transform: "translateY(10px)" },
                attrs: { type: "primary", icon: "el-icon-connection" },
                on: { click: _vm.handleCopy },
              },
              [_vm._v(" Sao chép cấu hình sang tỉnh khác ")]
            ),
            _vm.districtId
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-add-service-group filter-item",
                    staticStyle: { transform: "translateY(10px)" },
                    attrs: { type: "primary", icon: "el-icon-connection" },
                    on: { click: _vm.handleCopyCityToDistrict },
                  },
                  [_vm._v(" Sao chép cấu hình từ tỉnh xuống huyện ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.cityId
        ? _c(
            "el-table-draggable",
            { on: { drop: _vm.handleDrop } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: { data: _vm.appConfigs, border: "", fit: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "60", align: "center", label: "STT" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            return [
                              _c("span", [_vm._v(_vm._s($index + 1))]),
                              _c("i", {
                                staticClass: "el-icon-sort",
                                staticStyle: {
                                  cursor: "move",
                                  "font-size": "15px",
                                  "font-weight": "bold",
                                  color: "black",
                                  "margin-left": "4px",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3267151722
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Chức năng" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.appFeatureTypeTrans[row.appFeature]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2896715931
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Màn hình khởi đầu app" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": row.isFirstNavigate
                                    ? "Bật"
                                    : "Tắt",
                                },
                                on: {
                                  change: function (value) {
                                    return _vm.handleUpdateIsFirstNavigateConfig(
                                      row,
                                      value
                                    )
                                  },
                                },
                                model: {
                                  value: row.isFirstNavigate,
                                  callback: function ($$v) {
                                    _vm.$set(row, "isFirstNavigate", $$v)
                                  },
                                  expression: "row.isFirstNavigate",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1113318654
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Hiện mặc định trên app" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": row.isDefault ? "Bật" : "Tắt",
                                },
                                on: {
                                  change: function (value) {
                                    return _vm.handleUpdateDefaultConfig(
                                      row,
                                      value
                                    )
                                  },
                                },
                                model: {
                                  value: row.isDefault,
                                  callback: function ($$v) {
                                    _vm.$set(row, "isDefault", $$v)
                                  },
                                  expression: "row.isDefault",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2198579762
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Trạng thái" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": row.isEnabled ? "Bật" : "Tắt",
                                },
                                on: {
                                  change: function (value) {
                                    return _vm.handleUpdateConfig(row, value)
                                  },
                                },
                                model: {
                                  value: row.isEnabled,
                                  callback: function ($$v) {
                                    _vm.$set(row, "isEnabled", $$v)
                                  },
                                  expression: "row.isEnabled",
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      53547673
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("span", [_vm._v("Vui lòng chọn Tỉnh thành để xem cấu hình")]),
      _c("CopyConfigModal", {
        ref: "copyModal",
        attrs: { cities: _vm.cities },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }