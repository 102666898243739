var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: (_vm.status == "create" ? "Tạo" : "Cập nhật") + " thông báo",
        visible: _vm.visible,
        width: "800px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tiêu đề", prop: "title" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Nội dung", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "300",
                          "show-word-limit": "",
                          autosize: { minRows: 4, maxRows: 10 },
                        },
                        model: {
                          value: _vm.form.body,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "body", $$v)
                          },
                          expression: "form.body",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thông báo dành cho", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(
                          _vm.IdealNotificationTypeTrans,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.type == _vm.IdealNotificationType.Product
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "Sản phẩm", prop: "idealProductId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-key": "",
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.handleSearchProduct,
                              },
                              model: {
                                value: _vm.form.idealProductId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "idealProductId", $$v)
                                },
                                expression: "form.idealProductId",
                              },
                            },
                            _vm._l(_vm.products, function (product) {
                              return _c("el-option", {
                                key: product.id,
                                attrs: {
                                  label: product.name,
                                  value: product.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.type == _vm.IdealNotificationType.Banner
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Banner", prop: "idealBannerId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-key": "",
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.handleSearchBanner,
                              },
                              model: {
                                value: _vm.form.idealBannerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "idealBannerId", $$v)
                                },
                                expression: "form.idealBannerId",
                              },
                            },
                            _vm._l(_vm.banners, function (banner) {
                              return _c("el-option", {
                                key: banner.id,
                                attrs: {
                                  label: banner.title,
                                  value: banner.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tỉnh / thành phố", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: !_vm.$store.state.user.info.branch,
                            placeholder: "Tất cả",
                          },
                          on: { change: _vm.changeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        [
                          !_vm.$store.state.user.info.branch
                            ? _c("el-option", {
                                attrs: { label: "Tất cả", value: 0 },
                              })
                            : _vm._e(),
                          _vm._l(_vm.cities, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.nameWithType,
                                value: item.id,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "Tất cả",
                            clearable:
                              !_vm.branch ||
                              (_vm.branch && !_vm.branch.districts.length),
                          },
                          on: { change: _vm.changeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        [
                          !_vm.$store.state.user.info.branch ||
                          (_vm.$store.state.user.info.branch &&
                            !_vm.$store.state.user.info.branch.districts.length)
                            ? _c("el-option", {
                                attrs: { label: "Tất cả", value: 0 },
                              })
                            : _vm._e(),
                          _vm._l(_vm.districts, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.nameWithType,
                                value: item.id,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "Tất cả",
                            clearable:
                              !_vm.$store.state.user.info.branch ||
                              (_vm.$store.state.user.info.branch &&
                                !_vm.$store.state.user.info.branch.wards
                                  .length),
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        [
                          !_vm.$store.state.user.info.branch ||
                          (_vm.$store.state.user.info.branch &&
                            !_vm.$store.state.user.info.branch.wards.length)
                            ? _c("el-option", {
                                attrs: { label: "Tất cả", value: 0 },
                              })
                            : _vm._e(),
                          _vm._l(_vm.wards, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.nameWithType,
                                value: item.id,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.status == "create"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Hẹn lịch gửi" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      "value-format": "dd/MM/yyyy",
                                      type: "date",
                                    },
                                    model: {
                                      value: _vm.form.dateSchedule,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "dateSchedule", $$v)
                                      },
                                      expression: "form.dateSchedule",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-time-select", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "picker-options": {
                                        start: "00:00",
                                        step: "00:05",
                                        end: "23:55",
                                      },
                                    },
                                    model: {
                                      value: _vm.form.timeSchedule,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "timeSchedule", $$v)
                                      },
                                      expression: "form.timeSchedule",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("schedule-table", {
                        attrs: { idealNotificationId: _vm.form.id },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.status == "create" || _vm.$checkAction("update-noti")
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingSubmit, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }