var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: "Nạp tiền",
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.tempData,
                "label-position": "top",
              },
            },
            [
              _c("div", [
                _c("label", [_vm._v("Tủ chính: ")]),
                _c("span", [_vm._v(_vm._s(_vm.getBalance))]),
              ]),
              _c("div", [
                _c("label", [_vm._v("Tủ tín dụng: ")]),
                _c("span", [_vm._v(_vm._s(_vm.getBalanceCredit))]),
              ]),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Tổ chức & Hộ gia đình",
                            prop: "companyId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", placeholder: "" },
                              model: {
                                value: _vm.tempData.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "companyId", $$v)
                                },
                                expression: "tempData.companyId",
                              },
                            },
                            _vm._l(_vm.companies, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label:
                                    item.name +
                                    " (Mã số thuế: " +
                                    item.taxCode +
                                    ")",
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số tiền (VNĐ)", prop: "amount" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.tempData.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "amount", $$v)
                              },
                              expression: "tempData.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Nạp vào", prop: "balanceType" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.tempData.balanceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "balanceType", $$v)
                                },
                                expression: "tempData.balanceType",
                              },
                            },
                            [_vm._v("Tủ chính")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 2 },
                              model: {
                                value: _vm.tempData.balanceType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tempData, "balanceType", $$v)
                                },
                                expression: "tempData.balanceType",
                              },
                            },
                            [_vm._v("Tủ tín dụng")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Ghi chú" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 2, maxRows: 5 },
                            },
                            model: {
                              value: _vm.tempData.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempData, "note", $$v)
                              },
                              expression: "tempData.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: {
                click: function ($event) {
                  return _vm.createData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }