"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.createCredit = createCredit;
exports.findAll = findAll;
var _request = _interopRequireDefault(require("@/utils/request"));
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/companyDeposit",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/companyDeposit",
    method: "post",
    data: data
  });
}
function createCredit(data) {
  return (0, _request.default)({
    url: "/companyDeposit/credit",
    method: "post",
    data: data
  });
}