"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  data: function data() {
    return {
      center: {},
      currentPlace: null,
      markers: []
    };
  },
  props: {
    marker: {
      type: Object,
      default: function _default() {
        return {
          lat: 10.7884672,
          lng: 106.63047670000003
        };
      }
    }
  },
  watch: {
    marker: function marker(val) {
      var _this = this;
      var marker = {
        lat: val.lat,
        lng: val.lng
      };
      this.markers = [];
      if (isNaN(val.lat) && isNaN(val.lng)) {
        return;
      }
      this.$nextTick(function () {
        _this.markers[0] = {
          position: marker
        };
        _this.center = marker;
      });
    }
  },
  mounted: function mounted() {
    // this.geolocate();
  },
  methods: {
    setPlace: function setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },
    addMarker: function addMarker() {
      if (this.currentPlace) {
        var marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        };
        this.$emit("set:address", this.currentPlace.formatted_address);
        this.$emit("set:marker", marker);
        this.markers[0] = {
          position: marker
        };
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function geolocate() {
      var _this2 = this;
      navigator.geolocation.getCurrentPosition(function (position) {
        _this2.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
};