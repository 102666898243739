var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm sản phẩm")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái bán hàng"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "", remote: "" },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "type", $$v)
                    },
                    expression: "query.type",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "Tất cả", value: undefined },
                  }),
                  _c("el-option", {
                    attrs: { label: "Đang mở bán", value: 3 },
                  }),
                  _c("el-option", {
                    attrs: { label: "Chưa/Ngưng bán", value: 2 },
                  }),
                  _c("el-option", { attrs: { label: "Đã bán hết", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Trạng thái bốc thăm"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "", remote: "" },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.isDraw,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "isDraw", $$v)
                    },
                    expression: "query.isDraw",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "Tất cả", value: undefined },
                  }),
                  _c("el-option", {
                    attrs: { label: "Đã bốc thăm", value: true },
                  }),
                  _c("el-option", {
                    attrs: { label: "Chưa bốc thăm", value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Thời gian bốc thăm"),
              ]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "->",
                  "start-placeholder": "Từ ngày",
                  "end-placeholder": "Đến ngày",
                  format: "dd-MM-yyyy",
                  "value-format": "yyyy-MM-dd",
                },
                on: { change: _vm.queryDate },
                model: {
                  value: _vm.datePicker,
                  callback: function ($$v) {
                    _vm.datePicker = $$v
                  },
                  expression: "datePicker",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.$checkAction("add-product")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" Thêm mới ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.imageUrl
                      ? _c("img", {
                          attrs: { height: "80px", src: row.imageUrl },
                        })
                      : _vm._e(),
                    _c("h3", { staticStyle: { margin: "0" } }, [
                      _vm._v(
                        "Tên CTKM: " +
                          _vm._s(row.promotionName ? row.promotionName : "--")
                      ),
                    ]),
                    _c("h4", { staticStyle: { margin: "0" } }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                    _c("span", [_vm._v(_vm._s(row.code))]),
                    _c("br"),
                    row.isPublished && row.publishedAt > 0
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              margin: "0",
                              "margin-bottom": "10px",
                            },
                          },
                          [
                            _c("b", [_vm._v("Ngày mở bán:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatDateTime(
                                    row.publishedAt,
                                    "HH:mm, DD/MM/YYYY"
                                  )
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                    row.isAllowReward
                      ? _c(
                          "div",
                          { staticStyle: { "margin-bottom": "4px" } },
                          [
                            _c(
                              "el-tag",
                              { attrs: { type: "success", effect: "dark" } },
                              [
                                _c("b", [
                                  _vm._v("Tặng thưởng cho người giới thiệu"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row.isPublished &&
                    !_vm.checkIsSoldComplete(row) &&
                    row.isVisible
                      ? _c(
                          "el-tag",
                          { attrs: { size: "small", effect: "dark" } },
                          [_c("b", [_vm._v("Đang mở bán")])]
                        )
                      : !row.isPublished && row.isVisible
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "warning",
                              size: "small",
                              effect: "dark",
                            },
                          },
                          [_c("b", [_vm._v("Chưa/Ngưng bán")])]
                        )
                      : _vm._e(),
                    _vm.checkIsSoldComplete(row) && row.isVisible
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              size: "small",
                              effect: "dark",
                            },
                          },
                          [_c("b", [_vm._v("Đã bán hết")])]
                        )
                      : _vm._e(),
                    !row.isVisible
                      ? _c(
                          "div",
                          { staticStyle: { "margin-top": "5px" } },
                          [
                            _c(
                              "el-tag",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  type: "danger",
                                  size: "normal",
                                  effect: "dark",
                                },
                              },
                              [_c("b", [_vm._v("Đã ẩn")])]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: _vm.getLink(row.id),
                          placement: "right",
                          effect: "dark",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              type: "text",
                              size: "small",
                              icon: "el-icon-link",
                            },
                            on: {
                              click: function ($event) {
                                _vm.copyLink(_vm.getLink(row.id), $event)
                              },
                            },
                          },
                          [_vm._v("Copy link")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Các giải thưởng", prop: "prize" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.idealPrizes, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "right",
                              width: "400",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: item.imageUrl,
                                height: "60",
                                alt: "",
                              },
                            }),
                            _c("p", [
                              _c("b", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" (SL: " + _vm._s(item.quantity) + ")"),
                            ]),
                            _c("p", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$formatNumberVN(item.finalPrice)) +
                                    " VNĐ"
                                ),
                              ]),
                            ]),
                            _c("p", [_vm._v(_vm._s(item.description))]),
                            _c(
                              "div",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "p",
                                  [
                                    item.type == _vm.idealPrizeType.Main
                                      ? _c(
                                          "el-tag",
                                          {
                                            attrs: {
                                              size: "mini",
                                              effect: "dark",
                                            },
                                          },
                                          [_c("b", [_vm._v("Giải chính")])]
                                        )
                                      : _c(
                                          "el-tag",
                                          { attrs: { size: "mini" } },
                                          [_c("b", [_vm._v("Giải phụ")])]
                                        ),
                                    _vm._v(" "),
                                    _c("b", [_vm._v(_vm._s(item.name))]),
                                    _vm._v(" đã bốc thăm "),
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(item.used) +
                                          "/" +
                                          _vm._s(item.quantity)
                                      ),
                                    ]),
                                    _vm._v(" lần "),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin sản phẩm", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-progress", {
                      attrs: {
                        type: "dashboard",
                        color: _vm.colors,
                        percentage:
                          ((row.sold + row.pending) / row.quantity) * 100,
                        "stroke-width": 6,
                        format: function () {
                          return (
                            row.sold +
                            row.pending +
                            "/" +
                            (row.quantity == 0 ? "0" : row.quantity)
                          )
                        },
                      },
                    }),
                    _c("p", [
                      _c("b", [_vm._v("Giá bán: ")]),
                      _vm._v(
                        _vm._s(_vm.$formatNumberVN(row.finalPrice) + "vnđ") +
                          "/" +
                          _vm._s(row.unit || "sản phẩm")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày bốc thăm (dự kiến)", prop: "date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("b", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$formatDateTime(
                            row.maxDrawDateAt,
                            "HH:mm, DD/MM/YYYY"
                          )
                        )
                      ),
                    ]),
                    _c("br"),
                    row.renewIdealDraws.length
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: {
                              click: function () {
                                return _vm.handleViewLog(row)
                              },
                            },
                          },
                          [_vm._v("Lịch sử gia hạn bốc thăm")]
                        )
                      : _vm._e(),
                    row.idealDrawHistories.length
                      ? _c(
                          "div",
                          [
                            _c("el-divider", {
                              staticClass: "custom-divider",
                              staticStyle: { margin: "10px 0" },
                            }),
                            _vm.$formatDateTime(
                              row.maxDrawDateAt,
                              "YYYY-MM-DD"
                            ) !=
                            _vm.$formatDateTime(
                              row.idealDrawHistories[0].dateCreated,
                              "YYYY-MM-DD"
                            )
                              ? _c("li", [
                                  _c("b", [_vm._v("Ngày bốc thăm thực tế:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatDateTime(
                                          row.idealDrawHistories[0].dateCreated,
                                          "HH:mm, DD/MM/YYYY"
                                        )
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function () {
                                    return _vm.handleEdit(row, "idealCode")
                                  },
                                },
                              },
                              [_vm._v("Xem danh sách các thăm")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row.maxDrawDateAt < _vm.moment().unix() &&
                    !row.idealDrawHistories.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: {
                                  type: "danger",
                                  size: "small",
                                  effect: "dark",
                                },
                              },
                              [_c("b", [_vm._v("Chưa bốc thăm")])]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.$checkAction("copy-product")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(row)
                              },
                            },
                          },
                          [_vm._v(" Sao chép ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    row.isVisible && !_vm.checkIsSoldComplete(row)
                      ? _c(
                          "el-button",
                          {
                            attrs: { plain: "", type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleVisibleProduct(row)
                              },
                            },
                          },
                          [_vm._v(" Ẩn ")]
                        )
                      : _vm._e(),
                    !row.isVisible && !_vm.checkIsSoldComplete(row)
                      ? _c(
                          "el-button",
                          {
                            attrs: { plain: "", type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleVisibleProduct(row)
                              },
                            },
                          },
                          [_vm._v(" Hiện ")]
                        )
                      : _vm._e(),
                    row.sold + row.pending < row.quantity || row.quantity == 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: {
                              type: row.isPublished ? "danger" : "success",
                              size: "mini",
                            },
                            on: {
                              click: function () {
                                return _vm.handlePublish(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.isPublished
                                  ? "Tạm ngưng bán"
                                  : "Mở bán ngay"
                              )
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.$checkAction("delete-product")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" Xoá ")]
                        )
                      : _vm._e(),
                    row.sold + row.pending > 0 &&
                    row.idealPrizes.length &&
                    row.idealPrizes.some(function (item) {
                      return item.used < item.quantity
                    }) &&
                    row.sold + row.pending == row.quantity
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-star-on",
                              type: "warning",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDraw(row)
                              },
                            },
                          },
                          [_vm._v(" Bốc thăm ngay ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("IdealProductModal", {
        ref: "IdealProductModal",
        on: { submitOk: _vm.fetchData },
      }),
      _c("ideal-renew-log", { ref: "idealLogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }