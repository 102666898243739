var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Quận huyện" } },
            [
              _c("el-input", {
                attrs: { readonly: "" },
                model: {
                  value: _vm.form.nameWithType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nameWithType", $$v)
                  },
                  expression: "form.nameWithType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Kích hoạt người dùng sử dụng tiền khuyến mãi",
                    prop: "isEnableUsePromotionBalance",
                  },
                },
                [
                  _c("el-switch", {
                    staticStyle: { display: "block" },
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-text": "Bật",
                      "inactive-text": "Tắt",
                    },
                    model: {
                      value: _vm.form.isEnableUsePromotionBalance,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isEnableUsePromotionBalance", $$v)
                      },
                      expression: "form.isEnableUsePromotionBalance",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.updateData },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }