var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "depot-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Code", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên kho hàng", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "h3",
                  {
                    staticStyle: { "margin-top": "0", "margin-bottom": "8px" },
                  },
                  [_vm._v("Địa chỉ")]
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thành phố", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }