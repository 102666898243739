"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.object.to-string.js");
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    idealDrawHistories: {
      type: Object
    },
    idealPrize: {
      type: Object
    }
  },
  data: function data() {
    return {
      idealDrawCounter: 0
    };
  },
  watch: {
    idealPrize: {
      handler: function handler() {
        if (this.idealDrawHistories.length) {
          this.handleCountIdealDraw(this.idealDrawHistories);
        }
      },
      immediate: true
    }
  },
  methods: {
    handleCountIdealDraw: function handleCountIdealDraw(idealDrawHistories) {
      var _this = this;
      var idealPrizesHistory = idealDrawHistories.filter(function (e) {
        return e.idealAward.idealPrize.id == _this.idealPrize.id;
      });
      var totalHistory = idealPrizesHistory.length;
      this.idealDrawCounter = totalHistory;
    },
    getTotalPrize: function getTotalPrize(prizeId) {
      var find = this.idealProduct.idealPrizes.find(function (e) {
        return e.id == prizeId;
      });
      if (find) {
        return "/".concat(find.quantity);
      }
    }
  }
};