var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" tag ne "),
      _vm.idealProduct.isPublished && !_vm.checkIsSoldComplete(_vm.idealProduct)
        ? _c("el-tag", { attrs: { size: "small", effect: "dark" } }, [
            _c("b", [_vm._v("Đang mở bán")]),
          ])
        : _vm._e(),
      _vm.checkProductExpired(_vm.idealProduct)
        ? _c("el-tag", { attrs: { size: "small", effect: "dark" } }, [
            _c("b", [_vm._v("Đã hết thời hạn bán")]),
          ])
        : !_vm.row.isPublished
        ? _c(
            "el-tag",
            { attrs: { type: "warning", size: "small", effect: "dark" } },
            [_c("b", [_vm._v("Chưa/Ngưng bán")])]
          )
        : _vm._e(),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }