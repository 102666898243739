var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "label",
        [
          _vm._v("Bảng chiết khấu thứ hạng: "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
            [_vm._v("Cấu hình")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "10px" },
          attrs: { data: _vm.agentRankDetails, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "name",
              label: "Tên thứ hạng",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _vm._v(" " + _vm._s(row.target.name) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "name",
              label: "Chiết khấu (%)",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _vm._v(" " + _vm._s(row.percent) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "100",
              "class-name": "small-padding fixed-width",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "80px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("agent-rank-detail-modal", {
        ref: "AgentRankDetailModal",
        attrs: {
          formAgentRank: _vm.formAgentRank,
          agentRankDetails: _vm.agentRankDetails,
        },
        on: { "submit:ok": _vm.findOneAgentRankAsync },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }