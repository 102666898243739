"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cvSubmitApi = void 0;
var _request = require("@/utils/request");
var cvSubmitApi = exports.cvSubmitApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/cvSubmit',
      params: params
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/cvSubmit/".concat(id, "/complete"),
      method: 'patch'
    });
  },
  cancel: function cancel(id) {
    return (0, _request.request)({
      url: "/cvSubmit/".concat(id, "/cancel"),
      method: 'delete'
    });
  }
};