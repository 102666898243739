var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "customer-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
          },
        },
        [
          _c("h3", [_vm._v(" Thông tin chung ")]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Họ tên", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số điện thoại", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled:
                            !_vm.isAllowUpdatePhone ||
                            _vm.dialogStatus == "edit",
                        },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày sinh", prop: "" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "dd/MM/yyyy",
                          format: "dd/MM/yyyy",
                          type: "date",
                        },
                        model: {
                          value: _vm.form.birthday,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "birthday", $$v)
                          },
                          expression: "form.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Địa chỉ" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thành phố", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            disabled:
                              _vm.dialogStatus == "edit" &&
                              !_vm.visibleUpdateAddress,
                          },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled:
                              _vm.dialogStatus == "edit" &&
                              !_vm.visibleUpdateAddress,
                          },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled:
                              _vm.dialogStatus == "edit" &&
                              !_vm.visibleUpdateAddress,
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.form.jobType == _vm.CustomerJobType.Student
                    ? "Trường học"
                    : "Cơ quan"
                ) +
                " "
            ),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.form.jobType == _vm.CustomerJobType.Student
                            ? "Mã số lớp"
                            : "Chức danh",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.companyPosition,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyPosition", $$v)
                          },
                          expression: "form.companyPosition",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.form.jobType == _vm.CustomerJobType.Student
                            ? "MSSV"
                            : "Công việc",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.job,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "job", $$v)
                          },
                          expression: "form.job",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.form.jobType == _vm.CustomerJobType.Student
                            ? "Bậc học"
                            : "Đơn vị",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "companyName", $$v)
                          },
                          expression: "form.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.jobType == _vm.CustomerJobType.Student
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Ngành đăng ký" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.info1,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "info1", $$v)
                              },
                              expression: "form.info1",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("h3", [_vm._v("Giới thiệu")]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.dialogStatus == "edit"
                            ? "Mã giới thiệu (Cá nhân)"
                            : "Mã người giới thiệu",
                        prop: "refCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.dialogStatus == "edit" },
                        model: {
                          value: _vm.form.refCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "refCode", $$v)
                          },
                          expression: "form.refCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dialogStatus == "edit"
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Người giới thiệu" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.form.parent && _vm.form.parent.name,
                              disabled: true,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.dialogStatus == "edit"
                            ? "Tổ chức giới thiệu"
                            : "Mã giới thiệu(Tổ chức)",
                        prop: "refCode",
                      },
                    },
                    [
                      _vm.dialogStatus != "edit"
                        ? _c("el-input", {
                            attrs: { disabled: _vm.dialogStatus == "edit" },
                            model: {
                              value: _vm.form.refCodeCompany,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "refCodeCompany", $$v)
                              },
                              expression: "form.refCodeCompany",
                            },
                          })
                        : _c("el-input", {
                            attrs: {
                              disabled: "",
                              value:
                                _vm.form.companyRef && _vm.form.companyRef.name,
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h3", [_vm._v("Tủ tiền")]),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.dialogStatus == "edit"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tủ chính", prop: "balance" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { disabled: true },
                            model: {
                              value: _vm.form.balance,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "balance", $$v)
                              },
                              expression: "form.balance",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogStatus == "edit"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Tủ khuyến mãi",
                            prop: "balancePromotion",
                          },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: { disabled: true },
                            model: {
                              value: _vm.form.balancePromotion,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "balancePromotion", $$v)
                              },
                              expression: "form.balancePromotion",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.dialogStatus == "edit"
            ? _c(
                "div",
                { attrs: { gutter: 24 } },
                [
                  _c("h3", [_vm._v(" Danh sách việc làm (Fan Fclass) ")]),
                  _c("FanFclassCustomer", {
                    attrs: { partTimeJobs: _vm.form.partTimeJobs },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }