var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tên, mã giới thiệu" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: {
              query: _vm.listQuery,
              visibleWard: false,
              visibleDistrict: false,
            },
            on: { "change:address": _vm.getList },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Hình thức tổ chức"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Hình thức tổ chức" },
                  on: { change: _vm.handleQuery },
                  model: {
                    value: _vm.listQuery.companyType,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "companyType", $$v)
                    },
                    expression: "listQuery.companyType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.CompanyType, function (name, key) {
                    return _c("el-option", {
                      key: key,
                      attrs: { label: name, value: key },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Thanh toán credit"),
              ]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Thanh toán credit" },
                  on: { change: _vm.handleQuery },
                  model: {
                    value: _vm.listQuery.isCredit,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isCredit", $$v)
                    },
                    expression: "listQuery.isCredit",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", { attrs: { label: "Có", value: true } }),
                  _c("el-option", { attrs: { label: "Không", value: false } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v(" Tìm ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" Thêm Tổ chức & Hộ gia đình ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleImport },
                },
                [_vm._v(" Thêm hàng loạt (Excel) ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _vm.checkPermissionExport
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loadingExport,
                        icon: "el-icon-download",
                      },
                      on: { click: _vm.handleExportExcel },
                    },
                    [_vm._v(" Xuất excel ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Thông tin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "label",
                      {
                        staticStyle: { color: "black", "font-size": "16px" },
                        attrs: { for: "" },
                      },
                      [_vm._v(_vm._s(row.name))]
                    ),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v("MST: ")]),
                      _c("span", [_vm._v(_vm._s(row.taxCode))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Mã giới thiệu: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.refCode))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hình thức tổ chức" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          effect: "dark",
                          type: row.type == "COMPANY" ? "success" : "primary",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.CompanyType[row.type]) + " ")]
                    ),
                    _c("div", [
                      _c("span", [
                        _vm._v(
                          "(" +
                            _vm._s(
                              row.createdCustomer
                                ? "Khách hàng tạo: " +
                                    row.createdCustomer.name +
                                    " - " +
                                    row.createdCustomer.phone
                                : "Nhân viên tạo: " +
                                    (row.createdStaff
                                      ? row.createdStaff.name
                                      : "--")
                            ) +
                            ")"
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tỉnh/thành", prop: "city.nameWithType" },
          }),
          _c("el-table-column", {
            attrs: { label: "Tài khoản", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    [
                      _c("div", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("QRCFM DEBIT: "),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.balance))),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("QRCFM CREDIT: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(row.balanceCredit))
                            ),
                          ]),
                          _c("br"),
                          _c(
                            "el-link",
                            {
                              staticStyle: { "font-size": "14px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleUpdateCreditLimit(row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Định mức CREDIT: " +
                                  _vm._s(_vm.formatCurrency(row.creditLimit)) +
                                  " "
                              ),
                              _c("i", { staticClass: "el-icon-edit" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150px", label: "Hành động", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleView(row)
                              },
                            },
                          },
                          [_vm._v(" Thành viên ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "8px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v(" Cập nhật ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "8px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleTransaction(row)
                              },
                            },
                          },
                          [_vm._v(" Lịch sử GD ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "8px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleHistoryDebt(row)
                              },
                            },
                          },
                          [_vm._v(" Lịch sử nợ tín dụng ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "8px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "danger",
                              size: "mini",
                              icon: "el-icon-delete-solid",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" Xóa ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("company-member-dialog", { ref: "member-dialog" }),
      _c("company-transaction", { ref: "transaction" }),
      _c("company-debt", { ref: "debt" }),
      _c("company-modal", {
        ref: "company-modal",
        attrs: { cities: _vm.cities },
        on: { "submit:ok": _vm.getList },
      }),
      _c("import-company-modal", {
        ref: "ImportCompanyModal",
        on: { "submit:ok": _vm.getList },
      }),
      _c("credit-limit-modal", {
        ref: "CreditLimitModal",
        on: { "submit:ok": _vm.getList },
      }),
      _c("company-list-ref-dialog", { ref: "CompanyListRefDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }