var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cập nhật tỉnh thành",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "80px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Kích hoạt thưởng khách hàng đăng ký mới",
                      },
                    },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block" },
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": "Bật",
                          "inactive-text": "Tắt",
                        },
                        model: {
                          value: _vm.form.isEnableRewardCustomerRegister,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "isEnableRewardCustomerRegister",
                              $$v
                            )
                          },
                          expression: "form.isEnableRewardCustomerRegister",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.isEnableRewardCustomerRegister
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số tiền thưởng đăng ký mới" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.form.rewardCustomerRegisterAmount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "rewardCustomerRegisterAmount",
                                  $$v
                                )
                              },
                              expression: "form.rewardCustomerRegisterAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.updateData },
            },
            [_vm._v("Cập nhật")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }