"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRandomCharacter = exports.getCharactersData = void 0;
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.object.to-string.js");
var _slicedToArray2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/slicedToArray.js"));
var _easing = require("./easing");
var DEFAULT_CHARACTER_SET = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
var getRandomCharacter = exports.getRandomCharacter = function getRandomCharacter() {
  var set = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_CHARACTER_SET;
  return set[Math.floor(Math.random() * set.length)];
};
/**
 * returns an array of 2 numbers where the first one is
 * the duration of the random characters and the second one is the
 * the revealing duration
 */
var getPartsTime = function getPartsTime(duration, revealDuration) {
  if (revealDuration === 0) {
    return [duration, 0];
  }
  var revealFraction = revealDuration > 1 ? 1 : revealDuration;
  revealFraction = revealFraction < 0 ? 0 : revealFraction;
  var revealDurationSec = duration * revealFraction;
  return [duration - revealDurationSec, revealDurationSec];
};
/**
 * returns an array of objects where each object contains data for each character
 */
var getCharactersData = exports.getCharactersData = function getCharactersData(_ref) {
  var characters = _ref.characters,
    duration = _ref.duration,
    _ref$revealDuration = _ref.revealDuration,
    revealDuration = _ref$revealDuration === void 0 ? 0.6 : _ref$revealDuration,
    _ref$revealEasing = _ref.revealEasing,
    revealEasing = _ref$revealEasing === void 0 ? 'linear' : _ref$revealEasing,
    ignoreCharacterSet = _ref.ignoreCharacterSet;
  var charactersArray = Array.isArray(characters) ? characters : characters.split('');
  var _getPartsTime = getPartsTime(duration, revealDuration),
    _getPartsTime2 = (0, _slicedToArray2.default)(_getPartsTime, 2),
    randomSec = _getPartsTime2[0],
    revealingSec = _getPartsTime2[1];
  var easingFunc = _easing.easing[revealEasing];
  var interval = (0, _easing.getEasingInterval)(charactersArray, ignoreCharacterSet);
  var step = 0;
  var getRevealTime = function getRevealTime(isIgnored) {
    if (isIgnored || revealDuration === 0) {
      return duration;
    }
    var revealTime = easingFunc(step * interval, 0, revealingSec, 1);
    step += 1;
    return randomSec + revealTime;
  };
  return charactersArray.map(function (character) {
    var isIgnored = !!(ignoreCharacterSet !== null && ignoreCharacterSet !== void 0 && ignoreCharacterSet.find(function (ignoreCharacter) {
      return ignoreCharacter === character;
    }));
    return {
      character: character,
      isIgnored: isIgnored,
      revealTime: getRevealTime(isIgnored)
    };
  });
};