"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cvDetailApi = void 0;
var _request = require("@/utils/request");
var cvDetailApi = exports.cvDetailApi = {
  create: function create(data) {
    return (0, _request.request)({
      url: '/cvDetail',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/cvDetail/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/cvDetail/".concat(id),
      method: 'delete'
    });
  }
};