var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tiêu đề", prop: "banner.title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.banner.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.banner, "title", $$v)
                  },
                  expression: "form.banner.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tỉnh thành" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", clearable: !_vm.branch },
                  model: {
                    value: _vm.form.cityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cityId", $$v)
                    },
                    expression: "form.cityId",
                  },
                },
                _vm._l(_vm.cities, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-radio",
            {
              attrs: { label: _vm.BannerType.PopUp },
              model: {
                value: _vm.form.banner.type,
                callback: function ($$v) {
                  _vm.$set(_vm.form.banner, "type", $$v)
                },
                expression: "form.banner.type",
              },
            },
            [_vm._v("Cho cửa hàng")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: _vm.BannerType.PopUpPromotion },
              model: {
                value: _vm.form.banner.type,
                callback: function ($$v) {
                  _vm.$set(_vm.form.banner, "type", $$v)
                },
                expression: "form.banner.type",
              },
            },
            [_vm._v("Cho khuyến mãi")]
          ),
          _vm.form.banner.type == _vm.BannerType.PopUp
            ? _c(
                "el-form-item",
                { attrs: { label: "Cửa hàng", prop: "storeId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        remote: "",
                        "remote-method": _vm.fetchStores,
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.storeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "storeId", $$v)
                        },
                        expression: "form.storeId",
                      },
                    },
                    _vm._l(_vm.stores, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + " - " + item.address,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "Khuyến mãi", prop: "promotionId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.form.promotionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "promotionId", $$v)
                        },
                        expression: "form.promotionId",
                      },
                    },
                    _vm._l(_vm.promotions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.title + " - " + item.code,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { label: "Ngày bắt đầu", prop: "banner.startAt" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "dd/MM/yyyy",
                          "value-format": "timestamp",
                          type: "date",
                          placeholder: "Pick a day",
                        },
                        model: {
                          value: _vm.form.banner.startAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.banner, "startAt", $$v)
                          },
                          expression: "form.banner.startAt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày kết thúc", prop: "banner.endAt" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "dd/MM/yyyy",
                          "value-format": "timestamp",
                          type: "date",
                          placeholder: "Pick a day",
                        },
                        model: {
                          value: _vm.form.banner.endAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.banner, "endAt", $$v)
                          },
                          expression: "form.banner.endAt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "Popup (Tỷ lệ: 3/5, khuyến nghị: 700 x 1160, dung lượng tối đa 2MB)",
              },
            },
            [
              _c("SingleImageS3", {
                attrs: {
                  width: 300,
                  height: 120,
                  pathImage: _vm.form.banner.thumbnail,
                },
                on: { "upload:success": _vm.setImage },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }