"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolTypeApi = void 0;
var _request = require("@/utils/request");
var schoolTypeApi = exports.schoolTypeApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/schoolType',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/schoolType',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/schoolType/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/schoolType/".concat(id),
      method: 'delete'
    });
  }
};