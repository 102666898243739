var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driver-item" }, [
    _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("Tên: ")]),
      _c("span", [_vm._v(_vm._s(_vm.driver.name))]),
    ]),
    _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("Biển số: ")]),
      _c("span", [_vm._v(_vm._s(_vm.driver.numberPlates))]),
    ]),
    _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("SDT: ")]),
      _c("span", [_vm._v(_vm._s(_vm.driver.phone))]),
    ]),
    _c("div", [
      _c("label", { attrs: { for: "" } }, [_vm._v("Xe:")]),
      _c("span", [_vm._v(" " + _vm._s(_vm.vehiclesNames))]),
    ]),
    _vm.onlineTime
      ? _c("div", [
          _c("label", { attrs: { for: "" } }, [_vm._v("Online: ")]),
          _c("span", [_vm._v(_vm._s(_vm.$formatDateTime(_vm.onlineTime)))]),
        ])
      : _vm._e(),
    false
      ? _c("div", { staticClass: "avatar" }, [
          _c("img", {
            attrs: { src: _vm.$url.image(_vm.driver.avatar), alt: "" },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }