"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _agentOrder = require("@/types/agentOrder");
var _sponsor = require("@/types/sponsor");
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      selectedImage: "",
      tabName: "detail",
      GenderTypeTrans: _sponsor.GenderTypeTrans,
      OrderReiceptType: _agentOrder.OrderReceiptType
    };
  },
  methods: {
    // async handleViewImage(row) {
    //   this.selectedImage = row.imagePickup;
    //   await this.$nextTick();
    //   this.$refs.imageView.showViewer = true;
    // },
    handleClose: function handleClose() {
      // console.log("first");
      // this.formData = {};
      // this.row = {};
      this.dialogVisible = false;
    },
    handleOpen: function handleOpen(order) {
      console.log({
        order: order
      });
      this.formData = order;
      this.dialogVisible = true;
    }
  }
};