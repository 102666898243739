"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallCategoryApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var mallCategoryApi = exports.mallCategoryApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/mallCategory',
      params: params
    });
  },
  findTrees: function findTrees(params) {
    return (0, _request.default)({
      url: '/mallCategory/trees',
      params: params
    });
  },
  findDescendants: function findDescendants(parentId) {
    return (0, _request.default)({
      url: '/mallCategory/findDescendants',
      params: {
        parentId: parentId
      }
    });
  },
  updateIndex: function updateIndex(data) {
    return (0, _request.default)({
      url: '/mallCategory/index',
      method: 'put',
      data: data
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/mallCategory',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/mallCategory/".concat(id),
      method: 'put',
      data: data
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/mallCategory/".concat(id),
      method: 'delete'
    });
  }
};