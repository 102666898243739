var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.dialogStatus == "add"
            ? "Thêm chủ khách sạn"
            : "Thông tin chủ khách sạn",
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "info", label: "Thông tin" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dialogLoading,
                    expression: "dialogLoading",
                  },
                ],
              },
              [
                _c("HostInfo", {
                  ref: "HostInfo",
                  attrs: {
                    form: _vm.tempData,
                    formDisable: _vm.formDisable,
                    dialogStatus: _vm.dialogStatus,
                  },
                  on: {
                    "update:form": function ($event) {
                      _vm.tempData = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          !_vm.formDisable && _vm.tabActive == "info"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Lưu")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }