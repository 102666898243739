"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletMemberApi = void 0;
var _request = require("@/utils/request");
var walletMemberApi = exports.walletMemberApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/walletMember',
      params: params
    });
  }
};