var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Đã duyệt" } },
            [
              _c("mall-table", {
                attrs: {
                  status: _vm.ProfileStoreStatus.Approve,
                  isGetFromProvider: _vm.isGetFromProvider,
                  handleSelectMall: _vm.handleSelectMall,
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Chờ duyệt" } },
            [
              _c("mall-table", {
                attrs: {
                  status: _vm.ProfileStoreStatus.Pending,
                  isGetFromProvider: _vm.isGetFromProvider,
                  handleSelectMall: _vm.handleSelectMall,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }