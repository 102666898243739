var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("address-filter", {
            attrs: { query: _vm.listQuery },
            on: {
              "change:address": _vm.getTableData,
              "fetch:cities": _vm.setCities,
              "fetch:districts": _vm.setDistricts,
              "fetch:wards": _vm.setWards,
            },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm tên,sđt")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "Trạng thái" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.isBlock,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isBlock", $$v)
                    },
                    expression: "listQuery.isBlock",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", { attrs: { label: "Active", value: false } }),
                  _c("el-option", { attrs: { label: "Block", value: true } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tình trạng")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Tình trạng" },
                  model: {
                    value: _vm.listQuery.isOnline,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isOnline", $$v)
                    },
                    expression: "listQuery.isOnline",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", { attrs: { label: "Online", value: true } }),
                  _c("el-option", {
                    attrs: { label: "Không online", value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Tổ chức (Mã TC/ Tên)"),
              ]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.companySearch,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "companySearch", $$v)
                  },
                  expression: "listQuery.companySearch",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Mã giới thiệu cá nhân"),
              ]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.refCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "refCode", $$v)
                  },
                  expression: "listQuery.refCode",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ngày tạo")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "range-separator": "->",
                  "start-placeholder": "Từ ngày",
                  "end-placeholder": "Đến ngày",
                  format: "dd-MM-yyyy",
                  "value-format": "yyyy-MM-dd",
                },
                on: { change: _vm.queryDate },
                model: {
                  value: _vm.datePicker,
                  callback: function ($$v) {
                    _vm.datePicker = $$v
                  },
                  expression: "datePicker",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.visibleCreate
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.handleAdd()
                    },
                  },
                },
                [_vm._v(" Thêm người dùng ")]
              )
            : _vm._e(),
          _vm.visibleCreate
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleAddExcel },
                },
                [_vm._v(" Thêm người dùng (Excel) ")]
              )
            : _vm._e(),
          _vm.checkPermissionExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExport,
                  },
                  on: { click: _vm.handleExportExcelCustomer },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
          _vm.checkPermissionExportArea
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    type: "primary",
                    loading: _vm.loadingButton,
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.handleExportByArea },
                },
                [_vm._v("Xuất theo khu vực")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              width: "230px",
              prop: "nickname",
              label: "Họ tên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function () {
                            _vm.handleEdit(row)
                            _vm.dialogStatus = "edit"
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(row.name) + " ")]
                    ),
                    !row.isBlock
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" Active "),
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(" Block "),
                        ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" SĐT: ")]),
                      _c("span", [_vm._v(_vm._s(row.phone))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" Email: ")]),
                      _c("span", [_vm._v(_vm._s(row.email))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Ngày sinh: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.birthday))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Mã giới thiệu cá nhân: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.refCode))]),
                      _vm.visibleEditRefCode
                        ? _c("i", {
                            staticClass: "el-icon-edit icon-edit",
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdateRefCode(row)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                    row.parent
                      ? _c(
                          "div",
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Người giới thiệu: "),
                            ]),
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function () {
                                    _vm.handleEdit(row.parent)
                                    _vm.dialogStatus = "edit"
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row.parent.name) + " ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    row.companyRef
                      ? _c(
                          "div",
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(" Tổ chức: "),
                            ]),
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(" " + _vm._s(row.companyRef.name) + " "),
                            ]),
                            _c("br"),
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Mã của tổ chức: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.companyRef.refCode)),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleViewRef(row)
                              },
                            },
                          },
                          [_vm._v(" Danh sách giới thiệu ")]
                        ),
                      ],
                      1
                    ),
                    _vm.checkOnlineStore(row.lastOnlineAt)
                      ? _c("div", [
                          _c("label", [
                            _c("span", { staticClass: "dot online" }),
                            _vm._v(" Đang online: "),
                          ]),
                        ])
                      : _c("div", [
                          _c("label", [
                            _c("span", { staticClass: "dot offline" }),
                            _vm._v(" Không online: "),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formatFromNow(row.lastOnlineAt))),
                          ]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ", width: "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "0" } }, [
                      row.address
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Địa chỉ: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.address))]),
                          ])
                        : _vm._e(),
                      row.city
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tỉnh/ Thành phố: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.city.nameWithType))]),
                          ])
                        : _vm._e(),
                      row.district
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Quận huyện: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.district.nameWithType)),
                            ]),
                          ])
                        : _vm._e(),
                      row.ward
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phường xã: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.ward.nameWithType))]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tủ tiền" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tủ chính: "),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatNumberVN(row.balance)) + "đ"),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tủ khuyến mãi: "),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.balancePromotion)) +
                            "đ"
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Đơn hàng lần cuối", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.lastOrderAt
                      ? _c("div", [
                          _c("p", [
                            _c("b", [_vm._v("Mã đơn: ")]),
                            _vm._v(_vm._s(row.lastOrderCode)),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("Loại đơn: ")]),
                            _vm._v(
                              _vm._s(
                                _vm.orderTypeTrans[row.lastOrderType]
                                  ? _vm.orderTypeTrans[row.lastOrderType]
                                  : row.lastOrderType
                              )
                            ),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("Tổng cộng: ")]),
                            _vm._v(
                              _vm._s(_vm.$formatCurrency(row.lastOrderMoney))
                            ),
                          ]),
                          _c("p", [
                            _c("b", [_vm._v("Ngày mua: ")]),
                            _vm._v(_vm._s(_vm.formatDate(row.lastOrderAt))),
                          ]),
                        ])
                      : _c("div", [_vm._v(" -- ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "180",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          width: "120px !important",
                          display: "block",
                        },
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function () {
                            _vm.handleEdit(row)
                            _vm.dialogStatus = "edit"
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    _vm.visibleChat
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              "margin-top": "4px",
                              "margin-left": "0",
                            },
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-chat-line-round",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleChat(row.id)
                              },
                            },
                          },
                          [_vm._v(" Chat ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          width: "120px !important",
                          display: "block",
                          "margin-top": "4px",
                          "margin-left": "0",
                        },
                        attrs: { type: "success", size: "mini" },
                        on: {
                          click: function () {
                            _vm.resetPassword(row.id)
                          },
                        },
                      },
                      [_vm._v(" Cấp lại mật khẩu ")]
                    ),
                    !row.isBlock
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              "margin-top": "4px",
                              "margin-left": "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleBlock(row)
                              },
                            },
                          },
                          [_vm._v(" Khóa ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUnBlock(row)
                              },
                            },
                          },
                          [_vm._v(" Mở khóa ")]
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("customer-dialog", {
        ref: "user-dialog",
        on: {
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
      _c("customer-ref-dialog", { ref: "customer-ref" }),
      _c("update-ref-code-modal", {
        ref: "UpdateRefCodeModal",
        on: { "submit:ok": _vm.getTableData },
      }),
      _c("import-customer-modal", {
        ref: "ImportCustomerModal",
        on: { "submit:ok": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }