"use strict";

var _interopRequireWildcard = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
var _BarDoubleChart = _interopRequireDefault(require("../BarDoubleChart"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
var vehicleApi = _interopRequireWildcard(require("@/api/vehicle"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    BarChart: _BarDoubleChart.default
  },
  props: ["listQuery"],
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: "Tuần trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "Tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "3 tháng trước",
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      chartData: [],
      vehicles: [],
      vehicleId: null,
      vehicleName: ""
    };
  },
  watch: {
    date: function date(newVal) {
      this.getData();
    },
    vehicleId: function vehicleId(val) {
      var vehicle = this.vehicles.find(function (e) {
        return e.id == val;
      });
      if (vehicle) {
        this.vehicleName = vehicle.name;
      }
      this.getData();
    },
    listQuery: {
      handler: function handler(val) {
        this.getData();
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.fetchVehicle();
  },
  methods: {
    fetchVehicle: function fetchVehicle() {
      var _this = this;
      vehicleApi.get().then(function (res) {
        _this.vehicles = res.data;
        if (res.data.length) {
          _this.vehicleId = res.data[0].id;
          _this.vehicleName = res.data[0].name;
        }
        // this.getData();
      });
    },
    getData: function getData() {
      var _this2 = this;
      var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.listQuery), {}, {
        from: this.date[0],
        to: this.date[1],
        vehicleId: this.vehicleId
      });
      dashboard.salesLast30(data).then(function (res) {
        _this2.chartData = res.data.report;
        _this2.$nextTick().then(function () {
          _this2.$refs["chart"].initChart();
        });
      });
    }
  }
};