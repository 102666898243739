var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Banner dashboard" } },
            [_c("banner-table", { attrs: { type: _vm.BannerType.All } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: "", label: "Banner trong đơn thức ăn" } },
            [_c("banner-table", { attrs: { type: _vm.BannerType.Food } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }