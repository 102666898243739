var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "Thêm mới phụ thu" : "Cập nhật phụ thu",
        visible: _vm.visible,
        width: _vm.dialogWidth,
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          on: { "tab-click": _vm.changeTab },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thông tin", name: "info" } },
            [
              _c("surcharge-info", {
                ref: "surchargeInfo",
                attrs: { form: _vm.form },
              }),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "detail", label: "Chi tiết" } },
                [
                  _c("surcharge-detail", {
                    attrs: { surchargeId: _vm.form.id },
                    on: {
                      "update:surchargeId": function ($event) {
                        return _vm.$set(_vm.form, "id", $event)
                      },
                      "update:surcharge-id": function ($event) {
                        return _vm.$set(_vm.form, "id", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.tabName == "info"
        ? _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingButton, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Lưu")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }