var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Sao chép",
        visible: _vm.visible,
        width: "600px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Từ tỉnh thành", prop: "fromCityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.fromCityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fromCityId", $$v)
                            },
                            expression: "form.fromCityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đến tỉnh thành", prop: "toCityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.toCityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "toCityId", $$v)
                            },
                            expression: "form.toCityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.createData },
            },
            [_vm._v("Sao chép")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }