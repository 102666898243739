"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _deliveryOrder = require("@/types/delivery-order");
var _default = exports.default = {
  components: {
    OrderDeliveryTable: function OrderDeliveryTable() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrderDeliveryTable.vue"));
      });
    }
  },
  data: function data() {
    return {
      OrderDeliveryType: _deliveryOrder.OrderDeliveryType
    };
  }
};