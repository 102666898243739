var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mart-chart" },
    [
      _c(
        "div",
        { staticClass: "date-box" },
        [
          _c("b", { staticClass: "chart-title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": "To",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _c("bar-chart", {
        ref: "chart",
        staticStyle: { width: "100%" },
        attrs: {
          "data-chart": _vm.chartData,
          "pointer-name": "Doanh thu",
          "attr-get": "amount",
          "bar-color": "#34bfa3",
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.chartData = $event
          },
          "update:data-chart": function ($event) {
            _vm.chartData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }