var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "order-detail-list",
        title: "Thông tin xuất hóa đơn VAT",
        visible: _vm.dialogVisible,
        width: "600px",
        "append-to-body": "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-row",
        {
          staticStyle: { border: "1px solid #d4d4d4", "border-bottom": "0px" },
        },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f4f4f4" },
              },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.formData.type == _vm.OrderReiceptType.Company
                        ? "Tên công ty"
                        : "Tên cá nhân"
                    )
                  ),
                ]),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f9f9f9" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.formData.type == _vm.OrderReiceptType.Company
                      ? _vm.formData.companyName
                      : _vm.formData.name
                  )
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { border: "1px solid #d4d4d4", "border-bottom": "0px" },
        },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f4f4f4" },
              },
              [_c("label", [_vm._v("Mã số thuế")])]
            ),
          ]),
          _c("el-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f9f9f9" },
              },
              [_vm._v(_vm._s(_vm.formData.taxCode))]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { border: "1px solid #d4d4d4", "border-bottom": "0px" },
        },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f4f4f4" },
              },
              [_c("label", [_vm._v("Email")])]
            ),
          ]),
          _c("el-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f9f9f9" },
              },
              [_vm._v(_vm._s(_vm.formData.email))]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { border: "1px solid #d4d4d4" } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f4f4f4" },
              },
              [_c("label", [_vm._v("Địa chỉ")])]
            ),
          ]),
          _c("el-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              {
                staticClass: "grid-content",
                staticStyle: { "background-color": "#f9f9f9" },
              },
              [_vm._v(_vm._s(_vm.formData.address))]
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { width: "0", height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }