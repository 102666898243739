var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Trường")]),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  remote: "",
                  "remote-method": _vm.fetchSchools,
                },
                model: {
                  value: _vm.query.schoolId,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "schoolId", $$v)
                  },
                  expression: "query.schoolId",
                },
              },
              _vm._l(_vm.schools, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleImport },
              },
              [_vm._v("Import tuyển sinh")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "60", align: "center", label: "STT" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tên" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trường" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.school
                      ? _c("span", [_vm._v(_vm._s(row.school.name))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Đối tượng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.object))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phương thức tuyển sinh" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.schoolAdmissionMethods, function (item) {
                    return _c("div", { key: item.id }, [
                      _vm._v(_vm._s(item.name)),
                    ])
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Phí đăng ký xét tuyển" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$formatCurrency(row.price))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      { attrs: { type: _vm.getStatusTag(row).type } },
                      [_vm._v(_vm._s(_vm.getStatusTag(row).name))]
                    ),
                    row.status == _vm.SchoolAdmissionSubmitStatus.Reject
                      ? _c("div", [
                          _c("label", [_vm._v("Lý do từ chối: ")]),
                          _vm._v(" " + _vm._s(row.rejectReason) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "120",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px 0" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleView(row)
                              },
                            },
                          },
                          [_vm._v("Chi tiết")]
                        ),
                        _vm.checkPermissionApproveSchoolAdmission &&
                        row.status == _vm.SchoolAdmissionSubmitStatus.Pending
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "5px 0" },
                                    attrs: { type: "success", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApprove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Duyệt")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "5px 0" },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Từ chối")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("import-school-admission-modal", {
        ref: "ImportSchoolAdmissionModal",
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("school-admission-modal", {
        ref: "SchoolAdmission",
        on: { submitOk: _vm.fetchData },
      }),
      _c("approve-school-admission-modal", {
        ref: "ApproveSchoolAdmissionModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }