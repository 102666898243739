var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "application-content" },
      [
        _c("head-section", { attrs: { title: "Giấy giờ khác" } }),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Đã lắp thiết bị GSHT" } },
              [
                _c(
                  "el-radio",
                  {
                    attrs: { label: true },
                    model: {
                      value: _vm.form.isExistGhst,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isExistGhst", $$v)
                      },
                      expression: "form.isExistGhst",
                    },
                  },
                  [_vm._v("Đã lắp đặt")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: { label: false },
                    model: {
                      value: _vm.form.isExistGhst,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isExistGhst", $$v)
                      },
                      expression: "form.isExistGhst",
                    },
                  },
                  [_vm._v("Chưa lắp đặt")]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Giấy đăng ký xe (Mặt trước)",
                  prop: "registerVehiclePaper",
                },
              },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.registerVehiclePaper),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(
                        path,
                        "registerVehiclePaper"
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "Giấy đăng ký xe (Mặt sau)",
                  prop: "registerVehicleAfterPaper",
                },
              },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(
                      _vm.form.registerVehicleAfterPaper
                    ),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(
                        path,
                        "registerVehicleAfterPaper"
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Lý lịch tư pháp" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.judicialBackgroundImage),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(
                        path,
                        "judicialBackgroundImage"
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Giấy khám sức khoẻ" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.healthCertificateImage),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(
                        path,
                        "healthCertificateImage"
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Phù hiệu xe" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.vehicleBadgeImage),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "vehicleBadgeImage")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Lệnh vận tải của HTX" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(
                      _vm.form.transportOrderCooperativeImage
                    ),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(
                        path,
                        "transportOrderCooperativeImage"
                      )
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Giấy kiểm định ô tô" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.verificationPaper),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "verificationPaper")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Ngày hết hạn giấy kiểm định" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "dd/mm/yyyy", clearable: "" },
                  model: {
                    value: _vm.form.verificationPaperExpire,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "verificationPaperExpire", $$v)
                    },
                    expression: "form.verificationPaperExpire",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "BH VC tự nguyện" } },
              [
                _c("single-image", {
                  attrs: { width: 150, height: 150, pathImage: _vm.form.bhvc },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "bhvc")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Ngày hết hạn BH VC tự nguyện" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "dd/mm/yyyy", clearable: "" },
                  model: {
                    value: _vm.form.bhvcExpire,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bhvcExpire", $$v)
                    },
                    expression: "form.bhvcExpire",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "BH TNDS bắt buộc" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.bhtnds),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "bhtnds")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Ngày hết hạn BH TNDS bắt buộc" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "dd/mm/yyyy", clearable: "" },
                  model: {
                    value: _vm.form.bhtndsExpire,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bhtndsExpire", $$v)
                    },
                    expression: "form.bhtndsExpire",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Phù hiệu xe do sở GTVT cấp" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.carBadge),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "carBadge")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Ngày hết hạn phù hiệu xe do sở GTVT cấp" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "dd/mm/yyyy", clearable: "" },
                  model: {
                    value: _vm.form.carBadgeExpire,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "carBadgeExpire", $$v)
                    },
                    expression: "form.carBadgeExpire",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Phí sử dụng đường bộ" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.roadUseFee),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "roadUseFee")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Ngày hết hạn phí sử dụng đường bộ" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "dd/mm/yyyy", clearable: "" },
                  model: {
                    value: _vm.form.roadUseFeeExpire,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roadUseFeeExpire", $$v)
                    },
                    expression: "form.roadUseFeeExpire",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Sử dụng xe uỷ quyền, cho thuê", prop: "" } },
              [
                _c("single-image", {
                  attrs: {
                    width: 150,
                    height: 150,
                    pathImage: _vm.$url.image(_vm.form.useCarImage),
                  },
                  on: {
                    "upload:success": function (path) {
                      return _vm.handleUpdateSuccess(path, "useCarImage")
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btn-save",
            attrs: { type: "primary", size: "medium", loading: _vm.loading },
            on: { click: _vm.handleSubmit },
          },
          [_vm._v("Lưu")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }