var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật coupon" : "Thêm mới coupon",
        visible: _vm.visible,
        width: "1000px",
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { staticStyle: { padding: "1em" }, attrs: { gutter: 50 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Code", prop: "code" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tiêu đề", prop: "title" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Thời gian áp dụng",
                            prop: "datePicker",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "daterange",
                              placeholder: "DD/MM/YYYY",
                              format: "dd/MM/yyyy",
                              "value-format": "yyyy-MM-dd HH:mm",
                            },
                            model: {
                              value: _vm.form.datePicker,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "datePicker", $$v)
                              },
                              expression: "form.datePicker",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số lượng", prop: "quantity" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.form.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "quantity", $$v)
                              },
                              expression: "form.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mô tả", prop: "description" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 3, col: 24 },
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "border-left": "1px solid #efefef" },
                  attrs: { span: 12, offset: 0 },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Giảm giá theo", prop: "type" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: _vm.IdealCouponDiscountType.Percent,
                              },
                              on: {
                                change: function (value) {
                                  return (_vm.form.discountType = value)
                                },
                              },
                              model: {
                                value: _vm.form.discountType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "discountType", $$v)
                                },
                                expression: "form.discountType",
                              },
                            },
                            [_vm._v("Giảm theo phần trăm")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: _vm.IdealCouponDiscountType.Amount,
                              },
                              on: {
                                change: function (value) {
                                  return (_vm.form.discountType = value)
                                },
                              },
                              model: {
                                value: _vm.form.discountType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "discountType", $$v)
                                },
                                expression: "form.discountType",
                              },
                            },
                            [_vm._v("Giảm theo giá tiền")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.form.discountType ==
                              _vm.IdealCouponDiscountType.Percent
                                ? "Phần trăm giảm"
                                : "Số tiền giảm",
                            prop: "discountValue",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: {
                                    alias: "decimal",
                                    groupSeparator: ".",
                                    rightAlign: false,
                                    autoGroup: true,
                                    repeat: 20,
                                    autoUnmask: true,
                                    allowMinus: false,
                                  },
                                  expression:
                                    "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                },
                              ],
                              model: {
                                value: _vm.form.discountValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "discountValue", $$v)
                                },
                                expression: "form.discountValue",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.form.discountType ==
                                      _vm.IdealCouponDiscountType.Percent
                                      ? "%"
                                      : "VND"
                                  )
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Loại ưu đãi", prop: "discountType" },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: _vm.IdealCouponType.ShipFee },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [_vm._v("Giảm phí ship")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: _vm.IdealCouponType.Product },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [_vm._v("Giảm tiền hàng")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "5px" },
                          attrs: {
                            label: "Áp dụng cho đơn hàng từ",
                            prop: "conditionMinPrice",
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: {
                                    alias: "decimal",
                                    groupSeparator: ".",
                                    rightAlign: false,
                                    autoGroup: true,
                                    repeat: 20,
                                    autoUnmask: true,
                                    allowMinus: false,
                                  },
                                  expression:
                                    "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                },
                              ],
                              attrs: { disabled: _vm.noLimitConditionMinPrice },
                              model: {
                                value: _vm.form.conditionMinPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "conditionMinPrice", $$v)
                                },
                                expression: "form.conditionMinPrice",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("VND"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-bottom": "1em" },
                          attrs: { label: true },
                          on: { change: _vm.handleNolimitMinPrice },
                          model: {
                            value: _vm.noLimitConditionMinPrice,
                            callback: function ($$v) {
                              _vm.noLimitConditionMinPrice = $$v
                            },
                            expression: "noLimitConditionMinPrice",
                          },
                        },
                        [_vm._v("Không giới hạn")]
                      ),
                      _vm.form.discountType ==
                      _vm.IdealCouponDiscountType.Percent
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "5px" },
                              attrs: {
                                label: "Giảm tối đa",
                                prop: "discountMaxValue",
                              },
                            },
                            [
                              _c(
                                "el-input",
                                {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: {
                                        alias: "decimal",
                                        groupSeparator: ".",
                                        rightAlign: false,
                                        autoGroup: true,
                                        repeat: 20,
                                        autoUnmask: true,
                                        allowMinus: false,
                                      },
                                      expression:
                                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                    },
                                  ],
                                  attrs: { disabled: _vm.noLimitMaxValue },
                                  model: {
                                    value: _vm.form.discountMaxValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "discountMaxValue",
                                        $$v
                                      )
                                    },
                                    expression: "form.discountMaxValue",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v("VND"),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.discountType ==
                      _vm.IdealCouponDiscountType.Percent
                        ? _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-bottom": "1em" },
                              attrs: { label: true },
                              on: { change: _vm.handleNolimitMaxValue },
                              model: {
                                value: _vm.noLimitMaxValue,
                                callback: function ($$v) {
                                  _vm.noLimitMaxValue = $$v
                                },
                                expression: "noLimitMaxValue",
                              },
                            },
                            [_vm._v("Không giới hạn")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticStyle: {
            display: "flex",
            width: "100%",
            "justify-content": "end",
          },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.status == "create" || _vm.$checkAction("update-coupon")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.status == "create" ? "Tạo" : "Cập nhật"))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }