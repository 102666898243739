var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên trường" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Bậc học")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "", multiple: "", filterable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.schoolLevelIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "schoolLevelIds", $$v)
                  },
                  expression: "query.schoolLevelIds",
                },
              },
              _vm._l(_vm.schoolLevels, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh/thành")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.cityId,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "cityId", $$v)
                  },
                  expression: "query.cityId",
                },
              },
              _vm._l(_vm.cities, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Ngành")]),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  "remote-method": _vm.fetchSchoolMajor,
                  remote: "",
                  multiple: "",
                  filterable: "",
                },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.schoolMajorIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "schoolMajorIds", $$v)
                  },
                  expression: "query.schoolMajorIds",
                },
              },
              _vm._l(_vm.schoolMajors, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Loại trường")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "", multiple: "", filterable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.schoolTypeIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "schoolTypeIds", $$v)
                  },
                  expression: "query.schoolTypeIds",
                },
              },
              _vm._l(_vm.schoolTypes, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Vùng miền")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.regionId,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "regionId", $$v)
                  },
                  expression: "query.regionId",
                },
              },
              _vm._l(_vm.regions, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-school" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Cơ quan QL")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "" },
                on: { change: _vm.fetchData },
                model: {
                  value: _vm.query.organizationId,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "organizationId", $$v)
                  },
                  expression: "query.organizationId",
                },
              },
              _vm._l(_vm.organizations, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
        _vm.status == _vm.SchoolStatus.Approve
          ? _c(
              "div",
              { staticClass: "filter-item filter-button" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "small",
                    },
                    on: { click: _vm.handleCreate },
                  },
                  [_vm._v("Tạo mới")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.status == _vm.SchoolStatus.Approve
          ? _c(
              "div",
              { staticClass: "filter-item filter-button" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "small",
                    },
                    on: { click: _vm.handleImport },
                  },
                  [_vm._v("Import trường học")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "filter-item filter-button" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExport,
                },
                on: { click: _vm.handleExportExcel },
              },
              [_vm._v(" Xuất excel ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              prop: "nickname",
              label: "Tên trường",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", [
                        row.avatar
                          ? _c("img", {
                              staticStyle: {
                                width: "50px",
                                height: "auto",
                                "object-fit": "contain",
                                "margin-right": "10px",
                              },
                              attrs: { src: row.avatar, alt: "" },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUpdate(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(row.name) +
                                    " - " +
                                    _vm._s(row.code) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", [
                          _c("label", [_vm._v("Tên đăng nhập: ")]),
                          _c("span", [_vm._v(_vm._s(row.username))]),
                        ]),
                        _c("div", [
                          _c("label", [_vm._v("Mã trường: ")]),
                          _c("span", [_vm._v(_vm._s(row.code))]),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100px", label: "Cơ quan QL", prop: "address" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.organization
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [_vm._v(" " + _vm._s(row.organization.name) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100px", label: "Vùng miền", prop: "address" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.region
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [_vm._v(" " + _vm._s(row.region.name) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ", prop: "address" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.address) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "100px", label: "Ẩn/Hiện" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isVisible
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "font-weight": "bold" },
                            attrs: { size: "mini", effect: "dark" },
                          },
                          [_vm._v("Hiện")]
                        )
                      : _c(
                          "el-tag",
                          {
                            staticStyle: { "font-weight": "bold" },
                            attrs: {
                              type: "danger",
                              size: "mini",
                              effect: "dark",
                            },
                          },
                          [_vm._v("Ẩn")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "100px", label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: { "font-weight": "bold" },
                        attrs: {
                          type: _vm.StatusTransform[row.status].type,
                          size: "mini",
                          effect: "dark",
                        },
                      },
                      [_vm._v(_vm._s(_vm.StatusTransform[row.status].name))]
                    ),
                    row.status == _vm.SchoolStatus.Reject
                      ? _c(
                          "div",
                          { staticStyle: { "white-space": "pre-line" } },
                          [
                            _c("label", [_vm._v("Lý do từ chối: ")]),
                            _c("span", [_vm._v(_vm._s(row.reasonReject))]),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200", label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDate(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "200",
              "class-name": "small-padding fixed-width",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _vm.checkPermissionApproveSchool &&
                        row.status == _vm.SchoolStatus.Pending
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "flex-direction": "column",
                                  gap: "5px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApprove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Duyệt")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Từ chối")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        row.status == _vm.SchoolStatus.Approve
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: {
                                  type: row.isVisible ? "info" : "primary",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleVisible(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(row.isVisible ? "Ẩn" : "Hiển thị") +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        row.status == _vm.SchoolStatus.Approve
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleResetPassword(row)
                                  },
                                },
                              },
                              [_vm._v(" Cấp lại mật khẩu ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("import-school-modal", {
        ref: "ImportSchoolModal",
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("school-modal", {
        ref: "SchoolModal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }