var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _c("panel-group", { attrs: { listQuery: _vm.listQuery } }),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "20px" }, attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("OrderLast", { attrs: { listQuery: _vm.listQuery } })],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }