var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fan-fclass-customer" },
    _vm._l(_vm.partTimeJobs, function (item, index) {
      return _c("div", { key: index, attrs: { value: true } }, [
        _vm._v("- " + _vm._s(item.name)),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }