"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mallApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var mallApi = exports.mallApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/mall',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/mall/',
      data: data,
      method: 'post'
    });
  },
  online: function online(id) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/online"),
      method: 'post'
    });
  },
  offline: function offline(id) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/offline"),
      method: 'post'
    });
  },
  approve: function approve(mallId) {
    return (0, _request.default)({
      url: "/mall/".concat(mallId, "/approve"),
      method: 'post'
    });
  },
  transaction: function transaction(mallId, params) {
    return (0, _request.default)({
      url: "/mall/".concat(mallId, "/transaction"),
      params: params
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/update"),
      data: data,
      method: 'post'
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/mall/".concat(id),
      method: 'delete'
    });
  },
  block: function block(id) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/block"),
      method: 'put'
    });
  },
  unblock: function unblock(id) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/unblock"),
      method: 'put'
    });
  },
  password: function password(id, data) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/password"),
      method: 'post',
      data: data
    });
  },
  updateRateStar: function updateRateStar(id, data) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/update-rate-star"),
      method: 'post',
      data: data
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/mall/".concat(id, "/cancel"),
      method: 'post'
    });
  }
};