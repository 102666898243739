var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.rewards, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tài xế" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "15px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Tên: ")]),
                        _c("span", [_vm._v(_vm._s(row.driver.name))]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("SĐT: ")]),
                        _c("span", [_vm._v(_vm._s(row.driver.phone))]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("B.Số: ")]),
                        _c("span", [_vm._v(_vm._s(row.driver.numberPlates))]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số tiền", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.$formatNumberVN(row.amount)) + "đ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.DriverRewardTypeTrans[row.type]))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày giờ", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.$formatDateTime(row.date)))]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchRewards,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }