var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm đơn hàng" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Giảng viên")]),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  remote: "",
                  "remote-method": _vm.fetchHost,
                },
                model: {
                  value: _vm.query.eduHostId,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "eduHostId", $$v)
                  },
                  expression: "query.eduHostId",
                },
              },
              _vm._l(_vm.eduHosts, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Phương thức thanh toán"),
            ]),
            _c(
              "el-select",
              {
                staticStyle: { width: "150px" },
                attrs: { clearable: "" },
                model: {
                  value: _vm.query.paymentType,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "paymentType", $$v)
                  },
                  expression: "query.paymentType",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "MoMo", value: _vm.EduOrderPaymentType.Momo },
                }),
                _c("el-option", {
                  attrs: {
                    label: "VNPay",
                    value: _vm.EduOrderPaymentType.VnPay,
                  },
                }),
                _c("el-option", {
                  attrs: {
                    label: "Miễn phí",
                    value: _vm.EduOrderPaymentType.Free,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "left", label: "Code", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticStyle: { color: "#46a6ff" } }, [
                      _vm._v(_vm._s(row.code)),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatDate(row.dateCreated))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.customer.name) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(row.customer.phone) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Chi tiết", "min-width": "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticStyle: { "padding-left": "15px" } },
                      _vm._l(row.eduOrderDetails, function (order) {
                        return _c("li", { key: order.id }, [
                          _c(
                            "div",
                            { staticStyle: { flex: "1", display: "flex" } },
                            [
                              _c("span", { staticStyle: { flex: "1" } }, [
                                _vm._v(_vm._s(order.eduCourse.name)),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$formatCurrency(order.price))
                                ),
                              ]),
                            ]
                          ),
                          _c("span", [
                            _c("b", [_vm._v("Giáo viên: ")]),
                            _vm._v(
                              " " +
                                _vm._s(order.eduHost ? order.eduHost.name : "")
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin thanh toán", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" Đơn vị thanh toán: "),
                    _c("b", [_vm._v(_vm._s(row.paymentType))]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" Trạng thái: "),
                    _c("el-tag", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.PaymentStatusTrans[row.paymentStatus])
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Doanh thu", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%",
                        },
                      },
                      [
                        _c("b", [_vm._v("Tạm tính")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.subMoneyTotal))),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%",
                        },
                      },
                      [
                        _c("b", [_vm._v("Giảm giá")]),
                        _c("span", [
                          _vm._v(
                            "-" + _vm._s(_vm.formatCurrency(row.moneyDiscount))
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "100%",
                          "border-top": "1px solid #efefef",
                        },
                      },
                      [
                        _c("b", [_vm._v("Tổng cộng")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyFinal))),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Chiết khấu", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatCurrency(row.moneyCommission)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thực thu", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatCurrency(
                            row.moneyFinal - row.moneyCommission
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }