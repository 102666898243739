var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update" ? "Cập nhật tiện ích" : "Thêm mới tiện ích",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name", label: "Tên tiện ích" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _vm.parentId != null
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "icon",
                    label:
                      "Hình icon (Tỉ lệ: 1/1 - khuyến nghị: 300 x 300, dung lượng tối đa 2MB)",
                  },
                },
                [
                  _c("SingleImageS3", {
                    attrs: {
                      pathImage: _vm.form.icon,
                      width: 150,
                      height: 150,
                    },
                    on: { "upload:success": _vm.setImage },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }