var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "companyName", label: "Tên Công ty/Hộ Cá thể" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyName", $$v)
                  },
                  expression: "form.companyName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "companyPhone", label: "Số điện thoại" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyPhone", $$v)
                  },
                  expression: "form.companyPhone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "companyTaxCode", label: "Mã số thuế" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyTaxCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyTaxCode", $$v)
                  },
                  expression: "form.companyTaxCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Địa chỉ", prop: "companyAddress" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyAddress", $$v)
                  },
                  expression: "form.companyAddress",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Thành phố", prop: "companyCityId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", disabled: _vm.formDisable },
                  on: { change: _vm.handleChangeCity },
                  model: {
                    value: _vm.form.companyCityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "companyCityId", $$v)
                    },
                    expression: "form.companyCityId",
                  },
                },
                _vm._l(_vm.cities, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Quận huyện", prop: "companyDistrictId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    disabled: _vm.formDisable,
                  },
                  on: { change: _vm.handleChangeDistrict },
                  model: {
                    value: _vm.form.companyDistrictId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "companyDistrictId", $$v)
                    },
                    expression: "form.companyDistrictId",
                  },
                },
                _vm._l(_vm.districts, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Phường xã", prop: "companyWardId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    disabled: _vm.formDisable,
                  },
                  model: {
                    value: _vm.form.companyWardId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "companyWardId", $$v)
                    },
                    expression: "form.companyWardId",
                  },
                },
                _vm._l(_vm.wards, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.nameWithType, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 16, offset: 0 } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "companyEmail", label: "Email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyEmail", $$v)
                  },
                  expression: "form.companyEmail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "companyBankNumber",
                label: "Tài khoản ngân hàng số",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.companyBankNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyBankNumber", $$v)
                  },
                  expression: "form.companyBankNumber",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "companyBankName", label: "Tại ngân hàng" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    "allow-create": "",
                    placeholder: "Tìm hoặc hoặc nhập trực tiếp",
                  },
                  model: {
                    value: _vm.form.companyBankName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "companyBankName", $$v)
                    },
                    expression: "form.companyBankName",
                  },
                },
                _vm._l(_vm.bankList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "companyCertification",
                label: "Hình ảnh Giấy CNĐK Kinh doanh",
              },
            },
            [
              _c("single-image-s-3", {
                attrs: {
                  pathImage: _vm.form.companyCertification,
                  width: 200,
                  height: 200,
                },
                on: { "upload:success": _vm.handleUploadSuccess },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "companyCertification2",
                label: "Hình ảnh Giấy CNĐK Kinh doanh 2",
              },
            },
            [
              _c("single-image-s-3", {
                attrs: {
                  pathImage: _vm.form.companyCertification2,
                  width: 200,
                  height: 200,
                },
                on: { "upload:success": _vm.handleUploadSuccess2 },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }