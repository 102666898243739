var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "lecture-table" } },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm tên video" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.query.search,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "search", $$v)
                },
                expression: "query.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Khóa học")]),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  filterable: "",
                  remote: "",
                  "remote-method": _vm.fetchCourse,
                },
                model: {
                  value: _vm.query.eduCourseId,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "eduCourseId", $$v)
                  },
                  expression: "query.eduCourseId",
                },
              },
              _vm._l(_vm.eduCourse, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item btn" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm kiếm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "60", align: "center", label: "STT" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Video", prop: "url" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "info-box" }, [
                      _c(
                        "div",
                        {
                          staticClass: "info",
                          on: {
                            click: function ($event) {
                              return _vm.handleOpenVideo(row.sourceUrl)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "name" }, [
                            _c("i", { staticClass: "el-icon-video-play" }),
                            _vm._v("Xem video"),
                          ]),
                        ]
                      ),
                      _c("video", {
                        staticClass: "video",
                        staticStyle: { "object-fit": "cover" },
                        attrs: { src: row.sourceUrl, alt: "" },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tên video", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.name) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khoá học", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.eduCourseSection
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.eduCourseSection.eduCourse
                                  ? row.eduCourseSection.eduCourse.name
                                  : ""
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời lượng", prop: "duration" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.getTimeFormat(row.duration)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Ngày tạo: ")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Hành động",
              width: "160",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: {
                              type: "primary",
                              size: "mini",
                              icon: "el-icon-video-play",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleOpenVideo(row.sourceUrl)
                              },
                            },
                          },
                          [_vm._v("Xem video")]
                        ),
                        row.status == _vm.EduLectureStatus.Pending
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleApprove(row)
                                  },
                                },
                              },
                              [_vm._v("Duyệt")]
                            )
                          : _vm._e(),
                        row.status == _vm.EduLectureStatus.Pending
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReject(row)
                                  },
                                },
                              },
                              [_vm._v("Từ chối")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("VideoModal", {
        ref: "VideoModal",
        attrs: { title: "Video bài học" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }