var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { attrs: { for: "" } }, [_vm._v("JSON:")]),
      _c("el-input", {
        attrs: { autosize: { minRows: 4, maxRows: 10 }, type: "textarea" },
        model: {
          value: _vm.json,
          callback: function ($$v) {
            _vm.json = $$v
          },
          expression: "json",
        },
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "12px" } },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "Tên cột. Ex: vi" },
            model: {
              value: _vm.column,
              callback: function ($$v) {
                _vm.column = $$v
              },
              expression: "column",
            },
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleGetData },
            },
            [_vm._v("Download excel")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }