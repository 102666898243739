var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-form" },
    [
      _vm._m(0),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên đăng nhập", prop: "username" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Mật khẩu", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { "show-password": "" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("img", {
        staticStyle: { "vertical-align": "middle" },
        attrs: { width: "40", src: require("@/assets/images/icon3.png") },
      }),
      _c("span", { staticStyle: { "vertical-align": "middle" } }, [
        _vm._v("Thông tin đăng nhập"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }