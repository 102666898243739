var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-item" }, [
    _c("div", { staticClass: "prefix" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("div", { staticClass: "center" }, [
      !_vm.completed
        ? _c(
            "span",
            {
              class: {
                required: _vm.required,
                "non-required": !_vm.required,
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.required ? "Bắt buộc" : "Không bắt buộc") + " "
              ),
            ]
          )
        : _c(
            "span",
            {
              class: {
                completed: _vm.completed,
              },
            },
            [_vm._v(" Hoàn tất ")]
          ),
    ]),
    _c(
      "div",
      { staticClass: "suffix" },
      [_c("font-awesome-icon", { attrs: { icon: "chevron-right" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }