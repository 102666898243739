var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.modalTitle,
        visible: _vm.visible,
        width: "900px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hình ảnh", prop: "image" } },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.image,
                          width: 150,
                          height: 150,
                        },
                        on: { "upload:success": _vm.handleUploadSuccess },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Tên chương trình " +
                          (_vm.type == _vm.ScholarshipType.ScholarShip
                            ? "học bổng"
                            : "khuyến học/thiện nguyện"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.ScholarshipType.ScholarShip
                            ? "Loại học bổng"
                            : "Loại khuyến học/thiện nguyện",
                        prop: "scholarshipClassifyId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.scholarshipClassifyId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "scholarshipClassifyId", $$v)
                            },
                            expression: "form.scholarshipClassifyId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: 0 },
                          }),
                          _vm._l(_vm.scholarshipClassify, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.ScholarshipType.ScholarShip
                            ? "Trị giá học bổng"
                            : "Trị giá khuyến học/thiện nguyện",
                        prop: "value",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "value", $$v)
                          },
                          expression: "form.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đơn vị tính", prop: "currencyUnit" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.currencyUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "currencyUnit", $$v)
                            },
                            expression: "form.currencyUnit",
                          },
                        },
                        [
                          _vm._l(_vm.currencyUnit, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: item },
                            })
                          }),
                          _vm.type != _vm.ScholarshipType.ScholarShip
                            ? _vm._l(_vm.studyCurrencyUnit, function (item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item },
                                })
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.ScholarshipType.ScholarShip
                            ? "Điều kiện và tiêu chí"
                            : "Điều kiện xét, cấp học bổng khuyến khích học tập với học sinh, sinh viên",
                        prop: "conditionContent",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.conditionContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "conditionContent", $$v)
                          },
                          expression: "form.conditionContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.ScholarshipType.ScholarShip
                            ? "Số suất học bổng"
                            : "Số suất khuyến học/thiện nguyện",
                        prop: "quantity",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "quantity", $$v)
                          },
                          expression: "form.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Hiệu lực đến", prop: "expiredAt" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "Chọn một ngày",
                          format: "dd/MM/yyyy",
                        },
                        model: {
                          value: _vm.form.expiredAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expiredAt", $$v)
                          },
                          expression: "form.expiredAt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đối tượng", prop: "objectName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.objectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "objectName", $$v)
                          },
                          expression: "form.objectName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 10, align: "bottom", type: "flex" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Độ tuổi", prop: "fromAge" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.fromAge,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fromAge", $$v)
                                  },
                                  expression: "form.fromAge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "toAge" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.toAge,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "toAge", $$v)
                                  },
                                  expression: "form.toAge",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Mô tả đối tượng",
                        prop: "contentObject",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.contentObject,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contentObject", $$v)
                          },
                          expression: "form.contentObject",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Hồ sơ " +
                          (_vm.type == _vm.ScholarshipType.ScholarShip
                            ? "Học bổng"
                            : "Khuyến học/Thiện nguyện") +
                          " (Tải lên file PDF)",
                      },
                    },
                    [
                      _c("upload-file", {
                        attrs: {
                          maxFileSize: 20,
                          drag: false,
                          file: _vm.form.profileUrl,
                          accept: ".pdf",
                        },
                        on: {
                          "upload-success": function (path) {
                            return _vm.$set(_vm.form, "profileUrl", path)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trường", prop: "schoolId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            remote: true,
                            "remote-method": _vm.fetchSchool,
                          },
                          model: {
                            value: _vm.form.schoolId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "schoolId", $$v)
                            },
                            expression: "form.schoolId",
                          },
                        },
                        _vm._l(_vm.schools, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Bậc học", prop: "schoolLevelId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            remote: true,
                            "remote-method": _vm.fetchSchoolLevel,
                          },
                          model: {
                            value: _vm.form.schoolLevelId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "schoolLevelId", $$v)
                            },
                            expression: "form.schoolLevelId",
                          },
                        },
                        _vm._l(_vm.schoolLevels, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Địa điểm", prop: "street" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.street,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "street", $$v)
                          },
                          expression: "form.street",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quốc gia", prop: "nationId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            remote: true,
                            "remote-method": _vm.fetchNation,
                          },
                          model: {
                            value: _vm.form.nationId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "nationId", $$v)
                            },
                            expression: "form.nationId",
                          },
                        },
                        _vm._l(_vm.nations, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thành phố", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Kỳ nhập học" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "MM/yyyy",
                          type: "monthrange",
                          "start-placeholder": "Từ ngày",
                          "end-placeholder": "Đến ngày",
                          clearable: false,
                        },
                        model: {
                          value: _vm.studyDate,
                          callback: function ($$v) {
                            _vm.studyDate = $$v
                          },
                          expression: "studyDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Giới thiệu về " +
                          (_vm.type == _vm.ScholarshipType.ScholarShip
                            ? "học bổng"
                            : "khuyến học/thiện nguyện"),
                        prop: "desc",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.type == _vm.ScholarshipType.ScholarShip
                            ? "Điều kiện gia hạn học bổng"
                            : "Điều kiện gia hạn khuyến học/thiện nguyện",
                        prop: "contentRenew",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.contentRenew,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contentRenew", $$v)
                          },
                          expression: "form.contentRenew",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.type == _vm.ScholarshipType.ScholarShip
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "Link học bổng", prop: "otherLink" },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.otherLink,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "otherLink", $$v)
                              },
                              expression: "form.otherLink",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }