var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Thời gian")]),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "range-separator": "|",
                format: "dd/MM/yyyy",
                "start-placeholder": "Từ ngày",
                "end-placeholder": "Đến ngày",
                clearable: false,
              },
              on: { change: _vm.fetchData },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { loading: _vm.loadingExport, type: "primary" },
                on: { click: _vm.handleExport },
              },
              [_vm._v("Xuất Excel")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        _vm._l(_vm.columns, function (col, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              "min-width": "50px",
              width: col.label.length * 6 + 50 + "px",
              label: col.label,
              prop: col.prop,
              align: col.align || "left",
            },
          })
        }),
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }