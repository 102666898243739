var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: "Danh sách giới thiệu",
        visible: _vm.dialogVisible,
        width: "900px",
        fullscreen: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Khách hàng", name: "customer" } },
            [
              _c("company-ref-table", {
                attrs: { type: "customer", companyId: _vm.form.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Tài xế", name: "driver" } },
            [
              _c("company-ref-table", {
                attrs: { type: "driver", companyId: _vm.form.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Cửa hàng F&B", name: "store" } },
            [
              _c("company-ref-table", {
                attrs: { type: "store", companyId: _vm.form.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Cửa hàng Mall", name: "mall" } },
            [
              _c("company-ref-table", {
                attrs: { type: "mall", companyId: _vm.form.id },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }