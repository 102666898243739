"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerJobType = void 0;
var CustomerJobType;
(function (CustomerJobType) {
  CustomerJobType["Work"] = "WORK";
  CustomerJobType["Student"] = "STUDENT"; // đi học
})(CustomerJobType || (exports.CustomerJobType = CustomerJobType = {}));