var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tìm tiêu đề" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getList($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Ngày")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "To",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "Tất cả" },
                  on: { change: _vm.getList },
                  model: {
                    value: _vm.listQuery.isShow,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isShow", $$v)
                    },
                    expression: "listQuery.isShow",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", { attrs: { label: "Ẩn", value: false } }),
                  _c("el-option", { attrs: { label: "Hiện", value: true } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$checkAction("add-banner")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" Thêm Banner ")]
              )
            : _vm._e(),
          _vm.checkPermissionExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExport,
                  },
                  on: { click: _vm.handleExportExcel },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Tiêu đề" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticStyle: { "border-radius": "8px" },
                      attrs: {
                        src: _vm.$url.image(row.image),
                        width: "150",
                        alt: "",
                      },
                    }),
                    _c("br"),
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Vị trí" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.bannerLayoutTrans[row.layout].title) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thứ tự hiển thị", prop: "position" },
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-style-none" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Ngày tạo: "),
                        ]),
                        _vm._v(_vm._s(_vm.$formatDateTime(row.dateCreated))),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Bắt đầu: "),
                        ]),
                        _vm._v(
                          _vm._s(_vm.$formatDateTime(row.startAt, "DD/MM/YYYY"))
                        ),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Kết thúc: "),
                        ]),
                        _vm._v(
                          _vm._s(_vm.$formatDateTime(row.endAt, "DD/MM/YYYY"))
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isShow == false
                      ? _c(
                          "el-tag",
                          { attrs: { type: "danger", size: "normal" } },
                          [_c("b", [_vm._v("Đã ẩn")])]
                        )
                      : _c(
                          "el-tag",
                          { attrs: { type: "success", size: "normal" } },
                          [_c("b", [_vm._v("Đã hiện")])]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    !row.isShow
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(row)
                              },
                            },
                          },
                          [_vm._v(" Hiện ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleHide(row)
                              },
                            },
                          },
                          [_vm._v(" Ẩn ")]
                        ),
                    _vm.$checkAction("delete-banner")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" Xóa ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
            visible: _vm.dialogVisible,
            width: "700px",
            top: "15px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tiêu đề",
                                        prop: "title",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "title", $$v)
                                          },
                                          expression: "formData.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Vị trí hiển thị",
                                        prop: "layout",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            "value-key": "",
                                            placeholder: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.formData.layout,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "layout",
                                                $$v
                                              )
                                            },
                                            expression: "formData.layout",
                                          },
                                        },
                                        _vm._l(
                                          _vm.bannerLayoutTrans,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.title,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thứ tự hiển thị",
                                        prop: "position",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.position,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "position",
                                              $$v
                                            )
                                          },
                                          expression: "formData.position",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Sản phẩm",
                                        prop: "idealProductId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "value-key": "",
                                            placeholder: "Không chọn sản phẩm",
                                            clearable: "",
                                            filterable: "",
                                            remote: "",
                                            "remote-method":
                                              _vm.handleSearchProduct,
                                          },
                                          model: {
                                            value: _vm.formData.idealProductId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "idealProductId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.idealProductId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.products,
                                          function (product) {
                                            return _c("el-option", {
                                              key: product.id,
                                              attrs: {
                                                label: product.name,
                                                value: product.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thời gian diễn ra",
                                        prop: "datePicker",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "->",
                                          "start-placeholder": "Từ ngày",
                                          "end-placeholder": "Đến ngày",
                                          format: "dd-MM-yyyy",
                                        },
                                        model: {
                                          value: _vm.formData.datePicker,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "datePicker",
                                              $$v
                                            )
                                          },
                                          expression: "formData.datePicker",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { span: 24 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Hình ảnh (Tỉ lệ 4/1, khuyến nghị 1000 x 250, dung lượng tối đa 2MB)",
                                        prop: "image",
                                      },
                                    },
                                    [
                                      _c("SingleImageS3", {
                                        attrs: {
                                          pathImage: _vm.formData.image,
                                          width: 450,
                                          height: 150,
                                        },
                                        on: {
                                          "upload:success":
                                            _vm.handleUploadSuccess,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm.dialogStatus == "create" || _vm.$checkAction("update-banner")
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.submitLoading, type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus == "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("Đồng ý")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }