var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Chính sách hủy" } },
            [
              _c("booking-policy-table", {
                attrs: { type: _vm.BookingPolicyType.Cancel },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "An toàn" } },
            [
              _c("booking-policy-table", {
                attrs: { type: _vm.BookingPolicyType.Safe },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Quy định" } },
            [
              _c("booking-policy-table", {
                attrs: { type: _vm.BookingPolicyType.Regular },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Điều kiện đặt phòng" } },
            [
              _c("booking-policy-table", {
                attrs: { type: _vm.BookingPolicyType.PreconditionBooking },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }