var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật tin tặng quà"
            : "Thêm mới tin tặng quà",
        visible: _vm.visible,
        width: "800px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _c("h3", { staticClass: "color-blue" }, [
                      _vm._v("Thông tin đơn vị khuyến học/thiện nguyện"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Bên giao quà KH",
                        prop: "peopleGiftName",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.peopleGiftName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "peopleGiftName", $$v)
                          },
                          expression: "form.peopleGiftName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số điện thoại", prop: "contactPhone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.contactPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contactPhone", $$v)
                          },
                          expression: "form.contactPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _c("h3", { staticClass: "color-blue" }, [
                      _vm._v("Thông tin cá nhân nhận khuyến học/thiện nguyện"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Quà tặng cho",
                        prop: "sponsorGiftObjectIds",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.sponsorGiftObjectIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sponsorGiftObjectIds", $$v)
                            },
                            expression: "form.sponsorGiftObjectIds",
                          },
                        },
                        _vm._l(_vm.sponsorGiftObjects, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Giới tính", prop: "gender" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender",
                          },
                        },
                        [
                          _vm._l(_vm.GenderTypeTrans, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          _c("el-option", {
                            attrs: { label: "Tất cả", value: null },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Độ tuổi", prop: "sponsorGiftAgeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.sponsorGiftAgeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sponsorGiftAgeId", $$v)
                            },
                            expression: "form.sponsorGiftAgeId",
                          },
                        },
                        _vm._l(_vm.sponsorGiftAges, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thuộc đơn vị", prop: "tenDonVi" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.tenDonVi,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tenDonVi", $$v)
                          },
                          expression: "form.tenDonVi",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ở tại số", prop: "giftAddress" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.giftAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "giftAddress", $$v)
                          },
                          expression: "form.giftAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tỉnh/Thành", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận/Huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường/xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _c("h3", { staticClass: "color-blue" }, [
                      _vm._v("Thông tin quà khuyến học/thiện nguyện"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên quà tặng", prop: "giftName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.giftName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "giftName", $$v)
                          },
                          expression: "form.giftName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đơn vị tính", prop: "unit" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "unit", $$v)
                          },
                          expression: "form.unit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sô lượng", prop: "quantity" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.quantity,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "quantity", $$v)
                          },
                          expression: "form.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đơn giá", prop: "price" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "price", $$v)
                          },
                          expression: "form.price",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tổng giá trị", prop: "totalMoney" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.totalMoney,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "totalMoney", $$v)
                          },
                          expression: "form.totalMoney",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Số lượng của 01 phần quà KH",
                        prop: "quantityOnPerson",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.quantityOnPerson,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "quantityOnPerson", $$v)
                          },
                          expression: "form.quantityOnPerson",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tổng số phần quà" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        attrs: {
                          disabled: "",
                          value: Math.floor(
                            _vm.form.quantity / _vm.form.quantityOnPerson
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ngày hết hạn đăng ký",
                        prop: "expiredAt",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "dd/MM/yyyy",
                          "value-format": "timestamp",
                        },
                        model: {
                          value: _vm.form.expiredAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expiredAt", $$v)
                          },
                          expression: "form.expiredAt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _c("h3", { staticClass: "color-blue" }, [
                      _vm._v("Quy định về nhận quà khuyến học/thiện nguyện "),
                      _c("span", { staticStyle: { color: "#000" } }, [
                        _vm._v("(chọn 1 hay nhiều ô sau)"),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    _vm._l(_vm.scholarshipCondition, function (item) {
                      return _c(
                        "el-col",
                        {
                          key: item.id,
                          staticStyle: { "min-height": "51px" },
                          attrs: { span: 12 },
                        },
                        [
                          _c("CriteriaItem", {
                            attrs: {
                              form: _vm.form,
                              data: item,
                              select: _vm.getSelectCondition(item.id),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.tabActive != "branch"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }