var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "table-custom ",
      attrs: {
        id: "food-detail-list",
        title: "Chi tiết đơn - " + _vm.formData.code,
        visible: _vm.dialogVisible,
        width: "1400px",
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "detail", label: "Chi tiết đơn" } },
            [
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c("ul", { staticClass: "payment-detail" }, [
                    _c("li", [
                      _c("span", [_vm._v("Trạng thái thanh toán:")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.PaymentStatusTrans[_vm.formData.paymentStatus]
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "li",
                      [
                        _c("span", [_vm._v("Trạng thái đơn:")]),
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                _vm.orderTagOptions[_vm.formData.status] &&
                                _vm.orderTagOptions[_vm.formData.status].type,
                              size: "mini",
                              effect: "dark",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.IdealOrderStatusTrans[_vm.formData.status]
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("li", [
                      _c("span", [
                        _vm._v(
                          "Khuyến mãi " +
                            _vm._s(
                              _vm.formData.moneyDiscount > 0
                                ? "(Tiền hàng)"
                                : _vm.formData.moneyDiscountShipFee > 0
                                ? "(Phí ship)"
                                : ""
                            ) +
                            ":"
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.formData.moneyDiscount > 0
                              ? _vm.$formatCurrency(_vm.formData.moneyDiscount)
                              : _vm.$formatCurrency(
                                  _vm.formData.moneyDiscountShipFee
                                )
                          )
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("Tổng tiền hàng:")]),
                      _c("span", { staticClass: "warning" }, [
                        _vm._v(
                          _vm._s(_vm.$formatCurrency(_vm.formData.moneyProduct))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [_vm._v("Phí vận chuyển:")]),
                      _c("span", { staticClass: "warning" }, [
                        _vm._v(
                          _vm._s(_vm.$formatCurrency(_vm.formData.shipFee))
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("span", [
                        _vm._v(
                          "Người Dùng trả (" +
                            _vm._s(
                              _vm.PaymentTypeTrans[_vm.formData.paymentType]
                            ) +
                            "):"
                        ),
                      ]),
                      _c("span", { staticClass: "warning" }, [
                        _vm._v(
                          _vm._s(_vm.$formatCurrency(_vm.formData.moneyFinal))
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.formData.customer
                    ? _c("ul", { staticClass: "payment-detail" }, [
                        _vm.formData.customer.name.trim() !=
                        _vm.formData.receiverName.trim()
                          ? _c("li", [
                              _c("span", [_vm._v("Khách hàng:")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.formData.customer.name)),
                              ]),
                            ])
                          : _vm._e(),
                        _c("li", [
                          _c("span", [_vm._v("Người nhận đơn:")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formData.receiverName)),
                          ]),
                        ]),
                        _c("li", [
                          _c("span", [_vm._v("Số điện thoại:")]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formData.receiverPhone)),
                          ]),
                        ]),
                        _c("li", [
                          _c("span", [_vm._v("Đơn vị giao hàng:")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.carrierTypeTrans[_vm.formData.carrierType]
                              )
                            ),
                          ]),
                        ]),
                        _vm.formData.carrierType != _vm.CarrierType.Manual
                          ? _c("li", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "20px",
                                    "white-space": "nowrap",
                                  },
                                },
                                [_vm._v("Địa chỉ giao hàng:")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getFullAddress(
                                        _vm.formData,
                                        "RECEIVER"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.formData.reviewStar > 0
                ? _c("review-panel", {
                    staticStyle: { "margin-bottom": "12px" },
                    attrs: {
                      star: _vm.formData.reviewStar,
                      content: _vm.formData.reviewContent,
                    },
                  })
                : _vm._e(),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.formData.idealOrderDetails,
                    height: "60vh",
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "text-center",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    staticStyle: { "vertical-align": "initial" },
                    attrs: {
                      "class-name": "vertical-start",
                      prop: "name",
                      label: "Tên hàng",
                      align: "center",
                      width: "270",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "info-box" }, [
                              _c("div", {
                                staticClass: "info",
                                staticStyle: { "flex-direction": "column" },
                              }),
                              row.idealProduct
                                ? _c("img", {
                                    staticClass: "image",
                                    staticStyle: { "object-fit": "cover" },
                                    attrs: {
                                      src: _vm.$url.image(
                                        row.idealProduct.imageUrl
                                      ),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            row.idealProduct
                              ? _c(
                                  "h3",
                                  {
                                    staticClass: "name",
                                    staticStyle: { "margin-bottom": "0" },
                                  },
                                  [_vm._v(_vm._s(row.idealProduct.name))]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "name",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-bottom": "0",
                                },
                              },
                              [_vm._v(_vm._s(row.idealProduct.code))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.formData.productType ==
                  _vm.MallOrderProductType.MallProduct
                    ? _c("el-table-column", {
                        attrs: { label: "Phân loại" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.attr1Name
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.attr1Name) +
                                            ": " +
                                            _vm._s(row.attr1Value) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  row.attr2Name
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.attr2Name) +
                                            ": " +
                                            _vm._s(row.attr2Value) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1148552561
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "Số lượng",
                      prop: "quantity",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatNumberVN(row.quantity)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Phần thưởng",
                      prop: "quantity",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              _vm._l(
                                row.idealProduct.idealPrizes,
                                function (prize) {
                                  return _c(
                                    "p",
                                    {
                                      key: prize.id,
                                      staticStyle: { "margin-bottom": "0" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(prize.name) +
                                          " (" +
                                          _vm._s(prize.used) +
                                          "/" +
                                          _vm._s(prize.quantity) +
                                          ") "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Ngày bốc thăm",
                      prop: "quantity",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.$formatDateTime(
                                  row.idealProduct.maxDrawDateAt
                                )
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Giá", align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$formatNumberVN(row.price)) + "đ"
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Thành tiền" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatNumberVN(row.quantity * row.price)
                                ) + "đ"
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Mã dự thưởng" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function () {
                                    return _vm.handleViewCodeList(row.id)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-info" }),
                                _vm._v(" Xem mã dự thưởng "),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "log", label: "Logs" } },
            [
              _c("ideal-order-logging", {
                attrs: { idealProductId: _vm.formData.id },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ideal-code-list-modal", { ref: "idealCodeListRef" }),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { width: "0", height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }