var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tìm tiêu đề" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getList($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _vm.$checkAction("add-banner")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { transform: "translateY(10px)" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" Thêm Banner ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "Tiêu đề" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("img", {
                      staticStyle: { "border-radius": "8px" },
                      attrs: {
                        src: _vm.$url.image(row.image),
                        width: "150",
                        alt: "",
                      },
                    }),
                    _c("br"),
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.title)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mô tả ngắn" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.shortDesc) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "right",
              label: "Thứ tự hiển thị",
              prop: "position",
              width: "150px",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Vị trí", prop: "layout", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.CareerBannerLayoutTrans[row.layout])),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Loại banner", align: "center", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-tag", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.schoolBannerContentTypeTrans[row.contentType]
                            .label
                        )
                      ),
                    ]),
                    row.contentType == _vm.schoolBannerContentType.Link
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "text-decoration": "underline",
                                color: "#4e90ff",
                              },
                              attrs: { href: row.link, target: "_blank" },
                            },
                            [_vm._v("Truy cập liên kết")]
                          ),
                        ])
                      : _vm._e(),
                    row.contentType == _vm.schoolBannerContentType.School
                      ? _c("div", [_vm._v(_vm._s(row.school.name))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.isShow == false
                      ? _c(
                          "el-tag",
                          { attrs: { type: "danger", size: "normal" } },
                          [_c("b", [_vm._v("Đã ẩn")])]
                        )
                      : _c(
                          "el-tag",
                          { attrs: { type: "success", size: "normal" } },
                          [_c("b", [_vm._v("Đã hiện")])]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "230" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" Chi tiết ")]
                    ),
                    !row.isShow
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(row)
                              },
                            },
                          },
                          [_vm._v(" Hiện ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleHide(row)
                              },
                            },
                          },
                          [_vm._v(" Ẩn ")]
                        ),
                    _vm.$checkAction("delete-banner")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v(" Xóa ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title:
              _vm.dialogStatus == "update" ? "Cập nhật banner" : "Thêm banner",
            visible: _vm.dialogVisible,
            width: "700px",
            top: "15px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "text-center", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Hình ảnh (Tỉ lệ 4/1, khuyến nghị 428 x 100, dung lượng tối đa 2MB)",
                                prop: "image",
                              },
                            },
                            [
                              _c("SingleImageS3", {
                                attrs: {
                                  pathImage: _vm.formData.image,
                                  width: 450,
                                  height: 150,
                                },
                                on: {
                                  "upload:success": _vm.handleUploadSuccess,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tiêu đề",
                                        prop: "title",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formData, "title", $$v)
                                          },
                                          expression: "formData.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Mô tả ngắn",
                                        prop: "shortDesc",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.shortDesc,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "shortDesc",
                                              $$v
                                            )
                                          },
                                          expression: "formData.shortDesc",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Thứ tự hiển thị",
                                        prop: "position",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.formData.position,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "position",
                                              $$v
                                            )
                                          },
                                          expression: "formData.position",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Vị trí",
                                        prop: "layout",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { filterable: "" },
                                          model: {
                                            value: _vm.formData.layout,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "layout",
                                                $$v
                                              )
                                            },
                                            expression: "formData.layout",
                                          },
                                        },
                                        _vm._l(
                                          _vm.CareerBannerLayout,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label:
                                                  _vm.CareerBannerLayoutTrans[
                                                    item
                                                  ],
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Loại banner",
                                        prop: "contentType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "value-key": "",
                                            placeholder: "",
                                            clearable: "",
                                            filterable: "",
                                            remote: "",
                                            "remote-method":
                                              _vm.handleSearchSchool,
                                          },
                                          model: {
                                            value: _vm.formData.contentType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "contentType",
                                                $$v
                                              )
                                            },
                                            expression: "formData.contentType",
                                          },
                                        },
                                        _vm._l(
                                          Object.values(
                                            _vm.schoolBannerContentTypeTrans
                                          ),
                                          function (type) {
                                            return _c(
                                              "el-option",
                                              {
                                                key: type.value,
                                                attrs: {
                                                  label: type.label,
                                                  value: type.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(type.label) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.formData.contentType ==
                                  _vm.schoolBannerContentType.Content
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Mô tả",
                                            prop: "desc",
                                          },
                                        },
                                        [
                                          _c("Tinymce", {
                                            ref: "editor",
                                            attrs: {
                                              width: "100%",
                                              height: 400,
                                            },
                                            on: {
                                              onInit: _vm.handleContentInit,
                                            },
                                            model: {
                                              value: _vm.formData.desc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "desc",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.desc",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.formData.contentType ==
                                  _vm.schoolBannerContentType.School
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Trường học",
                                            prop: "schoolId",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "value-key": "",
                                                placeholder: "",
                                                clearable: "",
                                                filterable: "",
                                                remote: "",
                                                "remote-method":
                                                  _vm.handleSearchSchool,
                                              },
                                              model: {
                                                value: _vm.formData.schoolId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "schoolId",
                                                    $$v
                                                  )
                                                },
                                                expression: "formData.schoolId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.schools,
                                              function (school) {
                                                return _c("el-option", {
                                                  key: school.id,
                                                  attrs: {
                                                    label: school.name,
                                                    value: school.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.formData.contentType ==
                                  _vm.schoolBannerContentType.Link
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Link",
                                            prop: "link",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: _vm.formData.link,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData,
                                                  "link",
                                                  $$v
                                                )
                                              },
                                              expression: "formData.link",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm.dialogStatus == "create" || _vm.$checkAction("update-banner")
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.submitLoading, type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus == "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("Đồng ý")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }