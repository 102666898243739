var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "school-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ảnh đại diện", prop: "avatar" } },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.avatar,
                          width: 150,
                          sizeLimit: 5,
                          height: 150,
                        },
                        on: {
                          "upload:success": function (path) {
                            return _vm.handleUploadSuccess(path)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "text-center", attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ảnh bìa", prop: "thumbnail" } },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.thumbnail,
                          width: 300,
                          sizeLimit: 5,
                          height: 150,
                        },
                        on: {
                          "upload:success": function (path) {
                            return _vm.handleUploadSuccess(path, "thumbnail")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mã trường", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên đầy đủ", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên rút gọn", prop: "shortName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.shortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shortName", $$v)
                          },
                          expression: "form.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên đăng nhập", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.dialogStatus == "update" },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mật khẩu", prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: { "show-password": "" },
                            model: {
                              value: _vm.form.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Vùng miền", prop: "regionId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          model: {
                            value: _vm.form.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "regionId", $$v)
                            },
                            expression: "form.regionId",
                          },
                        },
                        _vm._l(_vm.regions, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Loại hình", prop: "schoolTypeIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: true,
                            filterable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.schoolTypeIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "schoolTypeIds", $$v)
                            },
                            expression: "form.schoolTypeIds",
                          },
                        },
                        _vm._l(_vm.schoolTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Cơ quan QL", prop: "organizationId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          model: {
                            value: _vm.form.organizationId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "organizationId", $$v)
                            },
                            expression: "form.organizationId",
                          },
                        },
                        _vm._l(_vm.organizations, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Vị trí", prop: "position" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.position,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "position", $$v)
                          },
                          expression: "form.position",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thẻ", prop: "schoolTagIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: true,
                            filterable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.schoolTagIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "schoolTagIds", $$v)
                            },
                            expression: "form.schoolTagIds",
                          },
                        },
                        _vm._l(_vm.schoolTags, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Giới thiệu trường", prop: "bio" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "150",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.bio,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bio", $$v)
                          },
                          expression: "form.bio",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "h3",
                  {
                    staticStyle: { "margin-top": "0", "margin-bottom": "8px" },
                  },
                  [_vm._v("Địa chỉ chính")]
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số nhà, tên đường", prop: "street" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.street,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "street", $$v)
                          },
                          expression: "form.street",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thành phố", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _vm.dialogStatus == "update"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function () {
                              return _vm.viewSchoolAddress()
                            },
                          },
                        },
                        [_vm._v("Danh sách cơ sở")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Bậc học", prop: "schoolLevelIds" } },
                    [
                      _c("el-tree", {
                        ref: "treeLevel",
                        staticClass: "school-info-tree",
                        attrs: {
                          "default-expand-all": true,
                          data: _vm.treeDataLevel,
                          props: _vm.defaultProps,
                          "check-on-click-node": true,
                          "show-checkbox": "",
                          "node-key": "id",
                        },
                        on: { "check-change": _vm.handleChangeLevel },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [_c("span", [_vm._v(_vm._s(data.title))])]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngành học", prop: "schoolMajorIds" } },
                    [
                      _c("el-tree", {
                        ref: "treeMajor",
                        staticClass: "school-info-tree",
                        attrs: {
                          "default-expand-all": true,
                          data: _vm.treeDataMajor,
                          props: _vm.defaultProps,
                          "check-on-click-node": true,
                          "show-checkbox": "",
                          "node-key": "id",
                        },
                        on: { "check-change": _vm.handleChangeMajor },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [_c("span", [_vm._v(_vm._s(data.title))])]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-top": "1px solid #efefef",
                    "padding-top": "20px",
                  },
                  attrs: { span: 24, offset: 0 },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Đơn vị tuyển là", prop: "ownerType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: _vm.eduHostOwnerType.Personal },
                          model: {
                            value: _vm.form.ownerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerType", $$v)
                            },
                            expression: "form.ownerType",
                          },
                        },
                        [_vm._v("Cá nhân")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: _vm.eduHostOwnerType.Company },
                          model: {
                            value: _vm.form.ownerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerType", $$v)
                            },
                            expression: "form.ownerType",
                          },
                        },
                        [_vm._v("Tổ chức")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.ownerType == _vm.eduHostOwnerType.Personal
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "margin-top": "0",
                            "margin-bottom": "8px",
                          },
                        },
                        [_vm._v("Thông tin Cá nhân")]
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Mã số thuế cá nhân",
                                    prop: "taxCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.taxCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "taxCode", $$v)
                                      },
                                      expression: "form.taxCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Giấy đăng ký kinh doanh tại quận/huyện",
                            prop: "businessLicenseImage",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.businessLicenseImage,
                              width: 150,
                              sizeLimit: 5,
                              height: 150,
                            },
                            on: {
                              "upload:success": function (path) {
                                return _vm.handleUploadSuccess(
                                  path,
                                  "businessLicenseImage"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.ownerType == _vm.eduHostOwnerType.Company
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "margin-top": "0",
                            "margin-bottom": "8px",
                          },
                        },
                        [_vm._v("Thông tin Tổ chức")]
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tên Tổ chức",
                                    prop: "companyName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.companyName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "companyName", $$v)
                                      },
                                      expression: "form.companyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12, offset: 0 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Mã số thuế",
                                    prop: "taxCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.taxCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "taxCode", $$v)
                                      },
                                      expression: "form.taxCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Địa chỉ", prop: "companyAddress" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.companyAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyAddress", $$v)
                              },
                              expression: "form.companyAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Tài khoản ngân hàng số",
                            prop: "bankNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.bankNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bankNumber", $$v)
                              },
                              expression: "form.bankNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Ngân hàng", prop: "bankId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.fetchBankList,
                              },
                              model: {
                                value: _vm.form.bankId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "bankId", $$v)
                                },
                                expression: "form.bankId",
                              },
                            },
                            _vm._l(_vm.bankList, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.fullName,
                                    value: item.id,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            float: "left",
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              width: "50px",
                                              src: item.logo,
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(
                                            " " + _vm._s(item.fullName) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            color: "#8492a6",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Giấy đăng ký kinh doanh Hộ cá thể",
                            prop: "businessLicenseImage",
                          },
                        },
                        [
                          _c("SingleImageS3", {
                            attrs: {
                              pathImage: _vm.form.businessLicenseImage,
                              width: 150,
                              sizeLimit: 5,
                              height: 150,
                            },
                            on: {
                              "upload:success": function (path) {
                                return _vm.handleUploadSuccess(
                                  path,
                                  "businessLicenseImage"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "h3",
                    {
                      staticStyle: {
                        "margin-top": "0",
                        "margin-bottom": "8px",
                      },
                    },
                    [_vm._v("Thông tin Đơn vị đăng ký")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Họ tên Đại diện tổ chức đăng ký là Ô/Bà",
                        prop: "ownerName",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.ownerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownerName", $$v)
                          },
                          expression: "form.ownerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày tháng năm sinh", prop: "ownDob" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "dd/MM/yyyy",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.ownDob,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownDob", $$v)
                          },
                          expression: "form.ownDob",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Địa chỉ", prop: "ownerAddress" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.ownerAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownerAddress", $$v)
                          },
                          expression: "form.ownerAddress",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "CMND / CCCD / Hộ chiếu số",
                        prop: "idNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.idNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "idNumber", $$v)
                          },
                          expression: "form.idNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ngày cấp", prop: "idDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "DD/MM/YYYY",
                          format: "dd/MM/yyyy",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.form.idDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "idDate", $$v)
                          },
                          expression: "form.idDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (Mặt trước)",
                                prop: "idFrontImage",
                              },
                            },
                            [
                              _c("SingleImageS3", {
                                attrs: {
                                  pathImage: _vm.form.idFrontImage,
                                  width: 150,
                                  sizeLimit: 5,
                                  height: 150,
                                },
                                on: {
                                  "upload:success": function (path) {
                                    return _vm.handleUploadSuccess(
                                      path,
                                      "idFrontImage"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (Mặt sau)",
                                prop: "idBackImage",
                              },
                            },
                            [
                              _c("SingleImageS3", {
                                attrs: {
                                  pathImage: _vm.form.idBackImage,
                                  width: 150,
                                  sizeLimit: 5,
                                  height: 150,
                                },
                                on: {
                                  "upload:success": function (path) {
                                    return _vm.handleUploadSuccess(
                                      path,
                                      "idBackImage"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  "Giấy CN Đại diện theo pháp luật hoặc Uỷ quyền",
                                prop: "certPresentImage",
                              },
                            },
                            [
                              _c("SingleImageS3", {
                                attrs: {
                                  pathImage: _vm.form.certPresentImage,
                                  width: 150,
                                  sizeLimit: 5,
                                  height: 150,
                                },
                                on: {
                                  "upload:success": function (path) {
                                    return _vm.handleUploadSuccess(
                                      path,
                                      "certPresentImage"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.status != _vm.SchoolStatus.Approve &&
              _vm.form.schoolAdmissions &&
              _vm.form.schoolAdmissions.length
                ? _c(
                    "div",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c("SchoolAdmissionInfo", {
                            attrs: {
                              form: _vm.form.schoolAdmissions[0],
                              schoolLevels: _vm.treeDataLevel,
                              schoolMajors: _vm.treeDataMajor,
                              formDisable: _vm.formDisable,
                              dialogStatus: _vm.dialogStatus,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("school-address", { ref: "schoolAddressRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }