var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      {
        class: "characters " + (_vm.isDrawed ? "isUnderline" : " "),
        on: { click: _vm.handleClick },
      },
      _vm._l(_vm.dataCharacter, function (item, index) {
        return _c(
          "span",
          {
            key: index,
            staticStyle: {
              minWidth: "45px",
              "font-weight": "700",
              display: "inline-block",
              "font-size": "60px",
              "text-align": "center",
              "font-family": "sans-serif",
            },
          },
          [_vm._v(" " + _vm._s(item) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }