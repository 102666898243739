"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eduCourseSectionApi = void 0;
var _request = require("@/utils/request");
var eduCourseSectionApi = exports.eduCourseSectionApi = {
  create: function create(data) {
    return (0, _request.request)({
      url: "/eduCourseSection",
      method: 'post',
      data: data
    });
  }
};