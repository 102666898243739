var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.vcoConfig,
          expression: "vcoConfig",
        },
      ],
      staticStyle: { "list-style": "none", "padding-left": "5px" },
    },
    [
      _c("li", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tên:")]),
        _c("span", [_vm._v(_vm._s(_vm.driver.name))]),
      ]),
      _c("li", [
        _c("label", { attrs: { for: "" } }, [_vm._v("SDT:")]),
        _c("span", [_vm._v(_vm._s(_vm.driver.phone))]),
      ]),
      _c("li", [
        _c("label", { attrs: { for: "" } }, [_vm._v("B.số:")]),
        _c("span", [_vm._v(_vm._s(_vm.driver.numberPlates))]),
      ]),
      _c("li", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Loại xe:")]),
        _c("span", [_vm._v(" " + _vm._s(_vm.vehiclesNames))]),
      ]),
      _vm.driver.onlineTime
        ? _c("li", [
            _c("label", { attrs: { for: "" } }, [_vm._v("Online:")]),
            _c("span", [
              _vm._v(_vm._s(_vm.$formatDateTime(_vm.driver.onlineTime))),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }