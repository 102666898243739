var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.status == "create"
            ? "Thêm chuỗi cửa hàng"
            : "Cập nhật chuỗi cửa hàng",
        visible: _vm.visible,
        width: "800px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: _vm.formDisable,
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tên", prop: "brandName.name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.brandName.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.brandName, "name", $$v)
                      },
                      expression: "form.brandName.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Cửa hàng", prop: "storeIds" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        remote: "",
                        multiple: "",
                        "remote-method": _vm.fetchStores,
                        filterable: "",
                      },
                      model: {
                        value: _vm.form.storeIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "storeIds", $$v)
                        },
                        expression: "form.storeIds",
                      },
                    },
                    _vm._l(_vm.stores, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + " - " + item.address,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "Logo (Tỷ lệ: 1/1, khuyến nghị: 400 x 400, dung lượng tối đa 2MB)",
                  },
                },
                [
                  _c("SingleImageS3", {
                    attrs: {
                      width: 200,
                      height: 200,
                      pathImage: _vm.form.brandName.logo,
                    },
                    on: { "upload:success": _vm.setImage },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          !_vm.formDisable
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "update"
                        ? _vm.updateData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }