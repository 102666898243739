var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "Product-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ảnh đại diện (Tỉ lệ đề nghị 1/1)",
                        prop: "image",
                      },
                    },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.image,
                          width: 150,
                          height: 150,
                        },
                        on: { "upload:success": _vm.handleUploadSuccess },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Hình ảnh sản phẩm (Đề xuất ảnh tỉ lệ 1/1)",
                        prop: "imageIds",
                      },
                    },
                    [
                      _c("MultipleImageS3", {
                        staticStyle: { "text-align": "center" },
                        attrs: { fileList: _vm.fileList },
                        on: {
                          "upload:success": _vm.handleUploadMultipleSuccess,
                          "remove:success": _vm.handleRemove,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên sản phẩm", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Danh mục sản phẩm",
                        prop: "productCategoryId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            "remote-method": _vm.fetchProductCategoryList,
                          },
                          model: {
                            value: _vm.form.productCategoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "productCategoryId", $$v)
                            },
                            expression: "form.productCategoryId",
                          },
                        },
                        _vm._l(_vm.productCategories, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thương hiệu", prop: "agentBrandId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            "remote-method": _vm.fetchAgentBrandList,
                          },
                          model: {
                            value: _vm.form.agentBrandId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "agentBrandId", $$v)
                            },
                            expression: "form.agentBrandId",
                          },
                        },
                        _vm._l(_vm.agentBrands, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Kho hàng", prop: "depotIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            "remote-method": _vm.fetchDepotList,
                            multiple: "",
                          },
                          model: {
                            value: _vm.form.depotIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "depotIds", $$v)
                            },
                            expression: "form.depotIds",
                          },
                        },
                        _vm._l(_vm.depots, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mã sản phẩm", prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Giá", prop: "price" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                              min: 1000,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, min: 1000}",
                          },
                        ],
                        staticClass: "input-number",
                        model: {
                          value: _vm.form.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "price", $$v)
                          },
                          expression: "form.price",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Chiết khấu trả cho Fclass (%)",
                        prop: "appCommissionPercent",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                              min: 0,
                              max: 100,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, min: 0, max: 100}",
                          },
                        ],
                        staticClass: "input-number",
                        attrs: { disabled: false },
                        model: {
                          value: _vm.form.appCommissionPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appCommissionPercent", $$v)
                          },
                          expression: "form.appCommissionPercent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Hoa hồng bán lẻ (%)",
                        prop: "commissionPercent",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                              min: 0,
                              max: 100,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, min: 0, max: 100}",
                          },
                        ],
                        staticClass: "input-number",
                        model: {
                          value: _vm.form.commissionPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "commissionPercent", $$v)
                          },
                          expression: "form.commissionPercent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thuế (%)", prop: "taxPercent" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                              min: 0,
                              max: 100,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, min: 0, max: 100}",
                          },
                        ],
                        staticClass: "input-number",
                        model: {
                          value: _vm.form.taxPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "taxPercent", $$v)
                          },
                          expression: "form.taxPercent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Nhà cung cấp", prop: "providerId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            "remote-method": _vm.fetchProviderList,
                          },
                          model: {
                            value: _vm.form.providerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "providerId", $$v)
                            },
                            expression: "form.providerId",
                          },
                        },
                        _vm._l(_vm.providers, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tài liệu sản phẩm", prop: "pdfFile" } },
                    [
                      _c("UploadFile", {
                        ref: "UploadFile",
                        attrs: { maxFileSize: 10, accept: ".pdf" },
                        on: {
                          "upload-success": function (path) {
                            return _vm.$set(_vm.form, "pdfFile", path)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mô tả", prop: "desc" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { width: "100%", height: 400 },
                        on: { onInit: _vm.handleContentInit },
                        model: {
                          value: _vm.form.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "desc", $$v)
                          },
                          expression: "form.desc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { props: "isTreatment" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": "Thêm thông tin",
                          "inactive-text": "Không thêm thông tin",
                        },
                        model: {
                          value: _vm.form.isTreatment,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isTreatment", $$v)
                          },
                          expression: "form.isTreatment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.isTreatment
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.form.extraContentList,
                            border: "",
                            fit: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "Tên", prop: "name" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            rules: {
                                              required: true,
                                              message: "Bắt buộc",
                                              trigger: "submit",
                                            },
                                            prop:
                                              "extraContentList." +
                                              $index +
                                              ".name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            on: { change: _vm.handleLog },
                                            model: {
                                              value: row.name,
                                              callback: function ($$v) {
                                                _vm.$set(row, "name", $$v)
                                              },
                                              expression: "row.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2918662067
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Mô tả" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            rules: {
                                              required: true,
                                              message: "Bắt buộc",
                                              trigger: "submit",
                                            },
                                            prop:
                                              "extraContentList." +
                                              $index +
                                              ".content",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: row.content,
                                              callback: function ($$v) {
                                                _vm.$set(row, "content", $$v)
                                              },
                                              expression: "row.content",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1113266320
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Thao tác", width: "100" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-form-item", [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-delete icon-delete",
                                          staticStyle: {
                                            cursor: "pointer",
                                            color: "red",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDeleteExtraContent(
                                                row.id
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3711483155
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.isTreatment
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "add-btn",
                          on: { click: _vm.handleAddValue },
                        },
                        [_vm._v("Thêm thông tin")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }