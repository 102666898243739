var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          on: { "tab-click": _vm.changeTab },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "transport", label: "Đơn đặt xe" } },
            [
              _c("order-transport-history", {
                attrs: { customerId: _vm.customerId },
                on: {
                  "update:customerId": function ($event) {
                    _vm.customerId = $event
                  },
                  "update:customer-id": function ($event) {
                    _vm.customerId = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Đơn giao hàng", name: "delivery" } },
            [
              _c("order-delivery-history", {
                ref: "order-delivery-history",
                attrs: { customerId: _vm.customerId },
                on: {
                  "update:customerId": function ($event) {
                    _vm.customerId = $event
                  },
                  "update:customer-id": function ($event) {
                    _vm.customerId = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Đơn đặt F&B", name: "food" } },
            [_c("order-food-history", { ref: "OrderFoodHistory" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Đơn đặt Mall", name: "mall" } },
            [_c("order-mall-history", { ref: "OrderMallHistory" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }