var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Xác nhận thanh toán",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Ngày thanh toán", prop: "payDate" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: { type: "date", format: "dd/MM/yyyy" },
                model: {
                  value: _vm.form.payDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "payDate", $$v)
                  },
                  expression: "form.payDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mã giao dịch", prop: "transNo" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.transNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "transNo", $$v)
                  },
                  expression: "form.transNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ngân hàng", prop: "bankId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "", filterable: "" },
                  model: {
                    value: _vm.form.bankId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bankId", $$v)
                    },
                    expression: "form.bankId",
                  },
                },
                _vm._l(_vm.banks, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name, value: item.id },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            float: "left",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c("img", {
                            attrs: { width: "50", src: item.logo, alt: "" },
                          }),
                          _vm._v(" " + _vm._s(item.fullName) + " "),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#2794e8",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.updateData },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }