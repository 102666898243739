var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-form" },
    [
      _vm._m(0),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Họ tên", prop: "ownerName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.ownerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerName", $$v)
                      },
                      expression: "form.ownerName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "CMND / CCCD / Hộ chiếu số:",
                    prop: "idNumber",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.idNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "idNumber", $$v)
                      },
                      expression: "form.idNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Địa chỉ", prop: "ownerAddress" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.ownerAddress,
              callback: function ($$v) {
                _vm.$set(_vm.form, "ownerAddress", $$v)
              },
              expression: "form.ownerAddress",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "idFrontImage",
            label: "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (trước)",
          },
        },
        [
          _c("single-image-s-3", {
            attrs: {
              pathImage: _vm.form.idFrontImage,
              width: 200,
              height: 200,
            },
            on: { "upload:success": _vm.handleUploadSuccess },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.form.idFrontImage
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleViewImage(_vm.form.idFrontImage)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-view" }),
                      _vm._v(" Xem ảnh "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "idBackImage",
            label: "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (sau)",
          },
        },
        [
          _c("single-image-s-3", {
            attrs: { pathImage: _vm.form.idBackImage, width: 200, height: 200 },
            on: { "upload:success": _vm.uploadAfterOk },
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.form.idBackImage
                ? _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleViewImage(_vm.form.idBackImage)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-view" }),
                      _vm._v(" Xem ảnh "),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { display: "none" },
        attrs: { src: _vm.image, "preview-src-list": [_vm.image] },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _c("img", {
        staticStyle: { "vertical-align": "middle" },
        attrs: { width: "40", src: require("@/assets/images/icon2.png") },
      }),
      _c("span", { staticStyle: { "vertical-align": "middle" } }, [
        _vm._v(
          "Thông tin Chủ sở hữu/Người Đại diện Công ty/Hộ Cá thể /Cá nhân"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }