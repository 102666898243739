"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.schoolConfigTrans = exports.requireFormFieldConfig = exports.SchoolWithdrawStatusTrans = exports.SchoolWithdrawStatus = exports.SchoolTransactionTypeTransform = exports.SchoolTransactionType = exports.SchoolStatus = exports.SchoolLearnType = exports.SchoolContentDefineType = exports.SchoolConfigParam = exports.SchoolBannerContentTypeTrans = exports.SchoolBannerContentType = exports.SchoolAdmissionSubmitStatus = exports.EduHostOwnerType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var SchoolAdmissionSubmitStatus;
(function (SchoolAdmissionSubmitStatus) {
  SchoolAdmissionSubmitStatus["Pending"] = "PENDING";
  SchoolAdmissionSubmitStatus["Approve"] = "APPROVE";
  SchoolAdmissionSubmitStatus["Reject"] = "REJECT";
})(SchoolAdmissionSubmitStatus || (exports.SchoolAdmissionSubmitStatus = SchoolAdmissionSubmitStatus = {}));
var SchoolLearnType;
(function (SchoolLearnType) {
  SchoolLearnType["Offline"] = "OFFLINE";
  SchoolLearnType["Online"] = "ONLINE";
})(SchoolLearnType || (exports.SchoolLearnType = SchoolLearnType = {}));
var SchoolStatus;
(function (SchoolStatus) {
  SchoolStatus["Pending"] = "PENDING";
  SchoolStatus["Approve"] = "APPROVE";
  SchoolStatus["Reject"] = "REJECT";
})(SchoolStatus || (exports.SchoolStatus = SchoolStatus = {}));
var SchoolWithdrawStatus;
(function (SchoolWithdrawStatus) {
  SchoolWithdrawStatus["Pending"] = "PENDING";
  SchoolWithdrawStatus["Complete"] = "COMPLETE";
  SchoolWithdrawStatus["Cancel"] = "CANCEL";
})(SchoolWithdrawStatus || (exports.SchoolWithdrawStatus = SchoolWithdrawStatus = {}));
var SchoolWithdrawStatusTrans = exports.SchoolWithdrawStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SchoolWithdrawStatus.Cancel, 'Từ chối'), SchoolWithdrawStatus.Pending, 'Chờ duyệt'), SchoolWithdrawStatus.Complete, 'Đã duyệt');
var SchoolTransactionType;
(function (SchoolTransactionType) {
  SchoolTransactionType["SubmitAdmission"] = "SUBMIT_ADMISSION";
  SchoolTransactionType["Withdraw"] = "WITHDRAW";
  SchoolTransactionType["CancelWithdraw"] = "CANCEL_WITHDRAW";
  SchoolTransactionType["CommissionSubmitAdmission"] = "COMMISSION_SUBMIT_ADMISSION";
})(SchoolTransactionType || (exports.SchoolTransactionType = SchoolTransactionType = {}));
var SchoolTransactionTypeTransform = exports.SchoolTransactionTypeTransform = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SchoolTransactionType.Withdraw, 'Rút tiền'), SchoolTransactionType.CancelWithdraw, 'Hủy rút tiền'), SchoolTransactionType.SubmitAdmission, 'Khách hàng thanh toán thành công tuyển sinh'), SchoolTransactionType.CommissionSubmitAdmission, 'Trừ chiết khấu');
var SchoolContentDefineType;
(function (SchoolContentDefineType) {
  SchoolContentDefineType["AdmissionSubmitFileAttach"] = "ADMISSION_FILE_ATTACH";
})(SchoolContentDefineType || (exports.SchoolContentDefineType = SchoolContentDefineType = {}));
var SchoolBannerContentType;
(function (SchoolBannerContentType) {
  SchoolBannerContentType["None"] = "NONE";
  SchoolBannerContentType["School"] = "SCHOOL";
  SchoolBannerContentType["Content"] = "CONTENT";
  SchoolBannerContentType["Link"] = "LINK";
})(SchoolBannerContentType || (exports.SchoolBannerContentType = SchoolBannerContentType = {}));
var SchoolBannerContentTypeTrans = exports.SchoolBannerContentTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, SchoolBannerContentType.None, {
  label: "Mặc định",
  value: SchoolBannerContentType.None
}), SchoolBannerContentType.Content, {
  label: "Dạng bài viết",
  value: SchoolBannerContentType.Content
}), SchoolBannerContentType.Link, {
  label: "Dạng liên kết ngoài",
  value: SchoolBannerContentType.Link
}), SchoolBannerContentType.School, {
  label: "Liên kết đến trường học",
  value: SchoolBannerContentType.School
});
var EduHostOwnerType;
(function (EduHostOwnerType) {
  EduHostOwnerType["Personal"] = "PERSONAL";
  EduHostOwnerType["Company"] = "COMPANY";
})(EduHostOwnerType || (exports.EduHostOwnerType = EduHostOwnerType = {}));
var SchoolConfigParam;
(function (SchoolConfigParam) {
  SchoolConfigParam["AdmissionFieldRequire"] = "ADMISSION_FIELD_REQUIRE";
})(SchoolConfigParam || (exports.SchoolConfigParam = SchoolConfigParam = {}));
var schoolConfigTrans = exports.schoolConfigTrans = (0, _defineProperty2.default)({}, SchoolConfigParam.AdmissionFieldRequire, "Các trường bắt buộc");
var requireFormFieldConfig = exports.requireFormFieldConfig = [{
  value: "image",
  label: "Hình ảnh"
}, {
  value: "name",
  label: "Tên chương trình tuyển sinh"
}, {
  value: "admissionTime",
  label: "Thời gian xét tuyển,"
}, {
  value: "courseName",
  label: "Tên khóa học"
}, {
  value: "courseTime",
  label: "Thời gian học"
}, {
  value: "numOfWeeks",
  label: "Số tuần học"
}, {
  value: "openDate",
  label: "Ngày khai giảng dự kiến"
}, {
  value: "price",
  label: "Thí sinh nộp lệ phí hồ sơ"
}, {
  value: "moneyCommission",
  label: "Chấp nhận thanh toán Phí tuyển sinh khoá nêu trên cho Fclass là"
}, {
  value: "address",
  label: "Địa chỉ"
}, {
  value: "schoolAdmissionMethodIds",
  label: "Phương thức tuyển sinh"
}, {
  value: "object",
  label: "Đối tượng tuyển sinh"
}, {
  value: "range",
  label: "Phạm vi tuyển sinh"
}, {
  value: "target",
  label: "Chỉ tiêu"
}, {
  value: "inputQuality",
  label: "Ngưỡng đảm bảo chất lượng đầu vào, điều kiện nhận đăng ký xét tuyển"
}, {
  value: "priorityPolicy",
  label: "Chính sách ưu tiên"
}, {
  value: "feeEstimated",
  label: "Học phí dự kiến với sinh viên và lộ trình tăng học phí tối đa cho từng năm"
}, {
  value: "note",
  label: "Các nội dung khác"
}];