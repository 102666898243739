var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: { data: _vm.data, border: "", fit: "" },
    },
    [
      _c("el-table-column", {
        attrs: { label: "Loại" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                row.type == "ONLINE"
                  ? _c("span", [
                      _vm._v(" Mở App 8 tiếng trở lên trong vòng 30 ngày. "),
                    ])
                  : row.type == "SALE" && row.period == "FIRST"
                  ? _c("span", [
                      _vm._v(
                        " Tài xế được giới thiệu 3 tháng đầu doanh thu đạt "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " Tài xế được giới thiệu 3 tháng tiếp theo doanh thu đạt "
                      ),
                    ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Doanh thu đạt" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                row.type == "SALE"
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.$formatNumberVN(row.sale)) + " "),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Tiền thưởng" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$formatNumberVN(row.rewardAmount)) +
                    "đ / xe "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Kích hoạt" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#13ce66",
                    "inactive-color": "#ff4949",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeEnable(row)
                    },
                  },
                  model: {
                    value: row.isEnable,
                    callback: function ($$v) {
                      _vm.$set(row, "isEnable", $$v)
                    },
                    expression: "row.isEnable",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { label: "Thao tác" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.onUpdate(row)
                      },
                    },
                  },
                  [_vm._v("Cập nhật")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }