"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SingleImage: _SingleImage.default
  },
  props: ["form"],
  methods: {
    verificationPaperOk: function verificationPaperOk(path) {
      this.$set(this.form, "verificationPaper", path);
    },
    registerVehiclePaperOk: function registerVehiclePaperOk(path) {
      this.$set(this.form, "registerVehiclePaper", path);
    },
    bhtndsOk: function bhtndsOk(path) {
      this.$set(this.form, "bhtnds", path);
    },
    updateVcOk: function updateVcOk(path) {
      this.$set(this.form, "bhvc", path);
    },
    carBadgeOk: function carBadgeOk(path) {
      this.$set(this.form, "carBadge", path);
    },
    roadUseFeeOk: function roadUseFeeOk(path) {
      this.$set(this.form, "roadUseFee", path);
    }
  }
};