"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MediaType = void 0;
var MediaType;
(function (MediaType) {
  MediaType["Image"] = "IMAGE";
  MediaType["Video"] = "VIDEO";
})(MediaType || (exports.MediaType = MediaType = {}));