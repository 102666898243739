var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        { staticClass: "right-control-tab" },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Đơn hàng" } },
            [
              _c("order-item", {
                attrs: { order: _vm.order },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("order-click", _vm.order)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Tài xế" } },
            [
              _c("driver-item", {
                attrs: { driver: _vm.driver },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("driver-click", _vm.driver)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }