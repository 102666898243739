"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoreTypeTrans = exports.StoreType = exports.StoreStatusTrans = exports.StoreStatus = exports.StoreRecommendTypeTrans = exports.StoreRecommendType = exports.ProfileStoreStatus = exports.CompanySaleType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var StoreStatus;
(function (StoreStatus) {
  StoreStatus["Online"] = "ONLINE";
  StoreStatus["Offline"] = "OFFLINE";
})(StoreStatus || (exports.StoreStatus = StoreStatus = {}));
var ProfileStoreStatus;
(function (ProfileStoreStatus) {
  ProfileStoreStatus["Pending"] = "PENDING";
  ProfileStoreStatus["Approve"] = "APPROVE";
})(ProfileStoreStatus || (exports.ProfileStoreStatus = ProfileStoreStatus = {}));
var StoreStatusTrans = exports.StoreStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, ProfileStoreStatus.Pending, 'Chờ duyệt'), ProfileStoreStatus.Approve, 'Đã duyệt');
var CompanySaleType;
(function (CompanySaleType) {
  CompanySaleType["Food"] = "FOOD";
  CompanySaleType["Market"] = "MARKET";
  CompanySaleType["All"] = "ALL";
})(CompanySaleType || (exports.CompanySaleType = CompanySaleType = {}));
var StoreType;
(function (StoreType) {
  StoreType["Individual"] = "INDIVIDUAL";
  StoreType["Company"] = "COMPANY";
})(StoreType || (exports.StoreType = StoreType = {}));
var StoreTypeTrans = exports.StoreTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, StoreType.Individual, 'Cá nhân'), StoreType.Company, 'Công ty / Hộ cá thể');
var StoreRecommendType;
(function (StoreRecommendType) {
  StoreRecommendType["Food"] = "FOOD";
  StoreRecommendType["Mart"] = "MART";
})(StoreRecommendType || (exports.StoreRecommendType = StoreRecommendType = {}));
var StoreRecommendTypeTrans = exports.StoreRecommendTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, StoreRecommendType.Food, 'Thức ăn'), StoreRecommendType.Mart, 'Đi chợ');