var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            loadingList: _vm.loadingList,
            fit: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Mã đơn hàng", prop: "code", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-code" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _c("b", [_vm._v(_vm._s(row.code))]),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$formatDateTime(row.dateCreated))
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin người đặt" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Tên: ")]),
                      row.agent
                        ? _c("span", [_vm._v(_vm._s(row.agent.name))])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Số điện thoại: ")]),
                      _c("span", [_vm._v(_vm._s(row.agent.phone))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Địa chỉ: ")]),
                      row.agent
                        ? _c("span", [
                            _vm._v(
                              _vm._s(row.agent.address || "Chưa có thông tin")
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Email: ")]),
                      _c("span", [
                        _vm._v(_vm._s(row.agent.email || "Chưa có thông tin")),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin người nhận" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Tên người nhận: ")]),
                      row.agentCustomer
                        ? _c("span", [_vm._v(_vm._s(row.agentCustomer.name))])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Số điện thoại: ")]),
                      row.agentCustomer
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                row.agentCustomer.phone || "Chưa có thông tin"
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Địa chỉ: ")]),
                      row.agentCustomer
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                row.agentCustomer.address || "Chưa có thông tin"
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nhà cung cấp" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Tên nhà cung cấp: ")]),
                      row.provider
                        ? _c("span", [_vm._v(_vm._s(row.provider.name))])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Số điện thoại: ")]),
                      row.provider
                        ? _c("span", [
                            _vm._v(
                              _vm._s(row.provider.phone || "Chưa có thông tin")
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Địa chỉ: ")]),
                      row.provider
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                row.provider.address || "Chưa có thông tin"
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin đơn hàng", width: "500" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Hình thức vận chuyển: ")]),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.AgentOrderShipProviderTrans[
                                  row.shipProvider
                                ].label
                              )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Phương thức thanh toán: ")]),
                        _c("div", [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.AgentOrderPaymentTypeTrans[
                                    row.paymentType
                                  ].label
                                )
                            ),
                          ]),
                          row.paymentStatus
                            ? _c("span", [
                                _vm._v(
                                  " (" +
                                    _vm._s(
                                      _vm.AgentOrderPaymentStatusTrans[
                                        row.paymentStatus
                                      ].label
                                    ) +
                                    ")"
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [
                          _vm._v(
                            "Phí ship (" +
                              _vm._s(
                                _vm.AgentOrderShipFeeByTrans[row.shipFeeBy]
                                  .label
                              ) +
                              "): "
                          ),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (row.shipFee != 0 ? "+" : "") +
                                " " +
                                _vm.$formatCurrency(row.shipFee)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Thuế: ")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (row.moneyTax != 0 ? "+" : "") +
                                " " +
                                _vm.$formatCurrency(row.moneyTax)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Khuyến mãi: ")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (row.moneyDiscount != 0 ? "-" : "") +
                                " " +
                                _vm.$formatCurrency(row.moneyDiscount)
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Tổng tiền hàng: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#C1952D",
                              "font-weight": "600",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$formatCurrency(row.moneyProduct))
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c("el-divider"),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Thành tiền: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#C1952D",
                              "font-weight": "600",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$formatCurrency(row.moneyFinal)))]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [_vm._v("Tổng hoa hồng bán lẻ: ")]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#C1952D",
                              "font-weight": "600",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$formatCurrency(row.moneyCommission))
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("label", [
                          _vm._v("Tổng chiết khấu của nhà cung cấp: "),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#C1952D",
                              "font-weight": "600",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$formatCurrency(row.moneyProviderCommission)
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái", align: "center", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.AgentOrderStatusTrans[row.status].color,
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.AgentOrderStatusTrans[row.status].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", align: "center", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          gap: "3px",
                        },
                      },
                      [
                        row.status == _vm.AgentOrderStatus.Pending
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { width: "170 !important" },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleConfirm(row)
                                  },
                                },
                              },
                              [_vm._v(" Xác nhận đơn ")]
                            )
                          : _vm._e(),
                        row.status == _vm.AgentOrderStatus.Pending
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { width: "170 !important" },
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancel(row)
                                  },
                                },
                              },
                              [_vm._v(" Hủy đơn ")]
                            )
                          : _vm._e(),
                        row.status == _vm.AgentOrderStatus.Confirm
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { width: "170 !important" },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelivering(row)
                                  },
                                },
                              },
                              [_vm._v(" Xác nhận giao hàng ")]
                            )
                          : _vm._e(),
                        row.status == _vm.AgentOrderStatus.Delivering
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { width: "170 !important" },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleComplete(row)
                                  },
                                },
                              },
                              [_vm._v(" Hoàn thành đơn ")]
                            )
                          : _vm._e(),
                        row.status == _vm.AgentOrderStatus.Confirm ||
                        row.status == _vm.AgentOrderStatus.Delivering
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { width: "170 !important" },
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleReturn(row)
                                  },
                                },
                              },
                              [_vm._v(" Hoàn trả ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "170 !important" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOpenDetail(row)
                              },
                            },
                          },
                          [_vm._v(" Xem chi tiết ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.agentOrderQuery.page,
          limit: _vm.agentOrderQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.agentOrderQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.agentOrderQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("image-modal", { ref: "ImageModal", attrs: { title: "" } }),
      _c("agent-order-detail-modal", {
        ref: "AgentOrderDetailModal",
        attrs: { title: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }