"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MediaType = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
var _objectSpread2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var MediaType = exports.MediaType = {
  Update: "UPDATE",
  Choose: "CHOOSE"
};
var _default2 = exports.default = {
  props: {
    selected: Object,
    onChoose: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    onDelete: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    onUpdate: {
      type: Function,
      default: function _default() {
        return {};
      }
    },
    data: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    type: {
      type: String,
      default: MediaType.Update
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      MediaType: MediaType
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    mediaListSelected: function mediaListSelected(state) {
      return state.media.listSelected;
    }
  })), {}, {
    handleSelected: function handleSelected() {
      var _this = this;
      if (!this.multiple) {
        return this.selected == this.data && "active";
      } else {
        return this.mediaListSelected.some(function (e) {
          return e.id == _this.data.id;
        }) && "active";
      }
    }
  }),
  methods: {
    handleSelect: function handleSelect() {
      this.$set(this.data, "selected", !this.data.selected);
    },
    handleCopy: function handleCopy($event) {
      (0, _clipboard.default)(this.data.url, $event);
    },
    handleCopyName: function handleCopyName($event) {
      (0, _clipboard.default)(this.data.name, $event);
    },
    handleChoose: function handleChoose() {
      if (typeof this.onChoose == "function") {
        this.onChoose(this.data);
      }
    },
    handleDelete: function handleDelete() {
      if (typeof this.onDelete == "function") {
        this.onDelete(this.data);
      }
    }
  }
};