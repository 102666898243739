var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _vm._l(_vm.form.eduReviewOptions, function (item, index) {
            return _c(
              "el-row",
              { key: index, attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 16 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nhập lựa chọn" },
                          model: {
                            value: item.name,
                            callback: function ($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "9px",
                        height: "20px",
                        width: "20px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleRemove(item, index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  ),
                ]),
              ],
              1
            )
          }),
          _c(
            "el-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "primary", plain: "", icon: "el-icon-plus" },
              on: { click: _vm.handleAddRow },
            },
            [_vm._v("Thêm dòng")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }