import { render, staticRenderFns } from "./ImagesOrder.vue?vue&type=template&id=056e22c5&scoped=true"
import script from "./ImagesOrder.vue?vue&type=script&lang=js"
export * from "./ImagesOrder.vue?vue&type=script&lang=js"
import style0 from "./ImagesOrder.vue?vue&type=style&index=0&id=056e22c5&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056e22c5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Untitled/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('056e22c5')) {
      api.createRecord('056e22c5', component.options)
    } else {
      api.reload('056e22c5', component.options)
    }
    module.hot.accept("./ImagesOrder.vue?vue&type=template&id=056e22c5&scoped=true", function () {
      api.rerender('056e22c5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order-food/components/ImagesOrder.vue"
export default component.exports