"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileAttachType = void 0;
var FileAttachType;
(function (FileAttachType) {
  FileAttachType["Image"] = "IMAGE";
  FileAttachType["File"] = "FILE";
  FileAttachType["Link"] = "LINK";
})(FileAttachType || (exports.FileAttachType = FileAttachType = {}));