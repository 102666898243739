var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "create" ? "Thêm mới khách sạn" : "Cập nhật khách sạn",
        visible: _vm.visible,
        fullscreen: "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thông tin", name: "info" } },
            [
              _c("hotel-info", {
                ref: "hotelInfo",
                attrs: {
                  form: _vm.form,
                  formStatus: _vm.status,
                  marker: _vm.marker,
                  "form-disable": _vm.formDisable,
                  utils: _vm.utils,
                },
              }),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Phòng", name: "room" } },
                [
                  _c("hotel-room", {
                    attrs: {
                      hotelId: _vm.form.id,
                      formStatus: _vm.status,
                      "form-disable": _vm.formDisable,
                      utils: _vm.listRoomUtil,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.tabName == "info"
            ? _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingButton, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Lưu")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }