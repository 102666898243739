var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm chiến dịch")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.$checkAction("idea-strategy-add")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleCreate },
                },
                [_vm._v(" Thêm mới ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Chiến dịch", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                          "flex-direction": "column",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [
                        row.imageUrl
                          ? _c("img", {
                              attrs: { height: "80px", src: row.imageUrl },
                            })
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  display: "flex",
                                  "justify-content": "center",
                                  "align-items": "center",
                                  "flex-direction": "column",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-video-play",
                                  staticStyle: { "font-size": "30px" },
                                }),
                                _c("span", [_vm._v("Video")]),
                              ]
                            ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { domProps: { innerHTML: _vm._s(row.name) } }),
                    _c("p", {
                      domProps: { innerHTML: _vm._s(row.description) },
                    }),
                    row.isVisible
                      ? _c(
                          "el-tag",
                          { attrs: { size: "mini", effect: "dark" } },
                          [_c("b", [_vm._v("Hiển thị")])]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                              size: "mini",
                              effect: "dark",
                            },
                          },
                          [_c("b", [_vm._v("Đã ẩn")])]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thời gian", prop: "date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      {
                        staticStyle: {
                          "list-style": "none",
                          "padding-left": "0",
                          "margin-left": "0",
                        },
                      },
                      [
                        _c("li", [
                          _c("b", [_vm._v("Từ ngày:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatDateTime(row.startAt, "DD/MM/YYYY")
                              )
                          ),
                        ]),
                        _c("li", [
                          _c("b", [_vm._v("Đến ngày:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatDateTime(row.endAt, "DD/MM/YYYY")
                              )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.idealProduct
                      ? _c("div", [
                          row.idealProduct.imageUrl
                            ? _c("img", {
                                attrs: {
                                  height: "80px",
                                  src: row.idealProduct.imageUrl,
                                },
                              })
                            : _vm._e(),
                          _c("h3", { staticStyle: { margin: "0" } }, [
                            _vm._v(_vm._s(row.idealProduct.name)),
                          ]),
                        ])
                      : _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v(" Không chọn sản phẩm "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-bottom": "5px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" Chi tiết ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-left": "0",
                              "margin-bottom": "5px",
                            },
                            attrs: {
                              type: row.isVisible ? "danger" : "primary",
                              plain: "",
                              size: "mini",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleHidden(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(row.isVisible ? "Ẩn" : "Hiển thị") +
                                " "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-left": "0",
                              "margin-bottom": "5px",
                            },
                            attrs: { type: "primary", size: "mini", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCopy(row)
                              },
                            },
                          },
                          [_vm._v(" Sao chép ")]
                        ),
                        _vm.$checkAction("idea-strategy-delete")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "0" },
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(row)
                                  },
                                },
                              },
                              [_vm._v(" Xoá ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("ideal-strategy-modal", {
        ref: "IdealStrategyModal",
        on: { submitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }