"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["formData", "token", "baseUrl", "baseUrlMedia", "vehicleCity"],
  data: function data() {
    return {
      configSystem: process.env.VUE_APP_CONFIG_SYSTEM
    };
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.$set(this.formData, "image", res.data.path);
      this.$emit("handleUploadSuccess");
    },
    beforeUpload: function beforeUpload() {
      this.$emit("beforeUpload");
    }
  }
};