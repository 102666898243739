"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fontStyleName = exports.excludesFontStyleName = void 0;
var fontStyleName = exports.fontStyleName = {
  name: 'Times New Roman',
  family: 4,
  size: 12
};
var excludesFontStyleName = exports.excludesFontStyleName = ['name', 'city.nameWithType', 'ward.nameWithType', 'district.nameWithType', 'driverProfile.permanentAddress', 'driverProfile.stayingAddress', 'address', 'order.customer.name', 'district', 'city', 'ward', 'driver.name', 'creater.name', 'staff.name', 'company.name', 'customer.name', 'sender.name', 'receiver.name'];