"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentDashboardApi = void 0;
var _request = require("@/utils/request");
var agentDashboardApi = exports.agentDashboardApi = {
  summary: function summary(params) {
    return (0, _request.request)({
      url: '/agentDashboard/summary',
      params: params
    });
  },
  summaryAgentOrder: function summaryAgentOrder(params) {
    return (0, _request.request)({
      url: '/agentDashboard/summary/agentOrder/byDate',
      params: params
    });
  }
};