var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "medic-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                _c(
                  "section",
                  [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v("Thông tin Doanh nghiệp"),
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          {
                            attrs: {
                              span: _vm.dialogStatus == "add" ? 12 : 24,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Tên tài khoản",
                                  prop: "username",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.dialogStatus == "edit",
                                  },
                                  model: {
                                    value: _vm.form.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "username", $$v)
                                    },
                                    expression: "form.username",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.dialogStatus == "add"
                          ? _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Mật khẩu",
                                      prop: "password",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { "show-password": "" },
                                      model: {
                                        value: _vm.form.password,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "password", $$v)
                                        },
                                        expression: "form.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Tên Cá nhân/ Tổ chức",
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyName", $$v)
                                    },
                                    expression: "form.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Mã số thuế",
                                  prop: "companyTaxCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.companyTaxCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyTaxCode", $$v)
                                    },
                                    expression: "form.companyTaxCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Số điện thoại",
                                  prop: "companyPhone",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.companyPhone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyPhone", $$v)
                                    },
                                    expression: "form.companyPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Email", prop: "companyEmail" },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.companyEmail,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyEmail", $$v)
                                    },
                                    expression: "form.companyEmail",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Địa chỉ",
                                  prop: "companyAddress",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.companyAddress,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyAddress", $$v)
                                    },
                                    expression: "form.companyAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Quốc gia",
                                  prop: "companyNationId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      remote: true,
                                      "remote-method": _vm.fetchNation,
                                    },
                                    model: {
                                      value: _vm.form.companyNationId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "companyNationId",
                                          $$v
                                        )
                                      },
                                      expression: "form.companyNationId",
                                    },
                                  },
                                  _vm._l(_vm.nations, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("AddressForm", {
                              attrs: {
                                form: _vm.form,
                                cityFieldName: "companyCityId",
                                districtFieldName: "companyDistrictId",
                                wardFieldName: "companyWardId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "section",
                  [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v("Thông tin Cơ sở y tế của doanh nghiệp"),
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Tên Cơ sở y tế",
                                  prop: "name",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Mã số thuế", prop: "taxCode" },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.taxCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "taxCode", $$v)
                                    },
                                    expression: "form.taxCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Số điện thoại",
                                  prop: "phone",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.phone,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "phone", $$v)
                                    },
                                    expression: "form.phone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Email", prop: "email" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Địa chỉ", prop: "address" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.form.address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "address", $$v)
                                    },
                                    expression: "form.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Quốc gia", prop: "nationId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      remote: true,
                                      "remote-method": _vm.fetchNation,
                                    },
                                    model: {
                                      value: _vm.form.nationId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "nationId", $$v)
                                      },
                                      expression: "form.nationId",
                                    },
                                  },
                                  _vm._l(_vm.nations, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [_c("AddressForm", { attrs: { form: _vm.form } })],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Hình thức Tổ chức của CSYT là",
                                  prop: "type",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "type", $$v)
                                      },
                                      expression: "form.type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.MedicTypeTrans,
                                    function (item, index) {
                                      return _c(
                                        "el-radio",
                                        { key: index, attrs: { label: index } },
                                        [_vm._v(" " + _vm._s(item) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Chuyên môn của  CSYT là",
                                  prop: "spec",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.spec,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "spec", $$v)
                                      },
                                      expression: "form.spec",
                                    },
                                  },
                                  _vm._l(
                                    _vm.MedicSpecTrans,
                                    function (item, index) {
                                      return _c(
                                        "el-radio",
                                        { key: index, attrs: { label: index } },
                                        [_vm._v(" " + _vm._s(item) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Quy mô Cơ sở Y tế",
                                  prop: "size",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    model: {
                                      value: _vm.form.size,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "size", $$v)
                                      },
                                      expression: "form.size",
                                    },
                                  },
                                  _vm._l(
                                    _vm.MedicSizeTrans,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: { label: item, value: index },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                _c(
                  "section",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label:
                                    "Phí sử dụng ứng dụng Fclass mà CSYT sẽ thanh toán là:",
                                  prop: "commissionValue",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                        },
                                        expression:
                                          "{alias: 'decimal', groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                      },
                                    ],
                                    attrs: { disabled: _vm.formDisable },
                                    model: {
                                      value: _vm.form.commissionValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "commissionValue",
                                          $$v
                                        )
                                      },
                                      expression: "form.commissionValue",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "append" },
                                        slot: "append",
                                      },
                                      [_vm._v("VNĐ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Giấy CNĐKKD/MST",
                                  size: "normal",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("UploadS3", {
                                          attrs: {
                                            maxFileSize: 10,
                                            width: 200,
                                            height: 200,
                                            fileUrl: _vm.form.businessImage1,
                                            fileTypeAccept: ["image/", "pdf"],
                                          },
                                          on: {
                                            "upload:success": function (url) {
                                              return _vm.handleUploadSuccess(
                                                "businessImage1",
                                                url
                                              )
                                            },
                                            delete: function ($event) {
                                              return _vm.handleDelete(
                                                "businessImage1"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("UploadS3", {
                                          attrs: {
                                            maxFileSize: 10,
                                            width: 200,
                                            height: 200,
                                            fileUrl: _vm.form.businessImage2,
                                            fileTypeAccept: ["image/", "pdf"],
                                          },
                                          on: {
                                            "upload:success": function (url) {
                                              return _vm.handleUploadSuccess(
                                                "businessImage2",
                                                url
                                              )
                                            },
                                            delete: function ($event) {
                                              return _vm.handleDelete(
                                                "businessImage2"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Giấy phép hành nghề y tế",
                                  size: "normal",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 20 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("UploadS3", {
                                          attrs: {
                                            maxFileSize: 10,
                                            width: 200,
                                            height: 200,
                                            fileUrl: _vm.form.medicImage1,
                                            fileTypeAccept: ["image/", "pdf"],
                                          },
                                          on: {
                                            "upload:success": function (url) {
                                              return _vm.handleUploadSuccess(
                                                "medicImage1",
                                                url
                                              )
                                            },
                                            delete: function ($event) {
                                              return _vm.handleDelete(
                                                "medicImage1"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c("UploadS3", {
                                          attrs: {
                                            maxFileSize: 10,
                                            width: 200,
                                            height: 200,
                                            fileUrl: _vm.form.medicImage2,
                                            fileTypeAccept: ["image/", "pdf"],
                                          },
                                          on: {
                                            "upload:success": function (url) {
                                              return _vm.handleUploadSuccess(
                                                "medicImage2",
                                                url
                                              )
                                            },
                                            delete: function ($event) {
                                              return _vm.handleDelete(
                                                "medicImage2"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Dịch vụ" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "room-utils-tree" },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "column",
                                        },
                                        model: {
                                          value: _vm.form.medicServiceIds,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "medicServiceIds",
                                              $$v
                                            )
                                          },
                                          expression: "form.medicServiceIds",
                                        },
                                      },
                                      _vm._l(
                                        _vm.medicServices,
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.id,
                                              attrs: { label: item.id },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Chuyên khoa" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "room-utils-tree" },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "column",
                                        },
                                        model: {
                                          value: _vm.form.medicCategoryIds,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "medicCategoryIds",
                                              $$v
                                            )
                                          },
                                          expression: "form.medicCategoryIds",
                                        },
                                      },
                                      _vm._l(
                                        _vm.medicCategories,
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.id,
                                              attrs: { label: item.id },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }