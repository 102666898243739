var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "1000px",
        top: "10px",
        "append-to-body": true,
        title: "Danh sách mã dự thưởng - (" + _vm.total + " mã)",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: {
                    "prefix-icon": "el-icon-search",
                    placeholder: "Tìm kiếm...",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.fetchData($event)
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "filter-item",
                staticStyle: { "margin-top": "20px" },
                attrs: { type: "primary", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.fetchData()
                  },
                },
              },
              [_vm._v(" Tìm ")]
            ),
          ],
          1
        ),
        _c("div", {
          staticClass: "color-green",
          staticStyle: { "margin-bottom": "10px" },
        }),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Mã dự thưởng", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v(_vm._s(row.code)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customer
                      ? _c("div", [
                          _c("h3", { staticStyle: { "margin-bottom": "0" } }, [
                            _vm._v(_vm._s(row.customer.name)),
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _c("b", [_vm._v("SĐT: ")]),
                            _vm._v(_vm._s(row.customer.phone)),
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                            _c("b", [_vm._v("Email: ")]),
                            _vm._v(_vm._s(row.customer.email)),
                          ]),
                        ])
                      : _c("div", [
                          _vm._v(" Mã dự thưởng chưa có người sở hữu "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "prize" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.idealProduct.imageUrl
                      ? _c("img", {
                          attrs: {
                            height: "80px",
                            src: row.idealProduct.imageUrl,
                          },
                        })
                      : _vm._e(),
                    row.idealProduct
                      ? _c("h3", { staticStyle: { margin: "0" } }, [
                          _vm._v(_vm._s(row.idealProduct.name)),
                        ])
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(row.idealProduct.code))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }