var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "fit-content",
        title: "Cấu hình banner",
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { width: "600px" },
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "top",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "bannerMall",
                label: "Banner (Tỉ lệ 4x1)",
                size: "normal",
              },
            },
            [
              !_vm.form.isHighlight
                ? _c("p", [
                    _vm._v(
                      "Vui lòng tải lên banner nổi bật, để đánh dấu hightlight cửa hàng này"
                    ),
                  ])
                : _vm._e(),
              _c("SingleImageS3", {
                attrs: {
                  pathImage: _vm.form.bannerMall,
                  width: 600,
                  height: 150,
                },
                on: { "upload:success": _vm.handleUploadSuccess },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("OK")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }