var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _vm.$devMode
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8, xl: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-add-service-group",
                      attrs: { type: "primary", icon: "el-icon-circle-plus" },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v(" Thêm ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _c(
        "el-table",
        { attrs: { data: _vm.tableDataContent, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Cấu hình" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.SHARE_CONFIG_TRANS[row.type]) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Nội dung" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(" " + _vm._s(row.content) + " ")])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("content-modal", {
        ref: "ContentModal",
        on: { "submit:ok": _vm.getData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { margin: "16px 0" } }, [
      _c("label", { staticClass: "type-info" }, [_vm._v("Cấu hình")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }