var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm khách hàng (SĐT, Tên,...)" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.queryList.search,
                callback: function ($$v) {
                  _vm.$set(_vm.queryList, "search", $$v)
                },
                expression: "queryList.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Cửa hàng Secondhand"),
            ]),
            _c(
              "el-select",
              {
                attrs: {
                  clearable: "",
                  placeholder: "",
                  filterable: "",
                  remote: "",
                  "remote-method": _vm.fetchStore,
                },
                on: {
                  change: function () {
                    _vm.fetchData()
                    _vm.summary()
                  },
                },
                model: {
                  value: _vm.queryList.mallId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryList, "mallId", $$v)
                  },
                  expression: "queryList.mallId",
                },
              },
              _vm._l(_vm.malls, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm.currentTab == _vm.MallReturnOrderStatus.Confirm
          ? _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Hạn phản hồi")]),
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    on: { change: _vm.fetchData },
                    model: {
                      value: _vm.queryList.isExpired,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryList, "isExpired", $$v)
                      },
                      expression: "queryList.isExpired",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "Quá hạn phản hồi", value: true },
                    }),
                    _c("el-option", {
                      attrs: { label: "Chưa quá hạn", value: false },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          on: { "tab-click": _vm.fetchData },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        _vm._l(_vm.summaryData, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.value, attrs: { label: item.label, name: item.value } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("div", [_vm._v(_vm._s(item.label))]),
                    _c("el-badge", {
                      attrs: {
                        value: item.total || undefined,
                        type: "primary",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "60", align: "center", label: "STT" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", align: "center", label: "Code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.code))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Sản phẩm", prop: "content", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs["detailProductModal"].open(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.mallReturnOrderDetails.length) +
                            " sản phẩm "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Số tiền hoàn lại",
              prop: "returnMoney",
              align: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatNumber(row.returnMoney)) + "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "content", "min-width": "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs["customer-dialog"].handleEdit(
                              row.customer
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(row.customer.name) +
                            " - " +
                            _vm._s(row.customer.phone) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mã đơn mall", "min-width": "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(" " + _vm._s(row.mallOrder.code) + " ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Mall", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-link", { attrs: { type: "primary" } }, [
                      _vm._v(" " + _vm._s(row.mall.name) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Phương án hoàn trả",
              prop: "returnMethod",
              align: "center",
              "min-width": "250",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          "white-space": "normal",
                          height: "auto",
                        },
                        attrs: {
                          type: _vm.mallReturnOtherMethodTrans[row.returnMethod]
                            .color,
                          size: "small",
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.mallReturnOtherMethodTrans[row.returnMethod]
                                .label
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Trạng thái",
              prop: "status",
              align: "center",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          "white-space": "normal",
                          height: "auto",
                        },
                        attrs: {
                          type: _vm.mallReturnOrderStatusTrans[row.status]
                            .color,
                          size: "small",
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(
                              _vm.mallReturnOrderStatusTrans[row.status].label
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Trả hàng/hoàn tiền ngay",
              prop: "status",
              align: "center",
              "min-width": "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          "white-space": "normal",
                          height: "auto",
                        },
                      },
                      [
                        row.isNeedReturnProduct
                          ? _c("b", [_vm._v("Yêu cầu trả hàng")])
                          : _c("b", [_vm._v("Hoàn tiền ngay")]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: { label: "Hạn phản hồi", prop: "content", width: "130" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _vm._v(" " + _vm._s(_vm.formatDate(row.expiredAt)) + " "),
                      _c("br"),
                      _vm.countRemainingDays(row.expiredAt) > 0
                        ? _c("span", [
                            _vm._v(
                              " Còn " +
                                _vm._s(_vm.countRemainingDays(row.expiredAt)) +
                                " ngày "
                            ),
                          ])
                        : _c(
                            "el-tag",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                "white-space": "normal",
                                height: "auto",
                              },
                              attrs: { effect: "dark", type: "danger" },
                            },
                            [_c("b", [_vm._v("Đã quá hạn")])]
                          ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c("span", [_vm._v("Hạn phản hồi")]),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "Nếu quá hạn thì yêu cầu sẽ tự động hủy",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-question",
                        staticStyle: { "margin-left": "4px" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { label: "Lý do trả hàng", prop: "content", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(" " + _vm._s(row.reason) + " ")])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              width: "230px",
              label: "Nhân viên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.confirmedStaff
                      ? _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(row.confirmedStaff.name)),
                        ])
                      : _vm._e(),
                    row.rejectedStaff
                      ? _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(row.rejectedStaff.name)),
                        ])
                      : _vm._e(),
                    row.confirmedStaff
                      ? _c("span", [_vm._v(" (Nhân viên duyệt)")])
                      : _vm._e(),
                    row.rejectedStaff
                      ? _c("span", [_vm._v(" (Nhân viên từ chối)")])
                      : _vm._e(),
                    row.confirmedAt
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Thời gian duyệt: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$formatDateTime(row.confirmedAt))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    row.rejectedAt
                      ? _c("div", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Thời gian từ chối: "),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$formatDateTime(row.rejectedAt))),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thao tác", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status == _vm.MallReturnOrderStatus.Pending
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function () {
                                    _vm.visibleModal = true
                                    _vm.selectedOrder = row
                                  },
                                },
                              },
                              [_vm._v("Xác nhận hoàn trả")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                          ],
                          1
                        )
                      : _vm._e(),
                    row.status == _vm.MallReturnOrderStatus.Pending
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              "margin-left": "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function () {
                                _vm.visibleModal = true
                                _vm.selectedOrder = row
                              },
                            },
                          },
                          [_vm._v("Từ chối hoàn trả")]
                        )
                      : _vm._e(),
                    row.status == _vm.MallReturnOrderStatus.MallComplain
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function () {
                                _vm.viewAppeal(row)
                                _vm.selectedOrder = row
                              },
                            },
                          },
                          [_vm._v("Xem khiếu nại của Mall")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.queryList.page,
          limit: _vm.queryList.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryList, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryList, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("customer-dialog", {
        ref: "customer-dialog",
        attrs: { "form-disable": true },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Xác nhận thao tác",
            visible: _vm.visibleModal,
            width: "400px",
          },
          on: { close: _vm.handleClose },
        },
        [
          _c("p", [
            _vm._v("Gửi yêu cầu cho cửa hàng Mall "),
            _c("br"),
            _vm._v(" hoàn tiền đơn hàng này?"),
          ]),
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.form.isNeedReturnProduct,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "isNeedReturnProduct", $$v)
                },
                expression: "form.isNeedReturnProduct",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v("Yêu cầu trả hàng"),
              ]),
              _c("el-radio", { attrs: { label: false } }, [
                _vm._v("Hoàn tiền ngay"),
              ]),
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("Đóng"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.handleRefund },
                },
                [_vm._v("Xác nhận")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("appeal-modal", {
        ref: "appealModal",
        on: { submitOk: _vm.fetchData },
      }),
      _c("detail-product-modal", {
        ref: "detailProductModal",
        on: { submitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }