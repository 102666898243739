"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _store = _interopRequireDefault(require("@/store"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    PanThumb: _PanThumb.default
  },
  data: function data() {
    return {
      user: {},
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA
    };
  },
  created: function created() {
    this.user = _store.default.getters.info;
  }
};