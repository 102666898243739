var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section-form bg-green" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Họ tên", prop: "ownerName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.ownerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerName", $$v)
                      },
                      expression: "form.ownerName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "CMND / CCCD / Hộ chiếu số:",
                    prop: "ownerIdentification",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.ownerIdentification,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerIdentification", $$v)
                      },
                      expression: "form.ownerIdentification",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Mã số thuế cá nhân",
                    prop: "personalTaxCode",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.personalTaxCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "personalTaxCode", $$v)
                      },
                      expression: "form.personalTaxCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Địa chỉ", prop: "ownerAddress" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.ownerAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerAddress", $$v)
                      },
                      expression: "form.ownerAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Tỉnh thành", prop: "ownerCityId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      on: { change: _vm.handleChangeCity },
                      model: {
                        value: _vm.form.ownerCityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ownerCityId", $$v)
                        },
                        expression: "form.ownerCityId",
                      },
                    },
                    _vm._l(_vm.cities, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Quận huyện", prop: "ownerDistrictId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      on: { change: _vm.handleChangeDistrict },
                      model: {
                        value: _vm.form.ownerDistrictId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ownerDistrictId", $$v)
                        },
                        expression: "form.ownerDistrictId",
                      },
                    },
                    _vm._l(_vm.districts, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Phường xã", prop: "ownerWardId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.form.ownerWardId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "ownerWardId", $$v)
                        },
                        expression: "form.ownerWardId",
                      },
                    },
                    _vm._l(_vm.wards, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.nameWithType, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "ownerPhone", label: "Số điện thoại" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.ownerPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerPhone", $$v)
                      },
                      expression: "form.ownerPhone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Email" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.ownerEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ownerEmail", $$v)
                      },
                      expression: "form.ownerEmail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "ownerIdentificationImage",
                    label: "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (Mặt trước)",
                  },
                },
                [
                  _c("single-image-s-3", {
                    attrs: {
                      pathImage: _vm.form.ownerIdentificationImage,
                      width: 200,
                      height: 200,
                    },
                    on: { "upload:success": _vm.handleUploadSuccess },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "ownerIdentificationImageAfter",
                    label: "Hình ảnh Giấy CMND / CCCD / Hộ chiếu (Mặt sau)",
                  },
                },
                [
                  _c("single-image-s-3", {
                    attrs: {
                      pathImage: _vm.form.ownerIdentificationImageAfter,
                      width: 200,
                      height: 200,
                    },
                    on: { "upload:success": _vm.uploadAfterOk },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }