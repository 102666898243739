var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "upload-multiple-control" } },
    [
      _c(
        "el-upload",
        {
          style: "margin:0 auto",
          attrs: {
            name: "file",
            drag: "",
            multiple: "",
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            "before-upload": _vm.beforeUpload,
            action: _vm.$baseUrl + _vm.urlUpload,
            "show-file-list": true,
            headers: { token: _vm.token },
            disabled: _vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function (ref) {
                var file = ref.file
                return _c(
                  "div",
                  {
                    staticStyle: {
                      "object-fit": "contain",
                      width: "100%",
                      height: "100%",
                    },
                  },
                  [
                    _vm.checkIsVideo(file.url)
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "object-fit": "contain",
                              width: "100%",
                              height: "100%",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "content-video" },
                              [
                                _c("el-button", {
                                  staticStyle: {
                                    position: "absolute",
                                    right: "0",
                                    top: "0",
                                    "z-index": "5",
                                  },
                                  attrs: {
                                    stype: "",
                                    type: "warning",
                                    icon: "el-icon-delete",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitRemove(file)
                                    },
                                  },
                                }),
                                _c("video", {
                                  staticStyle: {
                                    "object-fit": "contain",
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: {
                                    controls: "",
                                    src: _vm.$url.image(file.url),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _c("div", [
                          _c("img", {
                            staticClass: "image",
                            staticStyle: {
                              "object-fit": "contain",
                              width: "100%",
                              height: "100%",
                            },
                            attrs: { src: _vm.$url.image(file.url), alt: "" },
                          }),
                        ]),
                    _c("div", { staticClass: "label-name" }, [
                      file.status != "success"
                        ? _c("i", { staticClass: "el-icon-error" })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(file.name))]),
                    ]),
                    !_vm.checkIsVideo(file.url)
                      ? _c("span", { staticClass: "upload-actions" }, [
                          _c(
                            "span",
                            {
                              staticClass: "upload-actions-delete",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.emitRemove(file)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              },
            },
          ]),
        },
        [
          _vm.isProgressing
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.percentUpload },
              })
            : _vm._e(),
          !_vm.isProgressing
            ? [[_c("i", { staticClass: "el-icon-plus" })]]
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              !!_vm.percentUpload && _vm.percentUpload != 100
                ? _c("span", [
                    _vm._v("Loading: " + _vm._s(_vm.percentUpload) + "%"),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm._v(" Có thể upload được nhiều ảnh cùng lúc! "),
              _c("br"),
              _vm._v(" Tên file chỉ bao gồm "),
              _c("b", [
                _vm._v("(kí tự thường, kí tự in hoa, chữ số, '_' , '-')"),
              ]),
              _c("br"),
              _vm._v(" Lưu ý không bao gồm các ký tự đặc biệt (*,&,...) "),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }