var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Tải lên video",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 12 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Video bốc thăm",
                                prop: "videoUrl",
                              },
                            },
                            [
                              _c("UploadVideoS3", {
                                attrs: {
                                  videoUrl: _vm.form.videoUrl,
                                  width: 400,
                                  height: 200,
                                },
                                on: {
                                  "upload:success": _vm.setVideo,
                                  delete: _vm.handleDeleteVideo,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.createData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }