"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IdealCouponType = exports.IdealCouponDiscountType = void 0;
var IdealCouponType;
(function (IdealCouponType) {
  IdealCouponType["ShipFee"] = "SHIP_FEE";
  IdealCouponType["Product"] = "PRODUCT";
})(IdealCouponType || (exports.IdealCouponType = IdealCouponType = {}));
var IdealCouponDiscountType;
(function (IdealCouponDiscountType) {
  IdealCouponDiscountType["Amount"] = "AMOUNT";
  IdealCouponDiscountType["Percent"] = "PERCENT";
})(IdealCouponDiscountType || (exports.IdealCouponDiscountType = IdealCouponDiscountType = {}));