var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vehicle-company-item",
      on: {
        click: function ($event) {
          return _vm.onClick(_vm.vehicle)
        },
      },
    },
    [
      _c("img", {
        attrs: { src: _vm.$url.image(_vm.vehicle.image), width: "40", alt: "" },
      }),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tên: ")]),
        _c("span", [
          _vm._v(
            _vm._s(_vm.vehicle.name) +
              " " +
              _vm._s(_vm.vehicle.subName && " | " + _vm.vehicle.subName)
          ),
        ]),
      ]),
      _c("div", [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tiền thưởng: ")]),
        _c("span", [
          _vm._v(_vm._s(_vm.$formatNumberVN(_vm.vehicle.rewardAmount)) + " đ"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }