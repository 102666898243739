var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driver-item" }, [
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Tên: ")]),
      _c("span", [_vm._v(_vm._s(_vm.driver.name))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Biển số: ")]),
      _c("span", [_vm._v(_vm._s(_vm.driver.numberPlates))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("SDT: ")]),
      _c("span", [_vm._v(_vm._s(_vm.driver.phone))]),
    ]),
    _c(
      "div",
      { staticClass: "field" },
      [
        _c("label", { attrs: { for: "" } }, [_vm._v("Tình trạng hoạt động: ")]),
        _vm.driver.workingHours.length
          ? _vm._l(_vm.driver.workingHours, function (item, index) {
              return _c("div", { key: index }, [
                _c("span", [_vm._v(_vm._s(_vm.formatTime(item)))]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#05b901",
                      "font-weight": "bold",
                      display: "inline-block",
                      "margin-left": "8px",
                    },
                  },
                  [_vm._v("ONLINE")]
                ),
              ])
            })
          : _c("span", [_vm._v("Không có hoạt động")]),
      ],
      2
    ),
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Doanh thu: ")]),
      _c("span", [
        _vm._v(
          _vm._s(_vm.$formatNumberVN(_vm.driver.summary.totalIncome || 0)) + "đ"
        ),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Đơn hoàn thành: ")]),
      _c("span", [
        _vm._v(_vm._s(_vm.driver.summary.orderComplete || 0) + " đơn"),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Đơn đang giao: ")]),
      _c("span", [
        _vm._v(_vm._s(_vm.driver.totalOrderDelivering || 0) + " đơn"),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("label", { attrs: { for: "" } }, [_vm._v("Đơn hủy: ")]),
      _c("span", [
        _vm._v(_vm._s(_vm.driver.summary.orderCancel || 0) + " đơn"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }