"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.employerApi = void 0;
var _request = require("@/utils/request");
var employerApi = exports.employerApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/employer',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/employer/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/employer',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/employer/".concat(id),
      method: 'patch',
      data: data
    });
  },
  approve: function approve(id) {
    return (0, _request.request)({
      url: "/employer/".concat(id, "/approve"),
      method: 'patch'
    });
  },
  reject: function reject(id) {
    return (0, _request.request)({
      url: "/employer/".concat(id, "/reject"),
      method: 'delete'
    });
  },
  delete: function _delete(id) {
    return (0, _request.request)({
      url: "/employer/".concat(id),
      method: 'delete'
    });
  },
  resetPassword: function resetPassword(id, data) {
    return (0, _request.request)({
      url: "/employer/".concat(id, "/resetPassword"),
      method: 'patch',
      data: data
    });
  }
};