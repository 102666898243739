var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "application-content" },
      [
        _c("head-section", { attrs: { title: "Ngân hàng" } }),
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.rules,
              "label-position": "top",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Ngân hàng", prop: "bankName" } },
              [
                _c("el-input", {
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.form.bankName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bankName", $$v)
                    },
                    expression: "form.bankName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Số tài khoản", prop: "bankNumber" } },
              [
                _c("el-input", {
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.form.bankNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bankNumber", $$v)
                    },
                    expression: "form.bankNumber",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Chi nhánh", prop: "bankBranch" } },
              [
                _c("el-input", {
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.form.bankBranch,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bankBranch", $$v)
                    },
                    expression: "form.bankBranch",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Tên chủ thẻ", prop: "bankOwner" } },
              [
                _c("el-input", {
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.form.bankOwner,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bankOwner", $$v)
                    },
                    expression: "form.bankOwner",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticClass: "btn-save",
            attrs: { type: "primary", size: "medium", loading: _vm.loading },
            on: { click: _vm.handleSubmit },
          },
          [_vm._v("Lưu")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }