var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("store-revenue-bar-chart", {
        attrs: {
          storeId: _vm.storeId,
          isUsePromotion: false,
          title: "Tổng doanh thu",
        },
        on: {
          "update:storeId": function ($event) {
            _vm.storeId = $event
          },
          "update:store-id": function ($event) {
            _vm.storeId = $event
          },
        },
      }),
      _c("store-revenue-bar-chart", {
        attrs: {
          storeId: _vm.storeId,
          isUsePromotion: true,
          title: "Doanh thu (áp dụng khuyến mãi)",
        },
        on: {
          "update:storeId": function ($event) {
            _vm.storeId = $event
          },
          "update:store-id": function ($event) {
            _vm.storeId = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }