"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEasingInterval = exports.easing = void 0;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.includes.js");
var easing = exports.easing = {
  easeOutQuad: function easeOutQuad(t, b, c, d) {
    t /= d;
    return -c * t * (t - 2) + b;
  },
  easeInQuad: function easeInQuad(t, b, c, d) {
    t /= d;
    return c * t * t + b;
  },
  random: function random(_, b, c) {
    return Math.floor(Math.random() * (c - b + 1) + b);
  },
  linear: function linear(t, b, c, d) {
    return c * t / d + b;
  }
};
var getEasingInterval = exports.getEasingInterval = function getEasingInterval(charactersArray, ignoreCharacterSet) {
  var charactersToAnimate = charactersArray.filter(function (character) {
    return !(ignoreCharacterSet !== null && ignoreCharacterSet !== void 0 && ignoreCharacterSet.includes(character));
  }).length;
  return 1 / (charactersToAnimate - 1);
};