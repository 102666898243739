var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "update"
            ? "Cập nhật chiến dịch quảng cáo"
            : "Thêm mới chiến dịch quảng cáo",
        visible: _vm.visible,
        width: "700px",
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-checkbox",
            {
              staticStyle: { "margin-bottom": "1em" },
              attrs: { indeterminate: false },
              model: {
                value: _vm.isVideoUpload,
                callback: function ($$v) {
                  _vm.isVideoUpload = $$v
                },
                expression: "isVideoUpload",
              },
            },
            [_c("b", [_vm._v("Tải lên video")])]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 12 } },
            [
              _vm.isVideoUpload
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Video bốc thăm", prop: "videoUrl" } },
                    [
                      _c("UploadVideoS3", {
                        attrs: {
                          videoUrl: _vm.form.videoUrl,
                          width: 400,
                          height: 200,
                        },
                        on: {
                          "upload:success": _vm.setVideo,
                          delete: _vm.handleDeleteVideo,
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          "Hình ảnh (Kích thước 428x471, dung lượng tối đa 2MB)",
                        prop: "imageUrl",
                      },
                    },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.imageUrl,
                          width: 200,
                          height: 200,
                        },
                        on: { "upload:success": _vm.setImage },
                      }),
                    ],
                    1
                  ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên", prop: "name" } },
                    [
                      _c("Tinymce", {
                        ref: "nameForm",
                        attrs: {
                          value: _vm.form.name,
                          width: "100%",
                          height: 50,
                          lite: true,
                          menubar: "",
                          visibleUpload: false,
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Thời gian diễn ra", prop: "datePicker" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "->",
                          "start-placeholder": "Từ ngày",
                          "end-placeholder": "Đến ngày",
                          format: "dd-MM-yyyy",
                        },
                        model: {
                          value: _vm.form.datePicker,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "datePicker", $$v)
                          },
                          expression: "form.datePicker",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Liên kết đến sản phẩm",
                        prop: "idealProductId",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "block",
                            "margin-top": "-20px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _vm._v(
                            "Để trống nếu không muốn liên kết đến sản phẩm"
                          ),
                        ]
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "value-key": "",
                            placeholder: "Không chọn sản phẩm",
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "remote-method": _vm.handleSearchProduct,
                          },
                          model: {
                            value: _vm.form.idealProductId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "idealProductId", $$v)
                            },
                            expression: "form.idealProductId",
                          },
                        },
                        _vm._l(_vm.products, function (product) {
                          return _c("el-option", {
                            key: product.id,
                            attrs: { label: product.name, value: product.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mô tả", prop: "description" } },
                    [
                      _c("Tinymce", {
                        ref: "descForm",
                        attrs: {
                          value: _vm.form.description,
                          width: "100%",
                          height: 300,
                          lite: true,
                          menubar: "",
                          visibleUpload: false,
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticStyle: {
            display: "flex",
            width: "100%",
            "justify-content": "end",
          },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.status == "create" || _vm.$checkAction("idea-strategy-update")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      _vm.status == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }