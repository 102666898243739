"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MediaControl = _interopRequireDefault(require("./MediaControl"));
var _Media = require("./components/Media.vue");
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    MediaControl: _MediaControl.default
  },
  data: function data() {
    return {
      MediaType: _Media.MediaType
    };
  }
};