"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _format = require("@/utils/format");
var _dashboard = require("@/api/dashboard");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      tableDataDelivery: [],
      tableDataFood: []
    };
  },
  props: ["listQuery"],
  watch: {
    listQuery: {
      handler: function handler(val) {
        this.getTableData();
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    // this.getTableData();
  },
  methods: {
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData() {
      var _this = this;
      (0, _dashboard.top5Customers)(this.listQuery).then(function (res) {
        _this.tableData = res.data.top5.order;
        _this.tableDataDelivery = res.data.top5.orderDelivery;
        _this.tableDataFood = res.data.top5.orderFood;
      });
    }
  }
};