var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleAdd },
              },
              [_vm._v("Thêm Khách hàng")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleAddExcel },
              },
              [_vm._v("Thêm Khách hàng (Excel)")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.customers, border: "", fit: "" } },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              width: "230px",
              prop: "nickname",
              label: "Họ tên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-link", { attrs: { type: "primary" } }, [
                      _vm._v(" " + _vm._s(row.name) + " "),
                    ]),
                    !row.isBlock
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" Active "),
                        ])
                      : _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(" Block "),
                        ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" SĐT: ")]),
                      _c("span", [_vm._v(_vm._s(row.phone))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [_vm._v(" Email: ")]),
                      _c("span", [_vm._v(_vm._s(row.email))]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(" Ngày sinh: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.birthday))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khu vực", width: "300px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "0" } }, [
                      row.address
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Số nhà, đường: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.address))]),
                          ])
                        : _vm._e(),
                      row.city
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tỉnh / thành phố: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.city.nameWithType))]),
                          ])
                        : _vm._e(),
                      row.district
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Quận huyện: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(row.district.nameWithType)),
                            ]),
                          ])
                        : _vm._e(),
                      row.ward
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phường xã: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.ward.nameWithType))]),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Tủ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tủ chính: "),
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$formatNumberVN(row.balance)) + "đ"),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tủ khuyến mãi: "),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.balancePromotion)) +
                            "đ"
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("add-customer-modal", {
        ref: "AddCustomerModal",
        attrs: { promotionId: _vm.promotion.id },
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("import-customer-modal", {
        ref: "ImportCustomerModal",
        attrs: { promotionId: _vm.promotion.id },
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }