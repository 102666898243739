var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật" : "Thêm mới",
        visible: _vm.visible,
        fullscreen: "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Thông tin", name: "info" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "80px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tiêu đề",
                                        prop: "voucher.title",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.form.voucher.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "title",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Code",
                                        prop: "voucher.code",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.form.voucher.code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "code",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        label: "Chiến dịch bắt đầu",
                                        prop: "voucher.start",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          "value-format": "timestamp",
                                          type: "date",
                                          placeholder: "Pick a day",
                                        },
                                        model: {
                                          value: _vm.form.voucher.start,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "start",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.start",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Chiến dịch kết thúc",
                                        prop: "voucher.end",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          format: "dd/MM/yyyy",
                                          "value-format": "timestamp",
                                          type: "date",
                                          placeholder: "Pick a day",
                                        },
                                        model: {
                                          value: _vm.form.voucher.end,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "end",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.end",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Số lượng mỗi khách được nhận",
                                        prop: "voucher.quantity",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                            },
                                            expression:
                                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                          },
                                        ],
                                        model: {
                                          value: _vm.form.voucher.quantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "quantity",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.quantity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Giá trị đơn hàng tối thiểu",
                                        prop: "voucher.conditionMinPrice",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                            },
                                            expression:
                                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                          },
                                        ],
                                        model: {
                                          value:
                                            _vm.form.voucher.conditionMinPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "conditionMinPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.voucher.conditionMinPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Giảm giá theo",
                                        prop: "voucher.discountType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { placeholder: "" },
                                          model: {
                                            value:
                                              _vm.form.voucher.discountType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.voucher,
                                                "discountType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.voucher.discountType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "Theo giá",
                                              value: "PRICE",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "Theo phần trăm",
                                              value: "PERCENT",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: { height: "93px" },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Giá trị giảm giá",
                                        prop: "voucher.discountValue",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: {
                                                alias: "decimal",
                                                groupSeparator: ".",
                                                rightAlign: false,
                                                autoGroup: true,
                                                repeat: 20,
                                                autoUnmask: true,
                                                allowMinus: false,
                                              },
                                              expression:
                                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                            },
                                          ],
                                          model: {
                                            value:
                                              _vm.form.voucher.discountValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.voucher,
                                                "discountValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.voucher.discountValue",
                                          },
                                        },
                                        [
                                          _c("template", { slot: "append" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.form.voucher.discountType ==
                                                  _vm.DiscountType.Price
                                                  ? "VNĐ"
                                                  : "%"
                                              )
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              this.form.voucher.discountType ==
                              _vm.DiscountType.Percent
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Số tiền giảm tối đa (đ)",
                                            prop: "voucher.discountValueMax",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value: {
                                                  alias: "decimal",
                                                  groupSeparator: ".",
                                                  rightAlign: false,
                                                  autoGroup: true,
                                                  repeat: 20,
                                                  autoUnmask: true,
                                                  allowMinus: false,
                                                },
                                                expression:
                                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                              },
                                            ],
                                            attrs: {
                                              placeholder:
                                                "Để trống sẽ không áp dụng",
                                            },
                                            model: {
                                              value:
                                                _vm.form.voucher
                                                  .discountValueMax,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.voucher,
                                                  "discountValueMax",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.voucher.discountValueMax",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Giá bán voucher",
                                        prop: "voucher.price",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                            },
                                            expression:
                                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                          },
                                        ],
                                        model: {
                                          value: _vm.form.voucher.price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "price",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Số lượng voucher cần bán",
                                        prop: "voucher.remain",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              alias: "decimal",
                                              groupSeparator: ".",
                                              rightAlign: false,
                                              autoGroup: true,
                                              repeat: 20,
                                              autoUnmask: true,
                                              allowMinus: false,
                                            },
                                            expression:
                                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                          },
                                        ],
                                        model: {
                                          value: _vm.form.voucher.remain,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "remain",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.remain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { span: 12 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Hình ảnh - Hiển thị ở chi tiết khuyến mãi (Tỷ lệ: 20/8, khuyến nghị: 428 x 171, dung lượng tối đa 2MB)",
                                        prop: "voucher.image",
                                      },
                                    },
                                    [
                                      _c("SingleImageS3", {
                                        attrs: {
                                          pathImage: _vm.form.voucher.image,
                                          prefixFile: _vm.PrefixImage.Promotion,
                                          width: 300,
                                          height: 120,
                                        },
                                        on: { "upload:success": _vm.setImage },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label:
                                          "Hình ảnh - Hiển thị ở màn hình tìm quán (Tỷ lệ: 1/1, khuyến nghị: 700 x 700, dung lượng tối đa 2MB)",
                                        prop: "voucher.thumbnail",
                                      },
                                    },
                                    [
                                      _c("SingleImageS3", {
                                        attrs: {
                                          width: 200,
                                          height: 200,
                                          prefixFile:
                                            _vm.PrefixImage.PromotionSquare,
                                          pathImage: _vm.form.voucher.thumbnail,
                                        },
                                        on: {
                                          "upload:success": _vm.setThumbnail,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12, offset: 0 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Mô tả", prop: "body" } },
                                    [
                                      _c("Tinymce", {
                                        ref: "editor",
                                        attrs: { width: "100%", height: 400 },
                                        on: { onInit: _vm.setBody },
                                        model: {
                                          value: _vm.form.voucher.body,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.voucher,
                                              "body",
                                              $$v
                                            )
                                          },
                                          expression: "form.voucher.body",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Khách hàng", name: "customer" } },
                [
                  _c("customer", {
                    attrs: { promotion: _vm.form.voucher },
                    on: { "submit:ok": _vm.fetchDetail },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }