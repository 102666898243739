"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var reportApi = exports.reportApi = {
  customerAndDriver: function customerAndDriver(params) {
    return (0, _request.default)({
      url: '/report/customerAndDriver',
      params: params
    });
  },
  customerAndDriverDistrict: function customerAndDriverDistrict(params) {
    return (0, _request.default)({
      url: '/report/customerAndDriver/district',
      params: params
    });
  },
  orderDistrict: function orderDistrict(params) {
    return (0, _request.default)({
      url: '/report/order/district',
      params: params
    });
  },
  order: function order(params) {
    return (0, _request.default)({
      url: '/report/order',
      params: params
    });
  }
};