var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        "label-width": "80px",
        "label-position": "top",
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Số tài" } },
                [
                  _c("el-input", {
                    attrs: { placehol: "", der: "" },
                    model: {
                      value: _vm.form.driverNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "driverNumber", $$v)
                      },
                      expression: "form.driverNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Địa chỉ hộ khẩu" } },
                [
                  _c("el-input", {
                    attrs: { placehol: "", der: "" },
                    model: {
                      value: _vm.form.permanentAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "permanentAddress", $$v)
                      },
                      expression: "form.permanentAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Địa chỉ thường trú theo giấy tạm trú" } },
                [
                  _c("el-input", {
                    attrs: { placehol: "", der: "" },
                    model: {
                      value: _vm.form.stayingAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stayingAddress", $$v)
                      },
                      expression: "form.stayingAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Người giới thiệu" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.form.introduceBy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "introduceBy", $$v)
                      },
                      expression: "form.introduceBy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Đơn vị quản lý" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.form.managerUnit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "managerUnit", $$v)
                      },
                      expression: "form.managerUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Người quản lý" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "" },
                    model: {
                      value: _vm.form.managerBy,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "managerBy", $$v)
                      },
                      expression: "form.managerBy",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Lịch sử vi phạm giao thông" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", placehol: "", der: "" },
            model: {
              value: _vm.form.historyTrafficViolation,
              callback: function ($$v) {
                _vm.$set(_vm.form, "historyTrafficViolation", $$v)
              },
              expression: "form.historyTrafficViolation",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: " Lịch sử vi phạm nội quy Fclass" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", placehol: "", der: "" },
            model: {
              value: _vm.form.historyRulesViolation,
              callback: function ($$v) {
                _vm.$set(_vm.form, "historyRulesViolation", $$v)
              },
              expression: "form.historyRulesViolation",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }