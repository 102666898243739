"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agentOrderApi = void 0;
var _request = require("@/utils/request");
var agentOrderApi = exports.agentOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.request)({
      url: '/agentOrder',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id)
    });
  },
  create: function create(data) {
    return (0, _request.request)({
      url: '/agentOrder',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id),
      method: 'patch',
      data: data
    });
  },
  confirm: function confirm(id) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id, "/confirm"),
      method: 'patch'
    });
  },
  delivering: function delivering(id) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id, "/delivering"),
      method: 'patch'
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id, "/cancel"),
      method: 'patch',
      data: data
    });
  },
  return: function _return(id, data) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id, "/return"),
      method: 'patch',
      data: data
    });
  },
  complete: function complete(id) {
    return (0, _request.request)({
      url: "/agentOrder/".concat(id, "/complete"),
      method: 'patch'
    });
  },
  summaryStatus: function summaryStatus(params) {
    return (0, _request.request)({
      url: '/agentOrder/summary/status',
      params: params
    });
  }
};