var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm kiếm...",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Sản phẩm")]),
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "Nhập để tìm kiếm",
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "remote-method": _vm.handleSearchProduct,
                  },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.idealProductId,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "idealProductId", $$v)
                    },
                    expression: "query.idealProductId",
                  },
                },
                _vm._l(_vm.products, function (product) {
                  return _c("el-option", {
                    key: product.id,
                    attrs: {
                      label: product.name + " (" + product.code + ")",
                      value: product.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "", remote: "" },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.isDraw,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "isDraw", $$v)
                    },
                    expression: "query.isDraw",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "Tất cả", value: undefined },
                  }),
                  _c("el-option", {
                    attrs: { label: "Đã trúng", value: true },
                  }),
                  _c("el-option", {
                    attrs: { label: "Chưa trúng", value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.fetchData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "22px" },
              attrs: {
                type: "primary",
                icon: "el-icon-download",
                loading: _vm.loadingExport,
              },
              on: { click: _vm.handleExportExcel },
            },
            [_vm._v(" Xuất excel ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-custom" },
        [
          _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.query.page,
              limit: _vm.query.limit,
              pageSizes: [10, 20, 30, 50, 100],
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.query, "limit", $event)
              },
              pagination: _vm.fetchData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Thăm", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticStyle: { "font-size": "15px" } }, [
                      _c("b", [_vm._v(" " + _vm._s(row.code))]),
                    ]),
                    _c("br"),
                    _c("i", [
                      _vm._v(
                        "Tạo ngày: " +
                          _vm._s(_vm.$formatDateTime(row.assignedAt))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customer
                      ? _c(
                          "div",
                          [
                            _c("b", { staticStyle: { "font-size": "15px" } }, [
                              _vm._v(_vm._s(row.customer.name)),
                            ]),
                            _c("br"),
                            !row.customer.isBlock
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _c("b", [_vm._v("Active")]),
                                ])
                              : _c("el-tag", { attrs: { type: "danger" } }, [
                                  _c("b", [_vm._v("Block")]),
                                ]),
                            _c("div", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" SĐT: "),
                              ]),
                              _c("span", [_vm._v(_vm._s(row.customer.phone))]),
                            ]),
                            _c("div", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Email: "),
                              ]),
                              _c("span", [_vm._v(_vm._s(row.customer.email))]),
                            ]),
                          ],
                          1
                        )
                      : _c("div", [
                          _vm._v("Mã dự thưởng chưa có người sở hữu"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Đơn hàng", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.isGift
                      ? _c("div", [
                          _c("ul", { staticClass: "list-code" }, [
                            _c("li", [
                              _c("label", { attrs: { for: "" } }),
                              _c("span", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(row.idealOrderDetail.idealOrder.code)
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("li", [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Trạng thái: "),
                              ]),
                              _c("span", [
                                _c(
                                  "b",
                                  {
                                    class:
                                      "color-" +
                                      (_vm.tagOptions[
                                        row.idealOrderDetail.idealOrder.status
                                      ] &&
                                        _vm.tagOptions[
                                          row.idealOrderDetail.idealOrder.status
                                        ].color),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.IdealOrderStatusTrans[
                                          row.idealOrderDetail.idealOrder.status
                                        ]
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("li", [
                              _c("label", { attrs: { for: "" } }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatDateTime(
                                      row.idealOrderDetail.dateCreated
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          row.shipCode
                            ? _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [_c("el-tag", [_vm._v(_vm._s(row.shipCode))])],
                                1
                              )
                            : _vm._e(),
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  size: "normal",
                                  effect: "dark",
                                },
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    "Mã dự thưởng tặng cho người giới thiệu"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Giải thưởng", prop: "prize" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "ul",
                      { staticStyle: { "padding-left": "20px", margin: "0" } },
                      _vm._l(row.idealProduct.idealPrizes, function (prize) {
                        return _c(
                          "li",
                          { key: prize.id },
                          [
                            prize.type == _vm.idealPrizeType.Main
                              ? _c(
                                  "el-tag",
                                  { attrs: { size: "mini", effect: "dark" } },
                                  [_c("b", [_vm._v("Giải chính")])]
                                )
                              : _c("el-tag", { attrs: { size: "mini" } }, [
                                  _c("b", [_vm._v("Giải phụ")]),
                                ]),
                            _vm._v(
                              " " +
                                _vm._s(prize.name) +
                                " (" +
                                _vm._s(prize.used) +
                                "/" +
                                _vm._s(prize.quantity) +
                                ") - Giá: " +
                                _vm._s(_vm.$formatNumberVN(prize.finalPrice)) +
                                " VNĐ "
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c("el-divider", {
                      staticClass: "custom-divider",
                      staticStyle: { margin: "10px 0" },
                    }),
                    _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                      _c("b", [_vm._v("Ngày bốc thăm dự kiến:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatDateTime(
                              row.idealProduct.maxDrawDateAt,
                              "HH:mm, DD/MM/YYYY"
                            )
                          )
                      ),
                    ]),
                    row.idealProduct.idealDrawHistories.length &&
                    _vm.$formatDateTime(
                      row.idealProduct.idealDrawHistories[0].dateCreated,
                      "YYYY-MM-DD"
                    ) !=
                      _vm.$formatDateTime(
                        row.idealProduct.maxDrawDate,
                        "YYYY-MM-DD"
                      )
                      ? _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                          _c("b", [_vm._v("Ngày bốc thăm thực tế:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatDateTime(
                                  row.idealProduct.idealDrawHistories[0]
                                    .dateCreated,
                                  "HH:mm, DD/MM/YYYY"
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Trạng thái thăm", prop: "prize" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.idealProduct.idealDrawHistories.length
                      ? _c("div", [
                          row.idealAward
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: {
                                        type: "success",
                                        size: "normal",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-star-on",
                                      }),
                                      _vm._v(" "),
                                      _c("b", [_vm._v("Đã trúng")]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(row.idealAward.idealPrize.name)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("p", [
                                    row.isReward
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#1890ff" } },
                                          [
                                            _c("b", [
                                              _vm._v("(Đã trao thưởng)"),
                                            ]),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _c("b", [
                                              _vm._v("(Chưa trao thưởng)"),
                                            ]),
                                          ]
                                        ),
                                  ]),
                                  !row.isReward
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.handleConfirmDraw(
                                                row.id
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Xác nhận đã trao thưởng")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "danger", size: "normal" },
                                    },
                                    [_c("b", [_vm._v("Không trúng")])]
                                  ),
                                ],
                                1
                              ),
                        ])
                      : _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              { attrs: { type: "warning", size: "normal" } },
                              [_c("b", [_vm._v("Chưa bốc thăm")])]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-custom" },
        [
          _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.query.page,
              limit: _vm.query.limit,
              pageSizes: [10, 20, 30, 50, 100],
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.query, "limit", $event)
              },
              pagination: _vm.fetchData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }