"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cardOrderDashboardApi = void 0;
var _request = require("@/utils/request");
var cardOrderDashboardApi = exports.cardOrderDashboardApi = {
  summary: function summary(params) {
    return (0, _request.request)({
      url: '/dashboard/cardOrder/summaryByDate',
      params: params
    });
  }
};