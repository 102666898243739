var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "sponsor-gift-submit",
      attrs: {
        title: "Yêu cầu nhận quà khuyến học/thiện nguyện",
        visible: _vm.visible,
        width: "800px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _vm.isHadData
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "left",
                size: "mini",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _c("h3", { staticClass: "color-blue" }, [
                            _vm._v("Thông tin người yêu cầu nhận quà"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Tên" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.name))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Ngày sinh" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDate(_vm.form.dob))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "CCCD số" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.idNumber))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Cấp ngày" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatDate(_vm.form.idDate))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Địa chỉ thường trú" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.address))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "Địa chỉ nhà" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.homeAddress))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tỉnh/Thành phố" } },
                                [
                                  _vm.form.city
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.form.city.name)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Quận/Huyện" } },
                                [
                                  _vm.form.district
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.form.district.name)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Phường/Xã" } },
                                [
                                  _vm.form.ward
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.form.ward.name)),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Email" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.email))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số điện thoại" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.customer.phone))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "MSSV/ Công việc" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.mssv))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "MSL/ Chức danh" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.msl))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Bậc học/ Đơn vị" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.bacHoc))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Ngành ĐK" } }, [
                        _vm.form.sponsorMajor
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.form.sponsorMajor.name)),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm.form.school
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-form-item", { attrs: { label: "Trường" } }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.form.school.name)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Mã trường" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.form.school.code)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Địa chỉ trường" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.form.school.address)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _c("h3", { staticClass: "color-blue" }, [
                            _vm._v("Thông tin đơn vị khuyến học/thiện nguyện"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tên Cá nhân/Tập thể/Tổ chức" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.sponsor.name))])]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Địa chỉ giao quà KH" } },
                        [_c("span", [_vm._v(_vm._s(_vm.form.sponsor.address))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số điện thoại" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.sponsor.contactPhone)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Tỉnh/Thành phố" } },
                                [
                                  _vm.form.sponsor.city
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.form.sponsor.city.name)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Quận/Huyện" } },
                                [
                                  _vm.form.sponsor.district
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.form.sponsor.district.name)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Phường/Xã" } },
                                [
                                  _vm.form.sponsor.ward
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.form.sponsor.ward.name)
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Bên giao quà KH" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.sponsorGift.peopleGiftName)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số điện thoại" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.sponsorGift.contactPhone)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _c("h3", { staticClass: "color-blue" }, [
                            _vm._v(
                              "Thông tin cá nhân nhận khuyến học/thiện nguyện"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "Quà tặng cho" } }, [
                        _vm.giftDetail.sponsorGiftObjects &&
                        _vm.giftDetail.sponsorGiftObjects.length
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.giftDetail.sponsorGiftObjects
                                    .map(function (e) {
                                      return e.name
                                    })
                                    .join(", ")
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.SponsorGiftObjectTrans[
                                    _vm.form.sponsorGift.object
                                  ]
                                )
                              ),
                            ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "Giới tính" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              !!_vm.form.sponsorGift.gender
                                ? _vm.GenderTypeTrans[
                                    _vm.form.sponsorGift.gender
                                  ]
                                : "Tất cả"
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "Độ tuổi" } }, [
                        _vm.giftDetail.sponsorGiftAge
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.giftDetail.sponsorGiftAge.name)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Thuộc đơn vị" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.form.sponsorGift.tenDonVi)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Ở tại số" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.form.sponsorGift.giftAddress)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tỉnh/Thành phố" } },
                        [
                          _vm.form.sponsorGift.city
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.form.sponsorGift.city.name)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "Quận/Huyện" } }, [
                        _vm.form.sponsorGift.district
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.form.sponsorGift.district.name)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "Phường/Xã" } }, [
                        _vm.form.sponsorGift.ward
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.form.sponsorGift.ward.name)),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _c("h3", { staticClass: "color-blue" }, [
                            _vm._v("Thông tin quà khuyến học/thiện nguyện"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Tên quà tặng" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.form.sponsorGift.giftName)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Đơn vị tính" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.sponsorGift.unit))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Số lượng" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$formatNumberVN(_vm.form.sponsorGift.quantity)
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "Đơn giá" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$formatNumberVN(_vm.form.sponsorGift.price)
                            ) + "đ"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "Tổng giá trị" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$formatNumberVN(
                                _vm.form.sponsorGift.totalMoney
                              )
                            ) + "đ"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Số lượng của 01 phần quà KH" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$formatNumberVN(
                                  _vm.form.sponsorGift.quantityOnPerson
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tổng số phần quà" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$formatNumberVN(
                                  _vm.form.sponsorGift.quantity /
                                    _vm.form.sponsorGift.quantityOnPerson
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _c("h3", { staticClass: "color-blue" }, [
                            _vm._v(
                              "Quy định về nhận quà khuyến học/thiện nguyện"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.giftDetail.sponsorGiftDetails &&
                  !_vm.giftDetail.sponsorGiftDetails.length
                    ? [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isHocLoai
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Học loại" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.sponsorGift.hocLoaiText)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isDiemTB
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Điểm TB các môn cả năm >",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.sponsorGift.diemTBText)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isGiaoTiep
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Kỹ năng giao tiếp" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.sponsorGift.giaoTiepText
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isVangLoi
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Biết vâng lời" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.sponsorGift.vangLoiText)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isSiengNang
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Siêng năng" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.sponsorGift.siengNangText
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isKyLuat
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Kỷ luật" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.sponsorGift.kyLuatText)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isHoanCanh
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Hoàn cảnh gia đình" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.sponsorGift.hoanCanhText
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _vm.form.sponsorGift.isOther
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "Khác" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.form.sponsorGift.otherText)
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._l(_vm.groupSponsorGiftDetail, function (item) {
                        return _c(
                          "el-col",
                          {
                            key: item.scholarshipCondition.id,
                            attrs: { span: 12 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: item.scholarshipCondition.name,
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.value ||
                                        item.scholarshipConditionDetails
                                          .map(function (e) {
                                            return e.name
                                          })
                                          .join(", ")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          "margin-top": "10px",
                          "margin-bottom": "8px",
                        },
                      },
                      [_vm._v("Tệp đính kèm")]
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.form.fileAttaches, function (item) {
                          return _c(
                            "el-col",
                            { key: item.id, attrs: { span: 4 } },
                            [
                              _c("div", { staticClass: "item-attach" }, [
                                item.type == _vm.FileAttachType.Image
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                          },
                                          attrs: {
                                            src: item.url,
                                            fit: "contain",
                                            "preview-src-list": [item.url],
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.type == _vm.FileAttachType.File
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "column",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-document",
                                          staticStyle: { "font-size": "3rem" },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "color-blue",
                                            staticStyle: {
                                              cursor: "pointer",
                                              "margin-top": "12px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleViewPdf(
                                                  item.url
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-download",
                                            }),
                                            _c("span", [_vm._v(" Tải xuống")]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }