var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: { label: "No.", type: "index", "class-name": "text-center" },
          }),
          _c("el-table-column", { attrs: { label: "Tên", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Nick name", prop: "nickname" },
          }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
          }),
          _c("el-table-column", { attrs: { label: "Email", prop: "email" } }),
          _c("el-table-column", {
            attrs: { label: "Đơn đặt xe", prop: "orders" },
          }),
        ],
        1
      ),
      _c("div", { staticStyle: { height: "50px" } }),
      _c(
        "el-table",
        { attrs: { data: _vm.tableDataDelivery } },
        [
          _c("el-table-column", {
            attrs: { label: "No.", type: "index", "class-name": "text-center" },
          }),
          _c("el-table-column", { attrs: { label: "Tên", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "Nick name", prop: "nickname" },
          }),
          _c("el-table-column", {
            attrs: { label: "Số điện thoại", prop: "phone" },
          }),
          _c("el-table-column", { attrs: { label: "Email", prop: "email" } }),
          _c("el-table-column", {
            attrs: { label: "Đơn giao hàng", prop: "orders" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }