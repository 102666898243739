var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "date-box",
          staticStyle: { display: "flex", "margin-bottom": "10px" },
        },
        [
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": "To",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _c("bar-chart", {
        ref: "chart",
        attrs: {
          "data-chart": _vm.chartData,
          "pointer-name": "Tổng tiền",
          "attr-get": "money",
          "bar-color": "#34bfa3",
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.chartData = $event
          },
          "update:data-chart": function ($event) {
            _vm.chartData = $event
          },
        },
      }),
      _c("p", { staticClass: "chart-title" }, [
        _vm._v("Tổng tiền đơn hàng theo ngày"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }