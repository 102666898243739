"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contentDefineApi = void 0;
exports.findAll = findAll;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// @ts-check

function findAll() {
  return (0, _request.default)({
    url: "/contentDefine"
  });
}
function update(id, data) {
  return (0, _request.default)({
    url: "/contentDefine/".concat(id, "/update"),
    method: "post",
    data: data
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/contentDefine",
    data: data,
    method: "post"
  });
}
var contentDefineApi = exports.contentDefineApi = {
  findAll: findAll,
  update: update,
  create: create
};