"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countRemainingDays = countRemainingDays;
function countRemainingDays(expiredAt) {
  var now = new Date().getTime();
  var remainingTime = expiredAt * 1000 - now; // chuyển expiredAt thành miliseconds và trừ đi thời điểm hiện tại
  var remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24)); // chia cho số miliseconds trong một ngày và làm tròn lên để lấy số ngày
  return remainingDays;
}