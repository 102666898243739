"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AgentTransactionTypeTrans = exports.AgentTransactionType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var AgentTransactionType;
(function (AgentTransactionType) {
  AgentTransactionType["Order"] = "ORDER";
  AgentTransactionType["CommissionOrder"] = "COMMISSION_ORDER";
  AgentTransactionType["RejectWithdraw"] = "REJECT_WITHDRAW";
  AgentTransactionType["Withdraw"] = "WITHDRAW";
  AgentTransactionType["CommissionLine"] = "COMMISSION_LINE";
})(AgentTransactionType || (exports.AgentTransactionType = AgentTransactionType = {}));
var AgentTransactionTypeTrans = exports.AgentTransactionTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, AgentTransactionType.CommissionOrder, "Tiền chiết khấu đơn hàng"), AgentTransactionType.Order, "Tiền đơn hàng"), AgentTransactionType.RejectWithdraw, "Hủy lệnh rút"), AgentTransactionType.Withdraw, "Rút tiền"), AgentTransactionType.CommissionLine, "Hoa hồng theo cấp");