var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  "prefix-icon": "el-icon-search",
                  placeholder: "Tìm tên, sđt, cmnd, email",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.getTableData($event)
                  },
                },
                model: {
                  value: _vm.listQuery.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "search", $$v)
                  },
                  expression: "listQuery.search",
                },
              }),
            ],
            1
          ),
          _c("address-filter", {
            attrs: { query: _vm.listQuery },
            on: { "change:address": _vm.getTableData },
          }),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Loại xe")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.vehicleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "vehicleId", $$v)
                    },
                    expression: "listQuery.vehicleId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(_vm.vehicles, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: {
                        label: item.name + " - " + item.subName,
                        value: item.id,
                      },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Sắp xếp")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.sort,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "sort", $$v)
                    },
                    expression: "listQuery.sort",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "Ngày tạo", value: "CREATE" },
                  }),
                  _c("el-option", {
                    attrs: { label: "Ngày duyệt", value: "APPROVE" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Trạng thái hồ sơ")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.profileStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "profileStatus", $$v)
                    },
                    expression: "listQuery.profileStatus",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _vm._l(
                    Object.values(_vm.ProfileStatus).filter(function (e) {
                      return e != "COMPLETE"
                    }),
                    function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: {
                          label: _vm.ProfileStatusTrans[item],
                          value: item,
                        },
                      })
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Shipper")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "" },
                  on: { change: _vm.getTableData },
                  model: {
                    value: _vm.listQuery.isAcceptedMove,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "isAcceptedMove", $$v)
                    },
                    expression: "listQuery.isAcceptedMove",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "Tất cả", value: null } }),
                  _c("el-option", {
                    attrs: { label: "Là shipper", value: true },
                  }),
                  _c("el-option", {
                    attrs: { label: "Không là shipper", value: false },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-top": "22px" },
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getTableData()
                },
              },
            },
            [_vm._v(" Tìm ")]
          ),
          _vm.checkPermissionExport
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-top": "22px" },
                  attrs: {
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExport,
                  },
                  on: { click: _vm.handleExportExcelDriver },
                },
                [_vm._v(" Xuất excel ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "40" },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "username small-padding fixed-width",
              width: "130",
              prop: "name",
              label: "Họ tên",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "flex-direction": "column",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("div", { staticClass: "driver-status" }, [
                          _vm.checkOnline(row.lastOnlineAt)
                            ? _c("div", [
                                _c("span", { staticClass: "dot online" }),
                                _c("span", { staticClass: "content" }, [
                                  _vm._v("Đang online"),
                                ]),
                              ])
                            : _c("div", [
                                _c("span", { staticClass: "dot offline" }),
                                _c("span", { staticClass: "content" }, [
                                  _vm._v("Mở app lần cuối: "),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatFromNow(row.lastOnlineAt))
                                  ),
                                ]),
                              ]),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "technician",
                            staticStyle: { "font-weight": "bold" },
                            attrs: { type: "primary" },
                          },
                          [_vm._v(" " + _vm._s(row.name) + " ")]
                        ),
                        _c(
                          "div",
                          [
                            !row.isBlock
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v(" Active "),
                                ])
                              : _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(" Block "),
                                ]),
                          ],
                          1
                        ),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v(" Xem hồ sơ ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200px", label: "Thông tin", prop: "balance" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "8px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tủ chính:"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formatCurrency(row.balance))),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tủ khuyến mãi:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatCurrency(row.balancePromotion))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Email:")]),
                        _c("span", [_vm._v(_vm._s(row.email))]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [_vm._v("SĐT:")]),
                        _c("span", [_vm._v(_vm._s(row.phone))]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Mã giới thiệu:"),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.refCode))]),
                      ]),
                      row.parent
                        ? _c(
                            "li",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Giới thiệu (Cá nhân):"),
                              ]),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(row.parent)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.parent.name) + " ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      row.companyRef
                        ? _c(
                            "li",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Giới thiệu (Tổ chức):"),
                              ]),
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(" " + _vm._s(row.companyRef.name) + " "),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "li",
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Giới thiệu:"),
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleViewRef(row)
                                },
                              },
                            },
                            [_vm._v(" Danh sách ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "200px", label: "Thông tin xe" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "8px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Biển số:"),
                        ]),
                        _c("span", [_vm._v(_vm._s(row.numberPlates))]),
                      ]),
                      row.vehicleGroup
                        ? _c("li", [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Nhóm:"),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.vehicleGroup.name))]),
                          ])
                        : _vm._e(),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Loại xe:"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.generateVehicle(row.vehicles))),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Khu vực:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(row.city && row.city.nameWithType) +
                              " - " +
                              _vm._s(
                                row.district && row.district.nameWithType
                              ) +
                              " - " +
                              _vm._s(row.ward && row.ward.nameWithType)
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Người duyệt",
              prop: "completedStaff.name",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticStyle: { "padding-left": "8px" } }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Nhân viên duyệt hồ sơ online: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.approvedStaff ? row.approvedStaff.name : "--"
                            ) +
                              " (" +
                              _vm._s(_vm.$formatDateTime(row.acceptedAt)) +
                              ")"
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Nhân viên duyệt hồ sơ chính thức: "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.completedStaff
                                ? row.completedStaff.name
                                : "--"
                            ) +
                              " (" +
                              _vm._s(_vm.$formatDateTime(row.approvedAt)) +
                              ")"
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "100", label: "Trạng thái hồ sơ" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          "white-space": "normal",
                          height: "auto",
                        },
                        attrs: {
                          type:
                            _vm.tagOptions[row.profileStatus] &&
                            _vm.tagOptions[row.profileStatus].type,
                          effect:
                            _vm.tagOptions[row.profileStatus] &&
                            _vm.tagOptions[row.profileStatus].effect,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.ProfileStatusTrans[row.profileStatus]) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "50", label: "Shipper" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      attrs: { value: row.isAcceptedMove },
                      on: {
                        change: function ($event) {
                          return _vm.handleChangeAcceptMove(row, $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "60", label: "Đồng phục" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.driverProfile
                      ? _c("el-checkbox", {
                          attrs: {
                            value:
                              row.driverProfile && row.driverProfile.isUniform,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleChangeAcceptUniform(row, $event)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Hành động",
              width: "120",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px 0" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDetail(row)
                              },
                            },
                          },
                          [_vm._v(" Lịch sử khác ")]
                        ),
                        !row.isBlock
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { margin: "5px 0" },
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBlock(row)
                                  },
                                },
                              },
                              [_vm._v(" Khóa ")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: { margin: "5px 0" },
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUnBlock(row)
                                  },
                                },
                              },
                              [_vm._v(" Mở khóa ")]
                            ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px 0" },
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleLogout(row)
                              },
                            },
                          },
                          [_vm._v(" Logout ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("driver-dialog", {
        ref: "user-dialog",
        on: {
          "create:success": _vm.getTableData,
          "update:success": _vm.getTableData,
        },
      }),
      _c("driver-ref-dialog", { ref: "driver-ref" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }