"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdrawStoreApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var withdrawStoreApi = exports.withdrawStoreApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/withdrawStore',
      params: params
    });
  },
  promotion: function promotion(data) {
    return (0, _request.default)({
      url: '/withdrawStore/promotion',
      method: 'post',
      data: data
    });
  },
  main: function main(data) {
    return (0, _request.default)({
      url: '/withdrawStore',
      method: 'post',
      data: data
    });
  },
  cancel: function cancel(id, data) {
    return (0, _request.default)({
      url: "/withdrawStore/".concat(id, "/cancel"),
      method: "post",
      data: data
    });
  },
  complete: function complete(id, data) {
    return (0, _request.default)({
      url: "/withdrawStore/".concat(id, "/complete"),
      method: "post",
      data: data
    });
  }
};