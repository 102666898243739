var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "update" ? "Cập nhật trường" : "Thêm mới trường",
        visible: _vm.visible,
        width: "1200px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "info", label: "Thông tin" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                _c("SchoolInfo", {
                  ref: "SchoolInfo",
                  attrs: {
                    form: _vm.form,
                    formDisable: false,
                    dialogStatus: _vm.status,
                  },
                  on: {
                    "update:form": function ($event) {
                      _vm.form = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.status == "update" && _vm.form.status == _vm.SchoolStatus.Approve
            ? _c(
                "el-tab-pane",
                { attrs: { name: "transaction", label: "Lịch sử giao dịch" } },
                [
                  _c("SchoolTransactionPage", {
                    attrs: { schoolId: _vm.form.id },
                    on: {
                      "update:schoolId": function ($event) {
                        return _vm.$set(_vm.form, "id", $event)
                      },
                      "update:school-id": function ($event) {
                        return _vm.$set(_vm.form, "id", $event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }