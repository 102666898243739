"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findAll = findAll;
exports.orderDeliveryApi = void 0;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// @ts-check

function findAll(listQuery) {
  return (0, _request.default)({
    url: "/orderDelivery",
    params: listQuery
  });
}
function update(orderId, data) {
  return (0, _request.default)({
    url: "/orderDelivery/".concat(orderId, "/status/update"),
    method: "post",
    data: data
  });
}
var orderDeliveryApi = exports.orderDeliveryApi = {
  findAll: findAll,
  update: update,
  findOne: function findOne(orderId) {
    return (0, _request.default)({
      url: "/orderDelivery/".concat(orderId)
    });
  },
  complete: function complete(orderId) {
    return (0, _request.default)({
      url: "/orderDelivery/".concat(orderId, "/complete"),
      method: "post"
    });
  },
  cancel: function cancel(orderId) {
    return (0, _request.default)({
      url: "/orderDelivery/".concat(orderId, "/cancel"),
      method: "post"
    });
  },
  est: function est(data) {
    return (0, _request.default)({
      url: "/orderDelivery/estimate",
      method: "post",
      data: data
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/orderDelivery",
      method: "post",
      data: data
    });
  }
};