"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MallBannerType = void 0;
var MallBannerType;
(function (MallBannerType) {
  MallBannerType["MallProduct"] = "MALL_PRODUCT";
  MallBannerType["Mall"] = "MALL"; //Chỉ định 1 cửa hàng mall
})(MallBannerType || (exports.MallBannerType = MallBannerType = {}));