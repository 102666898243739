var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        rules: _vm.rules,
        model: _vm.form,
        "label-position": "top",
        "label-width": "120px",
      },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Tên cửa hàng", prop: "name" } },
        [
          _c("el-input", {
            attrs: { disabled: "" },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Tên đăng nhập", prop: "username" } },
        [
          _c("el-input", {
            model: {
              value: _vm.form.username,
              callback: function ($$v) {
                _vm.$set(_vm.form, "username", $$v)
              },
              expression: "form.username",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Mật khẩu", prop: "password" } },
        [
          _c("el-input", {
            attrs: { type: "password", "show-password": "" },
            model: {
              value: _vm.form.password,
              callback: function ($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password",
            },
          }),
        ],
        1
      ),
      !_vm.form.username && !_vm.form.lastPasswordUpdated
        ? _c(
            "el-tag",
            {
              staticStyle: { "margin-bottom": "12px" },
              attrs: { type: "danger", size: "normal" },
            },
            [
              _vm._v(
                "Chưa cập nhật mật khẩu tài khoản. Vui lòng cập nhật mật khẩu."
              ),
            ]
          )
        : _c(
            "el-tag",
            {
              staticStyle: { "margin-bottom": "12px" },
              attrs: { type: "info", size: "normal" },
            },
            [
              _vm._v(
                "Cập nhật mật khẩu lần cuối: " +
                  _vm._s(_vm.$formatDateTime(_vm.form.lastPasswordUpdated))
              ),
            ]
          ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("Cập nhật tài khoản")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }