"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MedicTypeTrans = exports.MedicType = exports.MedicStatus = exports.MedicSpecTrans = exports.MedicSpec = exports.MedicSizeTrans = exports.MedicSize = exports.MedicServicePricingTypeTrans = exports.MedicServicePricingType = exports.MedicInsuranceType = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MedicStatus;
(function (MedicStatus) {
  MedicStatus["Pending"] = "PENDING";
  MedicStatus["Approve"] = "APPROVE";
  MedicStatus["Reject"] = "REJECT";
})(MedicStatus || (exports.MedicStatus = MedicStatus = {}));
var MedicServicePricingType;
(function (MedicServicePricingType) {
  MedicServicePricingType["Normal"] = "NORMAL";
  MedicServicePricingType["Insurance"] = "INSURANCE";
})(MedicServicePricingType || (exports.MedicServicePricingType = MedicServicePricingType = {}));
var MedicServicePricingTypeTrans = exports.MedicServicePricingTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MedicServicePricingType.Normal, 'Dịch vụ thường'), MedicServicePricingType.Insurance, 'Dịch vụ có bảo hiểm');
var MedicInsuranceType;
(function (MedicInsuranceType) {
  MedicInsuranceType["Ward"] = "WARD";
})(MedicInsuranceType || (exports.MedicInsuranceType = MedicInsuranceType = {}));
var MedicSize;
(function (MedicSize) {
  MedicSize["Type1"] = "TYPE1";
  MedicSize["Type2"] = "TYPE2";
  MedicSize["Type3"] = "TYPE3";
  MedicSize["Type4"] = "TYPE4";
})(MedicSize || (exports.MedicSize = MedicSize = {}));
var MedicSizeTrans = exports.MedicSizeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MedicSize.Type1, '< 10 nhân viên y tế'), MedicSize.Type2, '< 30 nhân viên y tế'), MedicSize.Type3, '< 90 nhân viên y tế'), MedicSize.Type4, '> 100 nhân viên y tế');
var MedicType;
(function (MedicType) {
  MedicType["Clinic"] = "CLINIC";
  MedicType["Hospital"] = "HOSPITAL";
})(MedicType || (exports.MedicType = MedicType = {}));
var MedicTypeTrans = exports.MedicTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MedicType.Clinic, 'Phòng khám'), MedicType.Hospital, 'Bệnh viện');
var MedicSpec;
(function (MedicSpec) {
  MedicSpec["Poly"] = "POLY";
  MedicSpec["Specialist"] = "SPECIALIST";
})(MedicSpec || (exports.MedicSpec = MedicSpec = {}));
var MedicSpecTrans = exports.MedicSpecTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, MedicSpec.Poly, 'Đa khoa'), MedicSpec.Specialist, 'Chuyên khoa');