var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Tạo mới")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { width: "60", align: "center", label: "STT" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Lý do", prop: "content" } }),
          _c("el-table-column", {
            attrs: { label: "Thay đổi lần cuối", prop: "content" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(_vm.formatDate(row.dateCreated)) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thao tác", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v("Cập nhật")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [_vm._v("Xóa")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("CancelReasonModal", {
        ref: "CancelReasonModal",
        attrs: { module: _vm.MallModule.Mall },
        on: { submitOk: _vm.fetchData },
      }),
      _c("MallCategoryImportExcelModal", {
        ref: "MallCategoryImportExcelModal",
        on: { submitOk: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }