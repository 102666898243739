var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Mã giới thiệu" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.refCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "refCode", $$v)
                      },
                      expression: "form.refCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Thưởng tải app lần đầu" } },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: false,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                      },
                    ],
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.rewardRef,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rewardRef", $$v)
                      },
                      expression: "form.rewardRef",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }