"use strict";

var _interopRequireDefault = require("/Volumes/Untitled/project/028/028admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _driver = require("@/api/driver");
var _format = require("@/utils/format");
var _date = require("@/utils/date");
var _enum = require("@/utils/enum");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = require("@/views/order/index.vue");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      STATUS_ORDER: _enum.STATUS_ORDER,
      tagOptions: {
        FIND_DRIVER: {
          type: "warning",
          effect: "plain"
        },
        PICK_UP: {
          type: "",
          effect: "plain"
        },
        RUN: {
          type: "",
          effect: "dark"
        },
        PAY: {
          type: "success",
          effect: "plain"
        },
        COMPLETE: {
          type: "success",
          effect: "dark"
        },
        CUSTOMER_CANCEL: {
          type: "danger",
          effect: "plain"
        },
        DRIVER_CANCEL: {
          type: "danger",
          effect: "dark"
        }
      },
      driverId: null,
      tableData: [],
      listQuery: {},
      total: 0,
      tableLoading: false,
      PaymentStatus: _index.PaymentStatus
    };
  },
  methods: {
    formatDateTime: function formatDateTime(n) {
      return (0, _date.formatDateTime)(n);
    },
    formatNumberVN: function formatNumberVN(n) {
      return (0, _format.formatNumberVN)(n);
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    getTableData: function getTableData(driverId) {
      var _this = this;
      this.tableLoading = true;
      this.driverId = driverId;
      (0, _driver.orders)(driverId).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
      }).finally(function () {
        _this.tableLoading = false;
      });
    }
  }
};