var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "section",
                  [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v("Tủ, ngân hàng"),
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Tên chủ tài khoản",
                                      prop: "bankOwner",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "medium" },
                                      model: {
                                        value: _vm.form.bankOwner,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "bankOwner", $$v)
                                        },
                                        expression: "form.bankOwner",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số tài khoản",
                                      prop: "bankNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "medium" },
                                      model: {
                                        value: _vm.form.bankNumber,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "bankNumber", $$v)
                                        },
                                        expression: "form.bankNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngân hàng",
                                      prop: "bankName",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          "allow-create": "",
                                          filterable: "",
                                          size: "medium",
                                        },
                                        model: {
                                          value: _vm.form.bankName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "bankName", $$v)
                                          },
                                          expression: "form.bankName",
                                        },
                                      },
                                      _vm._l(_vm.bankList, function (item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: { label: item, value: item },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Chi nhánh",
                                      prop: "bankBranch",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "medium" },
                                      model: {
                                        value: _vm.form.bankBranch,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "bankBranch", $$v)
                                        },
                                        expression: "form.bankBranch",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }