var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "food-detail-list",
        title: "Chi tiết đơn - " + _vm.formData.code,
        visible: _vm.dialogVisible,
        width: "1200px",
        "append-to-body": "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "detail", label: "Chi tiết đơn" } },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("ul", { staticClass: "payment-detail" }, [
                  _c("li", [
                    _c("span", [_vm._v("Trạng thái thanh toán:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.PaymentStatusTrans[_vm.formData.paymentStatus]
                        )
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Giá cước:")]),
                    _c("span", { staticClass: "increase" }, [
                      _vm._v(
                        "+ " + _vm._s(_vm.$formatCurrency(_vm.formData.shipFee))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Khuyến mãi phí ship:")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyDiscount))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Tổng tiền hàng:")]),
                    _c("span", { staticClass: "warning" }, [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.moneyProduct))
                      ),
                    ]),
                  ]),
                  _c("li", [
                    _c("span", [
                      _vm._v(
                        "Người Dùng trả (" +
                          _vm._s(
                            _vm.PaymentTypeTrans[_vm.formData.paymentType]
                          ) +
                          "):"
                      ),
                    ]),
                    _c("span", { staticClass: "warning" }, [
                      _vm._v(
                        _vm._s(_vm.$formatCurrency(_vm.formData.finalPrice))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.formData.reviewStar > 0
                ? _c("review-panel", {
                    staticStyle: { "margin-bottom": "12px" },
                    attrs: {
                      star: _vm.formData.reviewStar,
                      content: _vm.formData.reviewContent,
                    },
                  })
                : _vm._e(),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.formData.mallOrderDetails,
                    border: "",
                    fit: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      "class-name": "text-center",
                      type: "index",
                      width: "50",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      sortable: "",
                      prop: "name",
                      label: "Tên hàng",
                      align: "center",
                      width: "270",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", { staticClass: "info-box" }, [
                              _c("div", { staticClass: "info" }, [
                                row.mallProduct
                                  ? _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(row.mallProduct.name)),
                                    ])
                                  : _vm._e(),
                                row.eVoucherLines.length
                                  ? _c("span", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(
                                          row.eVoucherLines[0].eVoucher.name
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              row.mallProduct
                                ? _c("img", {
                                    staticClass: "image",
                                    attrs: {
                                      src: _vm.$url.image(
                                        row.mallProduct.thumbnail
                                      ),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              row.eVoucherLines.length
                                ? _c("img", {
                                    staticClass: "image",
                                    attrs: {
                                      src: _vm.$url.image(
                                        row.eVoucherLines[0].eVoucher.imageUrl
                                      ),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.formData.productType ==
                  _vm.MallOrderProductType.MallProduct
                    ? _c("el-table-column", {
                        attrs: { label: "Phân loại" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  row.attr1Name
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.attr1Name) +
                                            ": " +
                                            _vm._s(row.attr1Value) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  row.attr2Name
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.attr2Name) +
                                            ": " +
                                            _vm._s(row.attr2Value) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1148552561
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "Số lượng",
                      prop: "quantity",
                      align: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatNumberVN(row.quantity)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Giá", align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$formatNumberVN(row.price)) + "đ"
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Thành tiền" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatNumberVN(row.quantity * row.price)
                                ) + "đ"
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "log", label: "Logs" } },
            [
              _c("food-order-logging", {
                attrs: { foodOrderId: _vm.formData.id },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { name: "review", label: "Đánh giá" } },
            [_c("review-page", { attrs: { mallOrderId: _vm.formData.id } })],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
      _c("el-image", {
        ref: "imageView",
        staticStyle: { width: "0", height: "0" },
        attrs: {
          src: _vm.selectedImage,
          "preview-src-list": [_vm.selectedImage],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }