var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Mã giới thiệu" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.refCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "refCode", $$v)
                  },
                  expression: "form.refCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vehicle-company-ref" },
        [
          _vm._l(_vm.vehicles, function (item) {
            return _c("vehicle-company-item", {
              key: item.id,
              attrs: { vehicle: item, onClick: _vm.handleClick },
            })
          }),
          _c("update-company-vehicle-ref-modal", {
            ref: "UpdateCompanyVehicleRefModal",
            attrs: { companyId: _vm.companyId },
            on: { "submit:ok": _vm.fetchCompanyVehicles },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }