"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
var _role = require("@/api/role");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogVisible: false,
      buttonLoading: false,
      role: {
        name: "",
        description: ""
      },
      rules: {
        name: {
          required: true,
          trigger: "submit",
          message: "Phải nhập tên quyền"
        }
      }
    };
  },
  methods: {
    handleAdd: function handleAdd() {
      var _this = this;
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this.$refs["form"].resetFields();
      });
    },
    createData: function createData() {
      var _this2 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this2.buttonLoading = true;
          var data = {
            role: _this2.role
          };
          (0, _role.addRole)(data).then(function () {
            _this2.dialogVisible = false;
            _this2.$notify({
              title: "Thành công",
              dangerouslyUseHTMLString: true,
              message: "Th\xEAm quy\u1EC1n <b> ".concat(_this2.role.name, "</b> th\xE0nh c\xF4ng"),
              type: "success"
            });
            _this2.$emit("add:success");
          }).finally(function () {
            _this2.buttonLoading = false;
          });
        }
      });
    }
  }
};