var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "criteria", attrs: { gutter: 10 } },
    [
      _c(
        "el-col",
        { attrs: { span: _vm.conditionNameSpan } },
        [
          _c(
            "el-form-item",
            [
              _c("el-checkbox", {
                staticStyle: { width: "100%" },
                attrs: { label: _vm.data.name, value: _vm.selectCheckbox },
                on: { change: _vm.handleSelectCondition },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectCheckbox
        ? _c(
            "el-col",
            { attrs: { span: _vm.contentSpan } },
            [
              _c(
                "el-form-item",
                [
                  _vm.data.type == _vm.ScholarshipConditionType.Select
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "", multiple: "" },
                          model: {
                            value: _vm.select.scholarshipConditionDetailIds,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.select,
                                "scholarshipConditionDetailIds",
                                $$v
                              )
                            },
                            expression: "select.scholarshipConditionDetailIds",
                          },
                        },
                        _vm._l(
                          _vm.data.scholarshipConditionDetails,
                          function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.data.type == _vm.ScholarshipConditionType.Number
                    ? _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.select.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.select, "value", $$v)
                          },
                          expression: "select.value",
                        },
                      })
                    : _vm._e(),
                  _vm.data.type == _vm.ScholarshipConditionType.Text
                    ? _c("el-input", {
                        model: {
                          value: _vm.select.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.select, "value", $$v)
                          },
                          expression: "select.value",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }