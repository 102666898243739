var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.getList },
          model: {
            value: _vm.listQuery.status,
            callback: function ($$v) {
              _vm.$set(_vm.listQuery, "status", $$v)
            },
            expression: "listQuery.status",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "all" } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v("Tất cả "),
            ]),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.AgentKYCStatus.Pending } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v(
                _vm._s(
                  _vm.agentKYCStatusTrans[_vm.AgentKYCStatus.Pending].label
                ) + " "
              ),
            ]),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.AgentKYCStatus.Approve } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v(
                _vm._s(
                  _vm.agentKYCStatusTrans[_vm.AgentKYCStatus.Approve].label
                ) + " "
              ),
            ]),
          ]),
          _c("el-tab-pane", { attrs: { name: _vm.AgentKYCStatus.Reject } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _vm._v(
                _vm._s(
                  _vm.agentKYCStatusTrans[_vm.AgentKYCStatus.Reject].label
                ) + " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item", staticStyle: { width: "250px" } },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
            _c("el-input", {
              attrs: { placeholder: "Tìm kiếm theo tên khách hàng" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getList($event)
                },
              },
              model: {
                value: _vm.listQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "", fit: "" },
        },
        [
          _c("el-table-column", { attrs: { type: "index" } }),
          _c("el-table-column", {
            attrs: { label: "CCCD/Passport mặt trước" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("img", {
                        staticClass: "image",
                        staticStyle: {
                          "object-fit": "contain",
                          width: "100%",
                          height: "100%",
                        },
                        attrs: {
                          src: _vm.$url.image(row.idImageFront),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handlePreviewImg(row.idImageFront)
                          },
                        },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "CCCD/Passport mặt sau" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("img", {
                        staticClass: "image",
                        staticStyle: {
                          "object-fit": "contain",
                          width: "100%",
                          height: "100%",
                        },
                        attrs: {
                          src: _vm.$url.image(row.idImageBack),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handlePreviewImg(row.idImageBack)
                          },
                        },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin khách hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Tên khách hàng: ")]),
                      _c("span", [_vm._v(_vm._s(row.name))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Ngày sinh: ")]),
                      _c("span", [_vm._v(_vm._s(row.dob))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Giới tính: ")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.GenderTypeTrans[row.gender])),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Email: ")]),
                      _c("span", [_vm._v(_vm._s(row.email))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Thông tin giấy tờ tùy thân" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Số CCCD/Passport: ")]),
                      _c("span", [_vm._v(_vm._s(row.idNumber))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Ngày cấp: ")]),
                      _c("span", [_vm._v(_vm._s(row.idDate))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Nơi cấp: ")]),
                      _c("span", [_vm._v(_vm._s(row.idPlace))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Địa chỉ thường trú" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Tỉnh/Thành phố: ")]),
                      row.city
                        ? _c("span", [_vm._v(_vm._s(row.city.nameWithType))])
                        : _c("span", [_vm._v("Chưa cập nhật")]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Quận/Huyện: ")]),
                      row.district
                        ? _c("span", [
                            _vm._v(_vm._s(row.district.nameWithType)),
                          ])
                        : _c("span", [_vm._v("Chưa cập nhật")]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Phường/Xã: ")]),
                      row.ward
                        ? _c("span", [_vm._v(_vm._s(row.ward.nameWithType))])
                        : _c("span", [_vm._v("Chưa cập nhật")]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Khu phố/Ấp/Thôn: ")]),
                      _c("span", [_vm._v(_vm._s(row.residential))]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Số nhà, tên đường: ")]),
                      _c("span", [_vm._v(_vm._s(row.street))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.agentKYCStatusTrans[row.status].color,
                          effect: "dark",
                        },
                      },
                      [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.agentKYCStatusTrans[row.status].label)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Ngày tạo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDateTime(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status === _vm.AgentKYCStatus.Pending
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleApprove(row)
                              },
                            },
                          },
                          [_vm._v(" Duyệt ")]
                        )
                      : _vm._e(),
                    row.status == _vm.AgentKYCStatus.Pending
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleReject(row)
                              },
                            },
                          },
                          [_vm._v(" Từ chối ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("image-modal", { ref: "ImageModal", attrs: { title: "" } }),
      [
        _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "Duyệt KYC",
                  visible: _vm.dialogVisible,
                  width: "500px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  close: _vm.closeModalApprove,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rulesApprove,
                          "label-width": "120px",
                          "label-position": "top",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Thứ hạng" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Chọn thứ hạng",
                                  "value-key": "id",
                                  disabled:
                                    this.currentRow &&
                                    this.currentRow.agent.parent,
                                },
                                model: {
                                  value: _vm.selectedRank,
                                  callback: function ($$v) {
                                    _vm.selectedRank = $$v
                                  },
                                  expression: "selectedRank",
                                },
                              },
                              _vm._l(_vm.agentRanks, function (rank) {
                                return _c("el-option", {
                                  key: rank.id,
                                  attrs: { label: rank.name, value: rank },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "Chọn người cấp cha" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "Chọn người cấp cha",
                                  "value-key": "id",
                                  disabled:
                                    (_vm.selectedRank &&
                                      _vm.selectedRank.level == 1) ||
                                    (this.currentRow &&
                                      this.currentRow.agent.parent),
                                },
                                model: {
                                  value: _vm.selectedAgent,
                                  callback: function ($$v) {
                                    _vm.selectedAgent = $$v
                                  },
                                  expression: "selectedAgent",
                                },
                              },
                              _vm._l(_vm.agents, function (agent) {
                                return _c("el-option", {
                                  key: agent.id,
                                  attrs: { label: agent.name, value: agent },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("Đóng")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmApprove },
                      },
                      [_vm._v("OK")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }