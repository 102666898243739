var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "customer-order-mall" } },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Code đơn hàng")]),
            _c("el-input", {
              staticStyle: { width: "300px" },
              attrs: {
                "prefix-icon": "el-icon-search",
                placeholder: "Tìm mã giao dịch",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getTableData(_vm.listQuery.customerId)
                },
              },
              model: {
                value: _vm.listQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Ngày giao dịch")]),
            _c("el-date-picker", {
              attrs: {
                type: "date",
                format: "dd/MM/yyyy",
                "range-separator": "To",
                "start-placeholder": "Start date",
                "end-placeholder": "End date",
                "value-format": "yyyy-MM-dd",
              },
              on: {
                change: function ($event) {
                  _vm.listQuery.page = 1
                  _vm.getTableData(_vm.listQuery.customerId)
                },
              },
              model: {
                value: _vm.listQuery.createdDate,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "createdDate", $$v)
                },
                expression: "listQuery.createdDate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  icon: "el-icon-search",
                },
                on: {
                  click: function ($event) {
                    return _vm.getTableData(_vm.listQuery.customerId)
                  },
                },
              },
              [_vm._v("Tìm")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          attrs: { data: _vm.tableData, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Code", prop: "code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "list-code-mall" }, [
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", { staticStyle: { "font-size": "20px" } }, [
                          _c("b", [_vm._v(_vm._s(row.code))]),
                        ]),
                      ]),
                      _c("li", [
                        _c("label", { attrs: { for: "" } }),
                        _c("span", [
                          _c("b", [_vm._v(_vm._s(row.codeContract || "--"))]),
                        ]),
                      ]),
                    ]),
                    row.shipCode
                      ? _c(
                          "div",
                          { staticStyle: { "text-align": "center" } },
                          [_c("el-tag", [_vm._v(_vm._s(row.shipCode))])],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { sortable: "", label: "Ngày", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(_vm.formatDateTime(row.dateCreated)))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Cửa hàng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.mall
                      ? _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(" " + _vm._s(row.mall.name) + " "),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              sortable: "",
              label: "Điểm nhận",
              prop: "sendFromAddress",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(_vm._s(row.sendFromAddress))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Chi tiết thanh toán", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("ul", { staticClass: "pay" }, [
                      _c("li", [
                        _c("span", [_vm._v("Trạng thái thanh toán:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.PaymentStatus[row.paymentStatus])),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tổng cước phí:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.shipFee))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Tổng tiền hàng:")]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.moneyProduct))),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("Khuyến mãi:")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$formatCurrency(row.moneyDiscount))
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [
                          _vm._v(
                            "Người Dùng trả (" +
                              _vm._s(_vm.PaymentTypeTrans[row.paymentType]) +
                              "):"
                          ),
                        ]),
                        _c("span", { staticClass: "warning" }, [
                          _vm._v(_vm._s(_vm.formatCurrency(row.finalPrice))),
                        ]),
                      ]),
                      _c(
                        "li",
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs["detail-dialog"].handleView(
                                    row
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-info" }),
                              _vm._v(" Xem Chi tiết "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("detail-dialog", { ref: "detail-dialog" }),
      _c("images-order", { ref: "images-dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }