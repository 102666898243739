var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "date-box", staticStyle: { display: "flex" } },
        [
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              align: "right",
              "unlink-panels": "",
              "range-separator": "To",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
              format: "dd/MM/yyyy",
              "value-format": "yyyy-MM-dd",
              "picker-options": _vm.pickerOptions,
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _c(
            "el-select",
            {
              staticStyle: { flex: "1", "margin-left": "8px" },
              attrs: { placeholder: "Trạng thái đơn hàng", clearable: "" },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-option", { attrs: { label: "Tất cả", value: null } }),
              _vm._l(_vm.STATUS_ORDER, function (name, key) {
                return _c("el-option", {
                  key: key,
                  attrs: { label: name, value: key },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("bar-chart", {
        ref: "chart",
        attrs: {
          "data-chart": _vm.chartData,
          "pointer-name": ["Đơn đặt xe", "Đơn giao hàng"],
          "attr-get": ["totalOrder", "totalOrderDelivery"],
          "bar-color": ["#588dd5", "#34bfa3"],
        },
        on: {
          "update:dataChart": function ($event) {
            _vm.chartData = $event
          },
          "update:data-chart": function ($event) {
            _vm.chartData = $event
          },
        },
      }),
      _c("p", { staticClass: "chart-title" }, [_vm._v("Đơn hàng")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }