import { render, staticRenderFns } from "./SchoolAdmissionInfo.vue?vue&type=template&id=f9292b9e&scoped=true"
import script from "./SchoolAdmissionInfo.vue?vue&type=script&lang=ts"
export * from "./SchoolAdmissionInfo.vue?vue&type=script&lang=ts"
import style0 from "./SchoolAdmissionInfo.vue?vue&type=style&index=0&id=f9292b9e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9292b9e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/Untitled/project/028/028admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f9292b9e')) {
      api.createRecord('f9292b9e', component.options)
    } else {
      api.reload('f9292b9e', component.options)
    }
    module.hot.accept("./SchoolAdmissionInfo.vue?vue&type=template&id=f9292b9e&scoped=true", function () {
      api.rerender('f9292b9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/career/components/SchoolAdmissionInfo.vue"
export default component.exports