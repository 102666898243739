var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "Agent-info" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            disabled: _vm.formDisable,
            "label-width": "120px",
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Avatar", prop: "avatar" } },
                    [
                      _c("SingleImageS3", {
                        attrs: {
                          pathImage: _vm.form.avatar,
                          width: 150,
                          height: 150,
                        },
                        on: { "upload:success": _vm.handleUploadSuccess },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Họ & tên", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số điện thoại", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tên đăng nhập", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.dialogStatus == "update" },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mật khẩu", prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: { "show-password": "" },
                            model: {
                              value: _vm.form.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Thứ hạng" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            "remote-method": _vm.fetchAgentRank,
                            disabled: "",
                          },
                          model: {
                            value: _vm.form.agentRankId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "agentRankId", $$v)
                            },
                            expression: "form.agentRankId",
                          },
                        },
                        _vm._l(_vm.agentRanks, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "h3",
                  {
                    staticStyle: { "margin-top": "0", "margin-bottom": "8px" },
                  },
                  [_vm._v("Địa chỉ")]
                ),
              ]),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Số nhà, tên đường", prop: "street" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.street,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "street", $$v)
                          },
                          expression: "form.street",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tỉnh/thành", prop: "cityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", disabled: _vm.formDisable },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.cityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cityId", $$v)
                            },
                            expression: "form.cityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Quận/huyện", prop: "districtId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.districtId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "districtId", $$v)
                            },
                            expression: "form.districtId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phường/xã", prop: "wardId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            disabled: _vm.formDisable,
                          },
                          model: {
                            value: _vm.form.wardId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "wardId", $$v)
                            },
                            expression: "form.wardId",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nameWithType, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }