var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: _vm.dialogStatus == "add" ? "Thêm tài xế" : "Lịch sử khác",
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        fullscreen: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClickTab },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _vm.dialogStatus == "add"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "info", label: "Thông tin chung" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.dialogLoading,
                          expression: "dialogLoading",
                        },
                      ],
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            disabled: _vm.dialogDisabled,
                            rules: _vm.rules,
                            model: _vm.tempData,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Họ tên", prop: "name" },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.tempData, "name", $$v)
                                          },
                                          expression: "tempData.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "Email", prop: "email" },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.email,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.tempData, "email", $$v)
                                          },
                                          expression: "tempData.email",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Số điện thoại",
                                        prop: "phone",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.tempData.phone,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.tempData, "phone", $$v)
                                          },
                                          expression: "tempData.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Đơn vị KDVT" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "",
                                            filterable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.tempData
                                                .transportBusinessUnitId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempData,
                                                "transportBusinessUnitId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tempData.transportBusinessUnitId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.transportBusinesses,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12, offset: 0 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Dịch vụ đăng ký",
                                        prop: "vehicleId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { filterable: "" },
                                          model: {
                                            value: _vm.tempData.vehicleId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempData,
                                                "vehicleId",
                                                $$v
                                              )
                                            },
                                            expression: "tempData.vehicleId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeVehicles,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label:
                                                  item.name +
                                                  " " +
                                                  (item.subName
                                                    ? " | " + item.subName
                                                    : ""),
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12, offset: 0 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Tỉnh thành",
                                        prop: "cityId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            placeholder: "Chọn tỉnh thành",
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.tempData.districtId = null
                                            },
                                          },
                                          model: {
                                            value: _vm.tempData.cityId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempData,
                                                "cityId",
                                                $$v
                                              )
                                            },
                                            expression: "tempData.cityId",
                                          },
                                        },
                                        _vm._l(_vm.cities, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.nameWithType,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12, offset: 0 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Quận huyện",
                                        prop: "districtId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            placeholder: "Chọn quận huyện",
                                          },
                                          model: {
                                            value: _vm.tempData.districtId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tempData,
                                                "districtId",
                                                $$v
                                              )
                                            },
                                            expression: "tempData.districtId",
                                          },
                                        },
                                        _vm._l(_vm.districts, function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.nameWithType,
                                              value: item.id,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "el-tabs",
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "tab-avatar",
                                  attrs: { label: "Avatar" },
                                },
                                [
                                  _c("single-image", {
                                    attrs: {
                                      pathImage: _vm.tempData.avatar,
                                      width: 150,
                                      height: 150,
                                      urlUpload: "/drivers/avatar/upload",
                                    },
                                    on: {
                                      "upload:success": _vm.handleAvatarSuccess,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "tab-cmnd",
                                  attrs: { label: "CMND" },
                                },
                                [
                                  _c("p", [_vm._v("Mặt trước")]),
                                  _c("single-image", {
                                    attrs: {
                                      pathImage:
                                        _vm.tempData.imageIdentityFront,
                                      width: 300,
                                      height: 150,
                                      urlUpload: "/drivers/avatar/upload",
                                    },
                                    on: {
                                      "upload:success": _vm.handleFrontSuccess,
                                    },
                                  }),
                                  _c("hr", {
                                    staticStyle: { margin: "16px 0" },
                                  }),
                                  _c("p", [_vm._v("Mặt sau")]),
                                  _c("single-image", {
                                    attrs: {
                                      pathImage: _vm.tempData.imageIdentityBack,
                                      width: 300,
                                      height: 150,
                                      urlUpload: "/drivers/avatar/upload",
                                    },
                                    on: {
                                      "upload:success": _vm.handleBackSuccess,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "tab-driver-license",
                                  attrs: { label: "Bằng lái xe" },
                                },
                                [
                                  _c("p", [_vm._v("Mặt trước")]),
                                  _c("SingleImage", {
                                    staticStyle: { "text-align": "center" },
                                    attrs: {
                                      pathImage:
                                        _vm.tempData.imageDriverLicenseFront,
                                    },
                                    on: {
                                      "upload:success":
                                        _vm.uploadDriverLinceFrontSuccess,
                                    },
                                  }),
                                  _c("p", [_vm._v("Mặt sau")]),
                                  _c("SingleImage", {
                                    staticStyle: { "text-align": "center" },
                                    attrs: {
                                      pathImage:
                                        _vm.tempData.imageDriverLicenseBack,
                                    },
                                    on: {
                                      "upload:success":
                                        _vm.uploadDriverLinceBackSuccess,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { name: "transaction", label: "Lịch sử giao dịch" } },
                [
                  _c("Transaction", {
                    attrs: { "driver-id": _vm.tempData.id },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Lịch sử đơn hàng", name: "order-history" } },
                [_c("order-history", { ref: "order-history" })],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Lịch sử đơn giao hàng",
                    name: "order-delivery-history",
                  },
                },
                [
                  _c("order-delivery-history", {
                    ref: "order-delivery-history",
                    attrs: { driverId: _vm.tempData.id },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Lịch sử đơn thức ăn",
                    name: "order-food-history",
                  },
                },
                [_c("order-food-history", { ref: "order-food-history" })],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Lịch sử online", name: "online-history" } },
                [
                  _c("driver-online-history", {
                    attrs: { driverId: _vm.tempData.id },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: "Lịch sử chuyển tiền",
                    name: "transfer-balance",
                  },
                },
                [_c("transfer-balance", { ref: "transfer-balance" })],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Lịch sử di chuyển", name: "tracking-gps" } },
                [_c("TrackingGPS", { ref: "tracking-gps" })],
                1
              )
            : _vm._e(),
          _vm.dialogStatus == "edit"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Tỉ lệ nhận chuyến", name: "order-rate" } },
                [_c("order-rate", { ref: "order-rate" })],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Thông tin thêm", name: "info-more" } },
                [_c("InfoMore", { attrs: { form: _vm.tempData } })],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Giấy tờ khác", name: "identity-tab" } },
                [_c("IdentityTab", { attrs: { form: _vm.tempData } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _vm.dialogStatus != "edit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Lưu")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }