var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Mã giới thiệu" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.refCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "refCode", $$v)
                  },
                  expression: "form.refCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Thưởng cửa hàng mall đăng ký" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                model: {
                  value: _vm.form.childrenMallReward,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "childrenMallReward", $$v)
                  },
                  expression: "form.childrenMallReward",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { loading: _vm.loading, type: "primary", size: "small" },
          on: { click: _vm.updateData },
        },
        [_vm._v("Cập nhật")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }