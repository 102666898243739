var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title:
          _vm.dialogStatus == "add" ? "Thêm chi nhánh" : "Cập nhật chi nhánh",
        visible: _vm.dialogVisible,
        width: _vm.dialogWidth,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.changeTab },
          model: {
            value: _vm.tabActive,
            callback: function ($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive",
          },
        },
        [
          _c("el-tab-pane", { attrs: { name: "info", label: "Thông tin" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.dialogLoading,
                    expression: "dialogLoading",
                  },
                ],
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "dataForm",
                    attrs: {
                      disabled: _vm.formDisable,
                      rules: _vm.rules,
                      model: _vm.tempData,
                      "label-position": "left",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "Tên", prop: "name" } },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.tempData.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempData, "name", $$v)
                                    },
                                    expression: "tempData.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Thành phố", prop: "cityIds" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { filterable: "" },
                                    on: { change: _vm.handleChangeCity },
                                    model: {
                                      value: _vm.tempData.cityIds,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "cityIds", $$v)
                                      },
                                      expression: "tempData.cityIds",
                                    },
                                  },
                                  _vm._l(_vm.cities, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Quận huyện",
                                  prop: "districtIds",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      multiple: "",
                                    },
                                    on: { change: _vm.handleChangeDistrict },
                                    model: {
                                      value: _vm.tempData.districtIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tempData,
                                          "districtIds",
                                          $$v
                                        )
                                      },
                                      expression: "tempData.districtIds",
                                    },
                                  },
                                  _vm._l(_vm.districts, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Phường xã", prop: "wardIds" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.tempData.wardIds,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempData, "wardIds", $$v)
                                      },
                                      expression: "tempData.wardIds",
                                    },
                                  },
                                  _vm._l(_vm.wards, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.nameWithType,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          !_vm.formDisable && _vm.tabActive == "info"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "edit"
                        ? _vm.editData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Đồng ý")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }