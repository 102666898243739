var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Mã qr code",
        visible: _vm.visible,
        width: "700px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "align-items": "center" },
                  attrs: { span: 24, offset: 0 },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "flex-direction": "column",
                      },
                    },
                    [
                      _c("qrcode-vue", {
                        attrs: { value: _vm.code, size: "200" },
                      }),
                      _c("div", { staticStyle: { "margin-top": "20px" } }, [
                        _vm._v(
                          " Mã sẽ hết hạn sau: " +
                            _vm._s(_vm.getTimeFormat(_vm.timeLeft)) +
                            " "
                        ),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.createQr },
                        },
                        [_vm._v("Tạo mã mới")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }