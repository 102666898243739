var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "ideal-draw-modal-1",
      attrs: {
        title: "Bốc thăm ngẫu nhiên",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        visible: _vm.visible,
        width: "500px",
        "show-close": false,
        fullscreen: "",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 12 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "font-size": "20px" },
                              attrs: {
                                label: "Chọn sản phẩm",
                                prop: "idealProductId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "Nhập để tìm kiếm",
                                    clearable: "",
                                    filterable: "",
                                    remote: "",
                                    "remote-method": _vm.handleSearchProduct,
                                  },
                                  on: { change: _vm.fetchPrize },
                                  model: {
                                    value: _vm.form.idealProductId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "idealProductId", $$v)
                                    },
                                    expression: "form.idealProductId",
                                  },
                                },
                                _vm._l(_vm.products, function (product) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: product.id,
                                      attrs: {
                                        disabled: _vm.checkDisable(product),
                                        value: product.id,
                                        label:
                                          product.name + " - " + product.code,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            transition:
                                              "el-collapse-transition",
                                            placement: "bottom",
                                            title: "Thông tin",
                                            width: "500",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          product.idealPrizes.length
                                            ? _c(
                                                "div",
                                                [
                                                  _vm._l(
                                                    product.idealPrizes,
                                                    function (prize, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            display: "flex",
                                                            "margin-bottom":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticStyle: {
                                                              "border-radius":
                                                                "10px",
                                                              "object-fit":
                                                                "cover",
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              src: prize.imageUrl,
                                                              alt: "",
                                                              width: "80",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            [
                                                              prize.type ==
                                                              _vm.IdealPrizeType
                                                                .Main
                                                                ? _c(
                                                                    "el-tag",
                                                                    {
                                                                      attrs: {
                                                                        type: "primary",
                                                                        size: "small",
                                                                        effect:
                                                                          "dark",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("b", [
                                                                        _vm._v(
                                                                          "Giải chính"
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              prize.type ==
                                                              _vm.IdealPrizeType
                                                                .Sub
                                                                ? _c(
                                                                    "el-tag",
                                                                    {
                                                                      attrs: {
                                                                        type: "primary",
                                                                        size: "small",
                                                                        effect:
                                                                          "light",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("b", [
                                                                        _vm._v(
                                                                          "Giải phụ"
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "summary-ideal-draw",
                                                                {
                                                                  attrs: {
                                                                    idealDrawHistories:
                                                                      product.idealDrawHistories,
                                                                    idealPrize:
                                                                      prize,
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "5px 0",
                                                                    "font-size":
                                                                      "18px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          prize.name
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "5px 0",
                                                                  },
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Giá:"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$formatNumberVN(
                                                                          prize.finalPrice
                                                                        )
                                                                      ) +
                                                                      " VNĐ"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _c("p", [
                                                    _c("b", [
                                                      _vm._v("Ngày bốc thăm:"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$formatDateTime(
                                                            product.maxDrawDateAt,
                                                            "HH:mm, DD/MM/YYYY"
                                                          )
                                                        )
                                                    ),
                                                  ]),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  style:
                                                    product.sold +
                                                      product.pending >
                                                      0 &&
                                                    (product.sold +
                                                      product.pending ==
                                                      product.quantity ||
                                                      product.quantity == 0)
                                                      ? "color:#4bcb4b; fontWeight:bold"
                                                      : "",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      product.name +
                                                        " - " +
                                                        product.code
                                                    )
                                                  ),
                                                ]
                                              ),
                                              product.quantity > 0
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "#8492a6",
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Đã bán: " +
                                                          _vm._s(
                                                            product.sold +
                                                              product.pending +
                                                              "/" +
                                                              product.quantity
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        float: "right",
                                                        color: "#8492a6",
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Không yêu cầu số lượng (Đã bán: " +
                                                          _vm._s(
                                                            product.sold +
                                                              product.pending
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Giải thưởng",
                                prop: "idealPrizeId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    remote: "",
                                  },
                                  model: {
                                    value: _vm.form.idealPrizeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "idealPrizeId", $$v)
                                    },
                                    expression: "form.idealPrizeId",
                                  },
                                },
                                _vm._l(_vm.idealPrizes, function (prize) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: prize.id,
                                      attrs: {
                                        disabled: prize.used == prize.quantity,
                                        value: prize.id,
                                        label: "" + prize.name,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "space-between",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              prize.type ==
                                              _vm.IdealPrizeType.Main
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                        width: "72px",
                                                        "text-align": "center",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        size: "small",
                                                        effect: "dark",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("Giải chính"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              prize.type ==
                                              _vm.IdealPrizeType.Sub
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                        width: "72px",
                                                        "text-align": "center",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                        size: "small",
                                                        effect: "light",
                                                      },
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v("Giải phụ"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("b", [
                                                _vm._v(_vm._s(prize.name)),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#8492a6" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(prize.used) +
                                                  "/" +
                                                  _vm._s(prize.quantity)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Họ và tên người bốc thăm",
                                prop: "drawedStaff",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    filterable: "",
                                    "allow-create": "",
                                    "multiple-limit": 1,
                                  },
                                  model: {
                                    value: _vm.form.drawedStaff,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "drawedStaff", $$v)
                                    },
                                    expression: "form.drawedStaff",
                                  },
                                },
                                _vm._l(
                                  _vm.staffNamesData.filter(function (e) {
                                    return e
                                  }),
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: item },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Chức vụ", prop: "" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    filterable: "",
                                    "allow-create": "",
                                    "multiple-limit": 1,
                                  },
                                  model: {
                                    value: _vm.form.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "title", $$v)
                                    },
                                    expression: "form.title",
                                  },
                                },
                                _vm._l(
                                  _vm.workPositionsData.filter(function (e) {
                                    return e
                                  }),
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: item },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Đơn vị", prop: "" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multiple: "",
                                    filterable: "",
                                    "allow-create": "",
                                    "multiple-limit": 1,
                                  },
                                  model: {
                                    value: _vm.form.branch,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "branch", $$v)
                                    },
                                    expression: "form.branch",
                                  },
                                },
                                _vm._l(
                                  _vm.companyNamesData.filter(function (e) {
                                    return e
                                  }),
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: item },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.confirmAction()
                },
              },
            },
            [_vm._v("Tiếp tục")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }