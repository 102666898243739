var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "filter-container",
          staticStyle: { "padding-bottom": "0" },
        },
        [
          _c(
            "div",
            { staticClass: "filter-item filter-school" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tìm kiếm")]),
              _c("el-input", {
                attrs: { placeholder: "Tìm tin tuyển dụng" },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.fetchData($event)
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item filter-school" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Nhà tuyển dụng")]),
              _c(
                "el-select",
                {
                  attrs: {
                    remote: true,
                    "remote-method": _vm.fetchEmployer,
                    clearable: "",
                    filterable: "",
                  },
                  on: { change: _vm.fetchData },
                  model: {
                    value: _vm.query.employerId,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "employerId", $$v)
                    },
                    expression: "query.employerId",
                  },
                },
                _vm._l(_vm.employers, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item filter-school filter-button" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "small",
                  },
                  on: { click: _vm.fetchData },
                },
                [_vm._v("Tìm kiếm")]
              ),
            ],
            1
          ),
          _vm.type == _vm.CareerType.Company
            ? _c(
                "div",
                { staticClass: "filter-item filter-school filter-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "small",
                      },
                      on: { click: _vm.handleCreate },
                    },
                    [_vm._v("Tạo mới")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { "class-name": "text-center", type: "index", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { prop: "code", label: "Mã tin", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.code) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "nickname",
              label:
                _vm.type == _vm.CareerType.Company
                  ? "Tiêu đề tuyển dụng"
                  : "Tiêu để người tìm việc",
              "min-width": "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(" " + _vm._s(row.name) + " "),
                    _c("div", [
                      _c("b", [_vm._v("Tin hết hạn vào ngày:")]),
                      _vm._v(" " + _vm._s(_vm.$formatDate(row.expiredAt))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.type == _vm.CareerType.Company
            ? _c("el-table-column", {
                attrs: { width: "200px", label: "Nhà tuyển dụng" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.employer
                            ? _c("div", [
                                _vm._v(" " + _vm._s(row.employer.name) + " "),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  928294177
                ),
              })
            : _vm._e(),
          _vm.type == _vm.CareerType.Customer
            ? _c("el-table-column", {
                attrs: { width: "200px", label: "Người đăng" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", [
                            _vm._v(" " + _vm._s(row.contactName) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  816874708
                ),
              })
            : _vm._e(),
          _vm.type == _vm.CareerType.Company
            ? _c("el-table-column", {
                attrs: {
                  width: "200px",
                  label: "Vị trí tuyển dụng",
                  prop: "workPosition",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [_vm._v(" " + _vm._s(row.workPosition) + " ")]
                      },
                    },
                  ],
                  null,
                  false,
                  1144664231
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { width: "200px", label: "Mức lương", prop: "workPosition" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.wageType == _vm.wageType.Fixed
                      ? _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$formatNumberVN(row.toMoney)) +
                              "đ "
                          ),
                        ])
                      : row.wageType == _vm.wageType.Range
                      ? _c("div", [
                          _vm._v(
                            " Lên đến " +
                              _vm._s(_vm.$formatNumberVN(row.toMoney)) +
                              "đ "
                          ),
                        ])
                      : _c("div", [_vm._v(" Thương lượng ")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              align: "center",
              label: "Hình thức làm việc",
              prop: "workType",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "center",
                        },
                      },
                      _vm._l(row.workTypes, function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: {
                              "font-weight": "bold",
                              "margin-bottom": "5px",
                            },
                            attrs: { size: "mini" },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              label: "Số năm kinh nghiệm",
              prop: "experienceYear",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.type == _vm.CareerType.Company
                      ? _c("div", [
                          row.careerYearConfig
                            ? _c("div", [
                                _vm._v(
                                  " " + _vm._s(row.careerYearConfig.name) + " "
                                ),
                              ])
                            : _c("div", [
                                _vm._v(" Không yêu cầu kinh nghiệm "),
                              ]),
                        ])
                      : _c("div", [
                          _vm._v(" " + _vm._s(row.experienceYear) + " "),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _vm.type == _vm.CareerType.Company
            ? _c("el-table-column", {
                attrs: { align: "center", width: "120px", label: "Ngành nghề" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          row.careerCategory
                            ? _c(
                                "el-tag",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "white-space": "normal",
                                    height: "auto",
                                  },
                                  attrs: { size: "mini", effect: "dark" },
                                },
                                [_vm._v(_vm._s(row.careerCategory.name))]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1105444616
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { align: "center", width: "120px", label: "Chiết khấu" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.moneyCommission)) +
                        "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Ngày tạo", prop: "dateCreated" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatDate(row.dateCreated)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Thao tác",
              width: "150",
              "class-name": "small-padding fixed-width",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "flex-direction": "column",
                          gap: "5px",
                        },
                      },
                      [
                        _vm.checkPermissionApprove &&
                        row.status == _vm.CareerStatus.Pending
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "flex-direction": "column",
                                  gap: "5px",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApprove(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Duyệt")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "120px !important",
                                      display: "block",
                                      margin: "0",
                                    },
                                    attrs: { type: "danger", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(row)
                                      },
                                    },
                                  },
                                  [_vm._v("Từ chối")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.type == _vm.CareerType.Customer && row.cvSample
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  width: "120px !important",
                                  display: "block",
                                  margin: "0",
                                },
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownload(row.cvSample.file)
                                  },
                                },
                              },
                              [_vm._v("Xem cv")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v("Cập nhật")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              width: "120px !important",
                              display: "block",
                              margin: "0",
                            },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("import-employer-modal", {
        ref: "ImportEmployerModal",
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("career-modal", {
        ref: "CareerModal",
        attrs: { type: _vm.type },
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }