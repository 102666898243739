"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateMessageLogging = exports.OrderLoggingType = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.number.to-fixed.js");
var OrderLoggingType;
(function (OrderLoggingType) {
  OrderLoggingType["CreateOrder"] = "CREATE_ORDER";
  OrderLoggingType["FindDriver"] = "FIND_DRIVER";
  OrderLoggingType["Accept"] = "ACCEPT";
  OrderLoggingType["CustomerCancel"] = "CUSTOMER_CANCEL";
  OrderLoggingType["StoreCancel"] = "STORE_CANCEL";
  OrderLoggingType["DriverCancel"] = "DRIVER_CANCEL";
  OrderLoggingType["StaffCancel"] = "STAFF_CANCEL";
  OrderLoggingType["Complete"] = "COMPLETE";
  OrderLoggingType["SendSignal"] = "SEND_SIGNAL";
  OrderLoggingType["StoreAccept"] = "STORE_ACCEPT";
  OrderLoggingType["StoreFindDriver"] = "STORE_FIND_DRIVER";
  OrderLoggingType["Waiting"] = "WAITING";
  OrderLoggingType["DriverAutoAccept"] = "DRIVER_AUTO_ACCEPT";
  OrderLoggingType["Run"] = "RUN";
  OrderLoggingType["UploadDeliveredImage"] = "UPLOAD_DELIVERED_IMAGE";
  OrderLoggingType["UploadPickImage"] = "UPLOAD_PICK_IMAGE";
  OrderLoggingType["StaffComplete"] = "STAFF_COMPLETE";
  OrderLoggingType["PendingPayment"] = "PENDING_PAYMENT";
  OrderLoggingType["UploadSenderIdentificationImage"] = "UPLOAD_SENDER_IDENTIFICATION_IMAGE";
  OrderLoggingType["DriverPaymentOrderFoodBalance"] = "DRIVER_PAYMENT_ORDER_FOOD_BALANCE";
  OrderLoggingType["DriverPaymentOrderFoodCash"] = "DRIVER_PAYMENT_ORDER_FOOD_CASH";
  OrderLoggingType["RefundDriverPaymentOrderFood"] = "REFUND_DRIVER_PAYMENT_ORDER_FOOD";
  OrderLoggingType["RefundDriverPaymentOrderBalance"] = "REFUND_DRIVER_PAYMENT_ORDER_BALANCE";
  OrderLoggingType["RefundCustomerPaymentOrderBalance"] = "REFUND_CUSTOMER_PAYMENT_ORDER_BALANCE";
  OrderLoggingType["StoreCommission"] = "STORE_COMMISSION";
  OrderLoggingType["StoreReceiveMoneyProduct"] = "STORE_RECEIVE_MONEY_PRODUCT";
  OrderLoggingType["CustomerPaymentOrderBalance"] = "CUSTOMER_PAYMENT_ORDER_BALANCE";
  OrderLoggingType["DriverCommission"] = "DRIVER_COMMISSION";
  OrderLoggingType["StorePaymentOrderDelivery"] = "STORE_PAYMENT_ORDER_DELIVERY";
  OrderLoggingType["RepayStorePaymentOrderDelivery"] = "REPAY_STORE_PAYMENT_ORDER_DELIVERY";
  OrderLoggingType["NotFoundDriver"] = "NOT_FOUND_DRIVER";
  OrderLoggingType["RefundDriverPaymentOrderFoodBalance"] = "REFUND_DRIVER_PAYMENT_ORDER_FOOD_BALANCE";
  //Store
  OrderLoggingType["StoreConfirming"] = "STORE_CONFIRMING";
  OrderLoggingType["StoreCooked"] = "STORE_COOKED";
  OrderLoggingType["RefundStoreCommission"] = "REFUND_STORE_COMMISSION";
  // *DRIVER*
  OrderLoggingType["DriverPaymentMoneyProductDeliveryOrder"] = "DRIVER_PAYMENT_MONEY_PRODUCT_DELIVERY_ORDER";
  OrderLoggingType["RefundDriverPaymentMoneyProductDeliveryOrder"] = "REFUND_DRIVER_PAYMENT_MONEY_PRODUCT_DELIVERY_ORDER";
  OrderLoggingType["DriverRefundDeliveryOrderDetail"] = "DRIVER_REFUND_DELIVERY_ORDER_DETAIL";
  OrderLoggingType["BackShipFee"] = "BACK_SHIP_FEE";
  OrderLoggingType["RefundShipFeeDriver"] = "REFUND_SHIP_FEE_DRIVER";
  //***DELIVERY ORDER***
  OrderLoggingType["CompleteDeliveryOrderDetail"] = "COMPLETE_DELIVERY_ORDER_DETAIL";
  OrderLoggingType["SubtractMoneyProductStore"] = "SUBTRACT_MONEY_PRODUCT_STORE";
  OrderLoggingType["RefundShipFeeStore"] = "REFUND_SHIP_FEE_STORE"; //hoàn tiền ship cho cửa hàng
})(OrderLoggingType || (exports.OrderLoggingType = OrderLoggingType = {}));
var generateMessageLogging = exports.generateMessageLogging = function generateMessageLogging(log) {
  var msg = "";
  switch (log.type) {
    case OrderLoggingType.FindDriver:
      msg = "Đang tìm tài xế";
      break;
    case OrderLoggingType.RepayStorePaymentOrderDelivery:
      msg = "Hoàn tiền ship cho cửa hàng";
      break;
    case OrderLoggingType.StorePaymentOrderDelivery:
      msg = "Cửa hàng thanh toán phí ship";
      break;
    case OrderLoggingType.Accept:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " ch\u1EA5p nh\u1EADn \u0111\u01A1n h\xE0ng");
      break;
    case OrderLoggingType.CustomerCancel:
      msg = "Kh\xE1ch h\xE0ng h\u1EE7y \u0111\u01A1n h\xE0ng";
      break;
    case OrderLoggingType.StoreCancel:
      msg = "C\u1EEDa h\xE0ng h\u1EE7y \u0111\u01A1n h\xE0ng";
      break;
    case OrderLoggingType.DriverCancel:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " h\u1EE7y \u0111\u01A1n h\xE0ng");
      break;
    case OrderLoggingType.StaffCancel:
      msg = "Nh\xE2n vi\xEAn ".concat(log.staff.name, " - ").concat(log.staff.phone, " h\u1EE7y \u0111\u01A1n h\xE0ng");
      break;
    case OrderLoggingType.Complete:
      msg = log.orderFood.deliveryBy == 'CUSTOMER' ? "C\u1EEDa h\xE0ng ho\xE0n th\xE0nh \u0111\u01A1n h\xE0ng" : "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " ho\xE0n th\xE0nh \u0111\u01A1n h\xE0ng");
      break;
    case OrderLoggingType.SendSignal:
      msg = "G\u1EEDi \u0111\u01A1n \u0111\u1EBFn t\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, ". Kho\u1EA3ng c\xE1ch c\u1EE7a t\xE0i x\u1EBF t\u1EDBi c\u1EEDa h\xE0ng l\xE0 ").concat(log.distance.toFixed(2), " Km");
      break;
    case OrderLoggingType.StoreAccept:
      msg = "C\u1EEDa h\xE0ng x\xE1c nh\u1EADn \u0111\u01A1n";
      break;
    case OrderLoggingType.StoreFindDriver:
      msg = "C\u1EEDa h\xE0ng chuy\u1EC3n tr\u1EA1ng th\xE1i \u0111\u01A1n sang \"T\xECm t\xE0i x\u1EBF\"";
      break;
    case OrderLoggingType.Waiting:
      msg = "Tr\u1EA1ng th\xE1i \"Ch\u1EDD c\u1EEDa h\xE0ng x\xE1c nh\u1EADn\"";
      break;
    case OrderLoggingType.Waiting:
      msg = "Tr\u1EA1ng th\xE1i \"Ch\u1EDD c\u1EEDa h\xE0ng x\xE1c nh\u1EADn\"";
      break;
    case OrderLoggingType.DriverAutoAccept:
      msg = "\u0110\u01A1n t\u1EF1 \u0111\u1ED9ng g\xE1n cho t\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone);
      break;
    case OrderLoggingType.Run:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " chuy\u1EC3n tr\u1EA1ng th\xE1i \u0111\u01A1n sang \"\u0110ang giao\"");
      break;
    case OrderLoggingType.UploadPickImage:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " upload \u1EA3nh nh\u1EADn h\xE0ng");
      break;
    case OrderLoggingType.StaffComplete:
      msg = "Nh\xE2n vi\xEAn ".concat(log.staff.name, " - ").concat(log.staff.phone, " ho\xE0n th\xE0nh \u0111\u01A1n");
      break;
    case OrderLoggingType.PendingPayment:
      msg = "Trang th\xE1i \"Ch\u1EDD thanh to\xE1n\"";
      break;
    case OrderLoggingType.UploadSenderIdentificationImage:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " upload \u1EA3nh CMND");
      break;
    case OrderLoggingType.UploadDeliveredImage:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " upload \u1EA3nh giao h\xE0ng");
      break;
    case OrderLoggingType.DriverPaymentOrderFoodBalance:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " thanh to\xE1n \u0111\u01A1n h\xE0ng b\u1EB1ng t\u1EE7");
      break;
    case OrderLoggingType.DriverPaymentOrderFoodCash:
      msg = "T\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone, " thanh to\xE1n \u0111\u01A1n h\xE0ng b\u1EB1ng t.m\u1EB7t");
      break;
    case OrderLoggingType.RefundDriverPaymentOrderFood:
      msg = "Ho\xE0n ti\u1EC1n cho t\xE0i x\u1EBF ".concat(log.driver.name, " - ").concat(log.driver.phone);
      break;
    case OrderLoggingType.RefundCustomerPaymentOrderBalance:
      msg = "Ho\xE0n ti\u1EC1n v\xE0o t\u1EE7 cho kh\xE1ch";
      break;
    case OrderLoggingType.StoreCommission:
      msg = "Chi\u1EBFt kh\u1EA5u c\u1EE7a c\u1EEDa h\xE0ng";
      break;
    case OrderLoggingType.StoreReceiveMoneyProduct:
      msg = "C\u1EEDa h\xE0ng nh\u1EADn ti\u1EC1n h\xE0ng";
      break;
    case OrderLoggingType.CustomerPaymentOrderBalance:
      msg = "Kh\xE1ch h\xE0ng thanh to\xE1n t\u1EE7";
      break;
    case OrderLoggingType.DriverCommission:
      msg = "Chi\u1EBFt kh\u1EA5u c\u1EE7a t\xE0i x\u1EBF";
      break;
    case OrderLoggingType.NotFoundDriver:
      msg = "Kh\xF4ng t\xECm th\u1EA5y t\xE0i x\u1EBF";
      break;
    case OrderLoggingType.RefundDriverPaymentOrderFoodBalance:
      msg = "Ho\xE0n ti\u1EC1n h\xE0ng cho t\xE0i x\u1EBF \u0111\u01A1n th\u1EE9c \u0103n";
      break;
    case OrderLoggingType.DriverPaymentMoneyProductDeliveryOrder:
      msg = "T\xE0i x\u1EBF thanh to\xE1n ti\u1EC1n h\xE0ng \u0111\u01A1n delivery cho store";
      break;
    case OrderLoggingType.RefundDriverPaymentMoneyProductDeliveryOrder:
      msg = "Ho\xE0n ti\u1EC1n h\xE0ng cho t\xE0i x\u1EBF khi \u0111\u01A1n b\u1ECB hu\u1EF7";
      break;
    case OrderLoggingType.DriverRefundDeliveryOrderDetail:
      msg = "T\xE0i x\u1EBF ho\xE0n \u0111\u01A1n chi ti\u1EBFt c\u1EE7a giao h\xE0ng";
      break;
    case OrderLoggingType.BackShipFee:
      msg = "Tr\u1EA3 l\u1EA1i ti\u1EC1n ship";
      break;
    case OrderLoggingType.RefundShipFeeDriver:
      msg = "Ho\xE0n tr\u1EA3 ti\u1EC1n ship khi b\u1ECB bom";
      break;
    case OrderLoggingType.CompleteDeliveryOrderDetail:
      msg = "Ho\xE0n th\xE0nh 1 \u0111i\u1EC3m giao";
      break;
    case OrderLoggingType.SubtractMoneyProductStore:
      msg = "Tr\u1EEB l\u1EA1i ti\u1EC1n h\xE0ng c\u1EE7a store khi \u0111\u01A1n b\u1ECB h\u1EE7y";
      break;
    case OrderLoggingType.RefundShipFeeStore:
      msg = "Ho\xE0n ti\u1EC1n ship cho c\u1EEDa h\xE0ng";
      break;
    case OrderLoggingType.StoreCooked:
      msg = "C\u1EEDa h\xE0ng \u0111\xE3 ch\u1EBF bi\u1EBFn xong";
      break;
    case OrderLoggingType.CreateOrder:
      msg = "Kh\xE1ch h\xE0ng t\u1EA1o \u0111\u01A1n h\xE0ng";
      break;
    case OrderLoggingType.RefundStoreCommission:
      msg = "Ho\xE0n ti\u1EC1n thanh to\xE1n chi\u1EBFt kh\u1EA5u (F&B)";
      break;
  }
  return msg;
};