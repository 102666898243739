var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "filter-container" }, [
      _c(
        "div",
        { staticClass: "filter-item" },
        [
          _c("label", { attrs: { for: "" } }, [_vm._v("Tỉnh thành")]),
          _c(
            "el-select",
            {
              attrs: {
                filterable: "",
                placeholder: "",
                disabled: _vm.$store.state.user.info.city,
              },
              model: {
                value: _vm.cityId,
                callback: function ($$v) {
                  _vm.cityId = $$v
                },
                expression: "cityId",
              },
            },
            _vm._l(_vm.cities, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      [
        _c("h3", [_vm._v(" Thưởng Đảm Bảo Thu Nhập Cho Mỗi Giờ Hoạt Động ")]),
        _c("guarantee-bonus-every-hour-page", {
          attrs: { cityId: _vm.cityId },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "32px" } },
      [
        _c("h3", [_vm._v(" Thưởng giá cước cuốc xe theo khung giờ ")]),
        _c("guarantee-bonus-time-frame-page", {
          attrs: { cityId: _vm.cityId },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "32px" } },
      [
        _c("h3", [_vm._v(" Thưởng đảm bảo doanh thu (cuốc ngắn) ")]),
        _c("guarantee-bonus-total-page", { attrs: { cityId: _vm.cityId } }),
      ],
      1
    ),
    _c(
      "div",
      { staticStyle: { "margin-top": "32px" } },
      [
        _c("h3", [_vm._v(" Thưởng doanh thu ")]),
        _c("guarantee-bonus-sale", { attrs: { cityId: _vm.cityId } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }